import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { DeliveryManagerSetting } from 'app/model/entity/delivery-manager-setting';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeliveryManagerSettingService {
  /**
   * URL for delivery-manager-setting API
   */
  deliveryManagerSettingUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/delivery-manager-setting';
  constructor(private http: HttpClient) {}

  /**
   * Be used by DELIVERY-MANAGER
   * get delivery manager setting
   */
  getDeliveryManagerSetting(): Observable<DeliveryManagerSetting> {
    return this.http.get<DeliveryManagerSetting>(`${this.deliveryManagerSettingUrl}/get-setting`);
  }

  /**
   * Be used by DELIVERY-MANAGER
   * save delivery manager setting
   */
  save(deliveryManagerSetting: DeliveryManagerSetting): Observable<DeliveryManagerSetting> {
    return this.http.post<DeliveryManagerSetting>(`${this.deliveryManagerSettingUrl}/save`, deliveryManagerSetting);
  }
}
