import { Injectable } from '@angular/core';
import { ValidationErrors, FormControl } from '@angular/forms';
import { Constant, TemplateModeEnum } from 'app/config/constants';
import { Media } from 'app/model/entity/media';
import { TemplateGroup } from 'app/model/entity/template-group';
import { Template } from '../model/entity/template';

@Injectable({
  providedIn: 'root'
})
export class ValidationFormService {
  constructor() {}

  public getValidatorErrorMessage(propertyName: string, validatorName: string, validatorValue?: any) {
    let config = {
      invalidEmpty: `${propertyName} cannot be empty.`,
      existName: `${propertyName} already exists.`,
      maxlength: `${propertyName} must contain no more than ${validatorValue.requiredLength} characters.`,
      minlength: `${propertyName} must contain at least ${validatorValue.requiredLength} characters.`,
      min: `${propertyName} must not be less than ${validatorValue.min}.`,
      max: `${propertyName} must not be greater than ${validatorValue.max}.`,
      invalidPositiveInteger: `${propertyName} must be a positive integer.`,
      invalidSpecialCharacter: `Sequence name cannot contain any of the following characters: ! @ # $ % ^ & * ( ) , . ? " : { } | < > / \\`,
      checkTimeHourMinuteQ: `${propertyName} is invalid`
    };
    return config[validatorName];
  }

  public invalidEmpty(control): ValidationErrors {
    var value = control.value;
    if (value != null && value !== '' && String(value).trim() != '') {
      return null;
    } else {
      return { invalidEmpty: true };
    }
  }

  public invalidSpecialCharacter(control): ValidationErrors {
    var value = control.value;
    if (String(value).match(Constant.FORMAT) || String(value).includes('\\')) {
      return { invalidSpecialCharacter: true };
    } else {
      return null;
    }
  }

  public existTemplateGroupName(templateGroups: Array<any>, templateMode: any) {
    return (control: FormControl) => {
      var value = control.value;
      if (
        templateGroups
          ?.filter(group => group.templateMode == templateMode)
          .findIndex(templateGroup => templateGroup.name == String(value)) == -1
      ) {
        return null;
      } else {
        return { existName: true };
      }
    };
  }

  public existTemplateName(templatesOfGroup: Array<any>) {
    return (control: FormControl) => {
      var value = control.value;
      if (templatesOfGroup.findIndex(template => template.name == String(value)) == -1) {
        return null;
      } else {
        return { existName: true };
      }
    };
  }

  public existMediaName(mediaList: Array<Media>) {
    return (control: FormControl) => {
      var value = control.value;
      if (mediaList.findIndex(media => media.name == String(value).trim()) == -1) {
        return null;
      } else {
        return { existName: true };
      }
    };
  }

  /**
   * validate max length
   * @param maxLength
   * @returns
   */
  public maxLength(maxLength: Number): any {
    return (control: FormControl) => {
      if (String(control.value).trim().length == 0) {
        return null;
      }
      if (String(control.value).length <= maxLength) {
        return null;
      }
      if (String(control.value).length > maxLength) {
        return { maxlength: true };
      }
    };
  }

  /**
   * check time hour minute
   * @param time
   * @returns
   */
  public checkTimeHourMinute(control): any {
    if (!String(control.value).match(Constant.FORMAT_HOUR_MINUTE_REGEX)) {
      console.log(typeof control.value);
      console.log(control.value + ':sai format');
      return { checkTimeHourMinuteQ: true };
    } else {
      console.log(control.value + ':dung format');
      return null;
    }
  }
}
