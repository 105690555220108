import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { NewsContent, ReferencePosition } from 'app/model/entity/news-content';
import { Template } from 'app/model/entity/template';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsContentService {
  /**
   * URL for news-content API
   */
  private readonly newsContentUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/news-contents';

  constructor(private http: HttpClient) {}

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * get all news contents
   */
  getNewsContents(): Observable<NewsContent[]> {
    return this.http.get<NewsContent[]>(`${this.newsContentUrl}/get-all-news-contents`);
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * get news content with full data template
   *
   * @param newsContentId
   */
  getNewsContentWithFullDataTemplateById(newsContentId: Number): Observable<NewsContent> {
    const params = new HttpParams().set('newsContentId', newsContentId + '');
    return this.http.get<NewsContent>(`${this.newsContentUrl}/get-news-content-with-full-data-template-by-id`, { params });
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * add news content
   *
   * @param newsContent
   */
  addNewsContent(newsContent: NewsContent): Observable<NewsContent> {
    return this.http.post<NewsContent>(`${this.newsContentUrl}/add`, newsContent);
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * edit news content
   *
   * @param newsContent
   */
  editNewsContent(newsContent: NewsContent): Observable<NewsContent> {
    return this.http.put<NewsContent>(`${this.newsContentUrl}/edit`, newsContent);
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * delete news content
   *
   * @param newsContent
   */
  deleteNewsContent(newsContentId: Number) {
    const params = new HttpParams().set('newsContentId', newsContentId + '');
    return this.http.delete(`${this.newsContentUrl}/delete`, { params });
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * change template news content
   *
   * @param newsContent
   */
  changeTemplateForNewsContent(newsContent: NewsContent) {
    return this.http.post(`${this.newsContentUrl}/change-template`, newsContent);
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * create output file
   *
   * @param content
   */
  createOutputFile(id: number, referencePositions: ReferencePosition[]) {
    const params = new HttpParams().set('newsContentId', id + '');
    return this.http.post(`${this.newsContentUrl}/create-output-file`, referencePositions, { params });
  }
}
