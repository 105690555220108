import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { AreaLED } from 'app/model/entity/led/area-led';
import { DataResponseArea } from 'app/model/entity/led/data-response-area';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for Area LED action
 */
export class AreaLEDService {
  /**
   * URL
   */
  areaUrl = Constant.BACKEND_URL + Constant.EDS_SERVICE_URL + 'api/areas';

  constructor(private http: HttpClient) {}

  /**
   * get areas by template id
   * @param templateId
   * @returns
   */
  public getAreasByTemplateId(templateId: Number): Observable<any> {
    return this.http.post<any>(`${this.areaUrl}/get-areas-by-template-id`, templateId);
  }

  /**
   * update detailed areas
   * @param dataResponse
   * @param templateId
   * @returns
   */
  public updateDetailedAreas(dataResponse: DataResponseArea, templateId: Number): Observable<any> {
    const params = new HttpParams().set('templateId', templateId + '');
    return this.http.post<any>(`${this.areaUrl}/update-details-areas`, dataResponse, { params });
  }

  /**
   * Get all area for route
   *
   * @param templateIds
   * @returns
   */
  public getAllAreaForRoute(templateIds: Number[]): Observable<any[]> {
    return this.http.post<any[]>(`${this.areaUrl}/get-all-area-for-route`, templateIds);
  }
}
