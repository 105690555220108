import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { HttpClient } from '@angular/common/http';
import { Layer } from 'app/model/entity/layer';

@Injectable({
  providedIn: 'root'
})
export class LayerService {
  private layerUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/layers';

  constructor(private http: HttpClient) {}

  addLayer(layer: Layer) {
    return this.http.post(this.layerUrl, layer);
  }
}
