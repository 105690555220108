import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DailySchedule, Program } from '../model/entity/playlist';
import { Constant } from 'app/config/constants';
import { Channel } from 'app/model/entity/channel';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for signage playlist action
 */
export class SignageDailyScheduleService {
  /**
   * URL for signage playlist API
   */
  dailyScheduleUrl = Constant.BACKEND_URL + Constant.DSC_SERVICE_URL + 'api/daily-schedules';

  constructor(private http: HttpClient) {}

  /**
   * Be used by DIGITAL-SIGNAGE-DISPLAY-COMPONENT
   * get daily schedules
   *
   * @param projectId id's project
   */
  getDailySchedulesByProjectId(projectId: Number): Observable<DailySchedule[]> {
    const params = new HttpParams().set('projectId', projectId + '');
    return this.http.get<DailySchedule[]>(
      `${this.dailyScheduleUrl}/${Constant.DIGITAL_SIGNAGE_CHANNEL_EDITOR_COMPONENT_URL}/get-daily-schedules-by-project-id`,
      {
        params
      }
    );
  }

  /**
   * Be used by DIGITAL-SIGNAGE-DISPLAY-COMPONENT
   * add new daily schedule
   *
   * @param {DailySchedule} dailySchedule new dailySchedule
   */
  addDailySchedule(dailySchedule: DailySchedule): Observable<DailySchedule> {
    return this.http.post<DailySchedule>(
      `${this.dailyScheduleUrl}/${Constant.DIGITAL_SIGNAGE_CHANNEL_EDITOR_COMPONENT_URL}/add`,
      dailySchedule
    );
  }

  /**
   * Be used by DIGITAL-SIGNAGE-DISPLAY-COMPONENT
   * edit daily schedule
   *
   * @param {DailySchedule} dailySchedule dailySchedule selected
   */
  editDailySchedule(dailySchedule: DailySchedule): Observable<DailySchedule> {
    return this.http.put<DailySchedule>(
      `${this.dailyScheduleUrl}/${Constant.DIGITAL_SIGNAGE_CHANNEL_EDITOR_COMPONENT_URL}/edit`,
      dailySchedule
    );
  }

  /**
   * Be used by DIGITAL-SIGNAGE-DISPLAY-COMPONENT
   * delete daily schedule
   *
   * @param {DailySchedule} dailySchedule dailySchedule selected
   */
  deleteDailySchedule(dailySchedule: DailySchedule) {
    const params = new HttpParams().set('dailyScheduleId', dailySchedule.id + '');
    return this.http.delete(`${this.dailyScheduleUrl}/${Constant.DIGITAL_SIGNAGE_CHANNEL_EDITOR_COMPONENT_URL}/delete`, {
      params
    });
  }

  /**
   * Be used by DIGITAL-SIGNAGE-DISPLAY-COMPONENT
   * update programs
   *
   * @param dailySchedule
   */
  updateProgramsOfDailySchedule(dailySchedule: DailySchedule) {
    return this.http.post<DailySchedule>(
      `${this.dailyScheduleUrl}/${Constant.DIGITAL_SIGNAGE_CHANNEL_EDITOR_COMPONENT_URL}/update-programs-for-daily-schedule`,
      dailySchedule
    );
  }
}
