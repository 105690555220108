import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConditionFilterMediaLog } from 'app/model/entity/condition-filter-medialog';
import { Observable } from 'rxjs';
import { Constant } from '../config/constants';

@Injectable({
  providedIn: 'root'
})
/**
 * Class ConditionFilterMediaLogService
 */
export class ConditionFilterMediaLogService {
  /**
   * condition filter media log url
   */
  private conditionFilterMediaLogUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/condition-filter-media-log';

  constructor(private http: HttpClient) {}

  /**
   * save condition filter media log
   * @param condition
   * @returns
   */
  public saveConditionFilterMediaLog(condition: ConditionFilterMediaLog) {
    return this.http.post<ConditionFilterMediaLog>(`${this.conditionFilterMediaLogUrl}/save-condition-filter-media-log`, condition);
  }

  /**
   * get condition filter media log by user id
   * @param condition
   * @returns
   */
  public getConditionFilterMediaLogByUserId(userId: number): Observable<ConditionFilterMediaLog> {
    return this.http.get<ConditionFilterMediaLog>(`${this.conditionFilterMediaLogUrl}/get-condition-filter-media-log-by-user-id/${userId}`);
  }

  /**
   * delete condition filter media log
   * @param condition
   * @returns
   */
  public deleteConditionFilterMediaLog(condition: ConditionFilterMediaLog) {
    return this.http.post(`${this.conditionFilterMediaLogUrl}/delete-condition-filter-media-log`, condition);
  }
}
