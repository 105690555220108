import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constant } from 'app/config/constants';
import { PublishInfo } from 'app/model/entity/publish-info';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for publish info action
 */
export class PublishInfoService {
  /**
   * URL of publish info API
   */
  publishInfoUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/publish-infos';

  constructor(private http: HttpClient) {}

  /**
   * Be used by PROJECT-MANAGER-COMPONENT
   * delete publish info
   *
   * @param listId list id publish info need to delete
   */
  deleteListPublishInfo(listId: Array<number>) {
    return this.http.delete(`${this.publishInfoUrl}/${Constant.PROJECT_MANAGER_COMPONENT_URL}/delete-publish-infos/${listId}`);
  }

  /**
   * Be used by PROJECT-MANAGER-COMPONENT
   * get publish infos by projectId and type
   *
   * @param projectId id's project is selected
   * @param typeFile BID/DSC/EDS/BSD/SIGD
   */
  getPublishInfosByProjectIdAndType(projectId: Number, typeFile: string): Observable<PublishInfo[]> {
    const params = new HttpParams().set('projectId', projectId + '').set('type', typeFile);
    return this.http.get<PublishInfo[]>(
      `${this.publishInfoUrl}/${Constant.PROJECT_MANAGER_COMPONENT_URL}/get-publish-infos-by-project-id-and-type`,
      { params }
    );
  }
}
