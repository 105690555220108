import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { NewsContentDetail } from 'app/model/entity/news-content-detail';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsContentDetailService {
  /**
   * URL for news-content-detail API
   */
  private readonly newsContentDetailUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/news-content-details';

  constructor(private http: HttpClient) {}

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * get news content details by content id
   *
   * @param contentId
   */
  getNewsContentDetailsByContentId(contentId: Number): Observable<NewsContentDetail[]> {
    const params = new HttpParams().set('contentId', contentId + '');
    return this.http.get<NewsContentDetail[]>(`${this.newsContentDetailUrl}/get-news-content-details-by-content-id`, { params });
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * save news content details
   *
   * @param newsContentDetails
   */
  saveNewsContentDetail(newsContentDetails: NewsContentDetail[]) {
    return this.http.post<NewsContentDetail[]>(`${this.newsContentDetailUrl}/save-news-content-details`, newsContentDetails);
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * reload data
   *
   * @param newsContentDetails
   */
  reloadNewsContentDetails(newsContentDetails: NewsContentDetail[]) {
    return this.http.post<NewsContentDetail[]>(`${this.newsContentDetailUrl}/reload-news-content-details`, newsContentDetails);
  }
}
