import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { EmergencyData } from 'app/model/entity/emergency-data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for emergency data action
 */
export class EmergencyDataService {
  /**
   * URL of emergency data API
   */
  emergencyDataUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/emergency-data';

  constructor(private http: HttpClient) {}

  /**
   * save emergency data
   * @param customTag new custom tag need to add
   */
  save(emergencyData: EmergencyData): Observable<EmergencyData> {
    return this.http.post<EmergencyData>(this.emergencyDataUrl, emergencyData);
  }

  /**
   * get emergency data
   */
  getEmergencyData(): Observable<EmergencyData[]> {
    return this.http.get<EmergencyData[]>(this.emergencyDataUrl);
  }

  /**
   * save emergency data
   * @param customTag new custom tag need to add
   */
  saveSchedule(emergencyData: EmergencyData): Observable<EmergencyData> {
    return this.http.post<EmergencyData>(`${this.emergencyDataUrl}-schedule`, emergencyData);
  }

  /**
   * get emergency data
   */
  getEmergencyScheduleData(): Observable<EmergencyData[]> {
    return this.http.get<EmergencyData[]>(`${this.emergencyDataUrl}-schedule`);
  }
}
