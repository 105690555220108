import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { text } from '@fortawesome/fontawesome-svg-core';
import { Constant } from 'app/config/constants';
import { DeliveryGroupDevice } from 'app/dialog/dialog-delivery-timetable/dialog-delivery-timetable.component';
import { Device } from 'app/model/entity/device';
import { DevicePublishInfo } from 'app/model/entity/device-publish-info';
import { PayloadDelivery, ResponsePublish } from 'app/model/entity/publish-info-timetable';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for device action
 */
export class DevicePublishInfoService {
  /**
   * URL of device publish info API
   */
  devicePublishUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/device-publish-infos';
  /**
   * user name
   */
  userName: string;
  constructor(private http: HttpClient, private commonService: CommonService) {
    this.userName = commonService.getCommonObject().userName;
  }

  /**
   * edit device publish info
   * @param Device device publish info need to edit
   */
  editDevicePublishInfo(devicePublishInfo: DevicePublishInfo): Observable<DevicePublishInfo> {
    return this.http.put<DevicePublishInfo>(this.devicePublishUrl, devicePublishInfo);
  }

  /**
   * add new device publish info
   * @param device device publish info need to add
   */
  addDevicePublishInfo(devicePublishInfo: DevicePublishInfo) {
    return this.http.post<DevicePublishInfo>(this.devicePublishUrl, devicePublishInfo);
  }

  /**
   *
   * @param devicePublishInfos
   */
  saveDevicePublishInfo(devicePublishInfos: DevicePublishInfo[]) {
    return this.http.post<DevicePublishInfo[]>(this.devicePublishUrl, devicePublishInfos);
  }

  /**
   * delivery
   *
   * @param device device publish info need to add
   */
  delivery(listDeviceSelected: Device[]): Observable<any> {
    const params = new HttpParams().set('author', this.userName);
    return this.http.post<Observable<any>>(`${this.devicePublishUrl}/delivery`, listDeviceSelected, { params });
  }

  /**
   * delete archive note
   *
   */
  deleteArchiveNote() {
    return this.http.delete(`${this.devicePublishUrl}/delete-archive-note`);
  }

  /**
   * drop publish information
   *
   * @param idPublishFile
   * @param idsDevice
   * @returns
   */
  dropPublishInfo(idPublishFile: number, idsDevice: string): Observable<Device[]> {
    const params = new HttpParams().set('idPublishFile', idPublishFile + '').set('idsDevice', idsDevice);
    return this.http.get<Device[]>(`${this.devicePublishUrl}/drop-publish-file`, { params });
  }

  /**
   * delivery for timetable editor
   *
   * @param responsePublish
   */
  deliveryForTimetable(responsePublish: ResponsePublish): Observable<Array<string>> {
    const params = new HttpParams().set('username', this.userName);
    return this.http.post<Array<string>>(`${this.devicePublishUrl}/delivery-for-timetable`, responsePublish, { params });
  }

  /**
   * delivery for simple signage editor
   *
   * @param responsePublish
   */
  deliveryForSimpleSignage(responsePublish: ResponsePublish): Observable<any> {
    const params = new HttpParams()
      .set('username', this.userName)
      .set('deviceId', responsePublish.deviceId + '')
      .set('publishFileName', responsePublish.publishFileName);
    return this.http.get<Observable<any>>(`${this.devicePublishUrl}/delivery-for-simple-signage`, { params });
  }

  /**
   * Call customer API for group devices
   *
   * @param deviceDeliveryGroups
   */
  public callAPIForGroupDevices(deviceDeliveryGroups: Array<DeliveryGroupDevice>): Observable<Array<string>> {
    return this.http.post<Array<string>>(`${this.devicePublishUrl}/call-api-for-group-devices-timetable`, deviceDeliveryGroups);
  }

  /**
   * Call customer API for single devices
   *
   * @param deviceIds
   */
  public callAPIForSingleDevices(deviceIds: Array<Number>): Observable<Array<string>> {
    return this.http.post<Array<string>>(`${this.devicePublishUrl}/call-api-for-single-devices-timetable`, deviceIds);
  }

  /**
   * delivery for simple signage editor
   *
   * @param responsePublish
   */
  deliveryForAnnouncement(responsePublish: ResponsePublish): Observable<any> {
    const params = new HttpParams()
      .set('username', this.userName)
      .set('deviceId', responsePublish.deviceId + '')
      .set('publishFileName', responsePublish.publishFileName);
    return this.http.get<Observable<any>>(`${this.devicePublishUrl}/delivery-for-announcement`, { params });
  }
}
