import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SortFilterObject } from 'app/model/entity/sort-filter-object';
import { AppState } from 'app/store/app.state';

@Injectable({
  providedIn: 'root'
})
export class SortFilterService {
  /**
   * Sort filter
   */
  private sortFilter: SortFilterObject;
  constructor(public readonly store: Store<AppState>) {
    this.store
      .select(state => state)
      .subscribe((componentState: any) => {
        this.sortFilter = componentState?.sortFilterState?.stateOfComponent?.sortFilterTimetable ?? new SortFilterObject();
      });
  }

  /**
   * Get sort filter object
   *
   * @returns
   */
  public getSortFilterObject(): SortFilterObject {
    return this.sortFilter;
  }
}
