import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { Folder } from 'app/model/entity/simple/folder';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for simple folder media action
 */
export class SimpleFolderMediaService {
  /**
   * URL for common simple folder
   */
  simpleFolderMediaUrl = Constant.BACKEND_URL + Constant.DSC_SERVICE_URL + 'api/simple-folder-medias';

  constructor(private http: HttpClient) {}

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * get all folders
   *
   * @returns
   */
  getAllFolderMedias(): Observable<Array<Folder>> {
    return this.http.get<Array<Folder>>(`${this.simpleFolderMediaUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/get-all`);
  }

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * save simple folder media
   *
   * @param simpleFolderMedia
   * @returns
   */
  save(simpleFolderMedia: Folder): Observable<Folder> {
    return this.http.post<Folder>(`${this.simpleFolderMediaUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/save`, simpleFolderMedia);
  }

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * check exist folder
   *
   * @param name
   * @param id
   */
  checkExistFolder(name: string, id: Number): Observable<boolean> {
    const params = new HttpParams().set('name', name).set('id', `${id}`);
    return this.http.get<boolean>(`${this.simpleFolderMediaUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/check-exist-folder`, { params });
  }

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * delete folder media
   *
   * @param id
   * @returns
   */
  deleteFolder(id: Number) {
    const params = new HttpParams().set('folderId', `${id}`);
    return this.http.delete(`${this.simpleFolderMediaUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/delete`, {
      params
    });
  }
}
