import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { Style } from 'app/model/entity/style';
import { StyleDetail } from 'app/model/entity/style-detail';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Class StyleServive
 */
export class StyleServive {
  /**
   * Style url
   */
  private readonly styleUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/styles';

  constructor(private http: HttpClient) {}

  /**
   * Be used by SIGNAGE-DISPLAY-EDITOR-COMPONENT
   * get styles
   *
   * @param projectId project id
   */
  getStylesByProjectId(projectId: Number): Observable<Array<Style>> {
    const params = new HttpParams().set('projectId', projectId + '');
    return this.http.get<Array<Style>>(`${this.styleUrl}/${Constant.SIGNAGE_DISPLAY_EDITOR_COMPONENT_URL}/get-styles-by-project-id`, {
      params
    });
  }

  /**
   * Be used by SIGNAGE-DISPLAY-EDITOR-COMPONENT
   * get style with full data template by id
   *
   * @param styleId style id
   */
  getStyleWithFullDataTemplateById(styleId: Number): Observable<Style> {
    const params = new HttpParams().set('styleId', styleId + '');
    return this.http.get<Style>(`${this.styleUrl}/${Constant.SIGNAGE_DISPLAY_EDITOR_COMPONENT_URL}/get-style-with-full-data-template`, {
      params
    });
  }

  /**
   * Be used by SIGNAGE-DISPLAY-EDITOR-COMPONENT
   * delete styles
   *
   * @param styles list styles checked
   */
  deleteStyles(styleIds: Array<Number>) {
    return this.http.post(`${this.styleUrl}/${Constant.SIGNAGE_DISPLAY_EDITOR_COMPONENT_URL}/delete-styles`, styleIds);
  }

  /**
   * Be used by SIGNAGE-DISPLAY-EDITOR-COMPONENT
   * save style
   *
   * @param style
   */
  saveStyle(style: Style): Observable<Style> {
    return this.http.post<Style>(`${this.styleUrl}/${Constant.SIGNAGE_DISPLAY_EDITOR_COMPONENT_URL}/save`, style);
  }

  /**
   * Be used by SIGNAGE-DISPLAY-EDITOR-COMPONENT
   * duplicate style
   *
   * @param style
   */
  duplicateStyle(style: Style, oldStyleId: Number): Observable<Style> {
    const params = new HttpParams().set('oldStyleId', `${oldStyleId}`);
    return this.http.post<Style>(`${this.styleUrl}/${Constant.SIGNAGE_DISPLAY_EDITOR_COMPONENT_URL}/duplicate-style`, style, { params });
  }

  /**
   * Be used by SIGNAGE-DISPLAY-EDITOR-COMPONENT
   * update templates for style
   *
   * @param style
   */
  updateTemplatesForStyle(style: Style): Observable<Style> {
    return this.http.post<Style>(`${this.styleUrl}/${Constant.SIGNAGE_DISPLAY_EDITOR_COMPONENT_URL}/update-templates-for-style`, style);
  }

  /**
   * Be used by SIGNAGE-DISPLAY-EDITOR-COMPONENT
   * update templates for styles
   *
   * @param style
   */
  updateTemplatesForStyles(styles: Array<Style>): Observable<Array<Style>> {
    return this.http.post<Array<Style>>(
      `${this.styleUrl}/${Constant.SIGNAGE_DISPLAY_EDITOR_COMPONENT_URL}/update-templates-for-styles`,
      styles
    );
  }

  /**
   * Be used by SIGNAGE-DISPLAY-EDITOR-COMPONENT
   * check exist style
   *
   * @param id
   * @param styleNo
   * @param suffix
   */
  checkExistStyle(styleNo: string, suffix: string, projectId: Number, id: Number): Observable<boolean> {
    const params = new HttpParams()
      .set('styleNo', styleNo)
      .set('suffix', suffix)
      .set('projectId', `${projectId}`)
      .set('id', `${id}`);
    return this.http.get<boolean>(`${this.styleUrl}/${Constant.SIGNAGE_DISPLAY_EDITOR_COMPONENT_URL}/check-exist-style`, { params });
  }
}
