import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConditionFilterDeviceLog } from 'app/model/entity/condition-filter-device-log';
import { Observable } from 'rxjs';
import { Constant } from '../config/constants';

@Injectable({
  providedIn: 'root'
})
/**
 * Class ConditionFilterDeviceLogService
 */
export class ConditionFilterDeviceLogService {
  /**
   * condition filter device log url
   */
  private conditionFilterDeviceLogUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/condition-filter-device-log';

  constructor(private http: HttpClient) {}

  /**
   * save condition filter device log
   * @param condition
   * @returns
   */
  public saveConditionFilterDeviceLog(condition: ConditionFilterDeviceLog): Observable<any> {
    return this.http.post<any>(`${this.conditionFilterDeviceLogUrl}/save-condition-filter-device-log`, condition);
  }

  /**
   * get condition filter device log by user id
   * @param condition
   * @returns
   */
  public getConditionFilterDeviceLogByUserId(userId: number): Observable<any> {
    return this.http.get<any>(`${this.conditionFilterDeviceLogUrl}/get-condition-filter-device-log-by-user-id/${userId}`);
  }

  /**
   * delete condition filter device log
   * @param condition
   * @returns
   */
  public deleteConditionFilterDeviceLog(condition: ConditionFilterDeviceLog) {
    return this.http.post(`${this.conditionFilterDeviceLogUrl}/delete-condition-filter-device-log`, condition);
  }
}
