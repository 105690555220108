import { ScheduleRegistrationDetail } from './../model/entity/schedule-registration-detail';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { DisplaySettingSchedule } from 'app/model/entity/schedule/display-setting-schedule';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';
import { RegexTime } from 'app/module/timetable-editor/timetable-editor.component';

@Injectable({
  providedIn: 'root'
})
export class ScheduleRegistrationService {
  private scheduleRegistrationUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/schedule-registration';

  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * get all schedule registration
   * @returns
   */
  public getScheduleByElement(routeId: any): Observable<any> {
    const params = new HttpParams().set('routeId', routeId);
    return this.http.get<any>(`${this.scheduleRegistrationUrl}/get-all-schedule-registration`, { params });
  }

  /**
   * read excel from client
   * @param files
   * @returns
   */
  public readExcelFromClient(files: File[], regexTime: RegexTime, timeDateLine: string, routeId: any): Observable<any> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    formData.append('regexTime', JSON.stringify(regexTime));
    formData.append('timeDateLine', timeDateLine);
    formData.append('routeId', routeId);
    return this.http.post<any>(`${this.scheduleRegistrationUrl}/read-excels`, formData);
  }

  /**
   * get display setting schedule registration
   * @returns
   */
  public getDisplaySettingSchedule(routeId: any): Observable<any> {
    const params = new HttpParams().set('routeId', routeId);
    return this.http.get<any>(`${this.scheduleRegistrationUrl}/get-display-setting-template`, { params });
  }

  /**
   * save display setting schedule registration
   * @param displaySettingSchedule
   * @returns
   */
  public saveDisplaySettingSchedule(displaySettingSchedule: DisplaySettingSchedule): Observable<any> {
    return this.http.post<any>(`${this.scheduleRegistrationUrl}/save-display-setting-template`, displaySettingSchedule);
  }
  /**
   * Be used by SCHEDULE-REGISTRATION-COMPONENT
   * write excel
   *
   * @param schedule
   * @returns
   */
  writeExcel(scheduleId: Number, routeId: any): Observable<any> {
    const params = new HttpParams().set('language', this.commonService.getCommonObject().setting?.language).set('routeId', routeId);
    return this.http.post(`${this.scheduleRegistrationUrl}/write-excel`, scheduleId, {
      responseType: 'blob',
      observe: 'response',
      params: params
    });
  }

  /**
   * overWritescheduleRegistrationDetail
   * @param timetables
   * @returns
   */
  public overWriteTimetables(routeId: any): Observable<any> {
    const params = new HttpParams().set('routeId', routeId);
    return this.http.get<any>(`${this.scheduleRegistrationUrl}/overwrite`, { params });
  }

  /**
   * addscheduleRegistrationDetail
   * @param timetables
   * @returns
   */
  public addTimetables(): Observable<any> {
    return this.http.get<any>(`${this.scheduleRegistrationUrl}/add-schedule`);
  }

  /**
   * Be used by SCHEDULE-REGISTRATION-COMPONENT
   * check exist timetable
   *
   * @param id
   * @param no
   * @param suffix
   */
  public checkExistTimetable(no: string, suffix: string, id: Number, routeId: any): Observable<boolean> {
    const params = new HttpParams()
      .set('no', no)
      .set('suffix', suffix)
      .set('id', `${id}`)
      .set('routeId', routeId);
    return this.http.get<boolean>(`${this.scheduleRegistrationUrl}/check-exist-timetable`, {
      params
    });
  }

  /**
   * Be used by SCHEDULE-REGISTRATION-COMPONENT
   * check exist timetable's name
   *
   * @param name
   * @param id
   */
  checkExistTimetableName(name: string, id: Number, routeId: any): Observable<boolean> {
    const params = new HttpParams()
      .set('name', name)
      .set('id', `${id}`)
      .set('routeId', routeId);
    return this.http.get<boolean>(`${this.scheduleRegistrationUrl}/check-exist-timetable-name`, { params });
  }

  /**
   * Be used by SCHEDULE-REGISTRATION-COMPONENT
   * save timetable
   *
   * @param scheduleRegistrationDetail
   */
  saveTimetable(scheduleRegistrationDetail: ScheduleRegistrationDetail): Observable<ScheduleRegistrationDetail> {
    return this.http.post<ScheduleRegistrationDetail>(`${this.scheduleRegistrationUrl}/save`, scheduleRegistrationDetail);
  }

  /**
   * check timetables setting calendar
   * @param timetableIds
   * @returns
   */
  public checkTimetablesSettingCalendar(timetableIds: Array<Number>): Observable<boolean> {
    return this.http.post<boolean>(`${this.scheduleRegistrationUrl}/check-timetables-setting-calendar`, timetableIds);
  }

  /**
   * Be used by SCHEDULE-REGISTRATION-COMPONENT
   * delete timetables
   *
   * @param timetableIds list timetable's id checked
   */
  deleteTimetables(timetableIds: Array<Number>) {
    return this.http.post(`${this.scheduleRegistrationUrl}/delete-timetables`, timetableIds);
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * save label for timetables
   *
   * @param timetables
   */
  saveLabelForTimetables(timetables: Array<ScheduleRegistrationDetail>): Observable<Array<ScheduleRegistrationDetail>> {
    return this.http.post<Array<ScheduleRegistrationDetail>>(`${this.scheduleRegistrationUrl}/save-label-for-timetables`, timetables);
  }

  /**
   * getAreaSwitchingTiming
   */
  getAreaSwitchingTiming(): Observable<any> {
    return this.http.get<any>(`${this.scheduleRegistrationUrl}/get-area-switching-timing`);
  }
}
