import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class ExecutingService {
  readonly subjectExecute = new Subject<boolean>();
  executingRequest = this.subjectExecute.asObservable();

  executing() {
    this.subjectExecute.next(true);
  }
  executed() {
    this.subjectExecute.next(false);
  }
}
