import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { FreeAreaMediaFile } from 'app/model/entity/free-area-media-file';
import { MediaValidator } from 'app/model/entity/media-validator';
import { Sequence } from 'app/model/entity/sequence';
import { StationContentMediaFile } from 'app/model/entity/station-content-media-file';
import { MediaFileDropped } from 'app/module/lcd-layout-editor/lcd-layout-editor.component';
import { Observable } from 'rxjs';
import { Media } from '../model/entity/media';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for media action
 */
export class MediaService {
  /**
   * URL for media API
   */
  mediaUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/media';

  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * Be used MEDIA-MANAGER-COMPONENT
   * get medias
   *
   * @param folderId folder id
   */
  getMediasByFolderId(folderId: Number): Observable<Media[]> {
    const params = new HttpParams().set('folderId', folderId + '');
    return this.http.get<Media[]>(`${this.mediaUrl}/${Constant.MEDIA_MANAGER_COMPONENT_URL}/get-medias-by-folder-id`, { params });
  }

  /**
   * Be used MEDIA-MANAGER-COMPONENT
   * get medias in station content folder
   *
   * @param folderId id folder
   * @returns {Observable<Media[]>} folder station content
   */
  getMediasInStationContentFolderByFolderId(folderId: Number) {
    const params = new HttpParams().set('folderId', folderId + '');
    return this.http.get<Media[]>(`${this.mediaUrl}/${Constant.MEDIA_MANAGER_COMPONENT_URL}/station-content/get-medias-by-folder-id`, {
      params
    });
  }

  /**
   * Be used MEDIA-MANAGER-COMPONENT
   * add media
   *
   * @param file file
   * @param folderId id folder
   */
  addMedia(file: File, folderId: any): Observable<Media> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folderId', folderId);
    return this.http.post<Media>(`${this.mediaUrl}/${Constant.MEDIA_MANAGER_COMPONENT_URL}/add`, formData);
  }

  /**
   * Be used MEDIA-MANAGER-COMPONENT
   * edit media
   *
   * @param media media need to edit
   * @param text
   * @param isEditScript
   */
  editMedia(media: Media, text: string, isEditScript: any): Observable<Media> {
    const params = new HttpParams().set('text', text).set('isEditScript', isEditScript);
    return this.http.put<Media>(`${this.mediaUrl}/${Constant.MEDIA_MANAGER_COMPONENT_URL}/edit`, media, { params });
  }

  /**
   * Be used MEDIA-MANAGER-COMPONENT
   * delete media
   *
   * @param id id media
   */
  deleteMedia(mediaId: Number) {
    const params = new HttpParams().set('mediaId', mediaId + '');
    return this.http.delete(`${this.mediaUrl}/${Constant.MEDIA_MANAGER_COMPONENT_URL}/delete`, { params });
  }

  /**
   * Be used MEDIA-MANAGER-COMPONENT
   * check duplicate media
   *
   * @param media media need to check
   * @returns
   */
  checkDuplicateMedia(media: Media): Observable<boolean> {
    return this.http.post<boolean>(`${this.mediaUrl}/${Constant.MEDIA_MANAGER_COMPONENT_URL}/check-duplicate-media`, media);
  }

  /**
   * Be used MEDIA-MANAGER-COMPONENT
   * add sequence
   *
   * @param sequence Sequence object
   */
  addSequence(sequence: Sequence): Observable<Media> {
    return this.http.post<Media>(`${this.mediaUrl}/${Constant.MEDIA_MANAGER_COMPONENT_URL}/add-sequence`, sequence);
  }

  /**
   * Be used MEDIA-MANAGER-COMPONENT
   * edit sequence
   *
   * @param sequence Sequence object
   */
  editSequence(sequence: Sequence) {
    return this.http.post(`${this.mediaUrl}/${Constant.MEDIA_MANAGER_COMPONENT_URL}/edit-sequence`, sequence);
  }

  /**
   * Be used MEDIA-MANAGER-COMPONENT
   * get media of sequence
   *
   * @param media
   */
  getMediasOfSequence(media: Media): Observable<Sequence> {
    return this.http.post<Sequence>(`${this.mediaUrl}/${Constant.MEDIA_MANAGER_COMPONENT_URL}/get-medias-of-sequence`, media);
  }

  /**
   * Be used STATION-DISPLAY-EDITOR-COMPONENT
   * upload file for station content
   *
   * @param files
   * @param stationContentMediaFiles
   */
  updateStationContentFolderFromPCAndUpdateCell(files: File[], stationContentMediaFiles: Array<StationContentMediaFile>) {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });
    formData.append('stationContentMediaFiles', JSON.stringify(stationContentMediaFiles));
    formData.append('projectName', this.commonService.getCommonObject().projectName);
    return this.http.post(`${this.mediaUrl}/${Constant.STATION_DISPLAY_EDITOR_COMPONENT_URL}/upload-from-PC-and-update-cell`, formData);
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * Upload media file for timetable detail
   *
   * @param files
   * @param freeAreaTimetableMediaFiles
   * @returns
   */
  public uploadMediaFileForTimetableDetail(
    files: File[],
    freeAreaTimetableMediaFiles: Array<FreeAreaMediaFile>
  ): Observable<Array<FreeAreaMediaFile>> {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });
    formData.append('freeAreaTimetableMediaFiles', JSON.stringify(freeAreaTimetableMediaFiles));
    return this.http.post<Array<FreeAreaMediaFile>>(
      `${this.mediaUrl}/${Constant.TIMETABLE_COMPONENT_URL}/upload-media-file-to-free-area-folder`,
      formData
    );
  }

  /**
   * Be used by TIMETABLE-OPERATION-COMPONENT
   * Upload media file for emergency
   *
   * @param files
   * @returns
   */
  public uploadEmergencyMedia(file: File): Observable<Media> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<Media>(`${this.mediaUrl}/${Constant.TIMETABLE_OPERATION_URL}/upload-media-from-pc-to-emergency`, formData);
  }

  /**
   * Be used STATION-DISPLAY-EDITOR-COMPONENT
   * upload media for station content
   *
   * @param stationContentMediaFiles
   */
  uploadStationContentFoldeFromMediaManagerrAndUpdateCell(
    stationContentMediaFiles: Array<StationContentMediaFile>
  ): Observable<Array<Media>> {
    const params = new HttpParams().set('projectName', this.commonService.getCommonObject().projectName);
    return this.http.post<Array<Media>>(
      `${this.mediaUrl}/${Constant.STATION_DISPLAY_EDITOR_COMPONENT_URL}/upload-from-media-manager-and-update-cell`,
      stationContentMediaFiles,
      {
        params
      }
    );
  }

  /**
   * get all owners
   */
  getAllOwners(): Observable<Array<string>> {
    return this.http.get<Array<string>>(`${this.mediaUrl}/get-all-owner`);
  }

  /**
   * Be used SIGNAGE-DISPLAY-COMPONENT
   * get media by id
   *
   * @param mediaId media id
   */
  getMediaById(mediaId: Number): Observable<Media> {
    const params = new HttpParams().set('mediaId', mediaId + '');
    return this.http.get<Media>(`${this.mediaUrl}/${Constant.MEDIA_MANAGER_COMPONENT_URL}/get-media-by-id`, { params });
  }

  /**
   * Be used DASHBOARD-COMPONENT
   * get media by name and type
   *
   * @param name
   * @param type
   */
  getMediaByNameAndTypeFullData(name: string, type: string): Observable<Media> {
    const params = new HttpParams().set('name', name).set('type', type);
    return this.http.get<Media>(`${this.mediaUrl}/${Constant.DASH_BOARD_COMPONENT_URL}/get-media-full-data-by-name-and-type`, { params });
  }

  /**
   * Get media name in file log
   *
   * @param medias
   * @returns
   */
  public getMediaNameInLog(medias: Array<string>): Observable<Array<Media>> {
    return this.http.post<Array<Media>>(`${this.mediaUrl}/${Constant.DASH_BOARD_COMPONENT_URL}/get-media-in-file-log`, medias);
  }

  /**
   * Get media url to delivery
   *
   * @param mediaId
   * @returns
   */
  public getMediaUrlToDelivery(mediaId: Number): Observable<string> {
    const params = new HttpParams().set('mediaId', `${mediaId}`);
    return this.http.get<string>(`${this.mediaUrl}/${Constant.TIMETABLE_OPERATION_URL}/get-media-url-to-delivery`, { params });
  }

  /**
   * validate image bitmap
   * @param mediaId
   * @returns
   */
  public validateImageBitmap(mediaId: number): Observable<string> {
    return this.http.post<string>(`${this.mediaUrl}/from-led/validate-image-bitmap`, mediaId);
  }

  /**
   * Be used by LCD Layout Editor
   * Upload media file
   *
   * @param files
   * @param mediaFilesDropped
   * @returns
   */
  public uploadMediaFromPcToLCD(files: File[], mediaFilesDropped: Array<MediaFileDropped>): Observable<Array<MediaFileDropped>> {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });
    formData.append('mediaFilesDropped', JSON.stringify(mediaFilesDropped));
    return this.http.post<Array<MediaFileDropped>>(
      `${this.mediaUrl}/${Constant.LCD_LAYOUT_EDITOR_COMPONENT_URL}/upload-media-from-pc-to-lcd`,
      formData
    );
  }

  /**
   * Be used by LCD Layout Editor
   * Get media validator
   *
   * @returns
   */
  public getMediaValidatorLCDLayoutEditor(): Observable<MediaValidator> {
    return this.http.get<MediaValidator>(`${this.mediaUrl}/${Constant.LCD_LAYOUT_EDITOR_COMPONENT_URL}/get-media-validator`);
  }

  /**
   * Be used by Index Word Editor
   * Get media validator
   *
   * @returns
   */
  public getMediaValidatorIndexWordEditor(): Observable<MediaValidator> {
    return this.http.get<MediaValidator>(`${this.mediaUrl}/${Constant.INDEX_WORD_EDITOR_COMPONENT_URL}/get-media-validator`);
  }

  /**
   * Be used by Index Word Editor
   * Upload media file
   *
   * @param file
   * @returns
   */
  public uploadMediaFromPcToIW(file: File): Observable<Media> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<Media>(`${this.mediaUrl}/${Constant.INDEX_WORD_EDITOR_COMPONENT_URL}/upload-media-from-pc-to-iw`, formData);
  }

  /**
   * Be used by Index Word Editor
   * get size of media by media id
   * @param mediaId
   * @returns
   */
  public getSizeMediaById(mediaId: Number): Observable<any> {
    return this.http.post<any>(`${this.mediaUrl}/get-size-media`, mediaId);
  }

  /**
   * Delete emergency image
   *
   * @param mediaId
   * @returns
   */
  public deleteEmergencyImage(mediaId: Number): Observable<any> {
    const params = new HttpParams().set('mediaId', mediaId + '');
    return this.http.delete(`${this.mediaUrl}/${Constant.MEDIA_MANAGER_COMPONENT_URL}/delete-emergency-image`, { params });
  }

  /**
   * convert PDF to image
   * @param fileDrop
   * @param folderName
   * @returns
   */
  public convertPDFToImage(fileDrop: File, folderName: string): Observable<Media> {
    const params = new HttpParams().set('folderName', folderName);
    const formData = new FormData();
    formData.append('file', fileDrop);
    return this.http.put<Media>(`${this.mediaUrl}/get-media-convert`, formData, { params });
  }

  /**
   * convert PDF to image
   * @param fileDrop
   * @param folderName
   * @returns
   */
  public checkFontsConvert(fileDrop: File): Observable<boolean> {
    const formData = new FormData();
    formData.append('file', fileDrop);
    return this.http.put<boolean>(`${this.mediaUrl}/check-fonts-convert`, formData);
  }

  /**
   * delete folder media drop from PC in bucket
   * @param folderName
   * @returns
   */
  public deleteFolderMediaFromPC(folderName: string): Observable<void> {
    return this.http.post<void>(`${this.mediaUrl}/delete-folder-media-drop-from-pc`, folderName);
  }

  /**
   * Be used by TIMETABLE-OPERATION-COMPONENT
   * Upload media file for dynamic message
   *
   * @param files
   * @param mediaFilesDropped
   * @returns
   */
  public uploadMediaFromPcToDynamicMessage(files: File[], mediaFilesDropped: Array<MediaFileDropped>): Observable<Array<MediaFileDropped>> {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });
    formData.append('mediaFilesDropped', JSON.stringify(mediaFilesDropped));
    return this.http.post<Array<MediaFileDropped>>(
      `${this.mediaUrl}/${Constant.TIMETABLE_OPERATION_URL}/upload-media-from-pc-to-dynamic-message`,
      formData
    );
  }

  /**
   * Get medias url to delivery dynamic message
   *
   * @param mediaIds
   * @returns
   */
  public getMediaUrlsDynamicMessage(mediaIds: Number[]): Observable<Array<string>> {
    return this.http.post<Array<string>>(`${this.mediaUrl}/${Constant.TIMETABLE_OPERATION_URL}/get-media-urls-dynamic-message`, mediaIds);
  }

  /**
   * delete medias dynamic message
   *
   * @param mediaIds
   * @returns
   */
  public deleteMediasDynamicMessage(mediaIds: Number[]): Observable<void> {
    return this.http.post<void>(`${this.mediaUrl}/${Constant.TIMETABLE_OPERATION_URL}/delete-medias-dynamic-message`, mediaIds);
  }
  /**
   * get Number Of Page Pdf
   * @param fileDrop
   * @returns
   */
  public getNumberOfPagePdf(fileDrop: File): Observable<number> {
    const formData = new FormData();
    formData.append('file', fileDrop);
    return this.http.post<number>(`${this.mediaUrl}/get-number-of-page-pdf`, formData);
  }

  /**
   * Be used by LCD Layout Editor
   * Get media validator
   *
   * @returns
   */
  public getMaxAreaOfTemplate(): Observable<any> {
    return this.http.get<any>(`${this.mediaUrl}/${Constant.LCD_LAYOUT_EDITOR_COMPONENT_URL}/get-max-area-of-template`);
  }

  /**
   * Be used by Ticket Editor
   * Upload media file
   *
   * @param file
   * @returns
   */
  public uploadMediaFromPcToTE(file: File): Observable<Media> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<Media>(
      `${this.mediaUrl}/${Constant.TICKET_EDITOR_COMPONENT_URL}/upload-media-from-pc-to-ticket-editor`,
      formData
    );
  }

  /**
   * Be used by Ticket Editor
   * delete media file
   *
   * @param fileName
   * @returns
   */
  public deleteMediaTE(fileName: String): Observable<any> {
    const params = new HttpParams().set('fileName', fileName + '');
    return this.http.delete(`${this.mediaUrl}/${Constant.TICKET_EDITOR_COMPONENT_URL}/delete-media-ticket-editor`, { params });
  }

  /**
   * Be used by Ticket Editor
   * Upload media file
   *
   * @param file
   * @returns
   */
  public getFileFromUrl(url: string): Observable<any> {
    return this.http.post(`${this.mediaUrl}/${Constant.TICKET_EDITOR_COMPONENT_URL}/get-file-from-url`, url, {
      responseType: 'blob',
      observe: 'response'
    });
  }
}
