import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * service class for transfering data between components
 */
export class DataService {
  constructor() {}

  private dataSource = new Subject<[string, any]>();
  currentData = this.dataSource.asObservable();

  /**
   * subject reset layout
   */
  private resetLayoutSubject = new Subject();
  resetLayoutAction = this.resetLayoutSubject.asObservable();

  /**
   * send data to subscriber
   * @param data tuple containing key and data
   */
  sendData(data: [string, any]) {
    this.dataSource.next(data);
  }

  /**
   * reset layout action when delete project
   * @param isResetLayout
   */
  resetLayout(isResetLayout: boolean): void {
    this.resetLayoutSubject.next(isResetLayout);
  }
}
