import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Route } from '../model/entity/route';
import { Constant } from 'app/config/constants';
import { BusStop } from 'app/model/entity/bus-stop';
import { RouteBusStop } from 'app/model/entity/route-bustop';

@Injectable({
  providedIn: 'root'
})
/**
 * Class RouteServive
 */
export class RouteService {
  /**
   * Route url
   */
  private readonly routeUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/routes';

  constructor(private http: HttpClient) {}

  /**
   * Be used by ROUTE-LIST-EDITOR-COMPONENT
   * get routes
   *
   * @param projectId id's project
   */
  getRoutesForRouteListEditorComponentByProjectId(projectId: Number): Observable<Route[]> {
    const params = new HttpParams().set('projectId', projectId + '');
    return this.http.get<Route[]>(`${this.routeUrl}/${Constant.ROUTE_LIST_EDITOR_COMPONENT_URL}/get-routes-by-project-id`, { params });
  }

  /**
   * Be used by ROUTE-LIST-EDITOR-COMPONENT
   * add a new route
   *
   * @param {Route} route new route
   */
  addRoute(route: Route): Observable<Route> {
    return this.http.post<Route>(`${this.routeUrl}/${Constant.ROUTE_LIST_EDITOR_COMPONENT_URL}/add`, route);
  }

  /**
   * Be used by ROUTE-LIST-EDITOR-COMPONENT
   * update a route
   *
   * @param {Route} route
   */
  updateRoute(route: Route): Observable<Route> {
    return this.http.put<Route>(`${this.routeUrl}/${Constant.ROUTE_LIST_EDITOR_COMPONENT_URL}/edit`, route);
  }

  /**
   * Be used by ROUTE-LIST-EDITOR-COMPONENT
   * delete routes
   *
   * @param routes list routes checked
   */
  deleteRoutes(routes: Route[]) {
    return this.http.post(`${this.routeUrl}/${Constant.ROUTE_LIST_EDITOR_COMPONENT_URL}/delete-routes`, routes);
  }

  /**
   * Be used by ROUTE-LIST-EDITOR-COMPONENT
   * update bus stop list's route
   *
   * @param {Route} route
   */
  updateBusStopsOfRoute(route: Route): Observable<RouteBusStop[]> {
    return this.http.post<RouteBusStop[]>(`${this.routeUrl}/${Constant.ROUTE_LIST_EDITOR_COMPONENT_URL}/update-bus-stops`, route);
  }

  /**
   * Be used by ROUTE-LIST-EDITOR-COMPONENT
   * delete BusStop Of Route
   *
   * @param routeId id route
   * @param busStopSelected bus stop selected
   */
  deleteBusStopOfRoute(routeId: Number, busStopSelected: BusStop) {
    const params = new HttpParams()
      .set('busStopId', busStopSelected.id + '')
      .set('routeId', routeId + '')
      .set('orderNo', busStopSelected.orderNo + '');
    return this.http.delete<Route>(`${this.routeUrl}/${Constant.ROUTE_LIST_EDITOR_COMPONENT_URL}/delete-bus-stops`, { params });
  }

  /**
   * Be used by ROUTE-LIST-EDITOR-COMPONENT
   * save label for routes
   *
   * @param routes
   */
  saveLabelForRoutes(routes: Route[]): Observable<Route[]> {
    return this.http.post<Route[]>(`${this.routeUrl}/${Constant.ROUTE_LIST_EDITOR_COMPONENT_URL}/save-label-for-routes`, routes);
  }

  /**
   * Be used by BUS-INFO-DISPLAY-EDITOR-COMPONENT
   * get routes
   *
   * @param projectId id's project
   */
  getRoutesForBusInfoDisplayComponentByProjectId(projectId: Number): Observable<Route[]> {
    const params = new HttpParams().set('projectId', projectId + '');
    return this.http.get<Route[]>(`${this.routeUrl}/${Constant.BUS_INFO_DISPLAY_COMPONENT_URL}/get-routes-by-project-id`, { params });
  }

  /**
   * Be used by BUS-INFO-DISPLAY-EDITOR-COMPONENT
   * get route
   *
   * @param routeId id route
   */
  getRouteWithFullDataTemplateById(routeId: Number): Observable<Route> {
    const params = new HttpParams().set('routeId', routeId + '');
    return this.http.get<Route>(`${this.routeUrl}/${Constant.BUS_INFO_DISPLAY_COMPONENT_URL}/get-route-with-full-data-template-by-id`, {
      params
    });
  }

  /**
   * Be used by BUS-INFO-DISPLAY-EDITOR-COMPONENT
   * change template for multiple routes
   *
   * @param routes List Route object
   */
  changeTemplateOfRoutes(routes: Route[]): Observable<Route[]> {
    return this.http.put<Route[]>(`${this.routeUrl}/change-template`, routes);
  }

  /**
   * Be used by STATION-DISPLAY-EDITOR-COMPONENT
   * Get routes with bus stops
   *
   * @param projectId id's project
   */
  getRoutesForStationDisplayEditorComponentByProjectId(projectId: Number): Observable<Route[]> {
    const params = new HttpParams().set('projectId', projectId + '');
    return this.http.get<Route[]>(`${this.routeUrl}/${Constant.STATION_DISPLAY_EDITOR_COMPONENT_URL}/get-routes-by-project-id`, { params });
  }

  /**
   * Be used by PROJECT-MANAGER-COMPONENT
   * get routes
   *
   * @param projectId id's project
   */
  getRoutesForProjectManagerComponentByProjectId(projectId: Number): Observable<Route[]> {
    const params = new HttpParams().set('projectId', projectId + '');
    return this.http.get<Route[]>(`${this.routeUrl}/${Constant.PROJECT_MANAGER_COMPONENT_URL}/get-routes-by-project-id`, { params });
  }

  /**
   * Be used by ROUTE-LIST-EDITOR-COMPONENT
   * duplicate a route
   *
   * @param {Route} route
   */
  duplicateRoute(route: Route): Observable<Route> {
    return this.http.post<Route>(`${this.routeUrl}/${Constant.ROUTE_LIST_EDITOR_COMPONENT_URL}/duplicate`, route);
  }
}
