import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { FontCharacterBitmap } from 'app/model/entity/led/font-character-bitmap';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FontService {
  private fontUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/font';
  constructor(private http: HttpClient) {}

  /**
   * extract font ttc
   * @param fileNameTTCs
   * @returns
   */
  public extractFontFromTTCtoTTF(fileNameTTCs: Array<string>): Observable<Array<string>> {
    return this.http.post<Array<string>>(this.fontUrl + '/extractTTC', fileNameTTCs);
  }

  /**
   * get all font name
   * @returns
   */
  public getAllFontName(): Observable<Array<string>> {
    return this.http.get<Array<string>>(`${this.fontUrl}/get-font-names`);
  }

  /**
   * get font data
   * @returns font name and url s3
   */
  public getFontUrlPresigned(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.fontUrl}/get-fonts`);
  }

  /**
   * upload font
   * @param urlFile
   * @param file
   */
  public uploadFile(files: Array<any>): Observable<any> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    return this.http.post<any>(`${this.fontUrl}/upload-fonts`, formData);
  }

  /**
   * get all font bit map
   * @returns
   */
  public getFontsBitmap(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.fontUrl}/get-list-font-bitmap`);
  }

  /**
   * get font size bitmaps
   * @param name 
   * @returns 
   */
  public getFontSizeBitmaps(name: string): Observable<Array<string>> {
    const params = new HttpParams().set('fontName', name);
    return this.http.get<Array<string>>(`${this.fontUrl}/get-list-font-size-bitmap`, { params });
  }

  /**
   * get information font characters bitmap for areas
   * @param fontCharacterBitmaps
   * @returns
   */
  public getFontsBitmapForAreas(fontCharacterBitmaps: Array<FontCharacterBitmap>): Observable<Array<any>> {
    return this.http.post<Array<any>>(`${this.fontUrl}/get-fonts-bitmap-for-areas`, fontCharacterBitmaps);
  }
}
