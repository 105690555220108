import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { User } from 'app/core/user/user.model';
import { Observable } from 'rxjs';
import { Project } from '../model/entity/project';

@Injectable({
  providedIn: 'root'
})
/**
 * Class ProjectServive
 */
export class ProjectService {
  /**
   * project url
   */
  private projectUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/projects';

  constructor(private http: HttpClient) {}

  /**
   * Be used by PROJECT-MANAGER-COMPONENT
   * add new project
   *
   * @param project new project need to add
   */
  addProject(project: Project): Observable<Project> {
    return this.http.post<Project>(`${this.projectUrl}/${Constant.PROJECT_MANAGER_COMPONENT_URL}/add`, project);
  }

  /**
   * Be used by PROJECT-MANAGER-COMPONENT
   * edit project
   *
   * @param project new project need to add
   */
  editProject(project: Project): Observable<Project> {
    return this.http.put<Project>(`${this.projectUrl}/${Constant.PROJECT_MANAGER_COMPONENT_URL}/edit`, project);
  }

  /**
   * Be used by PROJECT-MANAGER-COMPONENT
   * delete project
   *
   * @param projectId id's project need to delete
   */
  deleteProject(projectId: Number) {
    const params = new HttpParams().set('projectId', projectId + '');
    return this.http.delete(`${this.projectUrl}/${Constant.PROJECT_MANAGER_COMPONENT_URL}/delete`, { params });
  }

  /**
   * Be used by PROJECT-MANAGER-COMPONENT
   * get projects by userId
   *
   * @param userId userId id's user login
   */
  getProjectsByUserId(userId: Number, isRoot: boolean): Observable<Project[]> {
    const params = new HttpParams().set('userId', userId + '').set('isRoot', isRoot + '');
    return this.http.get<Project[]>(`${this.projectUrl}/${Constant.PROJECT_MANAGER_COMPONENT_URL}/get-projects-by-user-id`, {
      params
    });
  }

  /**
   * Be used by PROJECT-MANAGER-COMPONENT
   * get project members
   *
   * @param projectId id's project is selected
   */
  getProjectMembers(projectId: number): Observable<User[]> {
    const params = new HttpParams().set('projectId', projectId + '');
    return this.http.get<User[]>(`${this.projectUrl}/${Constant.PROJECT_MANAGER_COMPONENT_URL}/get-project-users-by-project-id`, {
      params
    });
  }
}
