import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { DeviceInformation } from 'app/dialog/dialog-delivery-timetable/dialog-delivery-timetable.component';
import { DeliveryStatusTimetable } from 'app/model/entity/delivery-status-timetable';
import { DeviceCalendar } from 'app/model/entity/device-calendar';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for delivery status timetable action
 */
export class DeliveryStatusTimetableService {
  /**
   * URL of device publish info API
   */
  deliveryStatusTimetableUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/delivery-status-timetable';
  /**
   * URL for common simple playlist
   */
  simpleDeliveryStatusUrl = Constant.BACKEND_URL + Constant.DSC_SERVICE_URL + 'api/simple-delivery-status';

  constructor(private http: HttpClient) {}

  /**
   * Be used TIMETABLE-COMPONENT
   * get delivery status for devices
   * @returns
   */
  getDeliveryStatusForDevices(): Observable<Array<DeliveryStatusTimetable>> {
    return this.http.get<Array<DeliveryStatusTimetable>>(`${this.deliveryStatusTimetableUrl}/get-delivery-status-for-devices`);
  }

  /**
   * Be used TIMETABLE-COMPONENT
   * update status first time for devices
   *
   * @param deviceIds
   */
  updateStatusFirstTimeForDevices(deviceIds: Array<Number>): Observable<DeliveryStatusTimetable[]> {
    return this.http.post<DeliveryStatusTimetable[]>(`${this.deliveryStatusTimetableUrl}/first-time/update-status-for-devices`, deviceIds);
  }

  /**
   * Be used TIMETABLE-COMPONENT
   * update status first time for devices
   *
   * @param deviceIds
   */
  updateStatusForDevices(deviceIds: Array<Number>): Observable<DeliveryStatusTimetable[]> {
    return this.http.post<DeliveryStatusTimetable[]>(`${this.deliveryStatusTimetableUrl}/update-status-for-devices`, deviceIds);
  }

  /**
   * Be used TIMETABLE-COMPONENT
   * cancel delivery
   *
   * @param deviceIds
   */
  cancelDelivery(deviceIds: Array<Number>): Observable<any> {
    return this.http.post<any>(`${this.deliveryStatusTimetableUrl}/cancel-delivery`, deviceIds);
  }
  /**
   * Be used TIMETABLE-COMPONENT
   * Update failed status for devices
   *
   * @param checkedDevices
   */
  public updateFailedStatusForDevices(checkedDevices: Array<DeviceCalendar>): Observable<Array<DeliveryStatusTimetable>> {
    return this.http.post<Array<DeliveryStatusTimetable>>(
      `${this.deliveryStatusTimetableUrl}/update-failed-status-for-devices`,
      checkedDevices
    );
  }

  /**
   * Be used TIMETABLE-OPERATION-COMPONENT
   * update delivery status for device
   *
   * @param deviceInformation
   */
  public updateDeliveryStatusForDeviceForTimetableOperation(deviceInformation: DeviceInformation): Observable<void> {
    return this.http.post<void>(
      `${this.deliveryStatusTimetableUrl}/${Constant.TIMETABLE_OPERATION_URL}/update-status-for-device`,
      deviceInformation
    );
  }

  /**
   * Get devices status from s3
   *
   * @param registrationIds
   * @returns
   */
  public getDevicesStatusFromS3(registrationIds: string[]): Observable<any> {
    return this.http.post<any>(`${this.deliveryStatusTimetableUrl}/get-devices-status-from-s3`, registrationIds);
  }

  /**
   * Be used TIMETABLE-COMPONENT
   * update status for group devices
   *
   * @param deliveryStatuses
   */
  public updateStatusForGroupDevices(deliveryStatuses: Array<DeliveryStatusTimetable>): Observable<any> {
    return this.http.post(`${this.deliveryStatusTimetableUrl}/update-status-for-group-devices`, deliveryStatuses);
  }

  /**
   * save delivery status for devices checked
   *
   * @param deliveryStatusTimetables
   * @returns
   */
  public saveDetailStatusForDevices(deliveryStatusTimetables: Array<DeliveryStatusTimetable>): Observable<void> {
    return this.http.post<void>(`${this.deliveryStatusTimetableUrl}/save-delivery-status-for-devices`, deliveryStatusTimetables);
  }

  /**
   * get Device Information
   *
   * @param deviceIds
   * @returns
   */
  public getDeviceInfo(deviceIds: string): Observable<any> {
    const params = new HttpParams().set('deviceIds', deviceIds);
    return this.http.get<any>(`${this.deliveryStatusTimetableUrl}/get-device-information`, { params });
  }
  /**
   * get Status Device Information
   * @param checkedDevices
   * @returns
   */
  public getStatusDeviceSimpleSignageEditor(checkedDevices: DeviceCalendar[]): Observable<any> {
    return this.http.post<any>(`${this.simpleDeliveryStatusUrl}/get-device-information-not-yet-completed`, checkedDevices);
  }
}
