import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { DeviceContentDay, DeviceSimplePlaylist } from 'app/dialog/dialog-delivery-simple/dialog-delivery-simple.component';
import { ContentDay } from 'app/model/entity/content-day';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Class SimplePlaylistContentDayService
 */
export class SimplePlaylistContentDayService {
  /**
   * simplePlaylist content day url
   */
  private simplePlaylistContentDayUrl = Constant.BACKEND_URL + Constant.DSC_SERVICE_URL + 'api/simple-playlist-content-days';

  constructor(private http: HttpClient) {}

  /**
   * Be used by SIMPLE-SIGNAGE-COMPONENT
   * get list content day by device's id
   *
   * @param deviceId id device
   */
  getContentDaysByDeviceId(deviceId: Number): Observable<ContentDay[]> {
    const params = new HttpParams().set('deviceId', deviceId + '');
    return this.http.get<ContentDay[]>(
      `${this.simplePlaylistContentDayUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/get-content-days-by-device-id`,
      {
        params
      }
    );
  }

  /**
   * Be used by SIMPLE-SIGNAGE-COMPONENT
   * update content days of device
   *
   * @param simplePlaylistContentDays
   * @param deviceId id device
   * @param isUnlimited
   */
  updateContentDaysOfDevice(
    simplePlaylistContentDays: Array<ContentDay>,
    deviceIds: Number[],
    isUnlimited: boolean
  ): Observable<ContentDay[]> {
    const params = new HttpParams().set('deviceIds', deviceIds + '').set('isUnlimited', isUnlimited + '');
    return this.http.post<ContentDay[]>(
      `${this.simplePlaylistContentDayUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/update-content-days-by-device-ids`,
      simplePlaylistContentDays,
      { params }
    );
  }

  /**
   * Be used by SIMPLE-SIGNAGE-COMPONENT
   * update status delivery for content day by device ids
   * @param deviceIds
   * @returns
   */
  updateStatusDeliveryForContentDayByDeviceIds(deviceIds: Array<Number>): Observable<any> {
    return this.http.post<Array<Number>>(
      `${this.simplePlaylistContentDayUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/update-status-delivery-for-content-days-by-device-ids`,
      deviceIds
    );
  }

  /**
   * Be used by SIMPLE-SIGNAGE-COMPONENT
   * check exist data for devices
   *
   * @param deviceIds
   * @returns
   */
  checkExistDataForDevices(deviceIds: Array<Number>): Observable<boolean[]> {
    return this.http.post<boolean[]>(
      `${this.simplePlaylistContentDayUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/check-exist-data-for-devices`,
      deviceIds
    );
  }

  /**
   * Be used by SIMPLE-SIGNAGE-COMPONENT
   * check exist data all date for devices
   *
   * @param deviceIds
   * @returns
   */
  checkExistDataAllDateForDevices(deviceIds: Array<Number>): Observable<boolean[]> {
    return this.http.post<boolean[]>(
      `${this.simplePlaylistContentDayUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/check-exist-data-all-date-for-devices`,
      deviceIds
    );
  }

  /**
   * Be used by SIMPLE-SIGNAGE-COMPONENT
   * check exist data sequence
   *
   * @param deviceIds
   * @returns
   */
  checkExistDataSequence(deviceIds: Array<Number>): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.simplePlaylistContentDayUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/check-exist-data-sequences`,
      deviceIds
    );
  }

  /**
   * Be used by SIMPLE-SIGNAGE-COMPONENT
   *
   * validate total size of devices
   *
   * @param deviceSimplePlaylists
   * @returns
   */
  validateTotalSizeOfDevices(deviceSimplePlaylists: Array<DeviceSimplePlaylist>): Observable<Array<string>> {
    return this.http.post<Array<string>>(
      `${this.simplePlaylistContentDayUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/validate-total-size-of-devices`,
      deviceSimplePlaylists
    );
  }

  /**
   * Be used by SIMPLE-SIGNAGE-COMPONENT
   * get list content day by list device's id
   *
   * @param deviceIds id device
   */
  getContentDaysByDeviceIds(deviceIds: Array<Number>): Observable<Array<DeviceContentDay>> {
    return this.http.post<Array<DeviceContentDay>>(
      `${this.simplePlaylistContentDayUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/get-content-days-by-device-ids`,
      deviceIds
    );
  }

  /**
   * Be used by SIMPLE-SIGNAGE-COMPONENT
   * get full data list content day by list device's id
   *
   * @param deviceIds id device
   */
  getFullDataContentDaysByDeviceIds(deviceIds: Array<Number>): Observable<Array<DeviceContentDay>> {
    return this.http.post<Array<DeviceContentDay>>(
      `${this.simplePlaylistContentDayUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/get-full-data-content-days-by-device-ids`,
      deviceIds
    );
  }
}
