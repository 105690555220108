import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { ContentDay } from 'app/model/entity/content-day';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentDayService {
  private readonly contentDayUrl = Constant.BACKEND_URL + Constant.DSC_SERVICE_URL + 'api/content-days';

  constructor(private http: HttpClient) {}

  /**
   * Be used by DIGITAL-SIGNAGE-DISPLAY-COMPONENT
   * get list content day by id channel
   *
   * @param channelId id channel
   */
  getContentDaysByChannelId(channelId: Number): Observable<ContentDay[]> {
    const params = new HttpParams().set('channelId', channelId + '');
    return this.http.get<ContentDay[]>(
      `${this.contentDayUrl}/${Constant.DIGITAL_SIGNAGE_CHANNEL_EDITOR_COMPONENT_URL}/get-content-days-by-channel-id`,
      { params }
    );
  }

  /**
   * Be used by DIGITAL-SIGNAGE-DISPLAY-COMPONENT
   * delete lis content days
   *
   * @param {ContentDay[]} contentDays
   */

  deleteContentDays(contentDays: ContentDay[]) {
    return this.http.post(
      `${this.contentDayUrl}/${Constant.DIGITAL_SIGNAGE_CHANNEL_EDITOR_COMPONENT_URL}/delete-content-days`,
      contentDays
    );
  }
}
