import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { TimetableDetail } from 'app/model/entity/timetable-detail';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimetableDetailService {
  /**
   * timetable detail url
   */
  private timetableDetailUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/timetables-detail';

  constructor(private http: HttpClient) {}

  /**
   * Be used by TIMETABLE-COMPONENT
   * Get timetable detail information
   *
   * @param timetableId
   * @returns
   */
  public getTimetableDetailInformation(timetableId: Number): Observable<Array<TimetableDetail>> {
    const params = new HttpParams().set('timetableId', timetableId + '');
    return this.http.get<Array<TimetableDetail>>(
      `${this.timetableDetailUrl}/${Constant.TIMETABLE_COMPONENT_URL}/get-timetable-detail-information`,
      {
        params
      }
    );
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * Save timetable Details
   *
   * @param timetableId
   * @param timetableDetails
   * @returns
   */
  public saveTimetableDetails(timetableId: Number, timetableDetails: Array<TimetableDetail>): Observable<Array<TimetableDetail>> {
    const params = new HttpParams().set('timetableId', timetableId + '');
    return this.http.post<Array<TimetableDetail>>(
      `${this.timetableDetailUrl}/${Constant.TIMETABLE_COMPONENT_URL}/save-timetable-detail-information`,
      timetableDetails,
      { params }
    );
  }
}
