import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MediaControllerService {
  constructor() {}

  playAudio(audio: HTMLAudioElement) {
    audio.load();
    audio.play();
  }

  stopAudio(audio: HTMLAudioElement) {
    audio.pause();
    audio.currentTime = 0;
    audio.src = '';
  }
}
