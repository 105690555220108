import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { DeviceInformation } from 'app/dialog/dialog-delivery-timetable/dialog-delivery-timetable.component';
import { CalendarInformation } from 'app/model/entity/calendar-information';
import { ContentDay } from 'app/model/entity/content-day';
import { Timetable } from 'app/model/entity/timetable';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Class TimetableContentDayService
 */
export class TimetableContentDayService {
  /**
   * timetable content day url
   */
  private timetableContentDayUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/timetable-content-days';
  private timetableDeliveredContentDayUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/timetable-delivered-content-days';

  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * Be used by TIMETABLE-COMPONENT
   * get list content day by id channel
   *
   * @param deviceId id device
   */
  getContentDaysByDeviceId(deviceId: Number): Observable<ContentDay[]> {
    const params = new HttpParams().set('deviceId', deviceId + '');
    return this.http.get<ContentDay[]>(`${this.timetableContentDayUrl}/${Constant.TIMETABLE_COMPONENT_URL}/get-content-days-by-device-id`, {
      params
    });
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * get list content day by device ids
   *
   * @param deviceIds list device ids
   */
  getContentDaysByDeviceIds(deviceIds: Array<Number>): Observable<ContentDay[][]> {
    return this.http.post<ContentDay[][]>(
      `${this.timetableContentDayUrl}/${Constant.TIMETABLE_COMPONENT_URL}/get-content-days-by-device-ids`,
      deviceIds
    );
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * get list content day by id channel
   *
   * @param timetableContentDays
   * @param deviceId id device
   * @param isUnlimited
   */
  updateContentDaysOfDevice(timetableContentDays: Array<ContentDay>, deviceId: Number, isUnlimited: boolean): Observable<ContentDay[]> {
    const params = new HttpParams().set('deviceId', deviceId + '').set('isUnlimited', isUnlimited + '');
    return this.http.post<ContentDay[]>(
      `${this.timetableContentDayUrl}/${Constant.TIMETABLE_COMPONENT_URL}/update-content-days-by-device-id`,
      timetableContentDays,
      { params }
    );
  }

  /**
   * check exist data for devices
   *
   * @param deviceIds
   * @returns
   */
  checkExistDataForDevices(deviceIds: Array<Number>): Observable<boolean[]> {
    return this.http.post<boolean[]>(
      `${this.timetableContentDayUrl}/${Constant.TIMETABLE_COMPONENT_URL}/check-exist-data-for-devices`,
      deviceIds
    );
  }

  /**
   * check exist data all date for devices
   *
   * @param deviceIds
   * @returns
   */
  checkExistDataAllDateForDevices(deviceIds: Array<Number>): Observable<boolean[]> {
    return this.http.post<boolean[]>(
      `${this.timetableContentDayUrl}/${Constant.TIMETABLE_COMPONENT_URL}/check-exist-data-all-date-for-devices`,
      deviceIds
    );
  }

  /**
   * save data delivered content day
   *
   * @param deviceInfos
   * @param changeoverOffset
   * @param switchingAreaTime
   */
  saveDataDeliveredContentDay(deviceInfos: Array<DeviceInformation>, changeoverOffset: number, switchingAreaTime: number): Observable<any> {
    const params = new HttpParams().set('changeoverOffset', `${changeoverOffset}`).set('switchingAreaTime', `${switchingAreaTime}`);
    return this.http.post(`${this.timetableDeliveredContentDayUrl}/${Constant.TIMETABLE_COMPONENT_URL}/save`, deviceInfos, { params });
  }

  /**
   * get list timetable id by list device id
   *
   * @param deviceIds
   * @returns
   */
  getTimetableIdsByDeviceIds(deviceIds: Array<Number>): Observable<Array<Number>> {
    return this.http.post<Array<Number>>(
      `${this.timetableContentDayUrl}/${Constant.TIMETABLE_COMPONENT_URL}/get-timetable-id-by-device-id`,
      deviceIds
    );
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * export calendar
   *
   * @param calendarInfo
   * @returns
   */
  exportCalendar(calendarInfo: CalendarInformation): Observable<any> {
    const params = new HttpParams().set('language', this.commonService.getCommonObject().setting?.language);
    return this.http.post(`${this.timetableContentDayUrl}/${Constant.TIMETABLE_COMPONENT_URL}/export-calendar`, calendarInfo, {
      responseType: 'blob',
      observe: 'response',
      params: params
    });
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * get timetables between dates
   *
   * @param calendarInfo
   * @returns
   */
  getTimetablesBetweenDates(calendarInfo: CalendarInformation): Observable<Array<Timetable>> {
    return this.http.post<Array<Timetable>>(
      `${this.timetableContentDayUrl}/${Constant.TIMETABLE_COMPONENT_URL}/get-timetables-between-dates`,
      calendarInfo
    );
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * get content days between dates
   *
   * @param calendarInfo
   * @returns
   */
  getContentDaysBetweenDates(calendarInfo: CalendarInformation): Observable<Array<ContentDay>> {
    return this.http.post<Array<ContentDay>>(
      `${this.timetableContentDayUrl}/${Constant.TIMETABLE_COMPONENT_URL}/get-content-days-between-dates`,
      calendarInfo
    );
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * save data content days copied of device
   *
   * @param timetableContentDays
   * @param deviceId id device
   * @param startDate
   * @param fullDate
   */
  saveContentDaysCopiedOfDevice(
    timetableContentDays: Array<ContentDay>,
    deviceId: Number,
    startDate: string,
    fullDate: string
  ): Observable<ContentDay[]> {
    const params = new HttpParams()
      .set('deviceId', deviceId + '')
      .set('startDate', startDate)
      .set('fullDate', fullDate);
    return this.http.post<ContentDay[]>(
      `${this.timetableContentDayUrl}/${Constant.TIMETABLE_COMPONENT_URL}/save-content-days-copied-by-device-id`,
      timetableContentDays,
      { params }
    );
  }

  /**
   * Be used by TIMETABLE-OPERATION-COMPONENT
   * get time date line for device
   *
   * @param deviceId
   * @returns
   */
  getTimeDateLine(deviceId: Number): Observable<number> {
    return this.http.post<number>(
      `${this.timetableDeliveredContentDayUrl}/${Constant.TIMETABLE_OPERATION_URL}/get-time-date-line`,
      deviceId
    );
  }

  /**
   * Be used by TIMETABLE-OPERATION-COMPONENT
   * get area switching timing
   *
   * @param deviceId
   * @returns
   */
  getAreaSwitchingTiming(deviceId: Number): Observable<number> {
    return this.http.post<number>(
      `${this.timetableDeliveredContentDayUrl}/${Constant.TIMETABLE_OPERATION_URL}/get-area-switching-timing`,
      deviceId
    );
  }
}
