import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { ScheduleDisplayIndex } from 'app/model/entity/schedule-display-index';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScheduleDisplayIndexService {
  /**
   * schedule display index url
   */
  private scheduleDisplayIndexUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/schedule-display-index';

  constructor(private http: HttpClient) {}
  /**
   * save schedule display index
   *
   * @param scheduleDisplayIndex
   * @returns
   */
  saveScheduleDisplayIndex(scheduleDisplayIndex: ScheduleDisplayIndex): Observable<ScheduleDisplayIndex> {
    return this.http.post<ScheduleDisplayIndex>(`${this.scheduleDisplayIndexUrl}/save-schedule-display-index`, scheduleDisplayIndex);
  }

  /**
   * delete schedule display index
   *
   * @returns
   */
  deleteScheduleDisplayIndex(): Observable<void> {
    return this.http.delete<void>(`${this.scheduleDisplayIndexUrl}/delete`);
  }
}
