import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';

@Injectable({
  providedIn: 'root'
})
export class ScheduleOperationInfoService {
  /**
   * Schedule operation information url
   */
  private scheduleOperationInfoUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/schedule-operation-info';
  constructor(private http: HttpClient) {}
  /**
   * Save operation information
   * @param payload
   * @returns
   */
  saveOperationInfo(payload: any) {
    return this.http.post(`${this.scheduleOperationInfoUrl}/save-schedule-operation-info`, payload);
  }
}
