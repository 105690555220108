import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from 'app/config/constants';
import { LoginService } from 'app/core/login/login.service';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { Common } from 'app/model/entity/common';
import { SaveMainStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { DialogService } from 'app/service/dialog.service';
import { UserSettingService } from 'app/service/user-setting.service';
import { AppState } from 'app/store/app.state';
import { JhiLanguageService } from 'ng-jhipster';
import { Subscription } from 'rxjs';

@Component({
  selector: 'login-modal',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginModalComponent implements OnInit {
  @ViewChild('username', { static: false }) username?: ElementRef;
  @ViewChild('password') password?: ElementRef;
  @ViewChild('accountId') accountId?: ElementRef;
  @Output() loginSuccess = new EventEmitter<any>();

  loginForm = this.fb.group({
    accountId: [''],
    username: [''],
    password: [''],
    rememberMe: [false]
  });

  public readonly FORMAT_ACCOUNT_ID = '^[A-Z]{1}[0-9]{4,5}$|^[A-Z]{2}[0-9]{4}$';
  public readonly ACCESS_TOKEN_PARAM = 'access_token';
  public readonly ACCESS_TOKEN_ERROR = 'error';
  public readonly ACCESS_TOKEN_ERROR_SUSPEND = 'error suspend';

  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * common object
   */
  commonObject: Common;
  constructor(
    private loginService: LoginService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    private languageService: JhiLanguageService,
    private translateService: TranslateService,
    private userSettingService: UserSettingService,
    public readonly store: Store<AppState>
  ) {
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.commonObject = componentState?.mainState?.stateOfComponent?.common ?? new Common();
        })
    );
  }

  ngOnInit(): void {
    if (this.username) {
      this.username.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * login
   */
  login(): void {
    let accountId = this.loginForm.get('accountId')?.value.trim();
    let userId = this.loginForm.get('username')?.value.trim();
    let password = this.loginForm.get('password')?.value;

    // validate account id
    if (accountId == '') {
      this.showErrorDialog(this.translateService.instant('login.account-id-empty'), this.setFocusAccountID);
      return;
    }
    if (!accountId.match(this.FORMAT_ACCOUNT_ID)) {
      this.showErrorDialog(this.translateService.instant('login.account-not-exist'), this.setFocusAccountID);
      return;
    }

    // validate userId empty
    if (userId == '') {
      this.showErrorDialog(this.translateService.instant('login.user-id-empty'), this.setFocusUserName);
      return;
    }

    // validate password empty
    if (password == '') {
      this.showErrorDialog(this.translateService.instant('login.pass-empty'), this.setFocusPassword);
      return;
    }
    this.accountId?.nativeElement.blur();
    this.username?.nativeElement.blur();
    this.password?.nativeElement.blur();
    this.callAPILogin(accountId, userId, password, false);
  }

  /**
   * call api to login
   * @param accountId
   * @param userId
   * @param password
   * @param isConfirmContinueLogin
   */
  private callAPILogin(accountId: string, userId: string, password: string, isConfirmContinueLogin: boolean): void {
    const ALREADY_LOGIN = 'already login';
    const CONFIRM_LOGIN = 'confirm login';
    // call api
    this.loginService
      .login({
        accountId: accountId.toLowerCase(),
        username: userId,
        password: password,
        rememberMe: false,
        isConfirmContinueLogin: isConfirmContinueLogin
      })
      .subscribe(
        data => {
          if (!data) {
            this.showErrorDialog(this.translateService.instant('login.account-not-exist'), this.setFocusAccountID);
            return;
          }
          if (data[this.ACCESS_TOKEN_PARAM] === this.ACCESS_TOKEN_ERROR_SUSPEND) {
            this.showErrorDialog(this.translateService.instant('login.account-suspend'), this.setFocusAccountID);
            return;
          }
          if (data[this.ACCESS_TOKEN_PARAM] === this.ACCESS_TOKEN_ERROR) {
            this.showErrorDialog(this.translateService.instant('login.invalid-info'), this.setFocusUserName);
            return;
          }
          if (data[this.ACCESS_TOKEN_PARAM] === ALREADY_LOGIN) {
            this.dialogService.showDialog(
              DialogConfirmComponent,
              {
                data: {
                  text: this.translateService.instant('login.confirm-login-same-user-id'),
                  button1: this.translateService.instant('login.forced-login'),
                  button2: this.translateService.instant('login.cancel')
                }
              },
              result => {
                if (result) {
                  this.callAPILogin(accountId, userId, password, true);
                }
              }
            );
          } else if (data[this.ACCESS_TOKEN_PARAM] === CONFIRM_LOGIN) {
            this.dialogService.showDialog(
              DialogConfirmComponent,
              {
                data: {
                  text: this.translateService.instant('login.confirm-login'),
                  button1: this.translateService.instant('main.logout-dialog.yes'),
                  button2: this.translateService.instant('main.logout-dialog.no')
                }
              },
              result => {
                if (result) {
                  this.callAPILogin(accountId, userId, password, true);
                }
              }
            );
          } else {
            this.commonObject.tenantName = accountId.toLowerCase();
            this.store.dispatch(
              new SaveMainStateAction({
                common: this.commonObject
              })
            );
            this.userSettingService.getInfoSetting().subscribe(userSettingData => {
              if (userSettingData) {
                this.commonObject.setting = userSettingData;
                this.store.dispatch(
                  new SaveMainStateAction({
                    common: this.commonObject
                  })
                );
                this.languageService.changeLanguage(userSettingData.language);
                this.loginSuccess.emit(userId);
              } else {
                this.showErrorDialog(this.translateService.instant('login.invalid-info'), this.setFocusUserName);
              }
            });
          }
        },
        error => {
          this.showErrorDialog(this.translateService.instant('login.invalid-info'), this.setFocusUserName, error);
          return;
        }
      );
  }

  /**
   * show error dialog
   *
   * @param errorMessage
   * @param callback
   * @param error
   */
  showErrorDialog(errorMessage: string, callback, error?: any): void {
    if (error?.status == Constant.NETWORK_ERROR_CODE) {
      errorMessage = this.translateService.instant('dialog-error.error-network-api');
    }
    this.dialogService.showDialog(
      DialogMessageComponent,
      { data: { title: this.translateService.instant('login.error'), text: errorMessage } },
      result => {
        if (!result) {
          callback(this);
        }
      }
    );
  }

  /**
   * set autofocus when error of username input
   * @param object
   */
  public setFocusUserName(object): void {
    setTimeout(() => {
      object.username?.nativeElement.focus();
    }, 0);
  }

  /**
   * set autofocus when error of password input
   * @param object
   */
  public setFocusPassword(object): void {
    setTimeout(() => {
      object.password?.nativeElement.focus();
    }, 0);
  }

  /**
   * set autofocus when error of accountId input
   * @param object
   */
  public setFocusAccountID(object): void {
    setTimeout(() => {
      object.accountId?.nativeElement.focus();
    }, 0);
  }
}
