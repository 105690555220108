import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constant } from 'app/config/constants';
import { RouteBusStop } from 'app/model/entity/route-bustop';

@Injectable({
  providedIn: 'root'
})
/**
 * Class RouteBusStopService
 */
export class RouteBusStopService {
  /**
   * route bus stop url
   */
  private readonly routeBusStopUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/route-bus-stops';

  constructor(private http: HttpClient) {}

  /**
   * Be used by STATION-DISPLAY-EDITOR-COMPONENT
   * Get route bus stops by project id
   *
   * @param projectId id's project
   */
  getRouteBusStopsByProjectId(projectId: Number): Observable<RouteBusStop[]> {
    const params = new HttpParams().set('projectId', projectId + '');
    return this.http.get<RouteBusStop[]>(
      `${this.routeBusStopUrl}/${Constant.STATION_DISPLAY_EDITOR_COMPONENT_URL}/get-route-bus-stops-by-project-id`,
      { params }
    );
  }

  /**
   * Be used by STATION-DISPLAY-EDITOR-COMPONENT
   * update list route bus stop
   *
   * @param routeBusStops List RouteBusStop object
   */
  updateRouteBusStops(routeBusStops: RouteBusStop[]) {
    return this.http.put(
      `${this.routeBusStopUrl}/${Constant.STATION_DISPLAY_EDITOR_COMPONENT_URL}/update-media-for-route-bus-stops`,
      routeBusStops
    );
  }
}
