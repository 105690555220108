import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Label } from '../model/entity/label';
import { Constant } from 'app/config/constants';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for label
 */
export class LabelService {
  /**
   * URL for media API
   */
  // labelUrl = "api/labels";
  private labelUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/labels';

  constructor(private http: HttpClient) {}

  /**
   * Get labels by functionId
   * @param functionId functionId of the screen
   */
  getLabels(functionId: number): Observable<Label[]> {
    const params = new HttpParams().set('functionId', functionId + '');
    return this.http.get<Label[]>(`${this.labelUrl}/get-labels`, { params });
  }

  /**
   * add a new label
   * @param {Label} label new route
   * @param functionId functionId of the screen
   */
  addLabel(label: Label, functionId: number): Observable<Label> {
    const params = new HttpParams().set('functionId', functionId + '');
    return this.http.post<Label>(`${this.labelUrl}/add`, label, { params });
  }

  /**
   * update label
   * @param {Label} label new route
   * @param functionId functionId of the screen
   */
  updateLabel(label: Label, functionId: number): Observable<Label> {
    const params = new HttpParams().set('functionId', functionId + '');
    return this.http.put<Label>(`${this.labelUrl}/edit`, label, { params });
  }

  /**
   * delete selected label
   * @param {Label} label selected label
   */
  deleteLabel(label: Label) {
    const params = new HttpParams().set('labelId', label.id + '');
    return this.http.delete(`${this.labelUrl}/delete`, { params });
  }

  /**
   * check exist label name
   *
   * @param functionId
   * @param label
   * @param id
   * @return
   */
  checkExistLabelName(functionId: number, id: Number, name: string) {
    const params = new HttpParams()
      .set('functionId', `${functionId}`)
      .set('labelId', `${id}`)
      .set('labelName', `${name}`);
    return this.http.get<boolean>(`${this.labelUrl}/checkExistLabelName`, { params });
  }

  /**
   * check exist label by id
   *
   * @param id
   * @return
   */
  checkExistLabelById(id: Number, functionId: Number) {
    const params = new HttpParams().set('labelId', `${id}`).set('functionId', `${functionId}`);
    return this.http.get<boolean>(`${this.labelUrl}/checkExistLabelById`, { params });
  }
}
