import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Channel } from '../model/entity/channel';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constant } from 'app/config/constants';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for signage channel action
 */
export class SignageChannelService {
  /**
   * URL for signage channel API
   */
  private readonly channelUrl = Constant.BACKEND_URL + Constant.DSC_SERVICE_URL + 'api/channels';

  constructor(private http: HttpClient) {}

  /**
   * Be used by DIGITAL-SIGNAGE-DISPLAY-COMPONENT
   * get channels
   *
   * @param projectId id project
   */
  getChannelsForDSCComponentByProjectId(projectId: Number): Observable<Channel[]> {
    const params = new HttpParams().set('projectId', projectId + '');
    return this.http.get<Channel[]>(
      `${this.channelUrl}/${Constant.DIGITAL_SIGNAGE_CHANNEL_EDITOR_COMPONENT_URL}/get-channels-by-project-id`,
      {
        params
      }
    );
  }

  /**
   * Be used by DIGITAL-SIGNAGE-DISPLAY-COMPONENT
   * add new channel
   *
   * @param {Channel} channel new channel
   */
  addChannel(channel: any): Observable<Channel> {
    return this.http.post<Channel>(`${this.channelUrl}/${Constant.DIGITAL_SIGNAGE_CHANNEL_EDITOR_COMPONENT_URL}/add`, channel);
  }

  /**
   * Be used by DIGITAL-SIGNAGE-DISPLAY-COMPONENT
   * edit channel
   *
   * @param {Channel} channel selected channel
   */
  editChannel(channel: any): Observable<Channel> {
    return this.http.put<Channel>(`${this.channelUrl}/${Constant.DIGITAL_SIGNAGE_CHANNEL_EDITOR_COMPONENT_URL}/edit`, channel);
  }

  /**
   * Be used by DIGITAL-SIGNAGE-DISPLAY-COMPONENT
   * delete channel
   *
   * @param channelId
   */
  deleteChannel(channelId: Number) {
    const params = new HttpParams().set('channelId', channelId + '');
    return this.http.delete(`${this.channelUrl}/${Constant.DIGITAL_SIGNAGE_CHANNEL_EDITOR_COMPONENT_URL}/delete`, { params });
  }

  /**
   * Be used by DIGITAL-SIGNAGE-DISPLAY-COMPONENT
   * update list content days
   *
   * @param channels
   */
  updateContentDays(channels: Array<any>) {
    return this.http.post(`${this.channelUrl}/${Constant.DIGITAL_SIGNAGE_CHANNEL_EDITOR_COMPONENT_URL}/update-content-days`, channels);
  }

  /**
   * Be used by PROJECT-MANAGER-COMPONENT
   * get channels
   *
   * @param projectId id project
   */
  getChannelsForProjectManagerComponentByProjectId(projectId: Number): Observable<Channel[]> {
    const params = new HttpParams().set('projectId', projectId + '');
    return this.http.get<Channel[]>(`${this.channelUrl}/${Constant.PROJECT_MANAGER_COMPONENT_URL}/get-channels-by-project-id`, {
      params
    });
  }

  /**
   * get all channels
   */
  getAllChannels(): Observable<Channel[]> {
    return this.http.get<Channel[]>(`${this.channelUrl}/get-all`);
  }
}
