import { EventEmitter, Injectable, Output, Renderer2 } from '@angular/core';
import { Helper } from 'app/common/helper';
import {
  AlignmentEnum,
  Constant,
  DestinationEnum,
  GIFFileExtensions,
  LinkDataPictureEnum,
  LinkDataTextEnum,
  ObjectFitEnum,
  OrientationEnum,
  ScrollDirectionsEnum,
  ScrollStatusEnum,
  TypeMediaFileEnum
} from 'app/config/constants';
import { Area } from 'app/model/entity/area';
import { Image } from 'app/model/entity/image';
import { Media } from 'app/model/entity/media';
import { PictureArea } from 'app/model/entity/picture-area';
import { Sequence } from 'app/model/entity/sequence';
import { Sound } from 'app/model/entity/sound';
import { Style } from 'app/model/entity/style';
import { Template } from 'app/model/entity/template';
import { TextArea } from 'app/model/entity/text-area';
import { Video } from 'app/model/entity/video';
import moment from 'moment';
import { interval, Subject } from 'rxjs';
import { repeatWhen, takeUntil } from 'rxjs/operators';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class DrawSignageService {
  /**
   * style
   */
  style: Style;
  /**
   * toSwitchBetweenPage
   */
  @Output() toSwitchBetweenPage = new EventEmitter<{ key: string; value: DestinationEnum }>();
  /**
   * true if preview on
   */
  isPlay: boolean;
  /**
   * list animation
   */
  animationList: Array<any> = new Array<any>();
  /**
   * media is set
   */
  mediaSetting: Media;
  /**
   * subject display 1
   */
  private readonly pausePreviewDisplay1Subject = new Subject<void>();
  private readonly startPreviewDisplay1Subject = new Subject<void>();
  private readonly clearPreviewDisplay1Subject = new Subject<void>();
  /**
   * subject display 2
   */
  private readonly pausePreviewDisplay2Subject = new Subject<void>();
  private readonly startPreviewDisplay2Subject = new Subject<void>();
  private readonly clearPreviewDisplay2Subject = new Subject<void>();

  constructor(private commonService: CommonService) {}

  /**
   * set up preview
   * @param style Style object
   */
  public setupPreview(style: Style, mediaSetting: Media): void {
    this.style = style;
    this.mediaSetting = mediaSetting;
  }

  /**
   * change state play/pause
   * @param isPlayOn
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  public changeStatePlayPause(isPlayOn: boolean, canvasDisplayId): void {
    this.isPlay = isPlayOn;
    if (this.isPlay) {
      if (canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID) {
        this.startPreviewDisplay1Subject.next();
      } else {
        this.startPreviewDisplay2Subject.next();
      }
    } else {
      if (canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID) {
        this.pausePreviewDisplay1Subject.next();
      } else {
        this.pausePreviewDisplay2Subject.next();
      }
    }
  }

  /**
   * draw preview station
   * @param display template
   * @param renderer
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  public drawPreviewSignage(display: Template, renderer: Renderer2, canvasDisplayId) {
    if (display) {
      var areas = Helper.getAllAreaTemplate(display);
      Promise.all(
        areas.map(async area => {
          // case area instanceof TextArea
          if (area.checkTypeTextArea()) {
            var textArea = area as TextArea;
            if (textArea.isFix) {
              // draw fix text
              this.drawAreaFixText(textArea, renderer, canvasDisplayId);
              // draw link text
            } else {
              switch (textArea.linkReferenceData) {
                case LinkDataTextEnum.CLOCK:
                  // draw link text if linkReferenceData is Clock
                  this.drawClock(textArea, renderer, canvasDisplayId);
                  break;
                case LinkDataTextEnum.FREE_TEXT:
                  // draw link text if linkReferenceData is free text
                  this.drawFreeText(textArea, renderer, canvasDisplayId);
                  break;
                case LinkDataTextEnum.EXTERNAL_SOURCE:
                  // draw link text if linkReferenceData is external source
                  // To do
                  break;
                default:
                  break;
              }
            }
            // case area instanceof Picture
          } else {
            var pictureArea = area.getArea() as PictureArea;
            if (pictureArea.isFix) {
              if (pictureArea?.media?.type == TypeMediaFileEnum.MP4) {
                // draw video fix picture
                await this.drawVideoFixPicture(pictureArea, renderer, canvasDisplayId);
              } else {
                // draw fix picture
                await this.drawAreaFixPicture(pictureArea, renderer, canvasDisplayId);
              }
              // draw link picture
            } else {
              switch (pictureArea.attribute) {
                case LinkDataPictureEnum.FREE_PICTURE:
                  // draw link picture if attribute is free picture
                  await this.drawFreePicture(pictureArea, renderer, canvasDisplayId);
                  break;
                case LinkDataPictureEnum.EXTERNAL_SOURCE:
                  // draw link picture if attribute is External Source
                  // To do
                  break;
                case LinkDataPictureEnum.SIGNAGE_CHANNEL:
                  // draw link picture if attribute is signage channel
                  if (!this.mediaSetting) {
                    return;
                  }
                  this.drawSignageChannel(pictureArea, this.mediaSetting, renderer, canvasDisplayId);
                  break;
                default:
                  break;
              }
            }
          }
        })
      );
    }
  }

  /**
   * draw free text
   * @param textArea TextArea
   * @param renderer
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private drawFreeText(textArea: TextArea, renderer: Renderer2, canvasDisplayId): void {
    if (!this.style?.styleDetails) {
      return;
    }
    const index =
      canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
        ? this.style.styleDetails.findIndex(styleDetail => styleDetail?.areaDisplay1?.id == textArea.id)
        : this.style.styleDetails.findIndex(styleDetail => styleDetail?.areaDisplay2?.id == textArea.id);
    if (index == -1) {
      return;
    }
    renderer.setStyle(textArea.canvas, 'visibility', 'visible');
    const text = this.style.styleDetails[index].mediaMain
      ? Helper.readTextFile(this.style.styleDetails[index].mediaMain?.url)
      : this.style.styleDetails[index].text;
    // draw areaText
    this.drawAreaText(textArea, canvasDisplayId, text);
  }

  /**
   * draw free picture
   * @param pictureArea PictureArea
   * @param renderer
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private drawFreePicture(pictureArea: PictureArea, renderer: Renderer2, canvasDisplayId): void {
    if (!this.style?.styleDetails) {
      return;
    }
    const index =
      canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
        ? this.style.styleDetails.findIndex(styleDetail => styleDetail?.areaDisplay1?.id == pictureArea.id)
        : this.style.styleDetails.findIndex(styleDetail => styleDetail?.areaDisplay2?.id == pictureArea.id);
    if (index == -1) {
      return;
    }
    const mediaMain = this.style.styleDetails[index]?.mediaMain;
    const mediaSound = this.style.styleDetails[index]?.mediaSound;
    if (!mediaMain && !mediaSound) {
      return;
    }
    renderer.setStyle(pictureArea.canvas, 'visibility', 'visible');
    pictureArea.media = mediaMain;
    if (pictureArea.media instanceof Image) {
      // draw areaPicture
      this.drawAreaPicture(pictureArea, canvasDisplayId);
      if (mediaSound) {
        // play sound attach image
        this.playSound(pictureArea, mediaSound, canvasDisplayId);
      }
    } else if (pictureArea.media instanceof Sound) {
      // play sound only
      this.playSound(pictureArea, undefined, canvasDisplayId);
    } else {
      // draw video
      this.drawVideoFixPicture(pictureArea, renderer, canvasDisplayId);
    }
  }

  /**
   * draw fix text preview
   * @param areaText TextArea
   * @param renderer Renderer2
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private drawAreaFixText(areaText: TextArea, renderer: Renderer2, canvasDisplayId): void {
    renderer.setStyle(areaText.canvas, 'visibility', 'visible');
    let text = areaText.text;
    // draw areaText
    this.drawAreaText(areaText, canvasDisplayId, text);
  }

  /**
   * draw text
   * @param areaText TextArea
   * @param text
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private drawAreaText(areaText: TextArea, canvasDisplayId: any, text?: string): void {
    // get ctx from canvas
    let ctx = areaText.canvas.getContext('2d');
    // draw color background
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // set style bold / italic / normal
    let textStyle = 'normal';
    if (areaText.isBold && !areaText.isItalic) {
      textStyle = Constant.BOLD_STYLE;
    } else if (areaText.isItalic && !areaText.isBold) {
      textStyle = Constant.ITALIC_STYLE;
    } else if (areaText.isItalic && areaText.isBold) {
      textStyle = `${Constant.BOLD_STYLE} ${Constant.ITALIC_STYLE}`;
    }
    // draw color text
    ctx.fillStyle = areaText.fontColor;
    // draw font text
    ctx.font = `${textStyle} ${areaText.fontSize}px ${!areaText.fontName ? 'Arial' : areaText.fontName}`;
    // set orientation
    if (areaText.orientation == OrientationEnum.HORIZONTAL) {
      this.drawTextOrientationHorizontal(ctx, areaText, text, canvasDisplayId);
    } else if (areaText.orientation == OrientationEnum.VERTICAL) {
      this.drawTextOrientationVertical(ctx, areaText, text, canvasDisplayId);
    } else if (areaText.orientation == OrientationEnum.SIDEWAYS) {
      this.drawTextOrientationSideways(ctx, areaText, text, canvasDisplayId);
    }
  }

  /**
   * draw text orientation horizontal
   * @param ctx
   * @param areaText area draw
   * @param text text need to draw
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private drawTextOrientationHorizontal(ctx: any, areaText: TextArea, text?: string, canvasDisplayId?: any): void {
    const referencePosition = Helper.getReferencePositionOrientationHorizontal(ctx, areaText);
    let referenceX = referencePosition.referenceX;
    let referenceY = referencePosition.referenceY;
    var textDraw = text ?? this.getTimer();
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // draw color text
    ctx.fillStyle = areaText.fontColor;
    ctx.fillText(textDraw, referenceX + areaText.posXScroll, referenceY + areaText.posYScroll);
    const widthMeasureText = ctx.measureText(textDraw).width;
    if (areaText.scrollStatus == ScrollStatusEnum.ON) {
      if (areaText[`subscription${canvasDisplayId}`]) {
        areaText[`subscription${canvasDisplayId}`].unsubscribe();
      }
      const observable = interval(50);
      const subscription =
        canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
          ? observable
              .pipe(
                takeUntil(this.clearPreviewDisplay1Subject),
                takeUntil(this.pausePreviewDisplay1Subject),
                repeatWhen(() => this.startPreviewDisplay1Subject)
              )
              .subscribe(() => {
                if (this.isPlay) {
                  this.drawTextScrollHorizontal(ctx, areaText, text, referenceX, referenceY, widthMeasureText);
                }
              })
          : observable
              .pipe(
                takeUntil(this.clearPreviewDisplay2Subject),
                takeUntil(this.pausePreviewDisplay2Subject),
                repeatWhen(() => this.startPreviewDisplay2Subject)
              )
              .subscribe(() => {
                if (this.isPlay) {
                  this.drawTextScrollHorizontal(ctx, areaText, text, referenceX, referenceY, widthMeasureText);
                }
              });
      areaText[`subscription${canvasDisplayId}`] = subscription;
      areaText[`clearPreviewSubject${canvasDisplayId}`] =
        canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
          ? this.clearPreviewDisplay1Subject.subscribe(() => {
              this.resetPositionScrollText(areaText);
              subscription.unsubscribe();
              areaText[`clearPreviewSubject${canvasDisplayId}`].unsubscribe();
            })
          : this.clearPreviewDisplay2Subject.subscribe(() => {
              this.resetPositionScrollText(areaText);
              subscription.unsubscribe();
              areaText[`clearPreviewSubject${canvasDisplayId}`].unsubscribe();
            });
    } else {
      areaText[`subscription${canvasDisplayId}`]?.unsubscribe();
    }
  }

  /**
   * draw text scroll horizontal
   * @param ctx
   * @param areaText
   * @param text
   * @param referenceX
   * @param referenceY
   * @param widthMeasureText
   */
  private drawTextScrollHorizontal(ctx, areaText, text, referenceX, referenceY, widthMeasureText): void {
    const textDraw = text ?? this.getTimer();
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.fontColor;
    ctx.fillText(textDraw, referenceX + areaText.posXScroll, referenceY + areaText.posYScroll);
    this.calcPositionScrollHorizontal(areaText, widthMeasureText, referenceX, referenceY);
  }

  /**
   * draw text orientation vertical
   * @param ctx
   * @param areaText TextArea
   * @param text
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private drawTextOrientationVertical(ctx: any, areaText: TextArea, text?: string, canvasDisplayId?: any): void {
    var textDraw = text ?? this.getTimer();
    var charsSplit = textDraw.split('');
    let metrics = ctx.measureText(textDraw);
    let firefox = navigator.userAgent.search('Firefox');
    let characterHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
    if (firefox > -1) {
      characterHeight = areaText.fontSize;
    }
    let textHeight = characterHeight * charsSplit.length;
    const referencePosition = Helper.getReferencePositionOrientationVertical(ctx, areaText, textHeight);
    let referenceX = referencePosition.referenceX;
    let referenceY = referencePosition.referenceY;
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // draw color text
    ctx.fillStyle = areaText.fontColor;
    var i = 0;
    var ch: string;
    var posY = 0.5;
    while ((ch = charsSplit[i++])) {
      var chWidth = ctx.measureText(ch).width;
      ctx.fillText(ch, referenceX - chWidth / 2 + areaText.posXScroll, referenceY + posY * characterHeight + areaText.posYScroll);
      posY++;
    }
    var widthMeasureText = ctx.measureText(textDraw).width;
    if (areaText.scrollStatus == ScrollStatusEnum.ON) {
      if (areaText[`subscription${canvasDisplayId}`]) {
        areaText[`subscription${canvasDisplayId}`].unsubscribe();
      }
      const observable = interval(50);
      const subscription =
        canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
          ? observable
              .pipe(
                takeUntil(this.clearPreviewDisplay1Subject),
                takeUntil(this.pausePreviewDisplay1Subject),
                repeatWhen(() => this.startPreviewDisplay1Subject)
              )
              .subscribe(() => {
                if (this.isPlay) {
                  this.drawTextScrollVertical(ctx, areaText, text, charsSplit, referenceX, referenceY, widthMeasureText);
                }
              })
          : observable
              .pipe(
                takeUntil(this.clearPreviewDisplay2Subject),
                takeUntil(this.pausePreviewDisplay2Subject),
                repeatWhen(() => this.startPreviewDisplay2Subject)
              )
              .subscribe(() => {
                if (this.isPlay) {
                  this.drawTextScrollVertical(ctx, areaText, text, charsSplit, referenceX, referenceY, widthMeasureText);
                }
              });
      areaText[`subscription${canvasDisplayId}`] = subscription;
      areaText[`clearPreviewSubject${canvasDisplayId}`] =
        canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
          ? this.clearPreviewDisplay1Subject.subscribe(() => {
              this.resetPositionScrollText(areaText);
              subscription.unsubscribe();
              areaText[`clearPreviewSubject${canvasDisplayId}`].unsubscribe();
            })
          : this.clearPreviewDisplay2Subject.subscribe(() => {
              this.resetPositionScrollText(areaText);
              subscription.unsubscribe();
              areaText[`clearPreviewSubject${canvasDisplayId}`].unsubscribe();
            });
    } else {
      areaText[`subscription${canvasDisplayId}`]?.unsubscribe();
    }
  }

  /**
   * draw text scroll vertical
   * @param ctx
   * @param areaText
   * @param text
   * @param charsSplit
   * @param referenceX
   * @param referenceY
   * @param widthMeasureText
   */
  private drawTextScrollVertical(ctx, areaText, text, charsSplit, referenceX, referenceY, widthMeasureText): void {
    const textDraw = text ?? this.getTimer();
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.fontColor;
    var i = 0;
    var ch: string;
    var posY = 0.5;
    while ((ch = charsSplit[i++])) {
      var chWidth = ctx.measureText(ch).width;
      ctx.fillText(ch, referenceX - chWidth / 2 + areaText.posXScroll, referenceY + posY * areaText.fontSize + areaText.posYScroll);
      posY++;
    }
    this.calcPositionScrollVertical(areaText, widthMeasureText, referenceX, textDraw);
  }

  /**
   * draw text orientation sideways
   * @param ctx
   * @param areaText TextArea
   * @param text
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private drawTextOrientationSideways(ctx: any, areaText: TextArea, text?: string, canvasDisplayId?: any): void {
    var textDraw = text ?? this.getTimer();
    let widthMeasureText = ctx.measureText(textDraw).width;
    const referencePosition = Helper.getReferencePositionOrientationSideways(ctx, areaText, widthMeasureText);
    const referenceX = referencePosition.referenceX;
    const referenceY = referencePosition.referenceY;
    ctx.rotate(Math.PI / 2);
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // draw color text
    ctx.fillStyle = areaText.fontColor;
    ctx.fillText(textDraw, referenceX + areaText.posYScroll, referenceY - areaText.posXScroll);
    ctx.resetTransform();
    if (areaText.scrollStatus == ScrollStatusEnum.ON) {
      if (areaText[`subscription${canvasDisplayId}`]) {
        areaText[`subscription${canvasDisplayId}`].unsubscribe();
      }
      const observable = interval(50);
      const subscription =
        canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
          ? observable
              .pipe(
                takeUntil(this.clearPreviewDisplay1Subject),
                takeUntil(this.pausePreviewDisplay1Subject),
                repeatWhen(() => this.startPreviewDisplay1Subject)
              )
              .subscribe(() => {
                if (this.isPlay) {
                  this.drawTextScrollSideways(ctx, areaText, text, referenceX, referenceY, widthMeasureText);
                }
              })
          : observable
              .pipe(
                takeUntil(this.clearPreviewDisplay2Subject),
                takeUntil(this.pausePreviewDisplay2Subject),
                repeatWhen(() => this.startPreviewDisplay2Subject)
              )
              .subscribe(() => {
                if (this.isPlay) {
                  this.drawTextScrollSideways(ctx, areaText, text, referenceX, referenceY, widthMeasureText);
                }
              });
      areaText[`subscription${canvasDisplayId}`] = subscription;
      areaText[`clearPreviewSubject${canvasDisplayId}`] =
        canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
          ? this.clearPreviewDisplay1Subject.subscribe(() => {
              this.resetPositionScrollText(areaText);
              subscription.unsubscribe();
              areaText[`clearPreviewSubject${canvasDisplayId}`].unsubscribe();
            })
          : this.clearPreviewDisplay2Subject.subscribe(() => {
              this.resetPositionScrollText(areaText);
              subscription.unsubscribe();
              areaText[`clearPreviewSubject${canvasDisplayId}`].unsubscribe();
            });
    } else {
      areaText[`subscription${canvasDisplayId}`]?.unsubscribe();
    }
  }

  /**
   * draw text scroll sideways
   * @param ctx
   * @param areaText
   * @param text
   * @param referenceX
   * @param referenceY
   * @param widthMeasureText
   */
  private drawTextScrollSideways(ctx, areaText, text, referenceX, referenceY, widthMeasureText): void {
    const textDraw = text ?? this.getTimer();
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.rotate(Math.PI / 2);
    ctx.fillStyle = areaText.fontColor;
    ctx.fillText(textDraw, referenceX + areaText.posYScroll, referenceY - areaText.posXScroll);
    this.calcPositionScrollSideway(areaText, widthMeasureText, referenceX);
    ctx.resetTransform();
  }

  /**
   * draw clock preview
   * @param area Area
   * @param display Template
   * @param canvasDisplayId
   */
  private drawClock(area: Area, renderer: Renderer2, canvasDisplayId) {
    let areaText = area as TextArea;
    renderer.setStyle(area.canvas, 'visibility', 'visible');
    if (areaText.scrollStatus == ScrollStatusEnum.OFF) {
      var time = setInterval(() => {
        // update timezone
        const textFill = this.getTimer();
        // draw areaText
        this.drawAreaText(areaText, canvasDisplayId, textFill);
      }, 50);
    } else {
      // draw areaText
      this.drawAreaText(areaText, canvasDisplayId);
    }
  }

  /**
   * reset position scroll text
   * @param textArea
   */
  private resetPositionScrollText(textArea: TextArea): void {
    textArea.posXScroll = 0;
    textArea.posYScroll = 0;
    textArea.timesScroll = 0;
  }

  /**
   * get timer
   */
  private getTimer(): string {
    // update timezone
    var offsetHour = 0;
    var offsetMinute = 0;
    var setting = this.commonService.getCommonObject().setting;
    if (setting) {
      offsetHour = setting.timezone.offsetHour;
      offsetMinute = setting.timezone.offsetMinute;
    }
    return moment
      .utc()
      .add(offsetHour, 'hour')
      .add(offsetMinute, 'minute')
      .format('HH:mm');
  }

  /**
   * draw fix picture preview
   * @param area Area
   * @param renderer Renderer2
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private async drawAreaFixPicture(areaPicture: PictureArea, renderer: Renderer2, canvasDisplayId) {
    renderer.setStyle(areaPicture.canvas, 'visibility', 'visible');
    if (areaPicture.media) {
      // draw areaPicture
      this.drawAreaPicture(areaPicture, canvasDisplayId);
    } else {
      this.playAudio(areaPicture, canvasDisplayId);
    }
  }

  /**
   * draw area picture
   * @param areaPicture PictureArea object
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private async drawAreaPicture(areaPicture: PictureArea, canvasDisplayId) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/octet-stream');
    myHeaders.append('Accept', '*/*');
    myHeaders.append('Access-Control-Allow-Origin', '*');
    myHeaders.append('Access-Control-Allow-Headers', '*');
    const myRequest = new Request(areaPicture.media.url, {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin'
    });
    await fetch(myRequest)
      .then(response => response.blob())
      .then(blob => createImageBitmap(blob))
      .then(bitmap => {
        areaPicture.canvas._img = bitmap;
      });
    // draw canvas
    let ctx = areaPicture.canvas.getContext('2d');
    ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);

    let pattern = ctx.createPattern(areaPicture.canvas._img, 'no-repeat');
    ctx.fillStyle = pattern;
    let mediaPosition = Helper.coverMedia(areaPicture.canvas, areaPicture.media, areaPicture.objectFit);
    if (areaPicture.objectFit == ObjectFitEnum.FILL) {
      ctx.drawImage(areaPicture.canvas._img, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
    } else {
      ctx.drawImage(
        areaPicture.canvas._img,
        mediaPosition.sX,
        mediaPosition.sY,
        mediaPosition.sWidth,
        mediaPosition.sHeight,
        mediaPosition.x,
        mediaPosition.y,
        mediaPosition.width,
        mediaPosition.height
      );
    }
    this.playAudio(areaPicture, canvasDisplayId);
  }

  /**
   * play audio
   * @param areaPicture PictureArea
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  public async playAudio(areaPicture: PictureArea, canvasDisplayId) {
    if (!areaPicture?.isFix) {
      return;
    }
    // play audio;
    if (!areaPicture.soundAPreview && areaPicture.soundA?.url) {
      areaPicture.soundAPreview = new Audio(areaPicture.soundA.url);
      areaPicture.soundAPreview.load();
    }
    if (!areaPicture.soundBPreview && areaPicture.soundB?.url) {
      areaPicture.soundBPreview = new Audio(areaPicture.soundB.url);
      areaPicture.soundBPreview.load();
    }
    if (this.isPlay) {
      areaPicture?.soundAPreview?.play();
      areaPicture?.soundBPreview?.play();
    }
    // play again
    if (areaPicture?.soundAPreview) {
      areaPicture.soundAPreview.onplay = () => {
        this.playSoundAgain(areaPicture, canvasDisplayId);
      };
    }
    if (areaPicture?.soundAPreview) {
      areaPicture.soundAPreview.onplay = () => {
        this.playSoundAgain(areaPicture, canvasDisplayId);
      };
    }
    // unsubscribe
    this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
    areaPicture[`pausePreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
        ? this.pausePreviewDisplay1Subject.subscribe(() => {
            this.pauseAudio(areaPicture);
          })
        : this.pausePreviewDisplay2Subject.subscribe(() => {
            this.pauseAudio(areaPicture);
          });
    areaPicture[`clearPreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
        ? this.clearPreviewDisplay1Subject.subscribe(() => {
            this.stopAudio(areaPicture);
            this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
          })
        : this.clearPreviewDisplay2Subject.subscribe(() => {
            this.stopAudio(areaPicture);
            this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
          });
    areaPicture[`startPreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
        ? this.startPreviewDisplay1Subject.subscribe(() => {
            this.playAudio(areaPicture, canvasDisplayId);
          })
        : this.startPreviewDisplay2Subject.subscribe(() => {
            this.playAudio(areaPicture, canvasDisplayId);
          });
  }

  /**
   * pause audio
   * @param areaPicture PictureArea
   */
  private pauseAudio(areaPicture: PictureArea): void {
    if (!areaPicture?.isFix) {
      return;
    }
    // pause
    if (areaPicture.soundAPreview) {
      areaPicture.soundAPreview.pause();
    }
    if (areaPicture.soundBPreview) {
      areaPicture.soundBPreview.pause();
    }
  }

  /**
   * stop audio
   * @param areaPicture PictureArea
   */
  private stopAudio(areaPicture: PictureArea): void {
    if (!areaPicture?.isFix) {
      return;
    }
    // stop
    if (areaPicture.soundAPreview) {
      areaPicture.soundAPreview.pause();
      areaPicture.soundAPreview.currentTime = 0;
      areaPicture.soundAPreview.load();
    }
    if (areaPicture.soundBPreview) {
      areaPicture.soundBPreview.pause();
      areaPicture.soundBPreview.currentTime = 0;
      areaPicture.soundBPreview.load();
    }
  }

  /**
   * play sound
   * @param mediaSound Media
   * @param areaPicture PictureArea
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private playSound(areaPicture: PictureArea, mediaSound?: Media, canvasDisplayId?: any): void {
    const url = mediaSound ? mediaSound?.url : areaPicture?.media?.url;
    if (!url) {
      return;
    }
    if (!areaPicture[`sound${canvasDisplayId}`]) {
      areaPicture[`sound${canvasDisplayId}`] = document.createElement('audio');
    }
    if (!areaPicture[`sound${canvasDisplayId}`]?.src) {
      areaPicture[`sound${canvasDisplayId}`].src = url;
    }
    if (this.isPlay) {
      areaPicture[`sound${canvasDisplayId}`]?.play();
    }
    // play again
    areaPicture[`sound${canvasDisplayId}`].onplay = () => {
      this.playSoundAgain(areaPicture, canvasDisplayId);
    };
    this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
    areaPicture[`pausePreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
        ? this.pausePreviewDisplay1Subject.subscribe(() => {
            this.pauseSound(areaPicture, canvasDisplayId);
          })
        : this.pausePreviewDisplay2Subject.subscribe(() => {
            this.pauseSound(areaPicture, canvasDisplayId);
          });
    areaPicture[`clearPreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
        ? this.clearPreviewDisplay1Subject.subscribe(() => {
            this.stopSound(areaPicture, canvasDisplayId);
            this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
          })
        : this.clearPreviewDisplay2Subject.subscribe(() => {
            this.stopSound(areaPicture, canvasDisplayId);
            this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
          });
    areaPicture[`startPreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
        ? this.startPreviewDisplay1Subject.subscribe(() => {
            if (mediaSound) {
              this.playSound(areaPicture, mediaSound, canvasDisplayId);
            } else {
              this.playSound(areaPicture, undefined, canvasDisplayId);
            }
          })
        : this.startPreviewDisplay2Subject.subscribe(() => {
            if (mediaSound) {
              this.playSound(areaPicture, mediaSound, canvasDisplayId);
            } else {
              this.playSound(areaPicture, undefined, canvasDisplayId);
            }
          });
  }

  /**
   * play again when ended
   * @param areaPicture
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private playSoundAgain(areaPicture: PictureArea, canvasDisplayId): void {
    // sound A
    if (areaPicture?.soundAPreview && areaPicture.soundAPreview.ended) {
      areaPicture.soundAPreview.currentTime = 0;
      areaPicture.soundAPreview.play().then(() => this.playSoundAgain(areaPicture, canvasDisplayId));
    }
    // sound B
    if (areaPicture?.soundBPreview && areaPicture.soundBPreview.ended) {
      areaPicture.soundBPreview.currentTime = 0;
      areaPicture.soundBPreview.play().then(() => this.playSoundAgain(areaPicture, canvasDisplayId));
    }
    // audio attach image
    if (areaPicture[`sound${canvasDisplayId}`] && areaPicture[`sound${canvasDisplayId}`].ended) {
      areaPicture[`sound${canvasDisplayId}`].currentTime = 0;
      areaPicture[`sound${canvasDisplayId}`].play().then(() => this.playSoundAgain(areaPicture, canvasDisplayId));
    }
    if (areaPicture[`animationId${canvasDisplayId}`]) {
      cancelAnimationFrame(areaPicture[`animationId${canvasDisplayId}`]);
    }
    areaPicture[`animationId${canvasDisplayId}`] = requestAnimationFrame(() => this.playSoundAgain(areaPicture, canvasDisplayId));
  }

  /**
   * stop sound
   * @param areaPicture PictureArea
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private stopSound(areaPicture: PictureArea, canvasDisplayId): void {
    if (!areaPicture?.[`sound${canvasDisplayId}`]) {
      return;
    }
    if (areaPicture[`sound${canvasDisplayId}`].currentTime > 0) {
      areaPicture[`sound${canvasDisplayId}`].pause();
      areaPicture[`sound${canvasDisplayId}`].currentTime = 0;
    }
    delete areaPicture[`sound${canvasDisplayId}`];
  }

  /**
   * pause sound
   * @param areaPicture PictureArea
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private pauseSound(areaPicture: PictureArea, canvasDisplayId): void {
    if (!areaPicture?.[`sound${canvasDisplayId}`]) {
      return;
    }
    if (areaPicture[`sound${canvasDisplayId}`].currentTime > 0) {
      areaPicture[`sound${canvasDisplayId}`].pause();
    }
  }

  /**
   * draw video fix picture preview
   * @param area Area
   * @param renderer Renderer2
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private async drawVideoFixPicture(areaPicture: PictureArea, renderer: Renderer2, canvasDisplayId) {
    renderer.setStyle(areaPicture.canvas, 'visibility', 'visible');
    if (areaPicture.media) {
      let ctx = areaPicture.canvas.getContext('2d');
      ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
      let mediaPos = Helper.coverMedia(areaPicture.canvas, areaPicture.media, areaPicture.objectFit);
      this.drawVideo(areaPicture, ctx, mediaPos, areaPicture.canvas, canvasDisplayId);
    }
  }

  /**
   * drawVideo
   * @param areaPicture PictureArea
   * @param ctx
   * @param mediaPos any
   * @param canvas
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private async drawVideo(areaPicture: PictureArea, ctx: any, mediaPos: any, canvas: any, canvasDisplayId) {
    if (!areaPicture.media) {
      return;
    }
    if (!areaPicture.videoPreview) {
      areaPicture.videoPreview = document.createElement('video');
    }
    areaPicture.videoPreview.src = '';
    if (areaPicture.videoPreview.src != areaPicture?.media?.url) {
      areaPicture.videoPreview.src = areaPicture?.media?.url ?? '';
      function drawOrigin() {
        areaPicture.videoPreview?.addEventListener(
          'loadeddata',
          function() {
            areaPicture.videoPreview.currentTime = 0;
          },
          false
        );
        areaPicture.videoPreview?.addEventListener(
          'seeked',
          function() {
            if (areaPicture.objectFit == ObjectFitEnum.FILL) {
              ctx.drawImage(areaPicture.videoPreview, mediaPos.x, mediaPos.y, mediaPos.width, mediaPos.height);
            } else {
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(
                areaPicture.videoPreview,
                mediaPos.sX,
                mediaPos.sY,
                mediaPos.sWidth,
                mediaPos.sHeight,
                mediaPos.x,
                mediaPos.y,
                mediaPos.width,
                mediaPos.height
              );
            }
          },
          false
        );
        return;
      }
      await areaPicture.videoPreview.addEventListener('timeupdate', drawOrigin, false);
      if (this.isPlay) {
        areaPicture.videoPreview.play();
      }
      areaPicture.videoPreview.onplay = () => {
        this.animationDrawVideo(ctx, areaPicture, mediaPos, canvas, canvasDisplayId);
      };
    }
    this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
    areaPicture[`startPreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
        ? this.startPreviewDisplay1Subject.subscribe(() => areaPicture.videoPreview.play())
        : this.startPreviewDisplay2Subject.subscribe(() => areaPicture.videoPreview.play());
    areaPicture[`pausePreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
        ? this.pausePreviewDisplay1Subject.subscribe(() => areaPicture.videoPreview.pause())
        : this.pausePreviewDisplay2Subject.subscribe(() => areaPicture.videoPreview.pause());
    areaPicture[`clearPreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID
        ? this.clearPreviewDisplay1Subject.subscribe(() => {
            this.pausePreviewDisplay1Subject.next();
            this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
          })
        : this.clearPreviewDisplay2Subject.subscribe(() => {
            this.pausePreviewDisplay2Subject.next();
            this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
          });
  }

  /**
   * animation draw area
   * @param ctx
   * @param areaPicture
   * @param mediaPos
   * @param canvas
   * @param canvasDisplayId
   */
  private animationDrawVideo(ctx: any, areaPicture: PictureArea, mediaPos: any, canvas: any, canvasDisplayId): void {
    if (areaPicture.media && areaPicture.media.type != TypeMediaFileEnum.MP4) {
      this.stopVideo(areaPicture, canvasDisplayId);
      return;
    }

    if (areaPicture.videoPreview?.ended) {
      areaPicture.videoPreview.currentTime = 0;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      areaPicture.videoPreview.play().then(() => this.animationDrawVideo(ctx, areaPicture, mediaPos, canvas, canvasDisplayId));
    }

    if (!areaPicture?.videoPreview?.src || areaPicture?.videoPreview?.src == '') {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      return;
    }
    if (areaPicture.objectFit == ObjectFitEnum.FILL) {
      ctx.drawImage(areaPicture.videoPreview, mediaPos.x, mediaPos.y, mediaPos.width, mediaPos.height);
    } else {
      ctx.drawImage(
        areaPicture.videoPreview,
        mediaPos.sX,
        mediaPos.sY,
        mediaPos.sWidth,
        mediaPos.sHeight,
        mediaPos.x,
        mediaPos.y,
        mediaPos.width,
        mediaPos.height
      );
    }
    if (areaPicture.videoPreview?.paused || !this.isPlay) {
      areaPicture.videoPreview && areaPicture.videoPreview.pause();
    }
    if (areaPicture[`animationId${canvasDisplayId}`]) {
      cancelAnimationFrame(areaPicture[`animationId${canvasDisplayId}`]);
    }
    areaPicture[`animationId${canvasDisplayId}`] = requestAnimationFrame(() =>
      this.animationDrawVideo(ctx, areaPicture, mediaPos, canvas, canvasDisplayId)
    );
  }

  /**
   * stop video
   * @param areaPicture PictureArea
   * @param canvasDisplayId
   */
  private stopVideo(areaPicture: PictureArea, canvasDisplayId): void {
    if (areaPicture.videoPreview) {
      let ctx = areaPicture.canvas.getContext('2d');
      ctx.clearRect(0, 0, areaPicture.width, areaPicture.height);
      cancelAnimationFrame(areaPicture[`animationId${canvasDisplayId}`]);
      areaPicture.videoPreview.pause();
      areaPicture.videoPreview.currentTime = 0;
      delete areaPicture.videoPreview;
    }
  }

  /**
   * clear canvas
   * @param canvasLayoutRealTime
   */
  public clearCanvas(canvasLayoutRealTime) {
    if (!canvasLayoutRealTime) {
      return;
    }
    let ctx = canvasLayoutRealTime.getContext('2d');
    ctx.clearRect(0, 0, canvasLayoutRealTime.width, canvasLayoutRealTime.height);
  }

  /**
   * clear canvas
   * @param template
   * @param canvasDisplayId
   */
  public clearCanvasAreas(template: Template, canvasDisplayId) {
    if (!template) {
      return;
    }
    let areas = Helper.getAllAreaTemplate(template);
    let pictureAreas = areas
      .filter(area => !area.checkTypeTextArea() && !area.isFix && area.getArea().attribute == LinkDataPictureEnum.SIGNAGE_CHANNEL)
      .map(areaData => areaData.getArea() as PictureArea);
    pictureAreas.forEach(pictureArea => {
      if (pictureArea?.media && pictureArea.media instanceof Video) {
        this.stopVideo(pictureArea, canvasDisplayId);
      }
      this.unsubscribedSubjectArea(pictureArea, canvasDisplayId);
      this.clearCanvas(pictureArea?.canvas);
    });
  }

  /**
   * unsubscribed Subject in Area
   * @param area area unsubscribe
   * @param canvasDisplayId
   */
  private unsubscribedSubjectArea(area: Area, canvasDisplayId): void {
    if (area[`pausePreviewSubject${canvasDisplayId}`]) {
      area[`pausePreviewSubject${canvasDisplayId}`].unsubscribe();
    }
    if (area[`clearPreviewSubject${canvasDisplayId}`]) {
      area[`clearPreviewSubject${canvasDisplayId}`].unsubscribe();
    }
    if (area[`startPreviewSubject${canvasDisplayId}`]) {
      area[`startPreviewSubject${canvasDisplayId}`].unsubscribe();
    }
  }

  /**
   * clear all threads draw
   * @param template Template
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  public clearAllThreadDrawTemplate(template: Template, canvasDisplayId): void {
    if (!template) {
      return;
    }
    const areas = Helper.getAllAreaTemplate(template);
    areas.forEach(area => {
      if (area[`subscription${canvasDisplayId}`]) {
        area[`subscription${canvasDisplayId}`].unsubscribe();
        delete area[`subscription${canvasDisplayId}`];
      }
    });
    if (canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID) {
      this.clearPreviewDisplay1Subject.next();
    } else {
      this.clearPreviewDisplay2Subject.next();
    }
  }

  /**
   * create all canvas area template
   * @param template Template
   * @param canvasContainerDisplay ElementRef
   * @param renderer
   */
  public createAllCanvasAreaTemplate(template: Template, canvasContainerDisplay: any, renderer: Renderer2): void {
    template?.layers &&
      template.layers.forEach(layer => {
        layer.areas.forEach(area => {
          this.createCanvasArea(area, canvasContainerDisplay, renderer);
        });
      });
  }

  /**
   * create canvas area
   * @param area Area
   * @param canvasContainerDisplay ElementRef
   * @param renderer Renderer2
   */
  public createCanvasArea(area: Area, canvasContainerDisplay: any, renderer: Renderer2): void {
    if (
      area.getArea().attribute == LinkDataPictureEnum.EMERGENCY_MESSAGE ||
      area.getArea().linkReferenceData == LinkDataTextEnum.EMERGENCY_MESSAGE
    ) {
      return;
    }
    const canvas = renderer.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.zIndex = area.index;
    canvas.style.left = area.posX + 'px';
    canvas.style.top = area.posY + 'px';
    canvas.style.width = area.width + 'px';
    canvas.style.height = area.height + 'px';
    canvas.width = area.width;
    canvas.height = area.height;
    if (area.isOnClickEvent) {
      canvas.style.cursor = 'pointer';
      let $this = this;
      canvas.addEventListener('click', function() {
        if (
          canvasContainerDisplay.nativeElement.id == Constant.CANVAS_DISPLAY_1_ID &&
          $this.style.display1Templates[area.onClickEventDestination]
        ) {
          $this.toSwitchBetweenPage.emit({ key: Constant.CANVAS_DISPLAY_1_ID, value: area.onClickEventDestination });
        }
        if (
          canvasContainerDisplay.nativeElement.id == Constant.CANVAS_DISPLAY_2_ID &&
          $this.style.display2Templates[area.onClickEventDestination]
        ) {
          $this.toSwitchBetweenPage.emit({ key: Constant.CANVAS_DISPLAY_2_ID, value: area.onClickEventDestination });
        }
      });
    }
    renderer.appendChild(canvasContainerDisplay.nativeElement, canvas);
    area.canvas = canvas;
  }

  /**
   * calculate position scroll Vertical
   * @param areaText TextArea
   * @param widthMeasureText width Measure Text
   * @param referenceX
   * @param str value
   */
  private calcPositionScrollVertical(areaText: TextArea, widthMeasureText, referenceX, str): void {
    var heightText = str.split('').length * areaText.fontSize;
    switch (areaText.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        areaText.posXScroll -= areaText.scrollSpeed / 20;
        if (areaText.posXScroll < -(widthMeasureText + referenceX)) {
          areaText.posXScroll = areaText.width + areaText.fontSize * 1.5 - referenceX;
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.RIGHT:
        areaText.posXScroll += areaText.scrollSpeed / 20;
        if (areaText.posXScroll > areaText.width) {
          areaText.posXScroll = -areaText.fontSize * 1.5 - referenceX;
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.UP:
        areaText.posYScroll -= areaText.scrollSpeed / 20;
        if (areaText.posYScroll < -heightText) {
          areaText.posYScroll = areaText.height;
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.DOWN:
        areaText.posYScroll += areaText.scrollSpeed / 20;
        if (areaText.posYScroll > areaText.height + heightText) {
          areaText.posYScroll = -heightText;
          areaText.timesScroll++;
        }
        break;
      default:
        break;
    }
  }

  /**
   * calculate position scroll Horizontal
   * @param areaText TextArea
   * @param widthMeasureText width Measure Text
   * @param referenceX
   * @param referenceY
   */
  private calcPositionScrollHorizontal(areaText: TextArea, widthMeasureText, referenceX, referenceY): void {
    switch (areaText.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        areaText.posXScroll -= areaText.scrollSpeed / 20;
        if (areaText.posXScroll < -(widthMeasureText + referenceX)) {
          areaText.posXScroll = areaText.width + widthMeasureText - referenceX;
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.RIGHT:
        areaText.posXScroll += areaText.scrollSpeed / 20;
        if (areaText.posXScroll > areaText.width) {
          areaText.posXScroll = -(widthMeasureText + referenceX);
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.UP:
        areaText.posYScroll -= areaText.scrollSpeed / 20;
        if (areaText.posYScroll < -areaText.fontSize * 1.5 - referenceY) {
          areaText.posYScroll = areaText.height - referenceY + areaText.fontSize;
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.DOWN:
        areaText.posYScroll += areaText.scrollSpeed / 20;
        if (areaText.posYScroll > areaText.height + areaText.fontSize * 1.5 - referenceY) {
          areaText.posYScroll = -areaText.fontSize * 1.5 - referenceY;
          areaText.timesScroll++;
        }
        break;
      default:
        break;
    }
  }

  /**
   * calculate position scroll Sideway
   * @param areaText TextArea
   * @param widthMeasureText width Measure Text
   * @param referenceX
   */
  private calcPositionScrollSideway(areaText: TextArea, widthMeasureText, referenceX): void {
    switch (areaText.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        areaText.posXScroll -= areaText.scrollSpeed / 20;
        if (areaText.posXScroll < -(areaText.width + referenceX)) {
          areaText.posXScroll = areaText.width + areaText.fontSize * 1.5;
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.RIGHT:
        areaText.posXScroll += areaText.scrollSpeed / 20;
        if (areaText.posXScroll > areaText.width + areaText.fontSize) {
          areaText.posXScroll = -(areaText.width + areaText.fontSize * 1.5);
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.UP:
        areaText.posYScroll -= areaText.scrollSpeed / 20;
        if (areaText.posYScroll < -(areaText.height + widthMeasureText + referenceX)) {
          areaText.posYScroll = widthMeasureText;
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.DOWN:
        areaText.posYScroll += areaText.scrollSpeed / 20;
        if (areaText.posYScroll > areaText.height) {
          areaText.posYScroll = -areaText.height - widthMeasureText;
        }
        break;
      default:
        break;
    }
  }

  /**
   * draw signage channel
   * @param pictureAreas
   * @param media
   * @param renderer
   * @param canvasDisplayId
   */
  public async drawSignageChannel(pictureArea: PictureArea, media: Media, renderer, canvasDisplayId) {
    if (media instanceof Image) {
      // draw image
      pictureArea.media = media;
      await this.drawAreaPicture(pictureArea, canvasDisplayId);
    } else if (media instanceof Video) {
      // draw video
      pictureArea.media = media;
      await this.drawVideoFixPicture(pictureArea, renderer, canvasDisplayId);
    } else if (media instanceof Sequence) {
      // Code here
    }
  }

  /**
   * draw areas signage channel
   * @param pictureAreas
   * @param renderer
   * @param canvasDisplayId
   */
  public drawAreasSignageChannel(display: Template, renderer, canvasDisplayId): void {
    if (!this.mediaSetting) {
      return;
    }
    let areas = Helper.getAllAreaTemplate(display);
    let pictureAreas = areas
      .filter(area => !area.checkTypeTextArea() && !area.isFix && area.getArea().attribute == LinkDataPictureEnum.SIGNAGE_CHANNEL)
      .map(areaData => areaData.getArea() as PictureArea);
    Promise.all(
      pictureAreas.map(async pictureArea => {
        await this.drawSignageChannel(pictureArea, this.mediaSetting, renderer, canvasDisplayId);
      })
    );
  }
}
