import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { DeliveryJob } from 'app/model/entity/delivery-job';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryJobService {
  /**
   * URL for delivery-job API
   */
  deliveryJobUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/delivery-jobs';
  /**
   * url customer api
   */
  customerUrl: string;

  constructor(private http: HttpClient, private commonService: CommonService) {
    this.customerUrl = commonService.getCommonObject().customerApiUrl;
  }

  /**
   * Be used by DELIVERY-MANAGER
   * get all jobs
   */
  getDeliveryJobs(): Observable<DeliveryJob[]> {
    return this.http.get<DeliveryJob[]>(`${this.deliveryJobUrl}/get-all-jobs`);
  }

  /**
   * Be used by DELIVERY-MANAGER
   * create new delivery job
   *
   * @param job
   */
  createDeliveryJob(job: DeliveryJob): Observable<DeliveryJob> {
    return this.http.post<DeliveryJob>(`${this.deliveryJobUrl}/add`, job);
  }

  /**
   * Be used by DELIVERY-MANAGER
   * update progress first time for jobs
   *
   * @param deliveryJobs
   */
  updateProgressFirstTimeForJobs(deliveryJobs: DeliveryJob[]): Observable<DeliveryJob[]> {
    return this.http.post<DeliveryJob[]>(`${this.deliveryJobUrl}/first-time/update-progress-for-jobs`, deliveryJobs);
  }

  /**
   * Be used by DELIVERY-MANAGER
   * update progress for jobs
   *
   * @param deliveryJobs
   */
  updateProgressForJobs(deliveryJobs: DeliveryJob[]): Observable<DeliveryJob[]> {
    return this.http.post<DeliveryJob[]>(`${this.deliveryJobUrl}/update-progress-for-jobs`, deliveryJobs);
  }

  /**
   * Be used by DELIVERY-MANAGER
   * update progress first time for detailed job
   *
   * @param deliveryJob
   */
  updateProgressFirstTimeForDetailedJob(deliveryJob: DeliveryJob): Observable<DeliveryJob> {
    return this.http.post<DeliveryJob>(`${this.deliveryJobUrl}/first-time/update-progress-for-detailed-job`, deliveryJob);
  }

  /**
   * Be used by DELIVERY-MANAGER
   * update progress for detailed job
   *
   * @param deliveryJob
   */
  updateProgressForDetailedJob(deliveryJob: DeliveryJob): Observable<DeliveryJob> {
    return this.http.post<DeliveryJob>(`${this.deliveryJobUrl}/update-progress-for-detailed-job`, deliveryJob);
  }

  /**
   * Be used by DELIVERY-MANAGER
   * save change status delivery jobs
   *
   * @param checkedDeliveryJobs
   */
  saveCancelledDeliveryJobs(checkedDeliveryJobs: DeliveryJob[]) {
    return this.http.post(`${this.deliveryJobUrl}/save-cancelled-delivery-jobs`, checkedDeliveryJobs);
  }

  /**
   * Be used by DELIVERY-MANAGER
   * delete delivery job by jobId
   *
   * @param jobId
   */
  deleteDeliveryJob(jobId: string) {
    const params = new HttpParams().set('jobId', jobId);
    return this.http.delete(`${this.deliveryJobUrl}/delete`, { params });
  }

  /**
   * Be used by DELIVERY-MANAGER
   * delete all removable job
   *
   * @param deletedJobIds
   */
  deleteAllRemovableJobs(deletedJobIds: string[]) {
    return this.http.post(`${this.deliveryJobUrl}/delete-all-removable-jobs`, deletedJobIds);
  }

  /**
   * Be used by DELIVERY-MANAGER
   * get jobId
   *
   * @param registrationIds
   */
  deliveryByCusApi(registrationIds: string[]): Observable<any> {
    const payload = JSON.stringify({ userid: this.commonService.getCommonObject().userName, id: registrationIds });
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.customerUrl}/delivery`, payload, { headers: headers });
  }

  /**
   * Be used by DELIVERY-MANAGER
   * cancel delivery job
   *
   * @param jobIds
   */
  cancelDeliveryJobByCusApi(jobId: string) {
    const payload = JSON.stringify({ jobId: jobId });
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.customerUrl}/cancel`, payload, { headers: headers });
  }

  /**
   * Be used by DELIVERY-MANAGER
   * delete delivery job
   *
   * @param jobId
   * @param id
   */
  deleteDeliveryJobByCusApi(jobId: string) {
    const payload = JSON.stringify({ jobId: jobId });
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.customerUrl}/delete`, payload, { headers: headers });
  }
}
