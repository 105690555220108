import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { OperationInformationSetting } from 'app/model/entity/operation-information-setting';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for OperationInformationSetting
 */
export class OperationInformationSettingService {
  /**
   * URL for operation information setting API
   */
  private operationInfoUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/operation-information-setting';

  constructor(private http: HttpClient) {}

  /**
   * Get all operation information setting
   */
  getOperationInfos(): Observable<Array<OperationInformationSetting>> {
    return this.http.get<Array<OperationInformationSetting>>(`${this.operationInfoUrl}/get-all`);
  }

  /**
   * Get all operation information setting
   */
  getOperationInfosSchedule(): Observable<Array<OperationInformationSetting>> {
    return this.http.get<Array<OperationInformationSetting>>(`${this.operationInfoUrl}/get-all-schedule`);
  }

  /**
   * save a operation information setting
   * @param operationInfo
   */
  saveOperationInfo(operationInfo: OperationInformationSetting): Observable<OperationInformationSetting> {
    return this.http.post<OperationInformationSetting>(`${this.operationInfoUrl}/save`, operationInfo);
  }

  /**
   * save list operation information setting
   * @param operationInfos
   */
  saveOperationInfos(operationInfos: Array<OperationInformationSetting>): Observable<void> {
    return this.http.post<void>(`${this.operationInfoUrl}/save-operations`, operationInfos);
  }

  /**
   * save a operation information setting
   * @param operationInfo
   */
  saveOperationInfoSchedule(operationInfo: OperationInformationSetting): Observable<OperationInformationSetting> {
    return this.http.post<OperationInformationSetting>(`${this.operationInfoUrl}/save-schedule`, operationInfo);
  }

  /**
   * save list operation information setting
   * @param operationInfos
   */
  saveOperationInfosSchedule(operationInfos: Array<OperationInformationSetting>): Observable<void> {
    return this.http.post<void>(`${this.operationInfoUrl}/save-operations-schedule`, operationInfos);
  }

  /**
   * delete operation information setting by id
   * @param operationInfoId
   */
  deleteOperationInfo(operationInfoId: Number): Observable<boolean> {
    const params = new HttpParams().set('operationInfoId', `${operationInfoId}`);
    return this.http.delete<boolean>(`${this.operationInfoUrl}/delete`, { params });
  }

  /**
   * delete operation information setting by id
   * @param operationInfoId
   */
  deleteOperationInfoSchedule(operationInfoId: Number): Observable<boolean> {
    const params = new HttpParams().set('operationInfoId', `${operationInfoId}`);
    return this.http.delete<boolean>(`${this.operationInfoUrl}/delete-schedule`, { params });
  }

  /**
   * check exist text
   *
   * @param text
   * @param operationInfoId
   * @return
   */
  checkExistText(operationInfoId: Number, text: string) {
    const params = new HttpParams().set('text', `${text}`).set('operationInfoId', `${operationInfoId}`);
    return this.http.get<boolean>(`${this.operationInfoUrl}/check-exist-text`, { params });
  }

  /**
   * check exist text
   *
   * @param text
   * @param operationInfoId
   * @return
   */
  checkExistTextSchedule(operationInfoId: Number, text: string) {
    const params = new HttpParams().set('text', `${text}`).set('operationInfoId', `${operationInfoId}`);
    return this.http.get<boolean>(`${this.operationInfoUrl}/check-exist-text-schedule`, { params });
  }
}
