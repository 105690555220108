import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { APIDetails } from 'app/model/entity/api-details';
import { OpenWeatherContentDetail } from 'app/model/entity/open-weather-content-detail';
import { WeatherContentDetail } from 'app/model/entity/weather-content-detail';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WeatherContentDetailService {
  /**
   * URL for weather-content-detail API
   */
  private readonly weatherContentDetailUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/weather-content-details';

  constructor(private http: HttpClient) {}

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * get weather content details by content id
   *
   * @param contentId
   */
  getWeatherContentDetailsByContentId(contentId: Number): Observable<WeatherContentDetail[]> {
    const params = new HttpParams().set('contentId', contentId + '');
    return this.http.get<WeatherContentDetail[]>(`${this.weatherContentDetailUrl}/get-weather-content-details-by-content-id`, { params });
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * get weather content details by content id
   *
   * @param contentId
   */
  getOpenWeatherContentDetailsByContentId(contentId: Number, APIDetails: APIDetails): Observable<OpenWeatherContentDetail[]> {
    const params = new HttpParams().set('contentId', contentId + '');
    const apidetails = APIDetails ? JSON.stringify(APIDetails) : null;
    return this.http.post<OpenWeatherContentDetail[]>(
      `${this.weatherContentDetailUrl}/get-open-weather-content-details-by-content-id`,
      apidetails,
      {
        params
      }
    );
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * save weather content details
   *
   * @param contentId
   */
  saveWeatherContentDetail(weatherContentDetails: WeatherContentDetail[], contentId: Number) {
    const params = new HttpParams().set('contentId', contentId + '');
    return this.http.post<WeatherContentDetail[]>(`${this.weatherContentDetailUrl}/save-weather-content-details`, weatherContentDetails, {
      params
    });
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * save weather content details
   *
   * @param contentId
   */
  saveOpenWeatherContentDetail(weatherContentDetails: OpenWeatherContentDetail[], contentId: Number, APIDetails: APIDetails) {
    let payload = {
      weatherContentDetails: weatherContentDetails,
      contentId: contentId,
      apiDetailString: APIDetails ? JSON.stringify(APIDetails) : null
    };
    return this.http.post<OpenWeatherContentDetail[]>(`${this.weatherContentDetailUrl}/save-open-weather-content-details`, payload);
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * reload data
   *
   * @param weatherContentDetails
   */
  reloadWeatherContentDetails(weatherContentDetails: WeatherContentDetail[]) {
    return this.http.post<WeatherContentDetail[]>(`${this.weatherContentDetailUrl}/reload-weather-content-details`, weatherContentDetails);
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * reload data
   *
   * @param weatherContentDetails
   */
  reloadOpenWeatherContentDetails(weatherContentDetails: OpenWeatherContentDetail[], APIDetails: APIDetails) {
    const params = new HttpParams().set('apiDetails', APIDetails ? JSON.stringify(APIDetails) : null + '');
    return this.http.post<OpenWeatherContentDetail[]>(
      `${this.weatherContentDetailUrl}/reload-open-weather-content-details`,
      weatherContentDetails,
      { params }
    );
  }
}
