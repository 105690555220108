import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PictureArea } from 'app/model/entity/picture-area';
import { Observable } from 'rxjs';
import { DataExternalSetting } from 'app/model/entity/data-external-setting';

@Injectable({
  providedIn: 'root'
})
export class PictureAreaService {
  private pictureAreaUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/picture-areas';

  constructor(private http: HttpClient) {}

  /**
   * get area external content by template id
   * @param templateId id of template
   * @returns list picture area
   */
  getPictureAreasExternalContentByTemplateId(templateId: number): Observable<PictureArea[]> {
    let params = new HttpParams().set('templateId', templateId.toString());
    return this.http.get<PictureArea[]>(`${this.pictureAreaUrl}/get-picture-area-external-content`, { params });
  }

  /**
   * get full data url media
   * @param dataExternalSetting data id area and id content output
   * @returns full data url media
   */
  getFullDataPictureAreaExternalContent(dataExternalSettings: DataExternalSetting[]) {
    return this.http.post<DataExternalSetting[]>(
      `${this.pictureAreaUrl}/get-full-data-picture-area-external-content`,
      dataExternalSettings
    );
  }

  /**
   *  get news url presigned
   * @param countPage
   * @returns
   */
  getNewsPictureUrlPresigned(countPage: number, idExternalContent: number): Observable<string> {
    let params = new HttpParams().set('countPage', countPage + '').set('idExternalContent', idExternalContent + '');
    return this.http.get<string>(`${this.pictureAreaUrl}/get-news-picture-url-presigned`, { params });
  }

  /**
   * update object fit area picture to fill
   * @param idsArea
   *
   */
  updateObjectFitToFill(idsArea: number[]) {
    return this.http.post(`${this.pictureAreaUrl}/update-object-fit`, idsArea);
  }
}
