import { Injectable, Renderer2 } from '@angular/core';
import { Helper } from 'app/common/helper';
import {
  AlignmentEnum,
  Constant,
  DisplayModelEnum,
  FontTypeEnum,
  ObjectFitEnum,
  ScrollDirectionsEnum,
  ScrollStatusEnum,
  SpeedEnum
} from 'app/config/constants';
import { TemplateLED } from 'app/model/entity/destination/template-led';
import { AreaLED } from 'app/model/entity/led/area-led';
import { PictureAreaLED } from 'app/model/entity/led/picture-area-led';
import { TextAreaLED } from 'app/model/entity/led/text-area-led';
import _ from 'lodash';
import { interval, Subject, Subscription } from 'rxjs';
import { repeatWhen, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DrawLEDService {
  /**
   * true if preview on
   */
  private isPlay: boolean;
  /**
   * True if start preview in Destination Sign
   */
  private isStartDestination: boolean = true;

  private readonly SUBSCRIPTION = 'subscription';
  private readonly LAST_POSITION_X = 'lastPositionX';
  private readonly CLEAR_PREVIEW_SUBJECT = 'clearPreviewSubject';
  private readonly IS_START = 'isStart';
  private readonly SRC_ELEMENT = 'src';
  private readonly URL_ELEMENT = 'url';
  private readonly FIRST_INDEX = 0;

  /**
   * subject display
   */
  private readonly pausePreviewDisplaySubject = new Subject<void>();
  private readonly startPreviewDisplaySubject = new Subject<void>();
  private readonly clearPreviewDisplaySubject = new Subject<void>();
  /**
   * timeouts display timetable
   */
  private timeoutsDisplay: Array<TimeOut> = new Array<TimeOut>();
  private subscriptionsDisplay: Array<SubscriptionLed> = new Array<SubscriptionLed>();
  displayModel: DisplayModelEnum;
  characterInfos = [];
  fontCharacters = [];

  /**
   * setup template
   * @param displayModel
   */
  public setupTemplate(displayModel: DisplayModelEnum) {
    this.displayModel = displayModel;
  }

  /**
   * draw area
   * @param area Area
   * @param fonts
   */
  public drawArea(area: AreaLED, fonts: []): void {
    if (!area) {
      return;
    }
    if (area.checkTypeTextArea()) {
      if (!fonts) {
        return;
      }
      this.handleDrawAreaText(area, fonts);
    } else {
      this.drawAreaPicture(area);
    }
  }

  /**
   * handle draw area text
   *
   * @param area
   * @param fonts
   * @param isPreviewOnDestination
   * @param displayModel
   */
  public async handleDrawAreaText(
    area: AreaLED,
    fonts: [],
    isPreviewOnDestination?: boolean,
    displayModel?: DisplayModelEnum
  ): Promise<void> {
    let fontBitmaps = this.getFontsBitmap(area, fonts);
    let imgBitmaps = [];
    let fontUrls = fontBitmaps.map(font => font[this.URL_ELEMENT]);
    for (let i = 0; i < fontUrls.length; i++) {
      let imgBitmap = await this.loadImgBitmap(fontUrls[i]);
      imgBitmaps.push(imgBitmap);
    }
    if (isPreviewOnDestination) {
      this.previewAreaText(area, fontBitmaps, imgBitmaps, displayModel);
    } else {
      this.drawAreaText(area, fontBitmaps, imgBitmaps);
    }
  }

  /**
   * draw area for led layout editor
   * @param area AreaLED
   * @param fonts
   */
  public async drawAreaLEDLayoutEditor(area: AreaLED, fonts: []) {
    if (!area) {
      return;
    }
    // draw text area
    if (area.checkTypeTextArea()) {
      if (!fonts) {
        return;
      }
      // draw font PC
      if (area.getArea().fontType == FontTypeEnum.PC_FONT) {
        this.drawFontPC(area, Constant.SCALE, this.displayModel);
        // draw font bitmap
      } else {
        if (this.displayModel == DisplayModelEnum.WHITE) {
          this.drawTextAreaWhite(area, fonts);
        } else {
          this.drawTextAreaColor(area, fonts);
        }
      }
      // draw picture area
    } else {
      this.drawAreaPictureLEDLayout(area);
    }
  }

  /**
   * draw text area font PC
   * @param area
   * @param scale
   * @param displayModel
   */
  private drawFontPC(area: AreaLED, scale: number, displayModel: DisplayModelEnum): void {
    let areaText = area as TextAreaLED;
    // get ctx from canvas
    let ctx = areaText.canvas.getContext('2d');
    areaText.canvas.style.letterSpacing = `${areaText.letterSpacing}px`;
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // draw color text
    let colorText = areaText.fontColor;
    ctx.fillStyle = colorText;
    let fontSize = areaText.fontSize;
    // draw font text
    ctx.font = `${fontSize}px ${areaText.fontName}`;
    ctx.fillText(areaText.text, 0, fontSize);
    let measureText = Math.ceil(ctx.measureText(areaText.text).width);
    var imgData = ctx.getImageData(0, 0, measureText, fontSize);
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    let positionDrawText = this.getPositionDrawFontPC(areaText, measureText, scale);
    createImageBitmap(imgData).then(renderer => {
      this.settingPreview(ctx);
      ctx.drawImage(renderer, 0, positionDrawText.areaPosY, measureText * scale, fontSize * scale);
      let imageData2 = ctx.getImageData(0, positionDrawText.areaPosY, measureText * scale, fontSize * scale);
      ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
      let data = imageData2.data;
      let lightNess = displayModel == DisplayModelEnum.WHITE ? 0.5 : 0.4;
      for (let i = 0; i < data.length; i += 4) {
        let r = data[i];
        let g = data[i + 1];
        let b = data[i + 2];
        let l = this.convertRgbToHsl(r, g, b);
        let fontColor = this.convertHexToRgba(colorText);
        let backgroundColor = this.convertHexToRgba(areaText.backgroundColor);
        if (l >= lightNess) {
          data[i] = fontColor.r;
          data[i + 1] = fontColor.g;
          data[i + 2] = fontColor.b;
          data[i + 3] = fontColor.a;
        } else {
          data[i] = !backgroundColor ? 0 : backgroundColor.r;
          data[i + 1] = !backgroundColor ? 0 : backgroundColor.g;
          data[i + 2] = !backgroundColor ? 0 : backgroundColor.b;
          data[i + 3] = 255;
        }
      }
      // draw color background
      ctx.fillStyle = areaText.backgroundColor;
      ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
      // draw text
      ctx.putImageData(imageData2, positionDrawText.areaPosX, positionDrawText.areaPosY);
      if (areaText.scrollStatus != ScrollStatusEnum.OFF) {
        this.drawScrollFontPC(area, displayModel, positionDrawText, ctx, imageData2, measureText);
      }
    });
  }

  /**
   * get position draw font PC
   * @param areaText
   * @param measureText
   * @param scale
   */
  private getPositionDrawFontPC(areaText: TextAreaLED, measureText: number, scale: number): any {
    let leadSpacing = areaText.leadSpacing * scale;
    let areaPosX = 0;
    switch (areaText.horizontalTextAlignment) {
      case AlignmentEnum.LEFT:
        areaPosX = leadSpacing - Constant.BORDER_WIDTH_AREA;
        break;
      case AlignmentEnum.CENTER:
        areaPosX = (areaText.canvas.width - measureText * scale - areaText.letterSpacing * scale) / 2 + leadSpacing;
        break;
      case AlignmentEnum.RIGHT:
        areaPosX = areaText.canvas.width - measureText * scale;
        break;
    }
    let areaPosY = (areaText.height - areaText.fontSize - areaText.baselineHeight) * scale;
    return { areaPosX: areaPosX, areaPosY: areaPosY };
  }

  /**
   * convert rgb to hsl
   * @param r
   * @param g
   * @param b
   * @returns
   */
  private convertRgbToHsl(r: number, g: number, b: number): number {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    return (max + min) / 2;
  }

  /**
   * convert hex to rgba
   * @param hex
   * @returns
   */
  private convertHexToRgba(hex): any {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      let r = parseInt(result[1], 16);
      let g = parseInt(result[2], 16);
      let b = parseInt(result[3], 16);
      let a = parseInt(result[4], 16);
      return { r: r, g: g, b: b, a: a };
    }
    return null;
  }

  /**
   * draw text area white
   * @param area
   * @param fonts
   */
  private async drawTextAreaWhite(area: AreaLED, fonts: []) {
    let fontBitmaps = this.getFontsBitmap(area, fonts);
    let imgBitmaps = [];
    let fontUrls = fontBitmaps.map(font => font[this.URL_ELEMENT]);
    for (let i = 0; i < fontUrls.length; i++) {
      let imgBitmap = await this.loadImgBitmap(fontUrls[i]);
      imgBitmaps.push(imgBitmap);
    }
    this.drawAreaText(area, fontBitmaps, imgBitmaps);
  }

  /**
   * draw text area color
   * @param area
   * @param fonts
   */
  private async drawTextAreaColor(area: AreaLED, fonts: []) {
    let fontBitmaps = this.getFontsBitmap(area, fonts);
    this.drawTextColor(area, fontBitmaps);
  }

  /**
   * draw template for led layout editor
   * @param areas
   * @param fonts
   */
  public drawTemplateLEDLayoutEditor(areas: Array<AreaLED>, fonts: []): void {
    Promise.all(
      areas.map(async area => {
        this.drawAreaLEDLayoutEditor(area, fonts);
      })
    );
  }

  /**
   * draw area text color
   * @param area AreaLED
   * @param fonts
   */
  public async drawTextColor(area: AreaLED, fonts: any[]) {
    let areaText = area as TextAreaLED;
    let canvas = areaText.canvas;
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (this.displayModel == DisplayModelEnum.FULL_COLOR) {
      ctx.fillStyle = areaText.backgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
    area.areaPosXs = [];
    let text = area.getArea().text;
    let fontsLoaded = [];
    for (let i = 0; i < text.length; i++) {
      let font = this.getFontBitmapByCharacter(text.charAt(i), fonts);
      if (!font) {
        continue;
      }
      let index = this.fontCharacters.findIndex(item => item.font == font);
      if (index != -1) {
        fontsLoaded.push(this.fontCharacters[index].bitmap);
        continue;
      }
      let fontCharacter = new FontCharacter(font);
      await fetch(this.createGetRequestMedia(font[this.URL_ELEMENT]))
        .then(response => response.blob())
        .then(blob => createImageBitmap(blob))
        .then(bitmap => {
          fontsLoaded.push(bitmap);
          fontCharacter.bitmap = bitmap;
        });
      this.fontCharacters.push(fontCharacter);
    }
    let referenceX = this.getReferenceXByAlignment(area, canvas, fontsLoaded, text, Constant.SCALE);
    for (let j = 0; j < fontsLoaded.length; j++) {
      this.drawCharacterColor(areaText, fontsLoaded[j], ctx, area, j, Constant.SCALE, referenceX);
    }
  }

  /**
   * draw character color
   * @param areaText
   * @param bitmap
   * @param ctx
   * @param area
   * @param index
   * @param scale
   * @param canvas
   * @param referenceX
   */
  private async drawCharacterColor(
    areaText: TextAreaLED,
    bitmap: any,
    ctx: any,
    area: AreaLED,
    index: number,
    scale: number,
    referenceX: number
  ): Promise<any> {
    let leadSpacing = area.getArea().leadSpacing * Constant.SCALE;
    let areaPosY = (area.height - bitmap.height - area.getArea().baselineHeight) * Constant.SCALE;
    let areaPosX = 0;
    if (index == 0) {
      areaPosX = -Constant.BORDER_WIDTH_AREA + leadSpacing + referenceX;
      if (areaPosX != 0) {
        areaPosX += Constant.BORDER_WIDTH_AREA;
      }
    } else {
      areaPosX = area.areaPosXs[index - 1] + area.getArea().letterSpacing * Constant.SCALE;
    }
    let offsetX = index == 0 ? leadSpacing + referenceX : areaPosX;
    area.areaPosXs.push(offsetX + bitmap.width * Constant.SCALE);
    this.settingPreview(ctx);
    let width = bitmap.width * scale;
    let height = bitmap.height * scale;
    let indexCharacter = this.characterInfos.findIndex(
      item => item.bmp == bitmap && item.fontColor == areaText.fontColor && item.backgroundColor == areaText.backgroundColor
    );
    if (indexCharacter == -1) {
      ctx.drawImage(bitmap, areaPosX, areaPosY, bitmap.width * scale, bitmap.height * scale);
      let imageData = ctx.getImageData(areaPosX, areaPosY, width, height);
      let data = imageData.data;
      let fontColor = this.convertHexToRgba(areaText.fontColor);
      let backgroundColor = this.convertHexToRgba(areaText.backgroundColor);

      for (let i = 0; i < data.length; i += 4) {
        let r = data[i];
        let g = data[i + 1];
        let b = data[i + 2];

        // If its white then change it
        if (r == 255 && g == 255 && b == 255) {
          // Change the white to whatever.
          data[i] = fontColor.r;
          data[i + 1] = fontColor.g;
          data[i + 2] = fontColor.b;
          data[i + 3] = fontColor.a;
        }
        if (!backgroundColor) {
          continue;
        }
        // If its black then change it
        if (r == 0 && g == 0 && b == 0) {
          // Change the black to whatever.
          data[i] = backgroundColor.r;
          data[i + 1] = backgroundColor.g;
          data[i + 2] = backgroundColor.b;
          data[i + 3] = backgroundColor.a;
        }
      }
      this.characterInfos.push({ bmp: bitmap, imgData: imageData, fontColor: fontColor, backgroundColor: backgroundColor });
      ctx.putImageData(imageData, areaPosX, areaPosY);
    } else {
      ctx.putImageData(this.characterInfos[indexCharacter].imgData, areaPosX, areaPosY);
    }
  }

  /**
   * create get request media
   * @param mediaUrl
   */
  public createGetRequestMedia(mediaUrl: string): Request {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/octet-stream');
    myHeaders.append('Accept', '*/*');
    myHeaders.append('Access-Control-Allow-Origin', '*');
    myHeaders.append('Access-Control-Allow-Headers', '*');
    return new Request(mediaUrl, {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin'
    });
  }

  /**
   * get font bitmap by character
   * @param character
   * @param fonts
   * @returns
   */
  private getFontBitmapByCharacter(character: string, fonts: any[]): any {
    if (!fonts) {
      return null;
    }
    let hexCharacter = this.getHexCharacter(character);
    let index = fonts.findIndex(font => font['name'] == hexCharacter);
    if (index != -1) {
      return fonts[index];
    }
    return null;
  }

  /**
   * get fonts bit map
   * @param area
   * @param fonts
   * @returns
   */
  private getFontsBitmap(area: AreaLED, fonts: []): any[] {
    if (!fonts) {
      return;
    }
    let fontBitmaps = [];
    let text = area.getArea().text;
    for (let i = 0; i < text.length; i++) {
      let hexCharacter = this.getHexCharacter(text.charAt(i));
      let index = fonts.findIndex(font => font['fontSize'] == area.getArea().fontSize && font['name'] == hexCharacter);
      if (index != -1) {
        fontBitmaps.push(fonts[index]);
      }
    }
    return fontBitmaps;
  }

  /**
   * get hex character
   * @param character
   * @returns
   */
  private getHexCharacter(character: any): string {
    return character
      .charCodeAt(0)
      .toString(16)
      .padStart(4, '0')
      .toUpperCase();
  }

  /**
   * draw area text
   * @param area AreaLED
   * @param fonts
   * @param imgBitmaps
   */
  public async drawAreaText(area: AreaLED, fonts: any[], imgBitmaps: any[]) {
    let areaText = area as TextAreaLED;
    let canvas = areaText.canvas;
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    area.areaPosXs = [];
    if (!imgBitmaps?.length) {
      return;
    }
    let text = area.getArea().text;
    let referenceX = this.getReferenceXByAlignment(area, canvas, imgBitmaps, text, Constant.SCALE);
    let indexStart = 0;
    for (let i = 0; i < text.length; i++) {
      let font = this.getFontBitmapByCharacter(text.charAt(i), fonts);
      if (font) {
        let index = imgBitmaps.findIndex(img => img[this.SRC_ELEMENT] == font[this.URL_ELEMENT]);
        if (index != -1) {
          await this.drawCharacter(imgBitmaps[index], ctx, area, indexStart, referenceX);
          indexStart++;
        }
      }
    }
  }

  /**
   * Preview area text
   *
   * @param area
   * @param fonts
   * @param imgBitmaps
   * @param displayModel
   */
  public async previewAreaText(area: AreaLED, fonts: any[], imgBitmaps: any[], displayModel: DisplayModelEnum): Promise<void> {
    let areaText = area as TextAreaLED;
    let canvas = areaText.canvas;
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (area.getArea().fontType == FontTypeEnum.PC_FONT) {
      this.drawFontPC(area, Constant.DESTINATION_SCALE, displayModel);
    } else {
      let texts = area.getArea().text.split('');
      area.areaPosXs = [];
      let referenceX = this.getReferenceXByAlignment(area, canvas, imgBitmaps, texts, Constant.DESTINATION_SCALE);
      referenceX = isNaN(referenceX) ? 0 : referenceX;
      let areasPosition: Array<AreaPosition> = this.getAreasPosition(texts, fonts, imgBitmaps, area, referenceX);
      if (displayModel == DisplayModelEnum.WHITE) {
        for (let i = 0; i < texts.length; i++) {
          let font = this.getFontBitmapByCharacter(texts[i], fonts);
          if (font) {
            let index = imgBitmaps.findIndex(img => img[this.SRC_ELEMENT] == font[this.URL_ELEMENT]);
            if (index != -1) {
              this.drawImgTextBitmap(
                imgBitmaps[index],
                ctx,
                areasPosition[i].posXScroll,
                areasPosition[i].posYScroll,
                Constant.DESTINATION_SCALE
              );
            }
          }
        }
        this.drawScrollAreaText(ctx, areaText, texts, fonts, referenceX, imgBitmaps, areasPosition, displayModel);
      } else {
        this.drawAreaTextColorDestination(fonts, ctx, texts, referenceX, areasPosition, imgBitmaps, areaText, displayModel);
      }
    }
  }

  /**
   * Draw area text color destination
   *
   * @param fonts
   * @param ctx
   * @param texts
   * @param referenceX
   * @param areasPosition
   * @param imgBitmaps
   * @param areaText
   * @param displayModel
   */
  private async drawAreaTextColorDestination(
    fonts: any[],
    ctx: any,
    texts: [],
    referenceX: number,
    areasPosition: AreaPosition[],
    imgBitmaps: any[],
    areaText: TextAreaLED,
    displayModel: DisplayModelEnum
  ): Promise<void> {
    this.fillBackgroundColor(displayModel, ctx, areaText);
    let fontsLoaded = [];
    for (let i = 0; i < texts.length; i++) {
      let font = this.getFontBitmapByCharacter(texts[i], fonts);
      if (!font) {
        fontsLoaded.push({ width: 0, height: 0 });
        continue;
      }
      let index = this.fontCharacters.findIndex(item => item.font == font);
      if (index != -1) {
        fontsLoaded.push(this.fontCharacters[index].bitmap);
        continue;
      }
      let fontCharacter = new FontCharacter(font);
      await fetch(this.createGetRequestMedia(font[this.URL_ELEMENT]))
        .then(response => response.blob())
        .then(blob => createImageBitmap(blob))
        .then(bitmap => {
          fontsLoaded.push(bitmap);
          fontCharacter.bitmap = bitmap;
        });
      this.fontCharacters.push(fontCharacter);
    }
    for (let j = 0; j < fontsLoaded.length; j++) {
      await this.drawCharacterColorDestinationSignFirstTime(areaText, fontsLoaded[j], ctx, Constant.DESTINATION_SCALE);
    }
    this.fillBackgroundColor(displayModel, ctx, areaText);
    for (let j = 0; j < fontsLoaded.length; j++) {
      await this.drawCharacterColorDestinationSign(areaText, fontsLoaded[j], ctx, j, areasPosition);
    }
    this.drawScrollAreaText(ctx, areaText, texts, fonts, referenceX, imgBitmaps, areasPosition, displayModel, fontsLoaded);
  }

  /**
   * Draw character color destination sign
   *
   * @param areaText
   * @param bitmap
   * @param ctx
   * @param index
   * @param areasPosition
   */
  private async drawCharacterColorDestinationSign(
    areaText: TextAreaLED,
    bitmap: any,
    ctx: any,
    index: number,
    areasPosition: AreaPosition[]
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!bitmap || (bitmap.width == 0 && bitmap.height == 0)) {
        return;
      }
      const areaPosX = _.cloneDeep(areasPosition[index].posXScroll);
      const areaPosY = _.cloneDeep(areasPosition[index].posYScroll);
      this.settingPreview(ctx);
      let indexCharacter = this.getIndexCharacter(bitmap, areaText);
      if (indexCharacter != -1) {
        ctx.putImageData(this.characterInfos[indexCharacter].imgData, areaPosX, areaPosY);
      }
      resolve();
    });
  }

  /**
   * Draw character color destination sign first time
   *
   * @param areaText
   * @param bitmap
   * @param ctx
   * @param scale
   */
  private async drawCharacterColorDestinationSignFirstTime(areaText: TextAreaLED, bitmap: any, ctx: any, scale: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!bitmap || (bitmap.width == 0 && bitmap.height == 0)) {
        return;
      }
      let width = bitmap.width * scale;
      let height = bitmap.height * scale;
      this.settingPreview(ctx);
      let indexCharacter = this.getIndexCharacter(bitmap, areaText);
      if (indexCharacter == -1) {
        ctx.drawImage(bitmap, 0, 0, width, height);
        let fontColor = this.convertHexToRgba(areaText.fontColor);
        let backgroundColor = this.convertHexToRgba(areaText.backgroundColor);

        let imageData = ctx.getImageData(0, 0, width, height);
        let data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
          let r = data[i];
          let g = data[i + 1];
          let b = data[i + 2];

          // If its white then change it
          if (r == 255 && g == 255 && b == 255) {
            // Change the white to whatever.
            data[i] = fontColor.r;
            data[i + 1] = fontColor.g;
            data[i + 2] = fontColor.b;
            data[i + 3] = fontColor.a;
          }
          if (!backgroundColor) {
            continue;
          }
          // If its black then change it
          if (r == 0 && g == 0 && b == 0) {
            // Change the black to whatever.
            data[i] = backgroundColor.r;
            data[i + 1] = backgroundColor.g;
            data[i + 2] = backgroundColor.b;
            data[i + 3] = backgroundColor.a;
          }
        }
        this.characterInfos.push({ bmp: bitmap, imgData: imageData, fontColor: fontColor, backgroundColor: backgroundColor });
        ctx.putImageData(imageData, 0, 0);
        ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
      }
      resolve();
    });
  }

  /**
   * Get index character
   *
   * @param bitmap
   * @param areaText
   * @returns
   */
  private getIndexCharacter(bitmap: any, areaText: TextAreaLED): number {
    return this.characterInfos.findIndex(
      item =>
        item.bmp == bitmap &&
        JSON.stringify(item.fontColor) == JSON.stringify(this.convertHexToRgba(areaText.fontColor)) &&
        JSON.stringify(item.backgroundColor) == JSON.stringify(this.convertHexToRgba(areaText.backgroundColor))
    );
  }

  /**
   * Get areas position
   *
   * @param texts
   * @param fonts
   * @param imgBitmaps
   * @param area
   * @param referenceX
   * @returns
   */
  private getAreasPosition(texts: string[], fonts: any[], imgBitmaps: any[], area: AreaLED, referenceX: number): Array<AreaPosition> {
    let areasPosition = new Array<AreaPosition>();
    let leadSpacing = area.getArea().leadSpacing * Constant.DESTINATION_SCALE;
    for (let i = 0; i < texts.length; i++) {
      let font = this.getFontBitmapByCharacter(texts[i], fonts);
      if (font) {
        let index = imgBitmaps.findIndex(img => img[this.SRC_ELEMENT] == font[this.URL_ELEMENT]);
        if (index != -1) {
          let areaPosY = (area.height - imgBitmaps[index].height - area.getArea().baselineHeight) * Constant.DESTINATION_SCALE;
          let areaPosX =
            i == 0
              ? leadSpacing + referenceX
              : areasPosition[i - 1].posXScroll +
                (areasPosition[i - 1].imageWidth + area.getArea().letterSpacing) * Constant.DESTINATION_SCALE;
          areasPosition.push(new AreaPosition(areaPosX, areaPosY, imgBitmaps[index].width));
        } else {
          let areaPosY = (area.height - area.getArea().fontSize - area.getArea().baselineHeight) * Constant.DESTINATION_SCALE;
          let areaPosX =
            i == 0
              ? leadSpacing + referenceX
              : areasPosition[i - 1].posXScroll +
                (areasPosition[i - 1].imageWidth + area.getArea().letterSpacing) * Constant.DESTINATION_SCALE;
          areasPosition.push(new AreaPosition(areaPosX, areaPosY, i == 0 ? leadSpacing : areasPosition[i - 1].imageWidth));
        }
      } else {
        let areaPosY = (area.height - area.getArea().fontSize - area.getArea().baselineHeight) * Constant.DESTINATION_SCALE;
        let areaPosX =
          i == 0
            ? leadSpacing + referenceX
            : areasPosition[i - 1].posXScroll +
              (areasPosition[i - 1].imageWidth + area.getArea().letterSpacing) * Constant.DESTINATION_SCALE;
        areasPosition.push(new AreaPosition(areaPosX, areaPosY, i == 0 ? leadSpacing : areasPosition[i - 1].imageWidth));
      }
    }
    return areasPosition;
  }

  /**
   * get referenceX by alignment
   * @param area
   * @param canvas
   * @param fontsLoaded
   * @param texts
   * @param scale
   */
  private getReferenceXByAlignment(area: AreaLED, canvas: any, fontsLoaded: any[], text: any, scale: number): number {
    const widthText = fontsLoaded.map(font => font.width).reduce((el1, el2) => el1 + el2, 0);
    switch (area.getArea().horizontalTextAlignment) {
      case AlignmentEnum.LEFT:
        return 0;
      case AlignmentEnum.CENTER:
        return (canvas.width - widthText * scale - area.getArea().letterSpacing * scale * (text.length - 1)) / 2;
      case AlignmentEnum.RIGHT:
        return (
          canvas.width - widthText * scale - area.getArea().letterSpacing * scale * (text.length - 1) - area.getArea().leadSpacing * scale
        );
    }
  }

  /**
   * draw character
   * @param font
   * @param ctx
   * @param area
   * @param index
   * @param referenceX
   */
  private async drawCharacter(img: any, ctx: any, area: AreaLED, index: number, referenceX: number): Promise<any> {
    let leadSpacing = area.getArea().leadSpacing * Constant.SCALE;
    let areaPosY = (area.height - img.height - area.getArea().baselineHeight) * Constant.SCALE;
    let areaPosX = 0;
    if (index == 0) {
      areaPosX = -Constant.BORDER_WIDTH_AREA + leadSpacing + referenceX;
      if (areaPosX != 0) {
        areaPosX += Constant.BORDER_WIDTH_AREA;
      }
    } else {
      areaPosX = area.areaPosXs[index - 1] + area.getArea().letterSpacing * Constant.SCALE;
    }
    let offsetX = index == 0 ? leadSpacing + referenceX : areaPosX;
    area.areaPosXs.push(offsetX + img.width * Constant.SCALE);
    this.drawImgTextBitmap(img, ctx, areaPosX, areaPosY, Constant.SCALE);
  }

  /**
   * load img bitmap
   * @param url
   * @returns
   */
  async loadImgBitmap(url) {
    if (!url) {
      return;
    }
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  }

  /**
   * scale to fill
   * @param img
   * @param ctx
   * @param areaPosX
   * @param areaPosY
   */
  private drawImgTextBitmap(img: any, ctx: any, areaPosX: number, areaPosY: number, scale: number) {
    ctx = ctx as CanvasRenderingContext2D;
    this.settingPreview(ctx);
    ctx.drawImage(img, areaPosX, areaPosY, img.width * scale, img.height * scale);
  }

  /**
   * Draw scroll area text
   *
   * @param ctx
   * @param textArea
   * @param texts
   * @param fonts
   * @param referenceX
   * @param imgBitmaps
   * @param areasPosition
   * @param displayModel
   * @param fontsLoaded
   */
  private drawScrollAreaText(
    ctx: any,
    textArea: AreaLED,
    texts: string[],
    fonts: any[],
    referenceX: number,
    imgBitmaps: any[],
    areasPosition: AreaPosition[],
    displayModel: DisplayModelEnum,
    fontsLoaded?: any[]
  ): void {
    var defaultPositionY = areasPosition[this.FIRST_INDEX]?.posYScroll;
    textArea[this.LAST_POSITION_X] = _.cloneDeep(areasPosition[areasPosition.length - 1]?.posXScroll);
    var resetPointPosition = this.getAreaPointResetPosition(textArea, areasPosition[this.FIRST_INDEX]?.posYScroll);
    if (textArea.scrollStatus == ScrollStatusEnum.OFF) {
      textArea[this.SUBSCRIPTION]?.unsubscribe();
      return;
    }
    textArea[this.IS_START] = this.isStartDestination;
    if (textArea[this.SUBSCRIPTION]) {
      textArea[this.SUBSCRIPTION].unsubscribe();
    }
    let timeout = setTimeout(
      async () => {
        if (!textArea[this.IS_START]) {
          if (displayModel == DisplayModelEnum.WHITE) {
            for (let i = 0; i < texts.length; i++) {
              let font = this.getFontBitmapByCharacter(texts[i], fonts);
              if (font) {
                let index = imgBitmaps.findIndex(img => img[this.SRC_ELEMENT] == font[this.URL_ELEMENT]);
                if (index != -1) {
                  this.drawImgTextBitmap(
                    imgBitmaps[index],
                    ctx,
                    areasPosition[i].posXScroll,
                    areasPosition[i].posYScroll,
                    Constant.DESTINATION_SCALE
                  );
                }
              }
            }
          } else {
            this.fillBackgroundColor(displayModel, ctx, textArea);
            for (let j = 0; j < fontsLoaded.length; j++) {
              await this.drawCharacterColorDestinationSign(textArea as TextAreaLED, fontsLoaded[j], ctx, j, areasPosition);
            }
          }
        }
        if (
          textArea.scrollStatus == ScrollStatusEnum.AUTO &&
          textArea.scrollDirection == ScrollDirectionsEnum.LEFT &&
          areasPosition[areasPosition.length - 1].posXScroll < textArea.width * Constant.DESTINATION_SCALE
        ) {
          return;
        }
        const subscription = this.setAreaTextInterval(
          ctx,
          textArea,
          texts,
          fonts,
          referenceX,
          imgBitmaps,
          areasPosition,
          displayModel,
          resetPointPosition,
          defaultPositionY,
          fontsLoaded
        );
        textArea[this.SUBSCRIPTION] = subscription;
        textArea[this.CLEAR_PREVIEW_SUBJECT] = this.clearPreviewDisplaySubject.subscribe(() => {
          subscription.unsubscribe();
          textArea[this.SUBSCRIPTION]?.unsubscribe();
        });
        let index = this.subscriptionsDisplay.findIndex(data => data.areaId == textArea.id);
        if (index != -1) {
          this.subscriptionsDisplay[index].subscriptions.push(subscription);
        } else {
          this.subscriptionsDisplay.push(new SubscriptionLed([subscription], textArea.id as number));
        }
      },
      textArea[this.IS_START] ||
        (textArea.scrollDirection != ScrollDirectionsEnum.UP && textArea.scrollDirection != ScrollDirectionsEnum.DOWN)
        ? 0
        : textArea.pauseTime * 1000
    );
    this.timeoutsDisplay.push(new TimeOut(timeout, textArea.getArea().linkReferenceData, textArea.id));
  }

  /**
   * Fill background color
   *
   * @param displayModel
   * @param ctx
   * @param textArea
   */
  private fillBackgroundColor(displayModel: DisplayModelEnum, ctx: any, textArea: AreaLED): void {
    if (displayModel == DisplayModelEnum.FULL_COLOR) {
      ctx.fillStyle = textArea.getArea().backgroundColor;
      ctx.fillRect(0, 0, textArea.canvas.width, textArea.canvas.height);
    }
  }

  /**
   * Set area text interval
   *
   * @param ctx
   * @param textArea
   * @param texts
   * @param fonts
   * @param referenceX
   * @param imgBitmaps
   * @param areasPosition
   * @param displayModel
   * @param resetPointPosition
   * @param defaultPositionY
   * @param fontsLoaded
   * @returns
   */
  private setAreaTextInterval(
    ctx: any,
    textArea: AreaLED,
    texts: string[],
    fonts: any[],
    referenceX: number,
    imgBitmaps: any[],
    areasPosition: AreaPosition[],
    displayModel: DisplayModelEnum,
    resetPointPosition: number,
    defaultPositionY: number,
    fontsLoaded?: any[]
  ): Subscription {
    return interval(20)
      .pipe(
        takeUntil(this.clearPreviewDisplaySubject),
        takeUntil(this.pausePreviewDisplaySubject),
        repeatWhen(() => this.startPreviewDisplaySubject)
      )
      .subscribe(async () => {
        if (displayModel == DisplayModelEnum.WHITE) {
          this.drawAreaTextInterval(
            textArea,
            ctx,
            texts,
            fonts,
            referenceX,
            imgBitmaps,
            areasPosition,
            resetPointPosition,
            defaultPositionY,
            displayModel
          );
        } else {
          this.drawAreaTextInterval(
            textArea,
            ctx,
            texts,
            fonts,
            referenceX,
            imgBitmaps,
            areasPosition,
            resetPointPosition,
            defaultPositionY,
            displayModel,
            fontsLoaded
          );
        }
      });
  }

  /**
   * Draw area text interval
   *
   * @param textArea
   * @param ctx
   * @param texts
   * @param fonts
   * @param referenceX
   * @param imgBitmaps
   * @param areasPosition
   * @param resetPointPosition
   * @param defaultPositionY
   * @param displayModel
   * @param fontsLoaded
   * @returns
   */
  private async drawAreaTextInterval(
    textArea: AreaLED,
    ctx: any,
    texts: string[],
    fonts: any[],
    referenceX: number,
    imgBitmaps: any[],
    areasPosition: AreaPosition[],
    resetPointPosition: number,
    defaultPositionY: number,
    displayModel: DisplayModelEnum,
    fontsLoaded?: any[]
  ): Promise<void> {
    if (!this.isPlay) {
      return;
    }
    if (textArea[this.IS_START]) {
      textArea[this.SUBSCRIPTION]?.unsubscribe();
      this.isStartDestination = false;
      textArea[this.IS_START] = false;
      if (displayModel == DisplayModelEnum.WHITE) {
        this.drawScrollAreaText(ctx, textArea, texts, fonts, referenceX, imgBitmaps, areasPosition, displayModel);
      } else {
        this.drawScrollAreaText(ctx, textArea, texts, fonts, referenceX, imgBitmaps, areasPosition, displayModel, fontsLoaded);
      }
    } else {
      ctx.clearRect(0, 0, textArea.canvas.width, textArea.canvas.height);
      this.fillBackgroundColor(displayModel, ctx, textArea);
      for (let i = 0; i < texts.length; i++) {
        let font = this.getFontBitmapByCharacter(texts[i], fonts);
        if (font) {
          let index = imgBitmaps.findIndex(img => img[this.SRC_ELEMENT] == font[this.URL_ELEMENT]);
          if (index != -1) {
            if (
              textArea.scrollDirection == ScrollDirectionsEnum.LEFT &&
              Math.floor(areasPosition[i].posXScroll) < -Math.floor(areasPosition[i].imageWidth)
            ) {
              areasPosition[i].posXScroll =
                i == 0
                  ? (textArea.width + textArea.getArea().leadSpacing) * Constant.DESTINATION_SCALE +
                    areasPosition[areasPosition.length - 1].posXScroll
                  : areasPosition[i - 1].posXScroll +
                    (textArea.getArea().letterSpacing + areasPosition[i - 1].imageWidth) * Constant.DESTINATION_SCALE;
            } else if (
              textArea.scrollDirection == ScrollDirectionsEnum.RIGHT &&
              Math.floor(areasPosition[0].posXScroll) > Math.floor(textArea.width) * Constant.DESTINATION_SCALE
            ) {
              this.redrawScrollTextDirectionRight(
                ctx,
                textArea,
                texts,
                fonts,
                referenceX,
                imgBitmaps,
                areasPosition,
                displayModel,
                resetPointPosition,
                defaultPositionY,
                fontsLoaded
              );
            } else if (
              textArea.scrollDirection == ScrollDirectionsEnum.UP &&
              Math.floor(areasPosition[i].posYScroll) == resetPointPosition
            ) {
              areasPosition[i].posYScroll = defaultPositionY;
            } else if (
              textArea.scrollDirection == ScrollDirectionsEnum.DOWN &&
              Math.floor(areasPosition[i].posYScroll) == resetPointPosition
            ) {
              areasPosition[i].posYScroll = defaultPositionY;
            } else if (textArea.scrollDirection == ScrollDirectionsEnum.SEMI_UP) {
              // TODO:
            } else if (textArea.scrollDirection == ScrollDirectionsEnum.SEMI_DOWN) {
              // TODO:
            } else {
              areasPosition[i].posXScroll = this.calcAreaTextPositionX(textArea as TextAreaLED, i, areasPosition);
              areasPosition[i].posYScroll = this.calcAreaTextPositionY(textArea as TextAreaLED, i, areasPosition);
            }
            if (displayModel == DisplayModelEnum.WHITE) {
              this.drawImgTextBitmap(
                imgBitmaps[index],
                ctx,
                areasPosition[i].posXScroll,
                areasPosition[i].posYScroll,
                Constant.DESTINATION_SCALE
              );
            } else {
              await this.drawCharacterColorDestinationSign(textArea as TextAreaLED, fontsLoaded[i], ctx, i, areasPosition);
            }
          }
        }
      }
      //clear and redraw when finish scroll UP/DOWN
      if (
        areasPosition.every(data => data.posYScroll == defaultPositionY) &&
        (textArea.scrollDirection == ScrollDirectionsEnum.DOWN || textArea.scrollDirection == ScrollDirectionsEnum.UP)
      ) {
        textArea[this.SUBSCRIPTION]?.unsubscribe();
        this.clearTimeoutFinishScroll(textArea);
        if (displayModel == DisplayModelEnum.WHITE) {
          this.drawScrollAreaText(ctx, textArea, texts, fonts, referenceX, imgBitmaps, areasPosition, displayModel);
        } else {
          this.drawScrollAreaText(ctx, textArea, texts, fonts, referenceX, imgBitmaps, areasPosition, displayModel, fontsLoaded);
        }
      }
    }
  }

  /**
   * Redraw scroll text direction right
   *
   * @param ctx
   * @param textArea
   * @param texts
   * @param fonts
   * @param referenceX
   * @param imgBitmaps
   * @param areasPosition
   * @param displayModel
   * @param resetPointPosition
   * @param defaultPositionY
   * @param fontsLoaded
   */
  private redrawScrollTextDirectionRight(
    ctx: any,
    textArea: AreaLED,
    texts: string[],
    fonts: any[],
    referenceX: number,
    imgBitmaps: any[],
    areasPosition: AreaPosition[],
    displayModel: DisplayModelEnum,
    resetPointPosition: number,
    defaultPositionY: number,
    fontsLoaded?: any[]
  ): void {
    textArea[this.SUBSCRIPTION]?.unsubscribe();
    this.clearTimeoutFinishScroll(textArea);
    areasPosition = this.resetAreasPosition(textArea, texts, _.cloneDeep(areasPosition));
    const subscription = this.setAreaTextInterval(
      ctx,
      textArea,
      texts,
      fonts,
      referenceX,
      imgBitmaps,
      areasPosition,
      displayModel,
      resetPointPosition,
      defaultPositionY,
      fontsLoaded
    );
    textArea[this.SUBSCRIPTION] = subscription;
    textArea[this.CLEAR_PREVIEW_SUBJECT] = this.clearPreviewDisplaySubject.subscribe(() => {
      subscription.unsubscribe();
      textArea[this.SUBSCRIPTION]?.unsubscribe();
    });
    let index = this.subscriptionsDisplay.findIndex(data => data.areaId == textArea.id);
    if (index != -1) {
      this.subscriptionsDisplay[index].subscriptions.push(subscription);
    } else {
      this.subscriptionsDisplay.push(new SubscriptionLed([subscription], textArea.id as number));
    }
  }

  /**
   * Reset areas position for scroll right
   *
   * @param textArea
   * @param texts
   * @param areasPositionOld
   * @returns
   */
  private resetAreasPosition(textArea: AreaLED, texts: string[], areasPositionOld: AreaPosition[]): Array<AreaPosition> {
    let areasPosition = areasPositionOld;
    for (let i = texts.length - 1; i >= 0; i--) {
      let posY = areasPositionOld[i].posYScroll;
      let posX = -areasPositionOld[i].imageWidth * Constant.DESTINATION_SCALE;
      if (i != texts.length - 1) {
        posX =
          areasPosition[i + 1].posXScroll -
          (areasPositionOld[i].imageWidth + textArea.getArea().letterSpacing) * Constant.DESTINATION_SCALE;
      }
      areasPosition[i] = new AreaPosition(posX, posY, areasPositionOld[i].imageWidth);
    }
    return areasPosition;
  }

  /**
   * Calculate area text position X
   *
   * @param textArea
   * @param index
   * @param areasPosition
   * @returns
   */
  private calcAreaTextPositionX(textArea: TextAreaLED, index: number, areasPosition: Array<AreaPosition>): number {
    switch (textArea.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        return areasPosition[index].posXScroll - this.getScrollSpeed(textArea) / 20;
      case ScrollDirectionsEnum.RIGHT:
        return areasPosition[index].posXScroll + this.getScrollSpeed(textArea) / 20;
      case ScrollDirectionsEnum.UP:
      case ScrollDirectionsEnum.DOWN:
      case ScrollDirectionsEnum.SEMI_UP:
      case ScrollDirectionsEnum.SEMI_DOWN:
        return areasPosition[index].posXScroll;
      default:
        return this.getScrollSpeed(textArea) / 20;
    }
  }

  /**
   * Calculate area text position Y
   *
   * @param textArea
   * @param index
   * @param areasPosition
   * @returns
   */
  private calcAreaTextPositionY(textArea: TextAreaLED, index: number, areasPosition: Array<AreaPosition>): number {
    switch (textArea.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
      case ScrollDirectionsEnum.RIGHT:
        return areasPosition[index].posYScroll;
      case ScrollDirectionsEnum.UP:
        if (Math.floor(areasPosition[index].posYScroll) < -Math.floor(textArea.height * Constant.DESTINATION_SCALE)) {
          return textArea.height * Constant.DESTINATION_SCALE + textArea.getArea().fontSize;
        }
        return areasPosition[index].posYScroll - this.getScrollSpeed(textArea) / 20;
      case ScrollDirectionsEnum.DOWN:
        if (Math.floor(areasPosition[index].posYScroll) > Math.floor(textArea.height * Constant.DESTINATION_SCALE)) {
          return -(textArea.height * Constant.DESTINATION_SCALE + textArea.getArea().fontSize);
        }
        return areasPosition[index].posYScroll + this.getScrollSpeed(textArea) / 20;
      case ScrollDirectionsEnum.SEMI_UP:
      case ScrollDirectionsEnum.SEMI_DOWN:
      default:
        return this.getScrollSpeed(textArea) / 20;
    }
  }

  /**
   * Get area point reset position
   *
   * @param textArea
   * @param posYScrollCurrent
   * @returns
   */
  private getAreaPointResetPosition(textArea: AreaLED, posYScrollCurrent: number): number {
    let posYScroll = textArea.height * Constant.DESTINATION_SCALE + textArea.getArea().fontSize;
    do {
      posYScroll = posYScroll - this.getScrollSpeed(textArea) / 20;
    } while (Math.floor(posYScroll) > posYScrollCurrent + (textArea.scrollDirection == ScrollDirectionsEnum.UP ? 1 : -1));
    return Math.floor(posYScroll);
  }

  /**
   * setting preview
   *
   * @param ctx
   */
  private settingPreview(ctx: any): void {
    ctx.mozImageSmoothingEnabled = false;
    ctx.webkitImageSmoothingEnabled = false;
    ctx.msImageSmoothingEnabled = false;
    ctx.imageSmoothingEnabled = false;
  }

  /**
   * draw area picture (image bitmap)
   * @param area AreaLED
   */
  public async drawAreaPicture(area: AreaLED): Promise<any> {
    let areaPicture = area as PictureAreaLED;
    if (!areaPicture.media) {
      return;
    }
    let canvas = areaPicture.canvas;
    let mediaPosition = Helper.coverMedia(canvas, areaPicture.media, areaPicture.objectFit);
    let ctx = canvas.getContext('2d');
    let $this = this;
    ctx.globalAlpha = 1;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    try {
      let img = new Image();
      img.onload = function() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        $this.settingPreview(ctx);
        $this.reDrawPictureArea(ctx, areaPicture, mediaPosition, img);
        $this.previewAreaPicture(ctx, areaPicture, mediaPosition, img);
      };
      img.src = areaPicture.media.url;
    } catch (error) {
      console.log('error draw 3', error);
      return true;
    }
  }

  /**
   * draw area picture for LED Layout Editor (image bitmap)
   * @param area AreaLED
   */
  public async drawAreaPictureLEDLayout(area: AreaLED): Promise<any> {
    let areaPicture = area as PictureAreaLED;
    let canvas = areaPicture.canvas;
    let mediaPosition = Helper.coverMedia(canvas, areaPicture.media, areaPicture.objectFit);
    let ctx = canvas.getContext('2d');
    if (areaPicture.media) {
      ctx.globalAlpha = 1;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      try {
        let img = new Image();
        img.onload = function() {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.mozImageSmoothingEnabled = false;
          ctx.webkitImageSmoothingEnabled = false;
          ctx.msImageSmoothingEnabled = false;
          ctx.imageSmoothingEnabled = false;
          if (areaPicture.objectFit == ObjectFitEnum.FILL) {
            ctx.drawImage(img, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
          } else {
            ctx.drawImage(
              img,
              mediaPosition.sX,
              mediaPosition.sY,
              mediaPosition.sWidth,
              mediaPosition.sHeight,
              mediaPosition.x,
              mediaPosition.y,
              mediaPosition.width,
              mediaPosition.height
            );
          }
        };
        img.src = areaPicture.media.url;
      } catch (error) {
        console.log('error draw 3', error);
      }
    }
  }

  /**
   * draw scroll font pc
   *
   * @param area
   * @param displayModel
   * @param positionDrawText
   * @param ctx
   * @param imageData
   * @param measureText
   */
  private drawScrollFontPC(
    area: AreaLED,
    displayModel: DisplayModelEnum,
    positionDrawText: any,
    ctx: any,
    imageData: any,
    measureText: number
  ): void {
    let textArea = area as TextAreaLED;
    textArea[this.IS_START] = this.isStartDestination;
    if (textArea[this.SUBSCRIPTION]) {
      textArea[this.SUBSCRIPTION].unsubscribe();
    }
    var resetPointPosition = this.getAreaPointResetPosition(textArea, positionDrawText.areaPosY);
    var defaultPositionY = positionDrawText.areaPosY;
    let timeout = setTimeout(
      () => {
        if (
          textArea.scrollStatus == ScrollStatusEnum.AUTO &&
          textArea.scrollDirection == ScrollDirectionsEnum.LEFT &&
          measureText < textArea.width * Constant.DESTINATION_SCALE
        ) {
          return;
        }
        const observable = interval(10);
        const subscription = observable
          .pipe(
            takeUntil(this.clearPreviewDisplaySubject),
            takeUntil(this.pausePreviewDisplaySubject),
            repeatWhen(() => this.startPreviewDisplaySubject)
          )
          .subscribe(() => {
            this.drawFontPCInterval(
              textArea,
              displayModel,
              positionDrawText,
              ctx,
              imageData,
              measureText,
              resetPointPosition,
              defaultPositionY
            );
          });
        textArea[this.SUBSCRIPTION] = subscription;
        textArea[this.CLEAR_PREVIEW_SUBJECT] = this.clearPreviewDisplaySubject.subscribe(() => {
          subscription.unsubscribe();
          textArea[this.SUBSCRIPTION]?.unsubscribe();
        });
      },
      textArea[this.IS_START] ||
        (textArea.scrollDirection != ScrollDirectionsEnum.UP && textArea.scrollDirection != ScrollDirectionsEnum.DOWN)
        ? 0
        : textArea.pauseTime * 1000
    );
    this.timeoutsDisplay.push(new TimeOut(timeout, textArea.linkReferenceData, textArea.id));
  }

  /**
   * Draw font pc interval
   *
   * @param area
   * @param displayModel
   * @param positionDrawText
   * @param ctx
   * @param imageData
   * @param measureText
   * @param resetPointPosition
   * @param defaultPositionY
   * @returns
   */
  private drawFontPCInterval(
    area: AreaLED,
    displayModel: DisplayModelEnum,
    positionDrawText: any,
    ctx: any,
    imageData: any,
    measureText: number,
    resetPointPosition: number,
    defaultPositionY: number
  ): void {
    if (!this.isPlay) {
      return;
    }
    // start preview
    if (area[this.IS_START]) {
      area[this.SUBSCRIPTION]?.unsubscribe();
      this.isStartDestination = false;
      area[this.IS_START] = false;
      this.drawScrollFontPC(area, displayModel, positionDrawText, ctx, imageData, measureText);
    } else {
      ctx.clearRect(0, 0, area.canvas.width, area.canvas.height);
      this.fillBackgroundColor(displayModel, ctx, area);
      if (
        area.scrollDirection == ScrollDirectionsEnum.LEFT &&
        Math.floor(positionDrawText.areaPosX) < -measureText * Constant.DESTINATION_SCALE
      ) {
        positionDrawText.areaPosX = area.width * Constant.DESTINATION_SCALE;
      } else if (
        area.scrollDirection == ScrollDirectionsEnum.RIGHT &&
        Math.floor(positionDrawText.areaPosX) > (Math.floor(area.width) + measureText) * Constant.DESTINATION_SCALE
      ) {
        positionDrawText.areaPosX = -measureText * Constant.DESTINATION_SCALE;
      } else if (
        (area.scrollDirection == ScrollDirectionsEnum.DOWN || area.scrollDirection == ScrollDirectionsEnum.UP) &&
        Math.floor(positionDrawText.areaPosY) == resetPointPosition
      ) {
        positionDrawText.areaPosY = defaultPositionY;
        area[this.SUBSCRIPTION]?.unsubscribe();
        this.clearTimeoutFinishScroll(area);
        this.drawScrollFontPC(area, displayModel, positionDrawText, ctx, imageData, measureText);
      } else if (area.scrollDirection == ScrollDirectionsEnum.SEMI_UP && Math.floor(positionDrawText.areaPosY) < -Math.floor(area.height)) {
        // TODO:
      } else if (
        area.scrollDirection == ScrollDirectionsEnum.SEMI_DOWN &&
        Math.floor(positionDrawText.areaPosY) < -Math.floor(area.height)
      ) {
        // TODO:
      } else {
        this.calcPositionDrawTextForFontPC(area, positionDrawText);
      }
      ctx.putImageData(imageData, positionDrawText.areaPosX, positionDrawText.areaPosY);
    }
  }

  /**
   * Calc position draw text for font pc
   *
   * @param area
   * @param positionDrawText
   */
  private calcPositionDrawTextForFontPC(area: AreaLED, positionDrawText: any): void {
    switch (area.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        positionDrawText.areaPosX -= this.getScrollSpeed(area) / 20;
        break;
      case ScrollDirectionsEnum.RIGHT:
        positionDrawText.areaPosX += this.getScrollSpeed(area) / 20;
        break;
      case ScrollDirectionsEnum.UP:
        positionDrawText.areaPosY -= this.getScrollSpeed(area) / 20;
        if (Math.floor(positionDrawText.areaPosY) < -Math.floor(area.height * Constant.DESTINATION_SCALE)) {
          positionDrawText.areaPosY = area.height * Constant.DESTINATION_SCALE + area.getArea().fontSize;
        }
        break;
      case ScrollDirectionsEnum.DOWN:
        positionDrawText.areaPosY += this.getScrollSpeed(area) / 20;
        if (Math.floor(positionDrawText.areaPosY) > Math.floor(area.height * Constant.DESTINATION_SCALE)) {
          positionDrawText.areaPosY = -(area.height * Constant.DESTINATION_SCALE + area.getArea().fontSize);
        }
        break;
      case ScrollDirectionsEnum.SEMI_UP:
        // TODO:
        break;
      case ScrollDirectionsEnum.SEMI_DOWN:
        // TODO:
        break;
      default:
        break;
    }
  }

  /**
   * preview area picture
   *
   * @param ctx
   * @param areaPicture
   * @param mediaPosition
   * @param image
   * @returns
   */
  private previewAreaPicture(ctx: any, areaPicture: PictureAreaLED, mediaPosition: any, image: any): void {
    if (areaPicture.scrollStatus == ScrollStatusEnum.OFF) {
      areaPicture[this.SUBSCRIPTION]?.unsubscribe();
      return;
    }
    areaPicture[this.IS_START] = this.isStartDestination;
    if (areaPicture[this.SUBSCRIPTION]) {
      areaPicture[this.SUBSCRIPTION].unsubscribe();
    }
    let timeout = setTimeout(
      () => {
        // reset when finish scroll
        if (!areaPicture[this.IS_START]) {
          this.reDrawPictureArea(ctx, areaPicture, mediaPosition, image);
        }
        const observable = interval(10);
        const subscription = observable
          .pipe(
            takeUntil(this.clearPreviewDisplaySubject),
            takeUntil(this.pausePreviewDisplaySubject),
            repeatWhen(() => this.startPreviewDisplaySubject)
          )
          .subscribe(() => {
            this.drawScrollAreaPicture(ctx, areaPicture, mediaPosition, image);
          });
        areaPicture[this.SUBSCRIPTION] = subscription;
        areaPicture[this.CLEAR_PREVIEW_SUBJECT] = this.clearPreviewDisplaySubject.subscribe(() => {
          subscription.unsubscribe();
          areaPicture[this.SUBSCRIPTION]?.unsubscribe();
        });
      },
      areaPicture[this.IS_START] ||
        (areaPicture.scrollDirection != ScrollDirectionsEnum.UP && areaPicture.scrollDirection != ScrollDirectionsEnum.DOWN)
        ? 0
        : areaPicture.pauseTime * 1000
    );
    this.timeoutsDisplay.push(new TimeOut(timeout, areaPicture.linkReferenceData, areaPicture.id));
  }

  /**
   * Get position x
   *
   * @param areaPicture
   * @returns
   */
  private getPositionX(areaPicture: PictureAreaLED): number {
    switch (areaPicture.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        return Math.floor(areaPicture.width * Constant.DESTINATION_SCALE + 10);
      case ScrollDirectionsEnum.RIGHT:
        return -Math.floor(areaPicture.width * Constant.DESTINATION_SCALE + 10);
      case ScrollDirectionsEnum.UP:
      case ScrollDirectionsEnum.DOWN:
        return 0;
      default:
        return Math.floor(areaPicture.width * Constant.DESTINATION_SCALE + 10);
    }
  }

  /**
   * Draw scroll area picture
   *
   * @param ctx
   * @param areaPicture
   * @param mediaPosition
   * @param image
   * @returns
   */
  private drawScrollAreaPicture(ctx: any, areaPicture: PictureAreaLED, mediaPosition: any, image: any): void {
    if (!this.isPlay) {
      return;
    }
    // start preview
    if (areaPicture[this.IS_START]) {
      areaPicture[this.SUBSCRIPTION]?.unsubscribe();
      this.isStartDestination = false;
      areaPicture[this.IS_START] = false;
      this.previewAreaPicture(ctx, areaPicture, mediaPosition, image);
    } else {
      if (
        areaPicture.scrollDirection == ScrollDirectionsEnum.LEFT &&
        Math.floor(areaPicture.posXScroll) < -Math.floor(areaPicture.width * Constant.DESTINATION_SCALE + 10)
      ) {
        areaPicture.posXScroll = this.getPositionX(areaPicture);
        this.resetScrollAreaPicture(areaPicture, ctx, mediaPosition, image);
      } else if (
        areaPicture.scrollDirection == ScrollDirectionsEnum.RIGHT &&
        Math.floor(areaPicture.posXScroll) > Math.floor(areaPicture.width * Constant.DESTINATION_SCALE + 10)
      ) {
        areaPicture.posXScroll = this.getPositionX(areaPicture);
        this.resetScrollAreaPicture(areaPicture, ctx, mediaPosition, image);
      } else if (areaPicture.scrollDirection == ScrollDirectionsEnum.UP && this.checkPositionYArea(areaPicture, true)) {
        areaPicture.posYScroll = 0;
        this.reDrawPictureArea(ctx, areaPicture, mediaPosition, image);
        this.resetScrollAreaPicture(areaPicture, ctx, mediaPosition, image);
      } else if (areaPicture.scrollDirection == ScrollDirectionsEnum.DOWN && this.checkPositionYArea(areaPicture, false)) {
        areaPicture.posYScroll = 0;
        this.reDrawPictureArea(ctx, areaPicture, mediaPosition, image);
        this.resetScrollAreaPicture(areaPicture, ctx, mediaPosition, image);
      } else {
        ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
        this.reDrawPictureArea(ctx, areaPicture, mediaPosition, image);
        this.calcPositionScrollHorizontal(areaPicture);
      }
    }
  }

  /**
   * Redraw picture area
   *
   * @param ctx
   * @param areaPicture
   * @param mediaPosition
   * @param image
   */
  private reDrawPictureArea(ctx: any, areaPicture: PictureAreaLED, mediaPosition: any, image: any): void {
    if (areaPicture.objectFit == ObjectFitEnum.FILL) {
      ctx.drawImage(image, areaPicture.posXScroll, areaPicture.posYScroll, mediaPosition.width, mediaPosition.height);
    } else {
      ctx.drawImage(
        image,
        mediaPosition.sX,
        mediaPosition.sY,
        mediaPosition.sWidth,
        mediaPosition.sHeight,
        areaPicture.posXScroll,
        areaPicture.posYScroll,
        mediaPosition.width,
        mediaPosition.height
      );
    }
  }

  /**
   * Reset scroll area picture
   *
   * @param areaPicture
   * @param ctx
   * @param mediaPosition
   * @param image
   */
  private resetScrollAreaPicture(areaPicture: PictureAreaLED, ctx: any, mediaPosition: any, image: any): void {
    areaPicture[this.SUBSCRIPTION]?.unsubscribe();
    this.clearTimeoutFinishScroll(areaPicture);
    this.previewAreaPicture(ctx, areaPicture, mediaPosition, image);
  }

  /**
   * Calc position scroll horizontal
   *
   * @param areaPicture
   */
  private calcPositionScrollHorizontal(areaPicture: PictureAreaLED) {
    switch (areaPicture.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        areaPicture.posXScroll -= this.getScrollSpeed(areaPicture) / 20;
        break;
      case ScrollDirectionsEnum.RIGHT:
        areaPicture.posXScroll += this.getScrollSpeed(areaPicture) / 20;
        break;
      case ScrollDirectionsEnum.UP:
        areaPicture.posYScroll -= this.getScrollSpeed(areaPicture) / 20;
        if (areaPicture.posYScroll < -areaPicture.height * Constant.DESTINATION_SCALE - 10) {
          areaPicture.posYScroll = areaPicture.height * Constant.DESTINATION_SCALE;
        }
        break;
      case ScrollDirectionsEnum.DOWN:
        areaPicture.posYScroll += this.getScrollSpeed(areaPicture) / 20;
        if (areaPicture.posYScroll > areaPicture.height * Constant.DESTINATION_SCALE + 10) {
          areaPicture.posYScroll = -areaPicture.height * Constant.DESTINATION_SCALE;
        }
        break;
      default:
        break;
    }
  }

  /**
   * Check position Y area
   *
   * @param area
   * @param isUp
   * @returns
   */
  private checkPositionYArea(area: AreaLED, isUp: boolean): boolean {
    return isUp
      ? Math.floor(area.posYScroll) > 0 && Math.floor(area.posYScroll) <= (this.getScrollSpeed(area) / 20) * Constant.DESTINATION_SCALE
      : Math.floor(area.posYScroll) < 0 && Math.floor(area.posYScroll) >= (-this.getScrollSpeed(area) / 20) * Constant.DESTINATION_SCALE;
  }

  /**
   * Get scroll speed
   *
   * @param area
   * @returns
   */
  private getScrollSpeed(area: AreaLED): number {
    switch (area.scrollSpeed) {
      case SpeedEnum.LOW:
        return 10;
      case SpeedEnum.MID:
        return 20;
      case SpeedEnum.HIGH:
        return 30;
      case SpeedEnum.VERY_HIGH:
        return 40;
      default:
        return 0;
    }
  }

  /**
   * Clear timeout finish scroll
   *
   * @param area
   */
  private clearTimeoutFinishScroll(area: AreaLED): void {
    const index = this.timeoutsDisplay.findIndex(timeout => timeout.areaId == area.id);
    if (index == -1) {
      return;
    }
    clearTimeout(this.timeoutsDisplay[index].time);
  }

  /**
   * change start state
   * @param isStart
   */
  public changeStartState(isStart: boolean): void {
    this.isStartDestination = isStart;
  }

  /**
   * clear canvas
   * @param canvasLayoutRealTime
   */
  public clearCanvas(canvasLayoutRealTime): void {
    if (!canvasLayoutRealTime) {
      return;
    }
    let ctx = canvasLayoutRealTime.getContext('2d');
    ctx.clearRect(0, 0, canvasLayoutRealTime.width, canvasLayoutRealTime.height);
  }

  /**
   * draw border area
   * @param pointMaxX
   * @param pointMaxY
   * @param pointMinX
   * @param pointMinY
   * @param canvas
   * @param color
   * @param isFix
   */
  public drawBorderArea(
    pointMaxX: number,
    pointMaxY: number,
    pointMinX: number,
    pointMinY: number,
    canvas: any,
    color: string,
    isFix: boolean
  ): void {
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (!isFix) {
      ctx.setLineDash([5, 3]);
    } else {
      ctx.setLineDash([]);
    }
    ctx.beginPath();
    ctx.moveTo(pointMinX, pointMinY);
    ctx.lineTo(pointMaxX, pointMinY);
    ctx.lineTo(pointMaxX, pointMaxY);
    ctx.lineTo(pointMinX, pointMaxY);
    ctx.lineTo(pointMinX, pointMinY);
    ctx.strokeStyle = color;
    ctx.lineWidth = Constant.BORDER_WIDTH_AREA;
    ctx.closePath();
    ctx.stroke();
    ctx.fillStyle = color;
  }

  /**
   * create canvas template
   * @param template template
   * @param canvasContainerDisplay ElementRef
   * @param renderer
   */
  public createCanvasTemplateLED(template: TemplateLED, canvasContainerDisplay: any, renderer: Renderer2): void {
    const canvas = renderer.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.background = '#000';
    canvas.style.width = template.width + 'px';
    canvas.style.height = template.height + 'px';
    canvas.width = template.width;
    canvas.height = template.height;
    renderer.appendChild(canvasContainerDisplay.nativeElement, canvas);
  }

  /**
   * create canvas to draw border area when create area
   * @param template
   * @param renderer
   * @param canvasLEDContainer
   */
  public createGridCanvasLayoutRealTime(template: TemplateLED, renderer: Renderer2, canvasLEDContainer: any) {
    const canvas = renderer.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.zIndex = '960';
    canvas.style.background = 'transparent';
    canvas.id = 'gridCanvasLED';
    canvas.style.width = template.width + 'px';
    canvas.style.height = template.height + 'px';
    canvas.width = template.width;
    canvas.height = template.height;
    var ctx = canvas.getContext('2d');
    ctx.strokeStyle = '#aaa';
    ctx.translate(0.5, 0.5);
    ctx.setLineDash([1, 3]);
    for (let i = 0; i < template.width; i += Constant.SCALE) {
      ctx.moveTo(i, 0);
      ctx.lineTo(i, template.height);
      ctx.stroke();
    }
    for (let j = 0; j < template.height; j += Constant.SCALE) {
      ctx.moveTo(0, j);
      ctx.lineTo(template.width, j);
      ctx.stroke();
    }
    renderer.appendChild(canvasLEDContainer.nativeElement, canvas);
  }

  /**
   * create canvas to draw border area when create area
   * @param template
   * @param renderer
   * @param canvasLEDContainer
   */
  public createCanvasLayoutRealTime(template: TemplateLED, renderer: Renderer2, canvasLEDContainer: any) {
    const canvas = renderer.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.zIndex = '970';
    canvas.style.background = 'transparent';
    canvas.id = Constant.CANVAS_LED_LAYOUT_ID;
    canvas.style.width = template.width + 'px';
    canvas.style.height = template.height + 'px';
    canvas.width = template.width;
    canvas.height = template.height;
    renderer.appendChild(canvasLEDContainer.nativeElement, canvas);
  }

  /**
   * create canvas area
   * @param area Area
   */
  public createCanvasArea(area: AreaLED, scale: number, renderer: Renderer2, canvasLEDContainer: any) {
    const canvas = renderer.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.zIndex = area.index;
    canvas.id = `Area + ${area.index}`;
    canvas.style.left = area.posX * scale + 'px';
    canvas.style.top = area.posY * scale + 'px';
    canvas.style.width = area.width * scale + 'px';
    canvas.style.height = area.height * scale + 'px';
    let color = area.checkTypeTextArea() ? '#00B050' : '#FF66FF';
    let borderStyle = area.isFix ? 'solid ' : 'dashed ';
    canvas.style.border = `${Constant.BORDER_WIDTH_AREA}px ` + borderStyle + color;
    canvas.width = area.width * scale;
    canvas.height = area.height * scale;
    renderer.appendChild(canvasLEDContainer.nativeElement, canvas);
    area.canvas = canvas;
  }

  /**
   * clear all threads draw template
   *
   * @param template
   * @returns
   */
  public clearAllThreadDrawTemplate(template: TemplateLED): void {
    if (!template || !template?.areas) {
      return;
    }
    const areas = template.areas;
    areas.forEach(area => {
      if (area[this.SUBSCRIPTION]) {
        area[this.SUBSCRIPTION].unsubscribe();
        delete area[this.SUBSCRIPTION];
      }
      this.clearTimeoutFinishScroll(area);
    });
    this.clearPreviewDisplaySubject.next();
  }

  /**
   * Draw fix picture
   *
   * @param areaPicture
   * @param renderer
   */
  public async drawAreaPictureOnDestination(areaPicture: PictureAreaLED, renderer: Renderer2): Promise<void> {
    renderer.setStyle(areaPicture.canvas, 'visibility', 'visible');
    if (areaPicture.media) {
      this.drawAreaPicture(areaPicture);
    }
  }

  /**
   * Change state play pause
   *
   * @param isPlayOn
   */
  public changeStatePlayPause(isPlayOn: boolean): void {
    this.isPlay = isPlayOn;
    if (this.isPlay) {
      this.startPreviewDisplaySubject.next();
    } else {
      this.pausePreviewDisplaySubject.next();
    }
  }
  /**
   * Pause preview
   */
  public pausePreview(): void {
    this.isPlay = false;
    this.pausePreviewDisplaySubject.next();
  }

  /**
   * Clear interval bus stop change over
   *
   * @param area
   */
  public clearIntervalBusStopChangeOver(area: AreaLED): void {
    area[this.SUBSCRIPTION]?.unsubscribe();
    this.timeoutsDisplay.forEach(timeout => {
      if (timeout.areaId == area.id) {
        clearTimeout(timeout.time);
      }
    });
    this.subscriptionsDisplay.forEach(sub => {
      if (sub.areaId == area.id) {
        sub.subscriptions.forEach(data => data.unsubscribe());
      }
    });
  }
}

/**
 * Class timeout
 */
class TimeOut {
  time: any;
  linkReferenceData: number;
  areaId: Number;
  constructor(time: any, linkReferenceData: number, areaId: Number) {
    this.time = time;
    this.linkReferenceData = linkReferenceData;
    this.areaId = areaId;
  }
}

class SubscriptionLed {
  areaId: number;
  subscriptions: Subscription[] = new Array<Subscription>();
  constructor(subscriptions: Subscription[], areaId: number) {
    this.subscriptions = subscriptions;
    this.areaId = areaId;
  }
}

export class AreaPosition {
  posXScroll: number;
  posYScroll: number;
  imageWidth: number;
  constructor(posXScroll: number, posYScroll: number, imageWidth: number) {
    this.posXScroll = posXScroll;
    this.posYScroll = posYScroll;
    this.imageWidth = imageWidth;
  }
}

export class FontCharacter {
  font: any;
  bitmap: any;

  constructor(font?: any, bitmap?: any) {
    this.font = font;
    this.bitmap = bitmap;
  }
}
