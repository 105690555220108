import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { OpenWeatherContent } from 'app/model/entity/open-weather-content';
import { APIDetails } from 'app/model/entity/api-details';

@Injectable({
  providedIn: 'root'
})
export class OpenWeatherContentService {
  /**
   * URL for weather-content API
   */
  private readonly openWeatherContentUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/open-weather-contents';

  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * read excel from client
   * @param files
   * @returns
   */
  public readExcelCities(file: File, action: string): Observable<any> {
    const params = new HttpParams().set('action', action);
    const formData = new FormData();
    formData.append('file', file);
    // }
    return this.http.post<any>(`${this.openWeatherContentUrl}/read-excels`, formData, { params });
  }

  /**
   * get all city
   * @returns
   */
  public getAllCity(): Observable<any> {
    return this.http.get<any>(`${this.openWeatherContentUrl}/get-all-city-imformation`);
  }

  /**
   * write excel city
   * @returns
   */
  public writeExcelCity(): Observable<any> {
    const params = new HttpParams().set('language', this.commonService.getCommonObject().setting?.language);
    return this.http.get(`${this.openWeatherContentUrl}/write-excel-city`, {
      responseType: 'blob',
      observe: 'response',
      params
    });
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * get all weather contents
   */
  public getOpenWeatherContents(): Observable<OpenWeatherContent[]> {
    return this.http.get<OpenWeatherContent[]>(`${this.openWeatherContentUrl}/get-all-open-weather-contents`);
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * auto Call API Weather
   */
  public autoCallAPIWeather(apiDetails: APIDetails, isCallAllContent: boolean, contentId?: number): Observable<OpenWeatherContent[]> {
    const params = new HttpParams().set('isCallAllContent', isCallAllContent.toString()).set('contentId', contentId.toString());
    return this.http.post<OpenWeatherContent[]>(`${this.openWeatherContentUrl}/update-open-weather-by-web-api`, apiDetails, { params });
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * save OpenWeatherContent
   *
   * @param weatherContent
   */
  public saveOpenWeatherContent(openWeatherContent: OpenWeatherContent, cities?: any): Observable<OpenWeatherContent> {
    openWeatherContent.cities = cities ? cities : openWeatherContent.cities;
    return this.http.post<OpenWeatherContent>(`${this.openWeatherContentUrl}/save-open-weather-content`, openWeatherContent);
  }
}
