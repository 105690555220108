import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { SettingDeviceAutoRefresh } from 'app/model/entity/setting-device-auto-refresh';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for setting device auto refresh action
 */
export class SettingDeviceAutoRefreshService {
  /**
   * URL for setting device auto refresh API
   */
  private readonly settingDeviceAutoRefreshUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/setting-device-auto-refresh';

  constructor(private http: HttpClient) {}

  /**
   * Be used by DASH-BOARD-COMPONENT
   * get setting device auto refresh
   */
  public getInformationSettingAutoRefresh(): Observable<Array<SettingDeviceAutoRefresh>> {
    return this.http.get<Array<SettingDeviceAutoRefresh>>(
      `${this.settingDeviceAutoRefreshUrl}/${Constant.DASH_BOARD_COMPONENT_URL}/get-setting-device-auto-refresh`
    );
  }

  /**
   * Be used by DASH-BOARD-COMPONENT
   * save data setting
   *
   * @param settingDeviceAutoRefresh
   */
  public saveDataSetting(settingDeviceAutoRefresh: SettingDeviceAutoRefresh): Observable<SettingDeviceAutoRefresh> {
    return this.http.post<SettingDeviceAutoRefresh>(
      `${this.settingDeviceAutoRefreshUrl}/${Constant.DASH_BOARD_COMPONENT_URL}/save`,
      settingDeviceAutoRefresh
    );
  }
}
