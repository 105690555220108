import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { TimetableSchedule } from 'app/model/entity/timetable-schedule';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Class TimetableScheduleService
 */
export class TimetableScheduleService {
  /**
   * timetable schedule url
   */
  private timetableScheduleUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/timetable-schedule';

  constructor(private http: HttpClient) {}

  /**
   * Be used by TIMETABLE-COMPONENT
   * get timetable schedule
   *
   * @param timetableId timetable id
   */
  getTimetableScheduleByTimetableId(timetableId: Number): Observable<TimetableSchedule> {
    const params = new HttpParams().set('timetableId', timetableId + '');
    return this.http.get<TimetableSchedule>(
      `${this.timetableScheduleUrl}/${Constant.TIMETABLE_COMPONENT_URL}/get-timetable-schedule-by-timetable-id`,
      {
        params
      }
    );
  }

  /**
   * save timetable schedule
   * @param timetableSchedule timetableSchedule
   */
  save(timetableSchedule: TimetableSchedule): Observable<TimetableSchedule> {
    return this.http.post<TimetableSchedule>(`${this.timetableScheduleUrl}/${Constant.TIMETABLE_COMPONENT_URL}/save`, timetableSchedule);
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * update item names
   *
   * @param timetableId
   * @param itemNames
   * @param items
   * @param isChangeScheduleHeader
   */
  updateItemNames(timetableId: Number, itemNames: Array<string>, items: Array<string>, isChangeScheduleHeader: boolean): Observable<void> {
    const formData = new FormData();
    formData.append('timetableId', timetableId + '');
    formData.append('itemNames', JSON.stringify(itemNames));
    formData.append('scheduleJson', JSON.stringify(items));
    formData.append('isChangeScheduleHeader', isChangeScheduleHeader + '');
    return this.http.post<void>(
      `${this.timetableScheduleUrl}/${Constant.TIMETABLE_COMPONENT_URL}/update-item-names-by-timetable-id`,
      formData
    );
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * delete schedule by timetable id
   *
   * @param timetableId
   * @returns
   */
  deleteScheduleByTimetableId(timetableId: Number): Observable<void> {
    const params = new HttpParams().set('timetableId', timetableId + '');
    return this.http.delete<void>(`${this.timetableScheduleUrl}/${Constant.TIMETABLE_COMPONENT_URL}/delete-by-timetable-id`, { params });
  }
}
