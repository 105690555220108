import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { UserSetting } from 'app/model/entity/user-setting';
import { Timezone } from 'app/dialog/dialog-setting/dialog-setting.component';

@Injectable({
  providedIn: 'root'
})
/**
 * template group service
 */
export class UserSettingService {
  private settingUrl = Constant.BACKEND_URL + Constant.UAA_SERVICE_URL + 'api/setting';
  private timezoneUrl = Constant.BACKEND_URL + Constant.UAA_SERVICE_URL + 'api/timezone';
  constructor(private http: HttpClient) {}

  /**
   * get information setting of user in DB
   */
  getInfoSetting(): Observable<UserSetting> {
    return this.http.get<UserSetting>(`${this.settingUrl}`);
  }

  saveInfoSetting(userSetting: UserSetting): Observable<UserSetting> {
    return this.http.post<UserSetting>(this.settingUrl, userSetting);
  }

  getAllTimeZone(): Observable<Timezone[]> {
    return this.http.get<Timezone[]>(this.timezoneUrl);
  }
}
