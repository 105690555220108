import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { DeliveryStatusDestination } from 'app/model/entity/delivery-status-destination';
import { DeviceCalendar } from 'app/model/entity/device-calendar';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for delivery status timetable action
 */
export class DeliveryStatusDestinationService {
  /**
   * URL of device publish info API
   */
  deliveryStatusDestinationUrl = Constant.BACKEND_URL + Constant.EDS_SERVICE_URL + 'api/delivery-status-destination';

  constructor(private http: HttpClient) {}

  /**
   * Be used DESTINATION-COMPONENT
   * get delivery status for devices
   * @returns
   */
  getDeliveryStatusForDevices(): Observable<Array<DeliveryStatusDestination>> {
    return this.http.get<Array<DeliveryStatusDestination>>(`${this.deliveryStatusDestinationUrl}/get-delivery-status-for-devices`);
  }

  /**
   * Be used DESTINATION-COMPONENT
   * update status first time for devices
   *
   * @param deviceIds
   */
  updateStatusFirstTimeForDevices(deviceIds: Array<Number>): Observable<DeliveryStatusDestination[]> {
    return this.http.post<DeliveryStatusDestination[]>(
      `${this.deliveryStatusDestinationUrl}/first-time/update-status-for-devices`,
      deviceIds
    );
  }

  /**
   * Be used DESTINATION-COMPONENT
   * update status first time for devices
   *
   * @param deviceIds
   */
  updateStatusForDevices(deviceIds: Array<Number>): Observable<DeliveryStatusDestination[]> {
    return this.http.post<DeliveryStatusDestination[]>(`${this.deliveryStatusDestinationUrl}/update-status-for-devices`, deviceIds);
  }

  /**
   * Be used DESTINATION-COMPONENT
   * cancel delivery
   *
   * @param devices
   */
  cancelDelivery(devices: Array<any>): Observable<any> {
    return this.http.post<any>(`${this.deliveryStatusDestinationUrl}/cancel-delivery`, devices);
  }

  /**
   * Be used DESTINATION-COMPONENT
   * Update failed status for devices
   * @param checkedDevices
   * @returns
   */
  public updateFailedStatusForDevices(checkedDevices: Array<DeviceCalendar>): Observable<Array<DeliveryStatusDestination>> {
    return this.http.post<Array<DeliveryStatusDestination>>(
      `${this.deliveryStatusDestinationUrl}/update-failed-status-for-devices`,
      checkedDevices
    );
  }
}
