import { Injectable } from '@angular/core';
import { Media } from 'app/model/entity/media.js';
import { Sound } from 'app/model/entity/sound';
import { UserSetting } from 'app/model/entity/user-setting';
import { VideoOnCanvasObject } from 'app/module/media-manager/media-manager.component';
import _ from 'lodash';
import moment from 'moment';
import { interval, Subject, Subscription } from 'rxjs';
import { repeatWhen, takeUntil, timeInterval } from 'rxjs/operators';
import { Helper } from '../common/helper';
import {
  AlignmentEnum,
  Constant,
  GIFFileExtensions,
  LinkDataPictureEnum,
  LinkDataTextEnum,
  ObjectFitEnum,
  OrientationEnum,
  OutputOptionEnum,
  ScrollDirectionsEnum,
  ScrollStatusEnum,
  TimingOffEnum,
  TimingOnEnum,
  TypeMediaFileEnum
} from '../config/constants';
import { Area } from '../model/entity/area.js';
import { EventBusStop } from '../model/entity/event-bus-stop';
import { PictureArea } from '../model/entity/picture-area.js';
import { Route } from '../model/entity/route';
import { Template } from '../model/entity/template.js';
import { TextArea } from '../model/entity/text-area.js';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class DrawService {
  /**
   * list timeout
   */
  timeoutList: Array<TimeOut> = new Array<TimeOut>();
  /**
   * true if next button
   */
  isNext: boolean;
  /**
   * true if play preview
   */
  isPlay: boolean;
  /**
   * route
   */
  route: Route;

  private readonly pausePreviewSubject = new Subject<void>();
  private readonly startPreviewSubject = new Subject<void>();
  private readonly clearPreviewSubject = new Subject<void>();

  eventsBusStop1: Array<EventBusStop> = new Array<EventBusStop>();
  eventsBusStop2: Array<EventBusStop> = new Array<EventBusStop>();

  setting: UserSetting;
  constructor(private commonService: CommonService) {
    this.setting = commonService.getCommonObject().setting;
  }

  /**
   * draw template
   * @param template
   */
  public drawTemplate(template: Template): void {
    var areas: Array<Area> = new Array<Area>();
    template.layers.forEach(layer => {
      layer.areas.forEach(area => {
        areas.push(area);
      });
    });
    Promise.all(
      areas.map(async area => {
        if (area.checkTypeTextArea()) {
          this.drawAreaText(area);
        } else {
          this.drawAreaPicture(area);
        }
      })
    );
  }

  /**
   * draw area
   * @param area Area
   */
  public drawArea(area: Area): void {
    if (!area) {
      return;
    }
    if (area.checkTypeTextArea()) {
      this.drawAreaText(area);
    } else {
      this.drawAreaPicture(area);
    }
  }

  /**
   * draw area text
   * @param area Area
   */
  public drawAreaText(area: Area): void {
    let areaText = area as TextArea;
    for (let canvas of [areaText.canvas]) {
      // get ctx from canvas
      let ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // draw color background
      ctx.fillStyle = areaText.backgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // set style bold / italic / normal
      let textStyle = 'normal';
      if (areaText.isBold && !areaText.isItalic) {
        textStyle = Constant.BOLD_STYLE;
      } else if (areaText.isItalic && !areaText.isBold) {
        textStyle = Constant.ITALIC_STYLE;
      } else if (areaText.isItalic && areaText.isBold) {
        textStyle = Constant.BOLD_STYLE + ' ' + Constant.ITALIC_STYLE;
      }

      // draw color text
      ctx.fillStyle = areaText.fontColor;

      // draw font text
      ctx.font = textStyle + ' ' + areaText.fontSize + 'px ' + (typeof areaText.fontName == 'undefined' ? 'Arial' : areaText.fontName);
      var text = areaText.text !== '' ? areaText.text : ' ';

      // set orientation
      if (areaText.orientation == OrientationEnum.HORIZONTAL) {
        const referencePosition = Helper.getReferencePositionOrientationHorizontal(ctx, areaText);
        ctx.fillText(text, referencePosition.referenceX, referencePosition.referenceY);
      } else if (areaText.orientation == OrientationEnum.VERTICAL) {
        var textDraw = text ?? this.getTimer();
        var charsSplit = textDraw.split('');
        let metrics = ctx.measureText(textDraw);
        let firefox = navigator.userAgent.search('Firefox');
        let characterHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
        if (firefox > -1) {
          characterHeight = areaText.fontSize;
        }
        let textHeight = characterHeight * charsSplit.length;
        const referencePosition = Helper.getReferencePositionOrientationVertical(ctx, areaText, textHeight);
        let referenceX = referencePosition.referenceX;
        let referenceY = referencePosition.referenceY;
        ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
        ctx.fillStyle = areaText.backgroundColor;
        ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
        // draw color text
        ctx.fillStyle = areaText.fontColor;
        var i = 0;
        var ch: string;
        var posY = 0.5;
        while ((ch = charsSplit[i++])) {
          var chWidth = ctx.measureText(ch).width;
          ctx.fillText(ch, referenceX - chWidth / 2, referenceY + posY * characterHeight);
          posY++;
        }
      } else if (areaText.orientation == OrientationEnum.SIDEWAYS) {
        let textWidth = ctx.measureText(text).width;
        const referencePosition = Helper.getReferencePositionOrientationSideways(ctx, areaText, textWidth);
        ctx.rotate(Math.PI / 2);
        ctx.fillText(text, referencePosition.referenceX, referencePosition.referenceY);
        ctx.resetTransform();
      }
    }
  }

  /**
   * draw area picture (media: video & image)
   * @param area Area
   */
  public async drawAreaPicture(area: Area) {
    let areaPicture = area as PictureArea;
    let canvas = areaPicture.canvas;
    if (areaPicture.media) {
      // case w, h image do not exist
      if (!areaPicture.media['width'] && !areaPicture.media['height']) {
        delete areaPicture.media['width'];
        delete areaPicture.media['height'];
        let imageInfo = await Helper.getImageInformation(areaPicture.media);
        areaPicture.media['width'] = `${imageInfo.width}`;
        areaPicture.media['height'] = `${imageInfo.height}`;
      }
    }
    let mediaPosition = Helper.coverMedia(canvas, areaPicture.media, areaPicture.objectFit);
    let ctx = canvas.getContext('2d');
    if (areaPicture.media) {
      ctx.globalAlpha = 1;
      if (areaPicture.media.type != TypeMediaFileEnum.MP4) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        try {
          let img = new Image();
          img.onload = function() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            if (areaPicture.objectFit == ObjectFitEnum.FILL) {
              ctx.drawImage(img, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
            } else {
              ctx.drawImage(
                img,
                mediaPosition.sX,
                mediaPosition.sY,
                mediaPosition.sWidth,
                mediaPosition.sHeight,
                mediaPosition.x,
                mediaPosition.y,
                mediaPosition.width,
                mediaPosition.height
              );
            }
          };
          img.src = areaPicture.media.url;
        } catch (error) {
          console.log('error draw 3', error);
          return true;
        }
        // }
      } else {
        areaPicture.videoPreview = document.createElement('video');
        areaPicture.videoPreview.src = areaPicture.media.url;
        areaPicture.videoPreview.muted = true;
        areaPicture.videoPreview
          .play()
          .then(() => {
            if (areaPicture.objectFit == ObjectFitEnum.FILL) {
              ctx.drawImage(areaPicture.videoPreview, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
            } else {
              ctx.drawImage(
                areaPicture.videoPreview,
                mediaPosition.sX,
                mediaPosition.sY,
                mediaPosition.sWidth,
                mediaPosition.sHeight,
                mediaPosition.x,
                mediaPosition.y,
                mediaPosition.width,
                mediaPosition.height
              );
            }
            let videoPreview = areaPicture.videoPreview.remove();
            if (videoPreview) {
              videoPreview
                .then(() => {
                  areaPicture.videoPreview.pause();
                })
                .catch(error => {
                  console.log('error draw 3', error);
                  return true;
                });
            }
          })
          .catch(error => {
            console.log('error draw 3', error);
            return true;
          });
      }
    }
  }

  public async drawPictureSequence(canvas: any, media: Media, objectFit: ObjectFitEnum) {
    await fetch(this.createGetRequestMedia(media.url))
      .then(response => response.blob())
      .then(blob => createImageBitmap(blob))
      .then(bitmap => {
        canvas._img = bitmap;
      });
    let ctx = canvas.getContext('2d');
    ctx.globalAlpha = 1;
    let mediaPosition = Helper.coverMedia(canvas, media, objectFit);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    let pattern = ctx.createPattern(canvas._img, 'no-repeat');
    ctx.fillStyle = pattern;
    // imageHtmlElement.addEventListener
    if (objectFit == ObjectFitEnum.FILL) {
      ctx.drawImage(canvas._img, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
    } else {
      ctx.drawImage(
        canvas._img,
        mediaPosition.sX,
        mediaPosition.sY,
        mediaPosition.sWidth,
        mediaPosition.sHeight,
        mediaPosition.x,
        mediaPosition.y,
        mediaPosition.width,
        mediaPosition.height
      );
    }
  }

  /**
   * draw border group
   * @param pointMaxX
   * @param pointMaxY
   * @param pointMinX
   * @param pointMinY
   * @param canvas
   * @param isClearOldBorder
   */
  drawBorderGroup(
    pointMaxX: number,
    pointMaxY: number,
    pointMinX: number,
    pointMinY: number,
    canvas: any,
    isClearOldBorder: boolean
  ): void {
    let ctx = canvas.getContext('2d');
    if (isClearOldBorder) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
    ctx.beginPath();
    ctx.moveTo(pointMinX, pointMinY);
    ctx.lineTo(pointMaxX, pointMinY);
    ctx.lineTo(pointMaxX, pointMaxY);
    ctx.lineTo(pointMinX, pointMaxY);
    ctx.lineTo(pointMinX, pointMinY);
    ctx.strokeStyle = '#347AFE';
    ctx.lineWidth = 3;
    ctx.closePath();
    ctx.stroke();
    ctx.fillStyle = '#347AFE';
  }

  /**
   * draw border area
   * @param pointMaxX
   * @param pointMaxY
   * @param pointMinX
   * @param pointMinY
   * @param canvas
   * @param color
   * @param isFix
   */
  public drawBorderArea(
    pointMaxX: number,
    pointMaxY: number,
    pointMinX: number,
    pointMinY: number,
    canvas: any,
    color: string,
    isFix: boolean
  ): void {
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (!isFix) {
      ctx.setLineDash([5, 3]);
    } else {
      ctx.setLineDash([]);
    }
    ctx.beginPath();
    ctx.moveTo(pointMinX, pointMinY);
    ctx.lineTo(pointMaxX, pointMinY);
    ctx.lineTo(pointMaxX, pointMaxY);
    ctx.lineTo(pointMinX, pointMaxY);
    ctx.lineTo(pointMinX, pointMinY);
    ctx.strokeStyle = color;
    ctx.lineWidth = 3;
    ctx.closePath();
    ctx.stroke();
    ctx.fillStyle = color;
  }

  /**
   * clear canvas
   * @param canvasLayoutRealTime
   */
  public clearCanvas(canvasLayoutRealTime) {
    if (!canvasLayoutRealTime) {
      return;
    }
    let ctx = canvasLayoutRealTime.getContext('2d');
    ctx.clearRect(0, 0, canvasLayoutRealTime.width, canvasLayoutRealTime.height);
  }

  /**
   * set up preview
   * @param display Template
   * @param route Route
   */
  public setupPreview(display: Template, route: Route, isDisplay2: boolean): void {
    this.route = route;
    display.count = 0;
    var eventsBusStop = isDisplay2 ? this.eventsBusStop2 : this.eventsBusStop1;
    eventsBusStop = new Array<EventBusStop>();
    this.route.busStops[0]?.events.forEach(eventBusStop => {
      eventBusStop.orderNoBusStop = this.route.busStops[0].orderNo;
      // push event Bus stop
      eventsBusStop.push(eventBusStop);
    });
  }

  /**
   * draw area link text
   * @param display
   * @param textArea
   * @param isDisplay2
   */
  private async drawAreaLinkText(display: Template, textArea: TextArea, isDisplay2: boolean) {
    switch (textArea.linkReferenceData) {
      case LinkDataTextEnum.ROUTE_NAME:
        // draw link text if linkReferenceData is Route Name
        await this.drawRoute(display, textArea, this.route?.name);
        break;
      case LinkDataTextEnum.ROUTE_NO:
        // draw link text if linkReferenceData is Route No
        await this.drawRoute(display, textArea, this.route?.routeNo);
        break;
      case LinkDataTextEnum.BUS_STOP_NAME:
        // draw link text if linkReferenceData is Bus Stop Name
        await this.drawTextBusStop(display, textArea, this.route, true);
        break;
      case LinkDataTextEnum.BUS_STOP_NO:
        // draw link text if linkReferenceData is Bus Stop No
        await this.drawTextBusStop(display, textArea, this.route, false);
        break;
      case LinkDataTextEnum.CLOCK:
        // draw link text if linkReferenceData is Clock
        await this.drawClock(display, textArea);
        break;
      case LinkDataTextEnum.INDEX_WORD:
        // draw link text if linkReferenceData is IndexWord
        await this.drawTextIndexWord(display, textArea, this.route);
        break;
      case LinkDataTextEnum.POSITION_DRIVEN:
        // draw link text if linkReferenceData is position driven
        var areaDisplayType = isDisplay2 ? 'areaDisplay2' : 'areaDisplay1';
        var eventsBusStop = this.getEventBusStop(display, textArea, isDisplay2);
        var eventBusStop = eventsBusStop.find(eventData => eventData[areaDisplayType]?.id == textArea.id);
        if (eventBusStop) {
          if (!this.isNext) {
            textArea['subscriptionClear']?.unsubscribe();
          }
          if (!textArea['isDrawing'] && textArea.isOn && eventBusStop.outputOption == OutputOptionEnum.SECOND) {
            const timeOut = interval(eventBusStop.duration * 1000);
            const subscription: Subscription = timeOut
              .pipe(
                timeInterval(),
                takeUntil(this.pausePreviewSubject),
                repeatWhen(() => this.startPreviewSubject)
              )
              .subscribe(
                () => {
                  if (!this.isPlay) {
                    subscription.add();
                  }
                  textArea['subscription']?.unsubscribe();
                  delete textArea['isDrawing'];
                  this.clearCanvas(textArea.canvas);
                  textArea.isChangeTemplateCount = false;
                  textArea['numberOfDraw'] = 1;
                  subscription.unsubscribe();
                },
                err => console.log(err)
              );
            if (!this.isPlay) {
              this.pausePreviewSubject.next();
            }
            textArea['subscriptionClear'] = subscription;
          }
          if (!textArea['numberOfDraw']) {
            await this.drawTextPositionDriven(display, textArea, this.route, isDisplay2);
          }
        } else {
          textArea?.['subscription']?.unsubscribe();
          this.clearCanvas(textArea.canvas);
        }
        break;
      case LinkDataTextEnum.EXTERNAL_SOURCE:
        break;
      default:
        break;
    }
  }

  /**
   * draw link picture area
   * @param display
   * @param pictureArea
   * @param isDisplay2
   */
  private async drawLinkPictureArea(display: Template, pictureArea: PictureArea, isDisplay2: boolean) {
    switch (pictureArea.attribute) {
      case LinkDataPictureEnum.INDEX_WORD:
        // draw link picture if attribute is IndexWord
        await this.drawPictureIndexWord(display, pictureArea, this.route, isDisplay2);
        break;
      case LinkDataPictureEnum.POSITION_DRIVEN:
        // draw link picture if attribute is Position Driven
        if (!pictureArea.media) {
          return;
        }
        var areaDisplayType = isDisplay2 ? 'areaDisplay2' : 'areaDisplay1';
        var eventsBusStop = this.getEventBusStop(display, pictureArea, isDisplay2);
        var eventBusStop = eventsBusStop.find(eventData => eventData[areaDisplayType]?.id == pictureArea.id);
        if (!this.isNext) {
          pictureArea['subscriptionClear']?.unsubscribe();
        }
        if (pictureArea.media.type != TypeMediaFileEnum.MP4) {
          if (!pictureArea['isDrawing'] && eventBusStop?.outputOption == OutputOptionEnum.SECOND) {
            const timeOut = interval(eventBusStop.duration * 1000);
            const subscription: Subscription = timeOut
              .pipe(
                timeInterval(),
                takeUntil(this.pausePreviewSubject),
                repeatWhen(() => this.startPreviewSubject)
              )
              .subscribe(
                () => {
                  if (!this.isPlay) {
                    subscription.add();
                  }
                  if (!pictureArea['isChangeDataEvent']) {
                    pictureArea['numberOfDraw'] = 1;
                  }
                  delete pictureArea['isDrawing'];
                  this.clearCanvas(pictureArea.canvas);
                  this.stopSoundEventPosition(pictureArea, this.route);
                  subscription.unsubscribe();
                },
                err => console.log(err)
              );
            if (!this.isPlay) {
              this.pausePreviewSubject.next();
            }
            pictureArea['subscriptionClear'] = subscription;
          }
          if (pictureArea['indexOri'] < display.count) {
            if (eventBusStop?.outputOption == OutputOptionEnum.SECOND) {
              pictureArea.isOn = true;
            } else {
              this.clearCanvas(pictureArea.canvas);
              this.stopSoundEventPosition(pictureArea, this.route);
              pictureArea.isOn = false;
            }
            return;
          }
          this.checkOutputOptionOff(display, pictureArea, isDisplay2);
          if (!pictureArea['isDrawing']) {
            await this.drawPicturePositionDriven(pictureArea, this.route, isDisplay2);
          }
        } else if (eventBusStop) {
          if (!pictureArea['isDrawing']) {
            await this.drawVideoPositionDriven(
              display,
              pictureArea,
              this.route,
              isDisplay2,
              eventBusStop.outputOption == OutputOptionEnum.REPEAT
            );
          }
        }
        break;
      case LinkDataPictureEnum.EXTERNAL_SOURCE:
        break;
      default:
        break;
    }
  }

  /**
   * draw start preview
   * @param display Template
   * @param isDisplay2
   */
  public drawStartPreview(display: Template, isDisplay2: boolean): void {
    if (!display) {
      return;
    }
    var areas = Helper.getAllAreaTemplate(display);
    Promise.all(
      areas.map(async area => {
        var isPositionDriven = area.checkTypeTextArea()
          ? area.getArea().linkReferenceData == LinkDataTextEnum.POSITION_DRIVEN
          : area.getArea().attribute == LinkDataPictureEnum.POSITION_DRIVEN;
        if (isPositionDriven) {
          this.checkOutputOptionOff(display, area, isDisplay2);
        } else if (this.route?.busStops.length > 0) {
          this.checkDisplayTimingOnOff(display, area, this.route);
        }
        // case area instanceof TextArea
        if (area.checkTypeTextArea()) {
          var textArea = area as TextArea;
          if (textArea.isFix) {
            // draw fix text
            await this.drawAreaFixText(display, textArea);
          } else {
            // draw link text
            await this.drawAreaLinkText(display, textArea, isDisplay2);
          }
          // case area instanceof Picture
        } else {
          var pictureArea = area.getArea() as PictureArea;
          if (pictureArea.isFix) {
            if (pictureArea.media) {
              if (pictureArea.media.type != TypeMediaFileEnum.MP4) {
                // draw fix picture
                await this.drawAreaFixPicture(pictureArea, isDisplay2);
              } else {
                // draw video fix picture
                pictureArea.isChangeTemplateCount = false;
                await this.drawVideoFixPicture(display, pictureArea, isDisplay2, false);
              }
            }
          } else {
            // draw link picture
            this.drawLinkPictureArea(display, pictureArea, isDisplay2);
          }
        }
      })
    );
  }

  /**
   * reset preview
   * @param display Template
   * @param route Route
   * @param isDisplay2
   */
  public rePreview(display: Template, route: Route, isDisplay2: boolean): void {
    // set up preview
    this.setupPreview(display, route, isDisplay2);
    // draw start preview
    this.drawStartPreview(display, isDisplay2);
  }

  /**
   * handle when click control preview
   * @param display Template
   * @param isNext true if next preview
   * @param route Route
   */
  public controlPreview(display: Template, isNext: boolean, isDisplay2: boolean): void {
    this.isNext = isNext;
    this.drawPreview(display, isDisplay2);
  }

  /**
   * handle event when click button
   * @param display Template
   * @param isDisplay2
   * @param areaList list areas
   */
  public handleEventOn(display: Template, isDisplay2: boolean, areaList?: Array<Area>): void {
    this.drawPreview(display, isDisplay2, areaList);
  }

  /**
   * draw video fix picture preview
   * @param display Template
   * @param areaPicture PictureArea
   * @param isDisplay2
   * @param isDrawEvent is draw area based ond Event
   */
  private async drawVideoFixPicture(display: Template, areaPicture: PictureArea, isDisplay2: boolean, isDrawEvent: boolean) {
    if (!areaPicture.canvas) {
      return;
    }
    if (areaPicture.isOn) {
      if (isDrawEvent) {
        areaPicture['isDrawing'] = false;
      }
      if (isDrawEvent || !areaPicture.isChangeTemplateCount) {
        let ctx = areaPicture.canvas.getContext('2d');
        ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
        let mediaPos = Helper.coverMedia(areaPicture.canvas, areaPicture.media, areaPicture.objectFit);
        this.drawVideo(display, areaPicture, ctx, mediaPos, areaPicture.canvas, false, isDisplay2);
      }
      areaPicture.isChangeTemplateCount = true;
    } else {
      areaPicture.canvas.getContext('2d').clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
      if (areaPicture && areaPicture.isFix) {
        this.stopAudio(areaPicture);
        this.stopVideo(areaPicture);
      }
      areaPicture.isChangeTemplateCount = false;
    }
  }

  /**
   * draw preview of template
   * @param display Template
   * @param isDisplay2
   * @param areaList list areas
   */
  public async drawPreview(display: Template, isDisplay2: boolean, areaList?: Array<Area>) {
    // get all area
    var areas = areaList ?? Helper.getAllAreaTemplate(display);
    if (!areas) {
      return;
    }
    Promise.all(
      areas.map(async area => {
        var isPositionDriven = area.checkTypeTextArea()
          ? area.getArea().linkReferenceData == LinkDataTextEnum.POSITION_DRIVEN
          : area.getArea().attribute == LinkDataPictureEnum.POSITION_DRIVEN;
        if (isPositionDriven) {
          this.checkOutputOptionOff(display, area, isDisplay2);
        } else if (this.route?.busStops.length > 0) {
          this.checkDisplayTimingOnOff(display, area, this.route);
        }
        if (area.checkTypeTextArea()) {
          var textArea = area as TextArea;
          if (textArea.isFix) {
            // draw fix text
            await this.drawAreaFixText(display, textArea);
          } else {
            // draw link text
            await this.drawAreaLinkText(display, textArea, isDisplay2);
          }
          // case area instanceof Picture
        } else {
          var pictureArea = area.getArea() as PictureArea;
          if (pictureArea.isFix) {
            if (pictureArea.media) {
              if (pictureArea.media.type != TypeMediaFileEnum.MP4) {
                // draw fix picture
                await this.drawAreaFixPicture(pictureArea, isDisplay2);
              } else {
                // draw video fix picture
                await this.drawVideoFixPicture(display, pictureArea, isDisplay2, !!areaList);
              }
            } else {
              this.clearCanvas(pictureArea.canvas);
            }
          } else {
            // draw link picture
            await this.drawLinkPictureArea(display, pictureArea, isDisplay2);
          }
        }
      })
    );
  }

  /**
   * check display timing on/off
   * @param display Template
   * @param area Area
   * @param route Route
   */
  private checkDisplayTimingOnOff(display: Template, area: Area, route: Route): void {
    switch (area.timingOn) {
      case TimingOnEnum.STOP_REQUEST:
        switch (area.timingOff) {
          case TimingOffEnum.DOOR_CLOSE:
          case TimingOffEnum.ARRIVAL:
            if (area.isOffEvent) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_STOP:
            if (display.count == route.busStops.length - 1) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_1_STOP:
            if (display.count == route.busStops.length - 2) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_2_STOP:
            if (display.count == route.busStops.length - 3) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_3_STOP:
            if (display.count == route.busStops.length - 4) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          default:
            break;
        }
        break;
      case TimingOnEnum.ARRIVAL:
        switch (area.timingOff) {
          case TimingOffEnum.DOOR_CLOSE:
          case TimingOffEnum.STOP_REQUEST:
            if (area.isOffEvent) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_STOP:
            if (display.count == route.busStops.length - 1) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_1_STOP:
            if (display.count == route.busStops.length - 2) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_2_STOP:
            if (display.count == route.busStops.length - 3) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_3_STOP:
            if (display.count == route.busStops.length - 4) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          default:
            break;
        }
        break;
      case TimingOnEnum.DOOR_CLOSE:
        switch (area.timingOff) {
          case TimingOffEnum.STOP_REQUEST:
          case TimingOffEnum.ARRIVAL:
            if (area.isOffEvent) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_STOP:
            if (display.count == route.busStops.length - 1) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_1_STOP:
            if (display.count == route.busStops.length - 2) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_2_STOP:
            if (display.count == route.busStops.length - 3) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_3_STOP:
            if (display.count == route.busStops.length - 4) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          default:
            break;
        }
        break;
      case TimingOnEnum.LAST_STOP:
        switch (area.timingOff) {
          case TimingOffEnum.DOOR_CLOSE:
          case TimingOffEnum.STOP_REQUEST:
          case TimingOffEnum.ARRIVAL:
            if (area.isOffEvent) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          default:
            break;
        }
        break;
      case TimingOnEnum.LAST_1_STOP:
        if (area.timingOff == TimingOffEnum.LAST_STOP && display.count == route.busStops.length - 1) {
          area.isOn = false;
          delete area['isHandleEvent'];
        }
        break;
      case TimingOnEnum.LAST_2_STOP:
        switch (area.timingOff) {
          case TimingOffEnum.LAST_STOP:
            if (display.count == route.busStops.length - 1) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_1_STOP:
            if (display.count == route.busStops.length - 2) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          default:
            break;
        }
        break;
      case TimingOnEnum.LAST_3_STOP:
        switch (area.timingOff) {
          case TimingOffEnum.STOP_REQUEST:
            delete area['isHandleEvent'];
            break;
          case TimingOffEnum.LAST_STOP:
            if (display.count == route.busStops.length - 1) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_1_STOP:
            if (display.count == route.busStops.length - 2) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_2_STOP:
            if (display.count == route.busStops.length - 3) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          default:
            break;
        }
        break;
      case TimingOnEnum.FROM_THE_BEGINNING:
        switch (area.timingOff) {
          case TimingOffEnum.DOOR_CLOSE:
          case TimingOffEnum.STOP_REQUEST:
          case TimingOffEnum.ARRIVAL:
            if (area.isOffEvent) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_STOP:
            if (display.count == route.busStops.length - 1) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_1_STOP:
            if (display.count == route.busStops.length - 2) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_2_STOP:
            if (display.count == route.busStops.length - 3) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          case TimingOffEnum.LAST_3_STOP:
            if (display.count == route.busStops.length - 4) {
              area.isOn = false;
              delete area['isHandleEvent'];
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  /**
   * create get request media
   * @param mediaUrl
   */
  public createGetRequestMedia(mediaUrl: string): Request {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/octet-stream');
    myHeaders.append('Accept', '*/*');
    myHeaders.append('Access-Control-Allow-Origin', '*');
    myHeaders.append('Access-Control-Allow-Headers', '*');
    return new Request(mediaUrl, {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin'
    });
  }

  /**
   * draw position driven preview (Picture Area)
   * @param area Area
   * @param route Route
   * @param isDisplay2
   */
  private async drawPicturePositionDriven(area: Area, route: Route, isDisplay2: boolean): Promise<void> {
    var areaPicture = area as PictureArea;
    var medias;
    if (route.busStops.length > 0) {
      var areaDisplayType = isDisplay2 ? 'areaDisplay2' : 'areaDisplay1';
      var eventsBusStop = isDisplay2 ? this.eventsBusStop2 : this.eventsBusStop1;
      medias = eventsBusStop.find(e => e[areaDisplayType]?.id == area.id)?.medias;
    }
    if (!medias) {
      this.clearCanvas(areaPicture.canvas);
      return;
    }
    if (areaPicture.isOn) {
      // fetch media
      await fetch(this.createGetRequestMedia(medias[Constant.MEDIA_TYPE_IMG].url))
        .then(response => response.blob())
        .then(blob => createImageBitmap(blob))
        .then(bitmap => {
          areaPicture.canvas._img = bitmap;
        });
      let ctx = areaPicture.canvas.getContext('2d');
      ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
      let pattern = ctx.createPattern(areaPicture.canvas._img, 'no-repeat');
      ctx.fillStyle = pattern;
      let mediaPosition = Helper.coverMedia(areaPicture.canvas, medias[Constant.MEDIA_TYPE_IMG], areaPicture.objectFit);
      // draw image
      if (areaPicture.objectFit == ObjectFitEnum.FILL) {
        ctx.drawImage(areaPicture.canvas._img, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
      } else {
        ctx.drawImage(
          areaPicture.canvas._img,
          mediaPosition.sX,
          mediaPosition.sY,
          mediaPosition.sWidth,
          mediaPosition.sHeight,
          mediaPosition.x,
          mediaPosition.y,
          mediaPosition.width,
          mediaPosition.height
        );
      }
      areaPicture.canvas._img.close();
      areaPicture['isDrawing'] = true;
      // play sound
      const sound =
        medias[Constant.MEDIA_TYPE_IMG] instanceof Sound
          ? medias[Constant.MEDIA_TYPE_IMG]
          : medias[Constant.MEDIA_TYPE_MP3] instanceof Sound
          ? medias[Constant.MEDIA_TYPE_MP3]
          : undefined;
      if (sound) {
        this.playSound(sound, areaPicture, this.isPlay);
        this.unsubscribedSubjectArea(areaPicture);
        areaPicture['startPreviewSubject'] = this.startPreviewSubject.subscribe(() => {
          if (areaPicture['soundPosition'] && !areaPicture['soundPosition'].ended) {
            areaPicture['soundPosition'].play();
          }
        });
        areaPicture['pausePreviewSubject'] = this.pausePreviewSubject.subscribe(() => {
          this.pauseSound(areaPicture);
        });
        areaPicture['clearPreviewSubject'] = this.clearPreviewSubject.subscribe(() => {
          this.stopSound(areaPicture);
          this.unsubscribedSubjectArea(areaPicture);
        });
      }
    } else {
      this.clearCanvas(areaPicture.canvas);
      areaPicture.isOn = false;
      this.stopSound(areaPicture);
    }
  }

  /**
   * stop sound event position
   * @param area
   * @param route
   */
  private stopSoundEventPosition(area: Area, route: Route): void {
    if (route.busStops.length < 1) {
      return;
    }
    this.stopSound(area as PictureArea);
  }

  /**
   * draw video position driven
   * @param display Template
   * @param area Area
   * @param route Route
   * @param isDisplay2
   * @param isRepeatVideo
   */
  private async drawVideoPositionDriven(display: Template, area: Area, route: Route, isDisplay2: boolean, isRepeatVideo?: boolean) {
    let areaPicture = area as PictureArea;
    if (areaPicture.isOn) {
      var media;
      if (route.busStops.length > 0) {
        var areaDisplayType = isDisplay2 ? 'areaDisplay2' : 'areaDisplay1';
        var eventsBusStop = isDisplay2 ? this.eventsBusStop2 : this.eventsBusStop1;
        media = eventsBusStop.find(e => e[areaDisplayType]?.id == area.id)?.medias[Constant.MEDIA_TYPE_IMG];
      }
      if (media) {
        let ctx = areaPicture.canvas.getContext('2d');
        ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
        let mediaPos = Helper.coverMedia(areaPicture.canvas, media, areaPicture.objectFit);
        this.drawVideo(display, areaPicture, ctx, mediaPos, areaPicture.canvas, !!isRepeatVideo, isDisplay2);
        return;
      }
    }
    //clear canvas
    areaPicture.canvas.getContext('2d').clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
    if (areaPicture.soundA || areaPicture.soundB) {
      this.stopAudio(areaPicture);
    }
    this.stopVideo(areaPicture);
  }

  /**
   * draw text position driven
   * @param display Template
   * @param area Area
   * @param route Route
   * @param isDisplay2
   */
  private async drawTextPositionDriven(display: Template, area: Area, route: Route, isDisplay2: boolean) {
    let textArea = area as TextArea;
    if (textArea.isOn) {
      if (!textArea.isChangeTemplateCount || (area.isOn && display.count == 0)) {
        textArea.posXScroll = 0;
        textArea.posYScroll = 0;
        textArea.timesScroll = 0;
      }
      var areaDisplay = isDisplay2 ? 'areaDisplay2' : 'areaDisplay1';
      if (route.busStops.length > 0) {
        let eventBusStop = route.busStops[display.count].events.find(eventBusStop => eventBusStop[areaDisplay]?.id == textArea.id);
        if (eventBusStop) {
          if (eventBusStop.medias?.length > 0) {
            textArea.text = Helper.readTextFile(_.get(eventBusStop, `medias[0]url`, '')) ?? ' ';
          } else {
            textArea.text = eventBusStop.text ?? ' ';
          }
        }
      }
      var text = textArea.text ?? ' ';
      this.drawText(textArea, text);
      textArea.isChangeTemplateCount = true;
    } else {
      if (area['subscription']) {
        area['subscription'].unsubscribe();
      }
      area.canvas.getContext('2d').clearRect(0, 0, area.canvas.width, area.canvas.height);
      textArea.isChangeTemplateCount = false;
    }
  }

  /**
   * check output option on
   * @param display Template
   * @param area Area
   * @param isDisplay2
   */
  private getEventBusStop(display: Template, area: Area, isDisplay2: boolean): Array<EventBusStop> {
    var displayType = isDisplay2 ? 'display2' : 'display1';
    var areaDisplayType = isDisplay2 ? 'areaDisplay2' : 'areaDisplay1';
    var eventsBusStop = isDisplay2 ? this.eventsBusStop2 : this.eventsBusStop1;
    const eventBusStopOld = _.cloneDeep(eventsBusStop);
    if (display.count >= 0) {
      for (let i = 0; i <= display.count; i++) {
        this.route?.busStops[i]?.events.forEach(eventBusStop => {
          let index;
          if (display.id == this.route[displayType]?.id) {
            index = eventsBusStop.findIndex(eventData => eventData[areaDisplayType]?.id == eventBusStop[areaDisplayType]?.id);
          }
          if (index > -1) {
            eventsBusStop[index] = eventBusStop;
            eventBusStop.orderNoBusStop = this.route.busStops[i].orderNo;
          } else {
            eventsBusStop.push(eventBusStop);
          }
        });
      }
    }
    eventBusStopOld.forEach((e, index) => {
      if (area.id === e[areaDisplayType]?.id && e.id != eventsBusStop[index].id) {
        area['isChangeDataEvent'] = true;
        delete area['numberOfDraw'];
      }
    });
    return eventsBusStop;
  }

  /**
   * check output option off
   * @param display Template
   * @param area Area
   * @param isDisplay2
   */
  private checkOutputOptionOff(display: Template, area: Area, isDisplay2: boolean): void {
    // var eventsBusStop = this.getEventBusStop(display, area, isDisplay2);
    var eventsBusStop = isDisplay2 ? this.eventsBusStop2 : this.eventsBusStop1;
    var areaDisplay = isDisplay2 ? 'areaDisplay2' : 'areaDisplay1';
    var eventBusStop = eventsBusStop.find(e => e[areaDisplay]?.id == area.id);
    switch (eventBusStop?.outputOption) {
      case OutputOptionEnum.ONE_STOP:
        if (area['indexOri'] < display.count) {
          area.isOn = false;
          area.isOffEvent = true;
        }
        break;
      case OutputOptionEnum.CONTINUOUS:
        if (display.count == this.route.busStops.length) {
          area.isOn = false;
        }
        break;
      case OutputOptionEnum.ONCE:
        if (area['numberOfDraw'] > 1) {
          area.isOn = false;
          area.isOffEvent = true;
        }
        break;
      default:
        break;
    }
  }

  /**
   * draw index word preview(LinkPicture)
   * @param display Template
   * @param area Area
   * @param route Route
   * @param isDisplay2
   */
  private async drawPictureIndexWord(display: Template, area: Area, route: Route, isDisplay2: boolean): Promise<void> {
    var areaPicture = area as PictureArea;
    let canvasId = isDisplay2 ? Constant.CANVAS_DISPLAY_2_ID : Constant.CANVAS_DISPLAY_1_ID;
    if (areaPicture.isOn) {
      // calculator index
      var index = display.count + areaPicture.referencePositionRow;
      if (index < route?.busStops.length) {
        var mediaIndexWord = null;
        if (route.busStops[index].indexWords[areaPicture.referencePositionColumn] == undefined) {
          mediaIndexWord = null;
        } else {
          var linkPicturePreview = route.busStops[index].indexWords.filter(indexWord => indexWord.groupId == area.indexWordGroupId);
          if (linkPicturePreview.length != 0) {
            mediaIndexWord = linkPicturePreview[areaPicture.referencePositionColumn]?.media
              ? linkPicturePreview[areaPicture.referencePositionColumn]?.media
              : null;
          }
        }
        var ctx = areaPicture.canvas.getContext('2d');
        ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
        areaPicture.media = mediaIndexWord;
        if (mediaIndexWord != null) {
          // find media by url
          await fetch(this.createGetRequestMedia(mediaIndexWord.url))
            .then(response => response.blob())
            .then(blob => createImageBitmap(blob))
            .then(bitmap => {
              areaPicture.canvas._img = bitmap;
            });
          // draw canvas
          let ctx = areaPicture.canvas.getContext('2d');
          let pattern = ctx.createPattern(areaPicture.canvas._img, 'no-repeat');
          ctx.fillStyle = pattern;
          let mediaPosition = Helper.coverMedia(areaPicture.canvas, areaPicture.media, areaPicture.objectFit);
          ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
          if (areaPicture.objectFit == ObjectFitEnum.FILL) {
            ctx.drawImage(areaPicture.canvas._img, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
          }
          ctx.drawImage(
            areaPicture.canvas._img,
            mediaPosition.sX,
            mediaPosition.sY,
            mediaPosition.sWidth,
            mediaPosition.sHeight,
            mediaPosition.x,
            mediaPosition.y,
            mediaPosition.width,
            mediaPosition.height
          );
        }
        return;
      }
    }
    this.clearCanvas(areaPicture.canvas);
  }

  /**
   * draw fix picture preview
   * @param area Area
   * @param isDisplay2
   */
  private async drawAreaFixPicture(area: Area, isDisplay2: boolean) {
    let areaPicture = area as PictureArea;
    let canvasId = isDisplay2 ? Constant.CANVAS_DISPLAY_2_ID : Constant.CANVAS_DISPLAY_1_ID;
    if (areaPicture.isOn) {
      if (areaPicture.media) {
        await fetch(this.createGetRequestMedia(areaPicture.media.url))
          .then(response => response.blob())
          .then(blob => createImageBitmap(blob))
          .then(bitmap => {
            area.canvas._img = bitmap;
          });
        let canvas = areaPicture.canvas;
        let ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        let pattern = ctx.createPattern(canvas._img, 'no-repeat');
        ctx.fillStyle = pattern;
        let mediaPosition = Helper.coverMedia(canvas, areaPicture.media, areaPicture.objectFit);
        if (areaPicture.objectFit == ObjectFitEnum.FILL) {
          ctx.drawImage(canvas._img, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
        } else {
          ctx.drawImage(
            canvas._img,
            mediaPosition.sX,
            mediaPosition.sY,
            mediaPosition.sWidth,
            mediaPosition.sHeight,
            mediaPosition.x,
            mediaPosition.y,
            mediaPosition.width,
            mediaPosition.height
          );
        }
      }
      this.playAudio(areaPicture);
    } else {
      area.canvas.getContext('2d').clearRect(0, 0, area.canvas.width, area.canvas.height);
      this.stopAudio(areaPicture);
    }
  }

  /**
   * draw clock preview
   * @param area Area
   * @param display Template
   */
  private async drawClock(display: Template, area: Area) {
    let areaText = area as TextArea;
    if (areaText.isOn) {
      if (!areaText.isChangeTemplateCount || (area.isOn && display.count == 0)) {
        areaText.posXScroll = 0;
        areaText.posYScroll = 0;
        areaText.timesScroll = 0;
      }
      if (areaText.scrollStatus == ScrollStatusEnum.OFF) {
        // TODO: rxjs
        var time = setInterval(() => {
          // update timezone
          let offsetHour = 0;
          let offsetMinute = 0;
          if (this.setting) {
            offsetHour = this.setting.timezone.offsetHour;
            offsetMinute = this.setting.timezone.offsetMinute;
          }
          var textFill = moment
            .utc()
            .add(offsetHour, 'hour')
            .add(offsetMinute, 'minute')
            .format('HH:mm');
          this.drawText(areaText, textFill);
        }, 50);
      } else {
        this.drawText(areaText);
      }
      areaText.isChangeTemplateCount = true;
    } else {
      if (area['subscription']) {
        area['subscription'].unsubscribe();
      }
      area.canvas.getContext('2d').clearRect(0, 0, area.canvas.width, area.canvas.height);
      areaText.isChangeTemplateCount = false;
    }
  }

  /**
   * draw info Route preview
   * @param display Template
   * @param area Area
   * @param text value to draw (routeName or routeNo)
   */
  private async drawRoute(display: Template, area: Area, text: string) {
    if (!area.canvas) {
      return;
    }
    let areaText = area as TextArea;
    if (areaText.isOn) {
      var textFill: string = '';
      if (areaText.text.includes('@')) {
        let stringReplace = areaText.text.replace(/@/g, text);
        textFill = stringReplace;
      } else {
        textFill = text;
      }
      if (!areaText.isChangeTemplateCount || (area.isOn && display.count == 0)) {
        areaText.posXScroll = 0;
        areaText.posYScroll = 0;
        areaText.timesScroll = 0;
      }
      this.drawText(areaText, textFill);
      areaText.isChangeTemplateCount = true;
    } else {
      if (area['subscription']) {
        area['subscription'].unsubscribe();
      }
      area.canvas.getContext('2d').clearRect(0, 0, area.canvas.width, area.canvas.height);
      areaText.isChangeTemplateCount = false;
    }
  }

  /**
   * draw BusStopName and BusStopNo
   * @param display Template
   * @param area Area
   * @param route Route
   * @param isBusStopName true if draw busStopName, false if draw bus stop no
   */
  private async drawTextBusStop(display: Template, area: Area, route: Route, isBusStopName: boolean) {
    var textArea = area as TextArea;
    if (textArea.isOn) {
      if (!textArea.isChangeTemplateCount || (area.isOn && display.count == 0)) {
        textArea.posXScroll = 0;
        textArea.posYScroll = 0;
        textArea.timesScroll = 0;
      }
      var index = display.count + textArea.referencePositionRow;
      var textOri = ' ';
      if (index < route?.busStops.length) {
        if (isBusStopName) {
          textOri = route.busStops[index].name == null ? ' ' : route.busStops[index].name;
        } else {
          textOri = route.busStops[index].no == null ? ' ' : route.busStops[index].no;
        }
      }
      var text: string = '';
      if (textArea.text.includes('@')) {
        let stringReplace = textArea.text.replace(/@/g, textOri);
        text = stringReplace;
      } else {
        text = textOri;
      }
      this.drawText(textArea, text);
      textArea.isChangeTemplateCount = true;
    } else {
      if (area['subscription']) {
        area['subscription'].unsubscribe();
      }
      area.canvas.getContext('2d').clearRect(0, 0, area.canvas.width, area.canvas.height);
      textArea.isChangeTemplateCount = false;
    }
  }

  /**
   * draw IndexWord preview
   * @param display Template
   * @param area Area
   * @param route Route
   */
  private async drawTextIndexWord(display: Template, area: Area, route: Route) {
    var textArea = area as TextArea;
    if (textArea.isOn) {
      if (!textArea.isChangeTemplateCount || (area.isOn && display.count == 0)) {
        textArea.posXScroll = 0;
        textArea.posYScroll = 0;
        textArea.timesScroll = 0;
      }
      var index = display.count + textArea.referencePositionRow;
      var textOri = ' ';
      if (index < route?.busStops.length) {
        if (!route.busStops[index].indexWords[textArea.referencePositionColumn]) {
          textOri = ' ';
        } else {
          var indexWordPreviewList = route.busStops[index].indexWords.filter(indexWord => indexWord.groupId == area.indexWordGroupId);
          textOri =
            indexWordPreviewList[textArea.referencePositionColumn] && indexWordPreviewList[textArea.referencePositionColumn].text
              ? indexWordPreviewList[textArea.referencePositionColumn].text
              : ' ';
        }
      }
      var text: string = '';
      if (textArea.text.includes('@')) {
        let stringReplace = textArea.text.replace(/@/g, textOri);
        text = stringReplace;
      } else {
        text = textOri;
      }
      this.drawText(textArea, text);
      textArea.isChangeTemplateCount = true;
    } else {
      if (area['subscription']) {
        area['subscription'].unsubscribe();
      }
      area.canvas.getContext('2d').clearRect(0, 0, area.canvas.width, area.canvas.height);
      textArea.isChangeTemplateCount = false;
    }
  }

  /**
   * get timer
   */
  private getTimer(): string {
    // update timezone
    var offsetHour = 0;
    var offsetMinute = 0;
    if (this.setting) {
      offsetHour = this.setting.timezone.offsetHour;
      offsetMinute = this.setting.timezone.offsetMinute;
    }
    return moment
      .utc()
      .add(offsetHour, 'hour')
      .add(offsetMinute, 'minute')
      .format('HH:mm');
  }

  /**
   * draw text orientation horizontal
   * @param ctx
   * @param areaText area draw
   * @param text text need to draw
   */
  private drawTextOrientationHorizontal(ctx: any, areaText: TextArea, text?: string): void {
    const referencePosition = Helper.getReferencePositionOrientationHorizontal(ctx, areaText);
    let referenceX = referencePosition.referenceX;
    let referenceY = referencePosition.referenceY;
    var textDraw = text ?? this.getTimer();
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // draw color text
    ctx.fillStyle = areaText.fontColor;
    ctx.fillText(textDraw, referenceX + areaText.posXScroll, referenceY + areaText.posYScroll);
    const widthMeasureText = ctx.measureText(textDraw).width;
    if (areaText.scrollStatus == ScrollStatusEnum.ON) {
      if (areaText['subscription']) {
        areaText['subscription'].unsubscribe();
      }
      const observable = interval(50);
      const subscription = observable
        .pipe(
          takeUntil(this.clearPreviewSubject),
          takeUntil(this.pausePreviewSubject),
          repeatWhen(() => this.startPreviewSubject)
        )
        .subscribe(() => {
          if (this.isPlay && areaText.isOn) {
            textDraw = text ?? this.getTimer();
            ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
            // draw color background
            ctx.fillStyle = areaText.backgroundColor;
            ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
            // draw color text
            ctx.fillStyle = areaText.fontColor;
            ctx.fillText(textDraw, referenceX + areaText.posXScroll, referenceY + areaText.posYScroll);
            this.calcPositionScrollHorizontal(areaText, widthMeasureText, referenceX, referenceY);
          }
        });
      areaText['subscription'] = subscription;
      areaText['clearPreviewSubject'] = this.clearPreviewSubject.subscribe(() => {
        subscription.unsubscribe();
        areaText['clearPreviewSubject'].unsubscribe();
      });
    } else {
      areaText['subscription']?.unsubscribe();
    }
  }

  /**
   * draw text orientation vertical
   * @param ctx
   * @param areaText TextArea
   * @param text
   */
  private drawTextOrientationVertical(ctx: any, areaText: TextArea, text?: string): void {
    var textDraw = text ?? this.getTimer();
    var charsSplit = textDraw.split('');
    let metrics = ctx.measureText(textDraw);
    let firefox = navigator.userAgent.search('Firefox');
    let characterHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
    if (firefox > -1) {
      characterHeight = areaText.fontSize;
    }
    let textHeight = characterHeight * charsSplit.length;
    const referencePosition = Helper.getReferencePositionOrientationVertical(ctx, areaText, textHeight);
    let referenceX = referencePosition.referenceX;
    let referenceY = referencePosition.referenceY;
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // draw color text
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // draw color text
    ctx.fillStyle = areaText.fontColor;
    var i = 0;
    var ch: string;
    var posY = 0.5;
    while ((ch = charsSplit[i++])) {
      var chWidth = ctx.measureText(ch).width;
      ctx.fillText(ch, referenceX - chWidth / 2 + areaText.posXScroll, referenceY + posY * characterHeight + areaText.posYScroll);
      posY++;
    }
    var widthMeasureText = ctx.measureText(textDraw).width;
    if (areaText.scrollStatus == ScrollStatusEnum.ON) {
      if (areaText['subscription']) {
        areaText['subscription'].unsubscribe();
      }
      const observable = interval(50);
      const subscription = observable.pipe(takeUntil(this.clearPreviewSubject)).subscribe(() => {
        if (this.isPlay && areaText.isOn) {
          textDraw = text ?? this.getTimer();
          ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
          // draw color background
          ctx.fillStyle = areaText.backgroundColor;
          ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
          // draw color text
          ctx.fillStyle = areaText.fontColor;
          var i = 0;
          var ch: string;
          var posY = 0.5;
          while ((ch = charsSplit[i++])) {
            var chWidth = ctx.measureText(ch).width;
            ctx.fillText(ch, referenceX - chWidth / 2 + areaText.posXScroll, referenceY + posY * areaText.fontSize + areaText.posYScroll);
            posY++;
          }
          this.calcPositionScrollVertical(areaText, widthMeasureText, referenceX, textDraw);
        }
      });
      areaText['subscription'] = subscription;
      this.clearPreviewSubject.subscribe(() => subscription.unsubscribe());
    } else {
      areaText['subscription']?.unsubscribe();
    }
  }

  /**
   * draw text orientation sideways
   * @param ctx
   * @param areaText TextArea
   * @param text
   */
  private drawTextOrientationSideways(ctx: any, areaText: TextArea, text?: string): void {
    var textDraw = text ?? this.getTimer();
    let widthMeasureText = ctx.measureText(textDraw).width;
    const referencePosition = Helper.getReferencePositionOrientationSideways(ctx, areaText, widthMeasureText);
    const referenceX = referencePosition.referenceX;
    const referenceY = referencePosition.referenceY;

    ctx.rotate(Math.PI / 2);
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // draw color text
    ctx.fillStyle = areaText.fontColor;
    ctx.fillText(textDraw, referenceX + areaText.posYScroll, referenceY - areaText.posXScroll);
    ctx.resetTransform();
    if (areaText.scrollStatus == ScrollStatusEnum.ON) {
      if (areaText['subscription']) {
        areaText['subscription'].unsubscribe();
      }
      const observable = interval(50);
      const subscription = observable.pipe(takeUntil(this.clearPreviewSubject)).subscribe(() => {
        if (this.isPlay && areaText.isOn) {
          textDraw = text ?? this.getTimer();
          ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
          // draw color background
          ctx.fillStyle = areaText.backgroundColor;
          ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
          ctx.rotate(Math.PI / 2);
          // draw color text
          ctx.fillStyle = areaText.fontColor;
          ctx.fillText(textDraw, referenceX + areaText.posYScroll, referenceY - areaText.posXScroll);
          this.calcPositionScrollSideway(areaText, widthMeasureText, referenceX);
          ctx.resetTransform();
        }
      });
      areaText['subscription'] = subscription;
      this.clearPreviewSubject.subscribe(() => subscription.unsubscribe());
    } else {
      areaText['subscription']?.unsubscribe();
    }
  }

  /**
   * draw text
   * @param areaText
   * @param text
   */
  public drawText(areaText: TextArea, text?: string): void {
    // get ctx from canvas
    let ctx = areaText.canvas.getContext('2d');
    // draw color background
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // set style bold / italic / normal
    let textStyle = 'normal';
    if (areaText.isBold && !areaText.isItalic) {
      textStyle = Constant.BOLD_STYLE;
    } else if (areaText.isItalic && !areaText.isBold) {
      textStyle = Constant.ITALIC_STYLE;
    } else if (areaText.isItalic && areaText.isBold) {
      textStyle = `${Constant.BOLD_STYLE} ${Constant.ITALIC_STYLE}`;
    }
    // draw color text
    ctx.fillStyle = areaText.fontColor;
    // draw font text
    ctx.font = `${textStyle} ${areaText.fontSize}px ${!areaText.fontName ? 'Arial' : areaText.fontName}`;
    // set orientation
    if (areaText.orientation == OrientationEnum.HORIZONTAL) {
      this.drawTextOrientationHorizontal(ctx, areaText, text);
    } else if (areaText.orientation == OrientationEnum.VERTICAL) {
      this.drawTextOrientationVertical(ctx, areaText, text);
    } else if (areaText.orientation == OrientationEnum.SIDEWAYS) {
      this.drawTextOrientationSideways(ctx, areaText, text);
    }
  }

  /**
   * draw fix text preview
   * @param display Template
   * @param area Area
   */
  private async drawAreaFixText(display: Template, areaText: TextArea) {
    if (!areaText.canvas) {
      return;
    }
    if (areaText.isOn) {
      if (!areaText.isChangeTemplateCount || (areaText.isOn && display.count == 0)) {
        areaText.posXScroll = 0;
        areaText.posYScroll = 0;
        areaText.timesScroll = 0;
      }
      var text = !_.isEmpty(areaText.text) ? areaText.text : ' ';
      this.drawText(areaText, text);
      areaText.isChangeTemplateCount = true;
    } else {
      if (areaText['subscription']) {
        areaText['subscription'].unsubscribe();
      }
      areaText.canvas.getContext('2d').clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
      areaText.isChangeTemplateCount = false;
    }
  }

  /**
   * calculate position scroll Horizontal
   * @param areaText TextArea
   * @param widthMeasureText width Measure Text
   * @param referenceX
   * @param referenceY
   */
  private calcPositionScrollHorizontal(areaText: TextArea, widthMeasureText, referenceX, referenceY): void {
    switch (areaText.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        areaText.posXScroll -= areaText.scrollSpeed / 20;
        if (areaText.posXScroll < -(widthMeasureText + referenceX)) {
          areaText.posXScroll = areaText.width + widthMeasureText - referenceX;
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.RIGHT:
        areaText.posXScroll += areaText.scrollSpeed / 20;
        if (areaText.posXScroll > areaText.width) {
          areaText.posXScroll = -(widthMeasureText + referenceX);
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.UP:
        areaText.posYScroll -= areaText.scrollSpeed / 20;
        if (areaText.posYScroll < -areaText.fontSize * 1.5 - referenceY) {
          areaText.posYScroll = areaText.height - referenceY + areaText.fontSize;
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.DOWN:
        areaText.posYScroll += areaText.scrollSpeed / 20;
        if (areaText.posYScroll > areaText.height + areaText.fontSize * 1.5 - referenceY) {
          areaText.posYScroll = -areaText.fontSize * 1.5 - referenceY;
          areaText.timesScroll++;
        }
        break;
      default:
        break;
    }
  }

  /**
   * calculate position scroll Vertical
   * @param areaText TextArea
   * @param widthMeasureText width Measure Text
   * @param referenceX
   * @param str value
   */
  private calcPositionScrollVertical(areaText: TextArea, widthMeasureText, referenceX, str): void {
    var heightText = str.split('').length * areaText.fontSize;
    switch (areaText.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        areaText.posXScroll -= areaText.scrollSpeed / 20;
        if (areaText.posXScroll < -(widthMeasureText + referenceX)) {
          areaText.posXScroll = areaText.width + areaText.fontSize * 1.5 - referenceX;
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.RIGHT:
        areaText.posXScroll += areaText.scrollSpeed / 20;
        if (areaText.posXScroll > areaText.width) {
          areaText.posXScroll = -areaText.fontSize * 1.5 - referenceX;
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.UP:
        areaText.posYScroll -= areaText.scrollSpeed / 20;
        //
        if (areaText.posYScroll < -heightText) {
          areaText.posYScroll = areaText.height;
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.DOWN:
        areaText.posYScroll += areaText.scrollSpeed / 20;
        //
        if (areaText.posYScroll > areaText.height + heightText) {
          areaText.posYScroll = -heightText;
          areaText.timesScroll++;
        }
        break;
      default:
        break;
    }
  }

  /**
   * calculate position scroll Sideway
   * @param areaText TextArea
   * @param widthMeasureText width Measure Text
   * @param referenceX
   */
  private calcPositionScrollSideway(areaText: TextArea, widthMeasureText, referenceX): void {
    switch (areaText.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        areaText.posXScroll -= areaText.scrollSpeed / 20;
        if (areaText.posXScroll < -(areaText.width + referenceX)) {
          areaText.posXScroll = areaText.width + areaText.fontSize * 1.5;
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.RIGHT:
        areaText.posXScroll += areaText.scrollSpeed / 20;
        if (areaText.posXScroll > areaText.width + areaText.fontSize) {
          areaText.posXScroll = -(areaText.width + areaText.fontSize * 1.5);
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.UP:
        areaText.posYScroll -= areaText.scrollSpeed / 20;
        if (areaText.posYScroll < -(areaText.height + widthMeasureText + referenceX)) {
          areaText.posYScroll = widthMeasureText;
          areaText.timesScroll++;
        }
        break;
      case ScrollDirectionsEnum.DOWN:
        areaText.posYScroll += areaText.scrollSpeed / 20;
        if (areaText.posYScroll > areaText.height) {
          areaText.posYScroll = -areaText.height - widthMeasureText;
        }
        break;
      default:
        break;
    }
  }

  /**
   * clear interval scroll list
   */
  public clearAnimationList(): void {
    //TODO:
    // if (this.animationList.length > 0) {
    //   this.animationList.forEach(item => {
    //     cancelAnimationFrame(item.requestAnimationFrameId);
    //     item.canvas.pause();
    //     item.canvas.currentTime = 0;
    //     item.canvas.load();
    //   });
    // }
    // this.animationList = [];
  }

  /**
   * clear all threads draw
   * @param template Template
   */
  public clearAllThreadDrawTemplate(template: Template): void {
    if (!template) {
      return;
    }
    const areas = Helper.getAllAreaTemplate(template);
    areas.forEach(area => {
      if (area['subscription']) {
        area['subscription'].unsubscribe();
        delete area['subscription'];
      }
    });
    this.clearAnimationList();
    this.clearPreviewSubject.next();
  }

  /**
   * animation draw area
   * @param display Template
   * @param ctx
   * @param areaPicture
   * @param mediaPos
   * @param canvas
   * @param isRepeat
   * @param isDisplay2
   */
  private animationDrawVideo(
    display: Template,
    ctx: any,
    areaPicture: PictureArea,
    mediaPos: any,
    canvas: any,
    isRepeat: boolean,
    isDisplay2: boolean
  ): void {
    this.checkDisplayTimingOnOff(display, areaPicture, this.route);
    this.checkOutputOptionOff(display, areaPicture, isDisplay2);
    if (!areaPicture.isOn || (areaPicture.media && areaPicture.media.type != TypeMediaFileEnum.MP4)) {
      this.stopVideo(areaPicture);
      return;
    }
    if (areaPicture.videoPreview?.ended) {
      if (!isRepeat) {
        areaPicture['numberOfDraw'] = areaPicture['numberOfDraw'] ? ++areaPicture['numberOfDraw'] : 1;
        this.stopVideo(areaPicture);
        return;
      }
      areaPicture.videoPreview.currentTime = 0;
      areaPicture.isChangeTemplateCount = false;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      areaPicture.videoPreview
        .play()
        .then(() => this.animationDrawVideo(display, ctx, areaPicture, mediaPos, canvas, isRepeat, isDisplay2))
        .catch(error => console.log('error draw 1', error));
    }

    if (!areaPicture.videoPreview.src || _.isEmpty(areaPicture.videoPreview.src)) {
      areaPicture.isOn = false;
      areaPicture.isOffEvent = true;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      return;
    }
    areaPicture['isDrawing'] = true;
    if (areaPicture.objectFit == ObjectFitEnum.FILL) {
      ctx.drawImage(areaPicture.videoPreview, mediaPos.x, mediaPos.y, mediaPos.width, mediaPos.height);
    } else {
      ctx.drawImage(
        areaPicture.videoPreview,
        mediaPos.sX,
        mediaPos.sY,
        mediaPos.sWidth,
        mediaPos.sHeight,
        mediaPos.x,
        mediaPos.y,
        mediaPos.width,
        mediaPos.height
      );
    }
    if (areaPicture.videoPreview?.paused || !this.isPlay) {
      areaPicture.videoPreview && areaPicture.videoPreview.pause();
    }
    if (areaPicture['animationId']) {
      cancelAnimationFrame(areaPicture['animationId']);
    }
    areaPicture['animationId'] = requestAnimationFrame(() =>
      this.animationDrawVideo(display, ctx, areaPicture, mediaPos, canvas, isRepeat, isDisplay2)
    );
  }

  /**
   * drawVideo
   * @param display Template
   * @param areaPicture PictureArea
   * @param ctx
   * @param mediaPos any
   * @param canvas
   * @param isRepeat
   * @param isDisplay2
   */
  private async drawVideo(
    display: Template,
    areaPicture: PictureArea,
    ctx: any,
    mediaPos: any,
    canvas: any,
    isRepeat: boolean,
    isDisplay2: boolean
  ) {
    if (!areaPicture.media || !areaPicture.isOn) {
      return;
    }
    if (!areaPicture.videoPreview) {
      areaPicture.videoPreview = document.createElement('video');
    }
    areaPicture.videoPreview.src = '';
    if (!areaPicture['isDrawing'] && (!this.isNext || areaPicture.videoPreview.src != areaPicture?.media?.url)) {
      areaPicture.videoPreview.src = areaPicture?.media?.url ?? '';
      function drawOrigin() {
        areaPicture.videoPreview?.addEventListener(
          'loadeddata',
          function() {
            areaPicture.videoPreview.currentTime = 0;
          },
          false
        );
        areaPicture.videoPreview?.addEventListener(
          'seeked',
          function() {
            if (areaPicture.objectFit == ObjectFitEnum.FILL) {
              ctx.drawImage(areaPicture.videoPreview, mediaPos.x, mediaPos.y, mediaPos.width, mediaPos.height);
            } else {
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(
                areaPicture.videoPreview,
                mediaPos.sX,
                mediaPos.sY,
                mediaPos.sWidth,
                mediaPos.sHeight,
                mediaPos.x,
                mediaPos.y,
                mediaPos.width,
                mediaPos.height
              );
            }
          },
          false
        );
        return;
      }
      await areaPicture.videoPreview.addEventListener('timeupdate', drawOrigin, false);
      if (this.isPlay) {
        areaPicture.videoPreview.play();
      }
      areaPicture.videoPreview.onplay = () => {
        this.animationDrawVideo(display, ctx, areaPicture, mediaPos, canvas, isRepeat, isDisplay2);
      };
    }
    this.unsubscribedSubjectArea(areaPicture);
    areaPicture['startPreviewSubject'] = this.startPreviewSubject.subscribe(() => areaPicture.videoPreview.play());
    areaPicture['pausePreviewSubject'] = this.pausePreviewSubject.subscribe(() => areaPicture.videoPreview.pause());
    areaPicture['clearPreviewSubject'] = this.clearPreviewSubject.subscribe(() => this.unsubscribedSubjectArea(areaPicture));
  }

  public drawVideoSequence(videoOnCanvasObject: VideoOnCanvasObject, canvas: any, objectFit: ObjectFitEnum) {
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    let mediaPos = Helper.coverMedia(canvas, videoOnCanvasObject.video, objectFit);
    videoOnCanvasObject.videoHtmlElement.addEventListener(
      'loadeddata',
      () => {
        videoOnCanvasObject.videoHtmlElement.currentTime = 0;
      },
      false
    );
    videoOnCanvasObject.videoHtmlElement?.addEventListener(
      'seeked',
      () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        if (objectFit == ObjectFitEnum.FILL) {
          ctx.drawImage(videoOnCanvasObject.videoHtmlElement, mediaPos.x, mediaPos.y, mediaPos.width, mediaPos.height);
        } else {
          ctx.drawImage(
            videoOnCanvasObject.videoHtmlElement,
            mediaPos.sX,
            mediaPos.sY,
            mediaPos.sWidth,
            mediaPos.sHeight,
            mediaPos.x,
            mediaPos.y,
            mediaPos.width,
            mediaPos.height
          );
        }
      },
      false
    );
    videoOnCanvasObject.videoHtmlElement.onpause = () => {
      cancelAnimationFrame(videoOnCanvasObject.idAnimationFrame);
    };
    videoOnCanvasObject.videoHtmlElement.onplay = () => {
      this.drawVideoSequenceAnimation(videoOnCanvasObject, canvas, ctx, mediaPos, objectFit);
    };
    return videoOnCanvasObject.videoHtmlElement;
  }

  drawVideoSequenceAnimation(videoOnCanvasObject: VideoOnCanvasObject, canvas: any, ctx: any, mediaPos: any, objectFit: ObjectFitEnum) {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (objectFit == ObjectFitEnum.FILL) {
      ctx.drawImage(videoOnCanvasObject.videoHtmlElement, mediaPos.x, mediaPos.y, mediaPos.width, mediaPos.height);
    } else {
      ctx.drawImage(
        videoOnCanvasObject.videoHtmlElement,
        mediaPos.sX,
        mediaPos.sY,
        mediaPos.sWidth,
        mediaPos.sHeight,
        mediaPos.x,
        mediaPos.y,
        mediaPos.width,
        mediaPos.height
      );
    }
    videoOnCanvasObject.idAnimationFrame = requestAnimationFrame(() =>
      this.drawVideoSequenceAnimation(videoOnCanvasObject, canvas, ctx, mediaPos, objectFit)
    );
  }

  /**
   * stop video
   * @param areaPicture PictureArea
   */
  public stopVideo(areaPicture: PictureArea): void {
    if (areaPicture.videoPreview) {
      let ctx = areaPicture.canvas.getContext('2d');
      ctx.clearRect(0, 0, areaPicture.width, areaPicture.height);
      cancelAnimationFrame(areaPicture['animationId']);
      areaPicture.videoPreview.pause();
      areaPicture.videoPreview.currentTime = 0;
      areaPicture.videoPreview.remove();
    }
  }

  /**
   * pause video
   * @param areaPicture PictureArea
   */
  public pauseVideo(areaPicture: PictureArea): void {
    if (areaPicture.media && areaPicture.media.type == TypeMediaFileEnum.MP4 && areaPicture.videoPreview && !this.isPlay) {
      var currentTime = areaPicture.videoPreview.currentTime;
      areaPicture.videoPreview.pause();
      areaPicture.videoPreview.currentTime = currentTime;
    }
  }

  /**
   * stop a video
   * @param areaPicture PictureArea
   */
  public stopAVideo(areaPicture: PictureArea): void {
    if (areaPicture.media && areaPicture.media.type == TypeMediaFileEnum.MP4 && areaPicture.videoPreview && !this.isPlay) {
      areaPicture.videoPreview.pause();
      areaPicture.videoPreview.currentTime = 0;
      var ctx = areaPicture.canvas.getContext('2d');
      ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
      areaPicture['isDrawing'] = false;
    }
  }

  /**
   * clear canvas area
   * @param area Area
   */
  public clearCanvasArea(area: Area): void {
    this.clearCanvas(area?.canvas);
    area['isDrawing'] = false;
  }

  /**
   * play video
   * @param areaPicture PictureArea
   */
  public playVideo(areaPicture: PictureArea): void {
    if (areaPicture.media && areaPicture.media.type == TypeMediaFileEnum.MP4 && areaPicture.videoPreview && this.isPlay) {
      areaPicture.videoPreview.play();
    }
  }

  /**
   * unsubscribed Subject in Area
   * @param area area unsubscribe
   */
  private unsubscribedSubjectArea(area: Area): void {
    if (area['pausePreviewSubject']) {
      area['pausePreviewSubject'].unsubscribe();
    }
    if (area['clearPreviewSubject']) {
      area['clearPreviewSubject'].unsubscribe();
    }
    if (area['startPreviewSubject']) {
      area['startPreviewSubject'].unsubscribe();
    }
  }

  /**
   * play audio
   * @param areaPicture PictureArea
   */
  public async playAudio(areaPicture: PictureArea) {
    if (!areaPicture?.isFix) {
      return;
    }
    // play audio;
    if (!areaPicture.soundAPreview && areaPicture.soundA?.url) {
      areaPicture.soundAPreview = new Audio(areaPicture.soundA.url);
      areaPicture.soundAPreview.load();
    }
    if (!areaPicture.soundBPreview && areaPicture.soundB?.url) {
      areaPicture.soundBPreview = new Audio(areaPicture.soundB.url);
      areaPicture.soundBPreview.load();
    }
    if (this.isPlay) {
      areaPicture?.soundAPreview?.paused && areaPicture.soundAPreview.play();
      areaPicture?.soundBPreview?.paused && areaPicture.soundBPreview.play();
    }
    this.unsubscribedSubjectArea(areaPicture);
    areaPicture['pausePreviewSubject'] = this.pausePreviewSubject.subscribe(() => {
      this.pauseAudio(areaPicture);
      this.pauseSound(areaPicture);
    });
    areaPicture['clearPreviewSubject'] = this.clearPreviewSubject.subscribe(() => {
      this.stopAudio(areaPicture);
      this.unsubscribedSubjectArea(areaPicture);
    });
    areaPicture['startPreviewSubject'] = this.startPreviewSubject.subscribe(() => {
      this.playAudio(areaPicture);
    });
  }

  /**
   * pause audio
   * @param areaPicture PictureArea
   */
  pauseAudio(areaPicture: PictureArea): void {
    if (!areaPicture?.isFix) {
      return;
    }
    if (areaPicture.soundAPreview) {
      areaPicture.soundAPreview.pause();
    }
    if (areaPicture.soundBPreview) {
      areaPicture.soundBPreview.pause();
    }
  }

  /**
   * stop audio
   * @param areaPicture PictureArea
   */
  stopAudio(areaPicture: PictureArea): void {
    if (!areaPicture?.isFix) {
      return;
    }
    if (areaPicture.soundAPreview) {
      areaPicture.soundAPreview.pause();
      areaPicture.soundAPreview.currentTime = 0;
      areaPicture.soundAPreview.load();
    }
    if (areaPicture.soundBPreview) {
      areaPicture.soundBPreview.pause();
      areaPicture.soundBPreview.currentTime = 0;
      areaPicture.soundBPreview.load();
    }
  }

  /**
   * play sound
   * @param media Media
   * @param areaPicture PictureArea
   * @param isPlay
   */
  playSound(media: Media, areaPicture: PictureArea, isPlay: boolean): void {
    const url = _.get(media, `url`);
    if (!url) {
      return;
    }
    if (!areaPicture['soundPosition']) {
      areaPicture['soundPosition'] = document.createElement('audio');
    }
    areaPicture['soundPosition'].pause();
    areaPicture['soundPosition'].src = url;
    if (isPlay) {
      areaPicture['soundPosition'].play();
    }
  }

  /**
   * stop sound
   * @param areaPicture PictureArea
   */
  stopSound(areaPicture: PictureArea): void {
    if (!areaPicture?.['soundPosition']) {
      return;
    }
    if (areaPicture['soundPosition'].currentTime > 0) {
      areaPicture['soundPosition'].pause();
      areaPicture['soundPosition'].currentTime = 0;
    }
    delete areaPicture['soundPosition'];
  }

  /**
   * pause sound
   * @param areaPicture PictureArea
   */
  pauseSound(areaPicture: PictureArea): void {
    if (!areaPicture?.['soundPosition']) {
      return;
    }
    if (areaPicture['soundPosition'].currentTime > 0) {
      areaPicture['soundPosition'].pause();
    }
  }

  /**
   * set attribute value play/pause
   * @param isPlay boolean: true: is play, false: is pause
   */
  public setIsPlay(isPlay: boolean): void {
    this.isPlay = isPlay;
    if (isPlay) {
      this.startPreviewSubject.next();
    } else {
      this.pausePreviewSubject.next();
    }
  }
}

/**
 * Class Timeout
 */
class TimeOut {
  time: any;
  id: Number;
  constructor(time: any, id: Number) {
    this.time = time;
    this.id = id;
  }
}
