import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { PublishSetting } from 'app/model/entity/publish-setting';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for publish timetable action
 */
export class PublishTimetableService {
  /**
   * URL of publish API
   */
  publishUrl = Constant.BACKEND_URL + Constant.PUBLISH_SERVICE_URL + 'api/publish';

  constructor(private http: HttpClient) {}

  /**
   * Be used by TIMETABLE-COMPONENT
   * get publish timetable
   * @param dataSetting
   * @param timeDateLine
   */
  publishDataTimetable(dataSetting: PublishSetting, timeDateLine: any): Observable<any> {
    const params = new HttpParams().set('timeDateLine', timeDateLine);
    return this.http.post<any>(`${this.publishUrl}/timetable-display`, dataSetting, {
      reportProgress: true,
      observe: 'events',
      params
    });
  }
  /**

   * Be used by TIMETABLE-EDITOR
   * download publish file timetable
   * @param publishSettings
   * @param timeDateLine
   */
  downloadDataPublishTimetable(publishSettings: PublishSetting[], timeDateLine: string): Observable<any> {
    const params = new HttpParams().set('timeDateLine', timeDateLine);
    return this.http.post(`${this.publishUrl}/${Constant.TIMETABLE_COMPONENT_URL}/download-data-publish`, publishSettings, {
      responseType: 'blob',
      observe: 'response',
      params
    });
  }

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * download publish file simple signage
   * @param publishSettings
   * @param isSimpleSyncSetting
   * @param isCheckedExpiration
   */
  downloadDataPublishSimpleSignage(
    publishSettings: PublishSetting[],
    isSimpleSyncSetting: boolean,
    isCheckedExpiration: boolean,
    isCheckedPlaybackTime: boolean
  ): Observable<any> {
    const params = new HttpParams()
      .set(Constant.IS_SIMPLE_SYNC_SETTING, isSimpleSyncSetting + '')
      .set(Constant.IS_CHECKED_EXPIRATION, isCheckedExpiration + '')
      .set(Constant.IS_CHECKED_PLAYBACK_TIME, isCheckedPlaybackTime + '');
    return this.http.post(`${this.publishUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/download-data-publish`, publishSettings, {
      responseType: 'blob',
      observe: 'response',
      params
    });
  }

  /**
   * delete file zip after download
   * @param fileName
   * @returns
   */
  deleteFileZipAfterDownload(fileName: string): Observable<void> {
    return this.http.post<void>(`${this.publishUrl}/delete-zip-temp`, fileName);
  }

  /**
   * publish Data Update Schedule Merge
   * @param timetables
   * @returns
   */
  publishDataUpdateScheduleMerge(timetableTiming: Number, templateIds: Number, timeDateLine: string, areaGroupName: any): Observable<any> {
    const params = new HttpParams()
      .set('changeOverOffset', `${timetableTiming}`)
      .set('timeDateLine', `${timeDateLine}`)
      .set('areaGroupName', areaGroupName);
    return this.http.post(`${this.publishUrl}/${Constant.TIMETABLE_COMPONENT_URL}/publish-data-monthly-schedule-merge`, templateIds, {
      params: params
    });
  }

  /**
   * registration Data Update Schedule
   * @param PublishSetting
   * @returns
   */
  updateDataRegistration(payload: any): Observable<any> {
    return this.http.post(`${this.publishUrl}/${Constant.TIMETABLE_COMPONENT_URL}/update-data-schedule-registration`, payload);
  }

  /**
   * download data publish announcement
   * @param publishSettings
   * @returns
   */
  downloadDataPublishAnnouncement(publishSettings: PublishSetting[]): Observable<any> {
    return this.http.post(`${this.publishUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/download-data-publish`, publishSettings, {
      responseType: 'blob',
      observe: 'response'
    });
  }
}
