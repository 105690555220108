import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constant } from 'app/config/constants';
import { FolderMedia } from 'app/model/entity/folder-media';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for folder media
 */
export class FolderMediaService {
  /**
   * URL of folder bucket API
   */
  folderMediaUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/folders-s3';

  constructor(private http: HttpClient) {}

  /**
   * Be used by MEDIA-MANAGER-COMPONENT
   * get media folders
   *
   * @returns {Observable<Array<FolderMedia>>} list of all folder medias
   */
  getMediaFoldersByUserId(userId: Number, isRoot: boolean): Observable<FolderMedia[]> {
    const params = new HttpParams().set('userId', userId + '').set('isRoot', isRoot + '');
    return this.http.get<FolderMedia[]>(`${this.folderMediaUrl}/${Constant.MEDIA_MANAGER_COMPONENT_URL}/get-media-folders-by-user-id`, {
      params
    });
  }
}
