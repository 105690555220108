import { EventEmitter, Injectable, Output, Renderer2 } from '@angular/core';
import { Helper } from 'app/common/helper';
import {
  AlignmentEnum,
  Constant,
  DestinationEnum,
  LinkDataPictureEnum,
  LinkDataTextEnum,
  ObjectFitEnum,
  OrientationEnum,
  ReferencePositionTimetableColumnEnum,
  ScreenCanvasIdEnum,
  ScrollDirectionsEnum,
  ScrollStatusEnum,
  TypeMediaFileEnum
} from 'app/config/constants';
import { Area } from 'app/model/entity/area';
import { DynamicMessage } from 'app/model/entity/dynamic-message';
import { EmergencyData } from 'app/model/entity/emergency-data';
import { Image } from 'app/model/entity/image';
import { Media } from 'app/model/entity/media';
import { PictureArea } from 'app/model/entity/picture-area';
import { Sequence } from 'app/model/entity/sequence';
import { Template } from 'app/model/entity/template';
import { TextArea } from 'app/model/entity/text-area';
import { Video } from 'app/model/entity/video';
import { ActiveScheduleRow } from 'app/module/timetable-operation-manager/timetable-operation-manager.component';
import _ from 'lodash';
import * as moment from 'moment';
import { interval, Subject, Subscription } from 'rxjs';
import { repeatWhen, takeUntil, timeInterval } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { CommonService } from './common.service';
import { PictureAreaService } from './picture-area-service';
import { TimetableScheduleMerge } from 'app/model/entity/schedule/timetable-schedule';
import { Layer } from 'app/model/entity/layer';

@Injectable({
  providedIn: 'root'
})
export class DrawScheduleMergeService {
  /**
   * true if preview on
   */
  isPlay: boolean;

  /**
   * media is set
   */
  mediaSetting: Media;

  /**
   * timetable
   */
  timetable: any;
  /**
   * toSwitchBetweenPage
   */
  @Output() toSwitchBetweenPage = new EventEmitter<{ key: string; value: DestinationEnum }>();

  /**
   * subject display 1
   */
  private readonly pausePreviewDisplay1Subject = new Subject<void>();
  private readonly startPreviewDisplay1Subject = new Subject<void>();
  private readonly clearPreviewDisplay1Subject = new Subject<void>();
  /**
   * list area clock intervals
   */
  listAreaClockIntervals = new Array<any>();
  /**
   * timeouts display 1 timetable
   */
  timeoutsDisplay1Timetable: Array<TimeOut> = new Array<TimeOut>();
  /**
   * timeouts display 2 timetable
   */
  timeoutsDisplay2Timetable: Array<TimeOut> = new Array<TimeOut>();
  /**
   * true if start preview Timetable Editor
   */
  isStartTimetable: boolean = true;
  /**
   * timeouts display 1 timetable operation
   */
  timeoutsDisplay1TimetableOperation: Array<TimeOut> = new Array<TimeOut>();
  /**
   * timeouts display 2 timetable operation
   */
  timeoutsDisplay2TimetableOperation: Array<TimeOut> = new Array<TimeOut>();
  /**
   * true if start preview Timetable Operation
   */
  isStartTimetableOperation: boolean = true;
  /**
   * medias of index words
   */
  mediaIndexWords = new Array<MediaIndexWord>();
  /**
   * key unique
   */
  keyUnique: any;
  /**
   * interval start time
   */
  intervalStartTime: number = 0;
  /**
   * switching timing
   */
  switchingTiming: number;
  /**
   * timetable schedule
   */
  timetableSchedule: TimetableScheduleMerge[];
  /**
   * current index timetable schedule
   */
  currentIndexTimetableSchedule: number;
  /**
   * reference position columns by template
   */
  referencePositionColumnsByTemplate: number[];
  /**
   * areas Drawing
   */
  areasDrawing: Area[] = [];
  /**
   * active schedule row
   */
  activeScheduleRow: ActiveScheduleRow;
  /**
   * dynamic messages
   */
  dynamicMessages: DynamicMessage[];
  /**
   * emergency
   */
  emergencyData: EmergencyData;

  /**
   * list interval draw news picture display 1
   */
  intervalsDrawNewsDisplay1: Array<any> = new Array<any>();
  /**
   * list interval draw news picture display 2
   */
  intervalsDrawNewsDisplay2: Array<any> = new Array<any>();
  /**
   * subscribe for get url media presigned for display 1
   */
  subscribesGetUrlPresignedDisplay1: Subscription[] = [];
  /**
   * subscribe for get url media presigned for display 2
   */
  subscribesGetUrlPresignedDisplay2: Subscription[] = [];

  dataResponse: any;
  pictureAreaService: PictureAreaService;
  areasIndexWordDisplay: Area[];
  /**
   * true if on emergency
   */
  isOnEmergency: boolean;

  /**
   * timeDateLine
   */
  timeDateLine: string;

  constructor(private commonService: CommonService) {}

  /**
   * clear media index words
   * @returns
   */
  public clearMediaIndexWords() {
    this.mediaIndexWords = new Array<MediaIndexWord>();
    this.keyUnique = uuidv4();
  }

  /**
   * clear all threads draw template
   * @param template
   * @param screenCanvasIdEnum
   * @returns
   */
  public clearAllThreadDrawTemplate(template: Template): void {
    if (!template) {
      return;
    }
    this.handleUnSubscriptionForLayer(template);
    const areas = Helper.getAllAreaTemplate(template);
    areas.forEach(area => {
      this.handleUnSubscriptionForArea(area);
    });
    this.clearPreviewDisplay1Subject.next();
  }

  /**
   * Handle un subscription for layer
   *
   * @param template
   * @returns
   */
  public handleUnSubscriptionForLayer(template: Template): void {
    if (!template.layers) {
      return;
    }
    template.layers.forEach(layer => {
      if (!layer[`subscription`]) {
        return;
      }
      layer[`subscription`].unsubscribe();
      delete layer[`subscription`];
    });
  }

  /**
   * Handle un subscription for area
   *
   * @param area
   * @param canvasDisplayId
   * @returns
   */
  public handleUnSubscriptionForArea(area: Area): void {
    this.resetPositionScrollText(area as TextArea);
    if (!area[`subscription`]) {
      return;
    }
    area[`subscription`].unsubscribe();
    delete area[`subscription`];
  }

  /**
   * change start state
   * @param isStart
   * @param screenCanvasIdEnum
   */
  public changeStartState(isStart: boolean) {
    this.isStartTimetable = isStart;
  }

  /**
   * reset data
   */
  public resetData(): void {
    this.areasDrawing = [];
    this.dynamicMessages = [];
    this.activeScheduleRow = null;
    this.emergencyData = null;
    this.referencePositionColumnsByTemplate = [];
    this.currentIndexTimetableSchedule = null;
    this.listAreaClockIntervals = [];
    this.timetableSchedule = [];
    this.isOnEmergency = false;
    this.dataResponse = null;
    this.areasIndexWordDisplay = [];
  }

  /**
   * change state play/pause
   *
   * @param isPlayOn
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum
   */
  public changeStatePlayPause(isPlayOn: boolean): void {
    this.isPlay = isPlayOn;
    if (this.isPlay) {
      this.startPreviewDisplay1Subject.next();
    } else {
      this.pausePreviewDisplay1Subject.next();
    }
  }

  /**
   * play preview
   *
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  public playPreview() {
    this.isPlay = true;
    this.startPreviewDisplay1Subject.next();
  }

  /**
   * pause preview
   *
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  public pausePreview() {
    this.isPlay = false;
    this.pausePreviewDisplay1Subject.next();
  }

  /**
   * set up preview
   *
   * @param timetable timetable object
   * @param mediaSetting Media
   */
  public setupPreview(timetable: any, mediaSetting: Media): void {
    this.timetable = timetable;
    this.mediaSetting = mediaSetting;
  }

  /**
   * create all canvas area template
   * @param template Template
   * @param canvasContainerDisplay ElementRef
   * @param renderer
   */
  public createAllCanvasAreaTemplate(template: Template, canvasContainerDisplay: any, renderer: Renderer2): void {
    template?.layers &&
      template.layers.forEach(layer => {
        layer.areas.forEach(area => {
          this.createCanvasArea(area, canvasContainerDisplay, renderer);
        });
      });
  }

  /**
   * create canvas template
   * @param template template
   * @param canvasContainerDisplay ElementRef
   * @param renderer
   */
  public createCanvasTemplate(template: Template, canvasContainerDisplay: any, renderer: Renderer2): void {
    const canvas = renderer.createElement('canvas');
    canvas.id = 'schedule-previewCanvas';
    canvas.style.position = 'absolute';
    canvas.style.background = '#000';
    canvas.style.width = template.width + 'px';
    canvas.style.height = template.height + 'px';
    canvas.width = template.width;
    canvas.height = template.height;
    renderer.appendChild(canvasContainerDisplay.nativeElement, canvas);
  }

  /**
   * create canvas area
   *
   * @param area Area
   * @param canvasContainerDisplay ElementRef
   * @param renderer Renderer2
   */
  public createCanvasArea(area: Area, canvasContainerDisplay: any, renderer: Renderer2): void {
    if (
      area.getArea().attribute == LinkDataPictureEnum.EMERGENCY_MESSAGE ||
      area.getArea().linkReferenceData == LinkDataTextEnum.EMERGENCY_MESSAGE
    ) {
      return;
    }
    const canvas = renderer.createElement('canvas');
    canvas.id = `schedule-previewCanvas-${area.id}`;
    canvas.style.position = 'absolute';
    canvas.style.zIndex = area.index;
    canvas.style.left = area.posX + 'px';
    canvas.style.top = area.posY + 'px';
    canvas.style.width = area.width + 'px';
    canvas.style.height = area.height + 'px';
    canvas.width = area.width;
    canvas.height = area.height;
    renderer.appendChild(canvasContainerDisplay.nativeElement, canvas);
    area.canvas = canvas;
  }

  /**
   * draw preview
   *
   * @param template template
   * @param renderer
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum (TimetableEditor or TimetableOperation)
   * @param isMonitorMode
   */
  public drawPreview(template: Template, renderer: Renderer2) {
    if (template) {
      template?.layers.forEach(layer => {
        if (!layer.isSwitchingArea || this.switchingTiming == 0) {
          Promise.all(
            layer.areas.map(async area => {
              if (!area.canvas) {
                return;
              }
              this.drawArea(area, renderer, template);
            })
          );
        } else {
          if (!layer.areas.length) {
            return;
          }
          layer.areas = _.orderBy(layer.areas, ['index'], ['desc']);
          this.drawArea(layer.areas[Constant.FIRST_ELEMENT_INDEX], renderer, template);
          this.areasDrawing.push(layer.areas[Constant.FIRST_ELEMENT_INDEX]);
          this.drawLayerSwitching(layer, renderer, template);
        }
      });
    }
  }

  /**
   * draw Layer Switching
   * @param layer
   * @param renderer
   * @param template
   */
  private drawLayerSwitching(layer: Layer, renderer: Renderer2, template: Template): void {
    if (layer[`subscription`]) {
      layer[`subscription`].unsubscribe();
    }
    if (!this.switchingTiming) {
      return;
    }
    const observable = interval(1000);
    const subscription = observable
      .pipe(
        takeUntil(this.clearPreviewDisplay1Subject),
        takeUntil(this.pausePreviewDisplay1Subject),
        repeatWhen(() => this.startPreviewDisplay1Subject)
      )
      .subscribe(count => {
        if (count == this.switchingTiming - 1) {
          this.drawLayerSwitchingForDisplay(layer, renderer, template);
        }
      });
    layer[`subscription`] = subscription;
    layer[`clearPreviewSubject`] = this.clearPreviewDisplay1Subject.subscribe(() => {
      subscription.unsubscribe();
      layer[`clearPreviewSubject`]?.unsubscribe();
    });
  }

  /**
   * draw layer switching for display
   * @param layer
   * @param renderer
   * @param template
   */
  private drawLayerSwitchingForDisplay(layer: Layer, renderer: Renderer2, template: Template): void {
    if (this.isPlay) {
      layer[`subscription`]?.unsubscribe();
      // find index of prevArea
      let index2 = layer.areas.findIndex(item2 => this.areasDrawing.map(area => area?.id)?.includes(item2?.id));
      if (index2 != -1) {
        // clear prevArea
        this.clearPreviousArea(layer.areas[index2]);
        if (index2 + 1 >= layer.areas.length) {
          index2 = -1;
        }
        // draw new area
        this.drawArea(layer.areas[index2 + 1], renderer, template);
        this.areasDrawing.push(layer.areas[index2 + 1]);
        // draw layer switching
        this.drawLayerSwitching(layer, renderer, template);
      }
    }
  }

  /**
   * set Area Switching Timing
   * @param switchingTiming
   */
  public setAreaSwitchingTiming(switchingTiming: number): void {
    this.switchingTiming = switchingTiming;
  }

  /**
   * draw Area
   * @param area
   * @param renderer
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param template
   * @param isMonitorMode
   * @returns
   */
  private async drawArea(area: Area, renderer: Renderer2, template: Template) {
    // case area instanceof TextArea
    if (area?.checkTypeTextArea()) {
      var textArea = area as TextArea;
      if (textArea.isFix) {
        // draw fix text
        this.drawAreaFixText(textArea, renderer);
      } else {
        switch (textArea.linkReferenceData) {
          // draw link text if linkReferenceData is clock
          case LinkDataTextEnum.CLOCK:
            this.drawClock(textArea, renderer);
            break;
          // draw link text if linkReferenceData is index word
          case LinkDataTextEnum.INDEX_WORD:
            this.drawIndexWordForDisplay(textArea, template);
            break;
          // draw link text if linkReferenceData is timetable
          case LinkDataTextEnum.TIMETABLE:
            this.drawTimetableForDisplay(textArea, renderer);
            break;
          default:
            break;
        }
      }
      // case area instanceof Picture
    } else {
      var pictureArea = area?.getArea() as PictureArea;
      if (pictureArea?.isFix) {
        if (pictureArea?.media?.type == TypeMediaFileEnum.MP4) {
          // draw video fix picture
          await this.drawVideoFixPicture(pictureArea, renderer);
        } else {
          // draw fix picture
          await this.drawAreaFixPicture(pictureArea, renderer, template);
        }
        // draw link picture
      } else {
        switch (pictureArea.attribute) {
          case LinkDataPictureEnum.SIGNAGE_CHANNEL:
            // draw signage channel
            if (!this.mediaSetting) {
              return;
            }
            this.drawSignageChannel(pictureArea, this.mediaSetting, renderer, template);
            break;
          case LinkDataPictureEnum.INDEX_WORD:
            // draw link picture if attribute is index word
            this.drawIndexWordForDisplay(pictureArea, template);
            break;
          default:
            break;
        }
      }
    }
  }

  /**
   * draw Timetable For Display
   * @param textArea
   * @param renderer
   */
  private drawTimetableForDisplay(textArea: TextArea, renderer: Renderer2) {
    if (this.timetableSchedule) {
      this.drawAreasTimetable(
        [textArea],
        renderer,
        this.timetableSchedule,
        this.currentIndexTimetableSchedule,
        this.referencePositionColumnsByTemplate
      );
    }
  }

  /**
   * draw index word for display
   * @param area
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param template
   */
  private drawIndexWordForDisplay(area: Area, template: Template): void {
    if (this.timetableSchedule && this.areasIndexWordDisplay) {
      this.setDataIndexWordForAreasDrawing([area], this.areasIndexWordDisplay);
      this.drawAreasIndexWord([area], this.timetableSchedule, template);
    }
  }

  /**
   * clear previous area
   * @param prevArea
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  private clearPreviousArea(prevArea: Area) {
    // clear interval clock, animation frame gif
    if (prevArea.checkTypeTextArea()) {
      let intervals = this.listAreaClockIntervals.filter(data => data.areaId == prevArea.id);
      if (intervals.length) {
        intervals.forEach(item => {
          clearInterval(item.time);
        });
        _.remove(this.listAreaClockIntervals, function(areaClock) {
          return intervals.map(data => data.areaId).includes(areaClock?.id);
        });
      }
    } else {
      let pictureArea = <PictureArea>prevArea;
      if (Helper.isVideo(pictureArea.media)) {
        pictureArea.videoPreview && pictureArea.videoPreview.pause();
        if (pictureArea[`animationId`]) {
          cancelAnimationFrame(pictureArea[`animationId`]);
        }
      }
    }
    if (!prevArea.checkTypeTextArea() && !prevArea.isFix && prevArea.getArea().attribute == LinkDataPictureEnum.EXTERNAL_CONTENT) {
      this.clearAllIntervalDrawsNewsDisplay1();
    }
    // clear timeout
    this.clearTimeoutStopDurationPrevArea(prevArea);
    // clear canvas prevArea
    this.handleUnSubscriptionForArea(prevArea);
    this.clearCanvas(prevArea.canvas);
    // remove area drawing
    _.remove(this.areasDrawing, function(areaDraw) {
      return areaDraw?.id == prevArea.id;
    });
  }

  /**
   * clear timeout stop duration prev area
   * @param prevArea
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  private clearTimeoutStopDurationPrevArea(prevArea: Area): void {
    let timeoutsTimetableEditor = this.timeoutsDisplay1Timetable.filter(time1 => time1.areaId == prevArea.id);
    for (let timeout of timeoutsTimetableEditor) {
      clearTimeout(timeout.time);
    }
  }

  /**
   * draw preview fix area timing on
   *
   * @param areasTimingOn areas timing on
   * @param renderer
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum (TimetableEditor or TimetableOperation)
   * @param isFinishSchedule
   * @param template
   */
  public drawPreviewFixAreaTimingOn(areasTimingOn: Area[], renderer: Renderer2, isFinishSchedule: boolean, template: Template) {
    Promise.all(
      areasTimingOn.map(async area => {
        if (!area.canvas) {
          return;
        }
        // case area instanceof TextArea
        if (area.checkTypeTextArea()) {
          var textArea = area as TextArea;
          // draw fix text
          this.drawAreaFixText(textArea, renderer, isFinishSchedule);
          // case area instanceof Picture
        } else {
          var pictureArea = area.getArea() as PictureArea;
          if (pictureArea?.media?.type == TypeMediaFileEnum.MP4) {
            // draw video fix picture
            await this.drawVideoFixPicture(pictureArea, renderer, isFinishSchedule);
          } else {
            // draw fix picture
            await this.drawAreaFixPicture(pictureArea, renderer, template, isFinishSchedule);
          }
        }
      })
    );
  }

  /**
   * clear fix area timing on
   * @param areasTimingOn
   * @param canvasDisplayId
   */
  public clearFixAreaTimingOn(areasTimingOn: Area[]): void {
    areasTimingOn.forEach(area => {
      if (!area.canvas) {
        return;
      }
      this.clearCanvas(area.canvas);
      if (area.checkTypeTextArea()) {
        if ((area as TextArea).scrollStatus != ScrollStatusEnum.OFF) {
          this.resetPositionScrollText(area as TextArea);
          area[`subscription`]?.unsubscribe();
          delete area[`subscription`];
        }
        return;
      }
      // clear video and sound
      if ((area as PictureArea)?.media?.type == TypeMediaFileEnum.MP4) {
        this.stopVideo(area as PictureArea);
      } else {
        this.stopAudio(area as PictureArea);
        this.unsubscribedSubjectArea(area);
      }
    });
  }

  /**
   * clear intervals clock
   */
  public clearIntervalsClock(): void {
    this.listAreaClockIntervals.forEach(item => {
      clearInterval(item.time);
    });
    this.listAreaClockIntervals = [];
  }

  /**
   * draw data schedule
   *
   * @param activeScheduleRow
   * @param textAreas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param referencePositionColumnsByTemplate
   */
  public drawSchedule(activeScheduleRow: ActiveScheduleRow, textAreas: TextArea[], referencePositionColumnsByTemplate: Array<number>) {
    if (!activeScheduleRow) {
      return;
    }
    Promise.all(
      textAreas.map(textArea => {
        if (!textArea.canvas) {
          return;
        }
        let ctx = textArea.canvas.getContext('2d');
        ctx.clearRect(0, 0, textArea.canvas.width, textArea.canvas.height);
        let textDraw: string = '';
        if (textArea.linkReferenceData == LinkDataTextEnum.TIMETABLE) {
          let indexPositionColumn = referencePositionColumnsByTemplate.find(column => column == textArea.referencePositionColumn);
          if (indexPositionColumn != undefined) {
            textDraw = activeScheduleRow[`current_${textArea.referencePositionRow}`]?.list[indexPositionColumn] ?? '';
          }
        } else if (textArea.linkReferenceData == LinkDataTextEnum.OPERATION_INFO) {
          textDraw = activeScheduleRow[`current_${textArea.referencePositionRow}`]?.operationInfo?.text;
        }
        if (
          textArea.linkReferenceData == LinkDataTextEnum.TIMETABLE &&
          textArea.referencePositionColumn == ReferencePositionTimetableColumnEnum.TIME &&
          textDraw.length != 0
        ) {
          textDraw = Helper.convertTimeTimetableSchedule(textDraw, true);
        }
        if (!textDraw || textDraw === '') {
          return;
        }
        this.drawAreaText(textArea, textDraw);
      })
    );
  }

  /**
   * draw index word by schedule
   *
   * @param areas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param template
   */
  public drawIndexWordBySchedule(areas: Area[], template: Template) {
    areas.forEach(area => {
      if (!area.canvas) {
        return;
      }
      let ctx = area.canvas.getContext('2d');
      ctx.clearRect(0, 0, area.canvas.width, area.canvas.height);

      if (area.checkTypeTextArea()) {
        const textDraw = area.getArea().text ?? Constant.EMPTY;
        if (textDraw === Constant.EMPTY) {
          return;
        }
        this.drawAreaText(area as TextArea, textDraw);
      } else if (area.getArea().media) {
        this.drawAreaIndexWord(area as PictureArea, template);
      }
    });
  }

  /**
   * draw all dynamic messages
   *
   * @param dynamicMessages
   * @param areas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param template
   * @returns
   */
  public drawDynamicMessages(
    dynamicMessages: DynamicMessage[],
    areas: Area[],
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    template: Template
  ) {
    if (areas?.length == 0) {
      return;
    }
    Promise.all(
      areas.map(area => {
        if (!area.canvas) {
          return;
        }
        if (area.checkTypeTextArea()) {
          let textArea = area as TextArea;
          const dynamicMessage = dynamicMessages.find(message => message.textArea?.id == textArea.id);
          let textDraw = dynamicMessage?.message ?? Constant.EMPTY;
          if (textDraw === Constant.EMPTY) {
            return;
          }
          this.drawAreaText(textArea, textDraw);
        } else {
          let pictureArea = area as PictureArea;
          const dynamicMessage = dynamicMessages.find(message => message.pictureArea?.id == pictureArea.id);
          let media = dynamicMessage?.media ?? null;
          if (!media) {
            return;
          }
          pictureArea.media = media;
          this.drawAreaPicture(pictureArea, template);
        }
      })
    );
  }

  /**
   * draw operation info
   *
   * @param activeScheduleRow
   * @param textAreas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  public drawOperationInfo(activeScheduleRow: ActiveScheduleRow, textAreas: TextArea[]) {
    Promise.all(
      textAreas.map(textArea => {
        if (!textArea.canvas) {
          return;
        }
        let ctx = textArea.canvas.getContext('2d');
        ctx.clearRect(0, 0, textArea.canvas.width, textArea.canvas.height);
        let textDraw = activeScheduleRow[`current_${textArea.referencePositionRow}`]?.operationInfo?.text;
        if (textDraw) {
          this.drawAreaText(textArea, textDraw);
        }
      })
    );
  }

  /**
   * Draw timetable monitor
   *
   * @param activeScheduleRow
   * @param textAreas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @returns
   */
  public drawTimetableMonitor(
    activeScheduleRow: ActiveScheduleRow,
    textAreas: TextArea[],
    referencePositionColumnsByTemplate: Array<number>
  ) {
    if (!activeScheduleRow) {
      return;
    }
    Promise.all(
      textAreas.map(textArea => {
        if (!textArea.canvas) {
          return;
        }
        let ctx = textArea.canvas.getContext('2d');
        ctx.clearRect(0, 0, textArea.canvas.width, textArea.canvas.height);
        let textDraw: string = '';
        let indexPositionColumn = referencePositionColumnsByTemplate.find(column => column == textArea.referencePositionColumn);
        if (indexPositionColumn != undefined) {
          textDraw = activeScheduleRow[`current_${textArea.referencePositionRow}`]?.list[indexPositionColumn] ?? '';
          if (
            textArea.linkReferenceData == LinkDataTextEnum.TIMETABLE &&
            textArea.referencePositionColumn == ReferencePositionTimetableColumnEnum.TIME
          ) {
            textDraw = Helper.convertTimeTimetableSchedule(textDraw, true);
          }
        }
        if (!textDraw || textDraw === '') {
          return;
        }
        this.drawAreaText(textArea, textDraw);
      })
    );
  }

  /**
   * draw operation info monitor
   *
   * @param delayOperationInfo
   * @param textAreas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  public drawOperationInfoMonitor(delayOperationInfo: any[], textAreas: TextArea[]) {
    Promise.all(
      textAreas.map(textArea => {
        if (!textArea.canvas) {
          return;
        }
        let ctx = textArea.canvas.getContext('2d');
        ctx.clearRect(0, 0, textArea.canvas.width, textArea.canvas.height);
        let textDraw = delayOperationInfo[textArea.referencePositionRow] ?? Constant.EMPTY;
        if (textDraw === Constant.EMPTY) {
          return;
        }
        this.drawAreaText(textArea, Helper.decodeHTML(textDraw));
      })
    );
  }

  /**
   *
   * @param areaTexts
   * @param renderer
   * @param canvasDisplayId
   * @param timetableSchedule
   * @param currentIndex
   * @param screenCanvasIdEnum
   * @param referencePositionColumnsByTemplate
   * @returns
   */
  public async drawAreasTimetable(
    areaTexts: TextArea[],
    renderer: Renderer2,
    timetableSchedule: TimetableScheduleMerge[],
    currentIndex: number,
    referencePositionColumnsByTemplate: Array<number>
  ) {
    if (!timetableSchedule || !areaTexts?.length) {
      return;
    }
    await areaTexts.forEach(areaText => {
      let textDraw =
        this.getTextDrawFromTimetableSchedule(areaText, timetableSchedule, currentIndex, referencePositionColumnsByTemplate) ??
        Constant.EMPTY;
      if (textDraw === Constant.EMPTY) {
        return;
      }
      // draw areaText
      renderer.setStyle(areaText.canvas, 'visibility', 'visible');
      this.drawAreaText(areaText, textDraw);
    });
  }

  /**
   * draw areas timetable layer on
   * @param areaTexts
   * @param renderer
   * @param canvasDisplayId
   * @param timetableSchedule
   * @param currentIndex
   * @param screenCanvasIdEnum
   * @param referencePositionColumnsByTemplate
   * @returns
   */
  public async drawAreasTimetableLayerOn(
    areaTexts: TextArea[],
    renderer: Renderer2,
    timetableSchedule: TimetableScheduleMerge[],
    currentIndex: number,
    referencePositionColumnsByTemplate: Array<number>
  ) {
    if (!timetableSchedule) {
      return;
    }
    const areaIdsDrawing = areaTexts?.map(area => area.id);
    const areasTimetable = this.areasDrawing.filter(areaText => areaIdsDrawing?.includes(areaText?.id));
    this.drawAreasTimetable(areasTimetable as TextArea[], renderer, timetableSchedule, currentIndex, referencePositionColumnsByTemplate);
  }

  /**
   * draw operation info
   *
   * @param activeScheduleRow
   * @param textAreas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  public drawOperationInfoOn(activeScheduleRow: ActiveScheduleRow, textAreas: TextArea[]) {
    const areaIdsDrawing = textAreas.map(area => area.id);
    const areasOperation = this.areasDrawing.filter(areaText => areaIdsDrawing.includes(areaText?.id));
    this.drawOperationInfo(activeScheduleRow, areasOperation as TextArea[]);
  }

  /**
   * set data schedule
   * @param timetableSchedule
   * @param dataResponse
   * @param pictureAreaService
   * @param areasIndexWordDisplay
   */
  public setDataPreviewScheduleMerge(
    timetableSchedule: TimetableScheduleMerge[],
    dataResponse?: any,
    pictureAreaService?: PictureAreaService,
    areasIndexWordDisplay?: Area[]
  ): void {
    if (timetableSchedule) {
      this.timetableSchedule = timetableSchedule;
    }
    if (dataResponse) {
      this.dataResponse = dataResponse;
    }
    if (pictureAreaService) {
      this.pictureAreaService = pictureAreaService;
    }
    if (areasIndexWordDisplay) {
      this.areasIndexWordDisplay = areasIndexWordDisplay;
    }
  }

  /**
   * set data preview timetable operation manager
   * @param activeScheduleRow
   * @param referencePositionColumnsByTemplate
   * @param dynamicMessages
   * @param emergencyData
   */
  public setDataPreviewTimetableOperationManager(
    activeScheduleRow: ActiveScheduleRow,
    referencePositionColumnsByTemplate?: number[],
    dynamicMessages?: DynamicMessage[],
    emergencyData?: EmergencyData,
    areasIndexWordDisplay?: Area[]
  ) {
    if (activeScheduleRow) {
      this.activeScheduleRow = activeScheduleRow;
    }
    if (referencePositionColumnsByTemplate) {
      this.referencePositionColumnsByTemplate = referencePositionColumnsByTemplate;
    }
    if (dynamicMessages) {
      this.dynamicMessages = dynamicMessages;
    }
    if (emergencyData) {
      this.emergencyData = emergencyData;
    }
    if (areasIndexWordDisplay) {
      this.areasIndexWordDisplay = areasIndexWordDisplay;
    }
  }

  /**
   * set data is on emergency
   * @param isOnEmergency
   */
  public setDataIsOnEmergency(isOnEmergency: boolean): void {
    this.isOnEmergency = isOnEmergency;
  }

  /**
   * set data timetables
   * @param currentIndex
   * @param referencePoint
   */
  public setDataTimetables(currentIndex: number, referencePoint: number[], timeDateLine: string): void {
    this.currentIndexTimetableSchedule = currentIndex;
    this.referencePositionColumnsByTemplate = referencePoint;
    this.timeDateLine = timeDateLine;
  }

  /**
   * draw area index world
   *
   * @param areas
   * @param timetableSchedule
   * @param template
   * @returns
   */
  public async drawAreasIndexWord(areas: Area[], timetableSchedule: TimetableScheduleMerge[], template: Template) {
    if (!timetableSchedule) {
      return;
    }
    Promise.all(
      areas.map(area => {
        if (area.checkTypeTextArea()) {
          const textDraw = area.getArea().text ?? Constant.EMPTY;
          if (textDraw === Constant.EMPTY) {
            return;
          }
          this.drawAreaText(area as TextArea, textDraw);
        } else if (area.getArea().media) {
          this.drawAreaIndexWord(area as PictureArea, template);
        }
      })
    );
  }

  /**
   * draw areas index word layer on
   * @param areas
   * @param timetableSchedule
   * @returns
   */
  public async drawAreasIndexWordLayerOn(areas: Area[], timetableSchedule: TimetableScheduleMerge[], template: Template) {
    if (!timetableSchedule) {
      return;
    }
    const areasIdIndexWord = areas?.map(area => area.id);
    let areasIndexWord = this.areasDrawing.filter(areaText => areasIdIndexWord?.includes(areaText?.id));
    this.setDataIndexWordForAreasDrawing(areasIndexWord, areas);
    this.drawAreasIndexWord(areasIndexWord, timetableSchedule, template);
  }

  /**
   * set data index word for areas drawing
   * @param areasIndexWord
   * @param areas
   */
  private setDataIndexWordForAreasDrawing(areasIndexWord: Area[], areas: Area[]) {
    areasIndexWord?.forEach(item => {
      let areaIndexWord = areas.find(area => area.id == item.id);
      item.getArea().text = areaIndexWord ? areaIndexWord.getArea().text : '';
      item.getArea().media = areaIndexWord ? areaIndexWord.getArea().media : null;
    });
  }

  /**
   * draw area index word
   *
   * @param areaPicture
   * @param template
   */
  private async drawAreaIndexWord(areaPicture: PictureArea, template: Template) {
    // if image is svg file -> convert w, h for file
    if (areaPicture.media) {
      if (!areaPicture.media['width'] && !areaPicture.media['height']) {
        delete areaPicture.media['width'];
        delete areaPicture.media['height'];
        let imageInfo = await Helper.getImageInformation(areaPicture.media);
        areaPicture.media['width'] = `${imageInfo.width}`;
        areaPicture.media['height'] = `${imageInfo.height}`;
      }
    }
    try {
      // draw canvas
      let $this = this;
      let ctx = areaPicture.canvas.getContext('2d');
      let mediaPosition = Helper.coverMedia(areaPicture.canvas, areaPicture.media, areaPicture.objectFit);
      let img = document.createElement('img');
      img.src = areaPicture.media.url;
      const indexArea = this.mediaIndexWords.findIndex(media => media?.key == this.keyUnique && media?.img.src == img.src);
      if (indexArea == -1) {
        this.mediaIndexWords.push(new MediaIndexWord(this.keyUnique, img));
      } else {
        this.mediaIndexWords[indexArea] = new MediaIndexWord(this.keyUnique, img);
      }
      let layerOfArea = template ? Helper.findLayerOfArea(areaPicture, template) : undefined;
      img.onload = function() {
        if (
          !layerOfArea ||
          !layerOfArea.isSwitchingArea ||
          !$this.switchingTiming ||
          (layerOfArea.isSwitchingArea && $this.areasDrawing?.map(area => area?.id)?.includes(areaPicture?.id))
        ) {
          let index = $this.mediaIndexWords.findIndex(media => media?.key == $this.keyUnique && media?.img.src == img.src);
          if (index == -1) {
            return;
          }
          ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
          if (areaPicture.objectFit == ObjectFitEnum.FILL) {
            ctx.drawImage($this.mediaIndexWords[index].img, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
          } else {
            ctx.drawImage(
              $this.mediaIndexWords[index].img,
              mediaPosition.sX,
              mediaPosition.sY,
              mediaPosition.sWidth,
              mediaPosition.sHeight,
              mediaPosition.x,
              mediaPosition.y,
              mediaPosition.width,
              mediaPosition.height
            );
          }
        }
      };
    } catch (error) {
      console.log('error draw 3', error);
    }
  }

  /**
   * get text form schedule
   * @param textArea
   * @param timetableSchedule
   * @param currentIndex
   * @param referencePositionColumnsByTemplate
   * @returns
   */
  public getTextDrawFromTimetableSchedule(
    textArea: TextArea,
    timetableSchedule: any,
    currentIndex: number,
    referencePositionColumnsByTemplate: Array<number>
  ): string {
    // get list item detail
    let itemDetails = timetableSchedule;
    // get index of row area
    let indexCurrentOfArea = textArea.referencePositionRow + currentIndex;
    let indexPositionColumn = referencePositionColumnsByTemplate.find(column => column == textArea.referencePositionColumn);
    if (indexPositionColumn == undefined) {
      return '';
    }
    if (
      !timetableSchedule.length ||
      !timetableSchedule[indexCurrentOfArea] ||
      !timetableSchedule[indexCurrentOfArea][indexPositionColumn]
    ) {
      return '';
    }
    let textResult = '';
    textResult = timetableSchedule[indexCurrentOfArea][indexPositionColumn];
    // if bad format
    if (itemDetails && itemDetails[indexCurrentOfArea] && textArea.referencePositionColumn == ReferencePositionTimetableColumnEnum.TIME) {
      // if text start with 0, remove
      if (textResult?.startsWith('0')) {
        textResult = textResult.substring(1);
      }
      // if text type as 'HH:MM:SS' => only get HH:MM
      if (textResult?.split(':').length == Constant.NUMBER_ELEMENTS_OF_SECOND_FORMAT) {
        textResult = textResult.substring(0, textResult.length - 3);
      }
      if (!timetableSchedule[indexCurrentOfArea].invalid) {
        const dataArrTime = textResult.split(':');
        let hh = parseInt(dataArrTime[0]) > 23 ? parseInt(dataArrTime[0]) - 24 : dataArrTime[0];
        textResult = hh + ':' + dataArrTime[1];
      }
    }
    // return textResult
    return textResult;
  }

  /**
   * draw fix text preview
   * @param areaText TextArea
   * @param renderer Renderer2
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum
   * @param isFinishSchedule
   */
  private drawAreaFixText(areaText: TextArea, renderer: Renderer2, isFinishSchedule?: boolean): void {
    if (areaText.isTimingOn && !isFinishSchedule) {
      return;
    }
    renderer.setStyle(areaText.canvas, 'visibility', 'visible');
    let text = areaText.text;
    // draw areaText
    this.drawAreaText(areaText, text);
  }

  /**
   * draw text
   * @param areaText TextArea
   * @param text
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private drawAreaText(areaText: TextArea, text?: string): void {
    // get ctx from canvas
    let ctx = areaText.canvas.getContext('2d');
    // draw color background
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // set style bold / italic / normal
    let textStyle = 'normal';
    if (areaText.isBold && !areaText.isItalic) {
      textStyle = Constant.BOLD_STYLE;
    } else if (areaText.isItalic && !areaText.isBold) {
      textStyle = Constant.ITALIC_STYLE;
    } else if (areaText.isItalic && areaText.isBold) {
      textStyle = `${Constant.BOLD_STYLE} ${Constant.ITALIC_STYLE}`;
    }
    // draw color text
    ctx.fillStyle = areaText.fontColor;
    // draw font text
    ctx.font = `${textStyle} ${areaText.fontSize}px ${!areaText.fontName ? 'Arial' : areaText.fontName}`;
    // set orientation
    if (areaText.orientation == OrientationEnum.HORIZONTAL) {
      this.drawTextOrientationHorizontal(ctx, areaText, text);
    } else if (areaText.orientation == OrientationEnum.VERTICAL) {
      this.drawTextOrientationVertical(ctx, areaText, text);
    } else if (areaText.orientation == OrientationEnum.SIDEWAYS) {
      this.drawTextOrientationSideways(ctx, areaText, text);
    }
  }

  /**
   * draw text orientation horizontal
   * @param ctx
   * @param areaText area draw
   * @param text text need to draw
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum (timetableEditor or timetableOperation)
   */
  private drawTextOrientationHorizontal(ctx: any, areaText: TextArea, text: string): void {
    var distanceX = 0;
    var textDraw = text ?? this.getTimer();
    const measureText = ctx.measureText(textDraw);
    const widthMeasureText = measureText.width;
    const referencePosition = Helper.getReferencePositionOrientationHorizontal(ctx, areaText);
    let referenceX = referencePosition.referenceX;
    let referenceY = referencePosition.referenceY;
    switch (ctx.textAlign) {
      case 'left':
        referenceX = 0;
        break;
      case 'center':
        distanceX = referenceX - widthMeasureText / 2;
        break;
      case 'right':
        distanceX = referenceX - widthMeasureText;
        break;
    }
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // draw color text
    ctx.fillStyle = areaText.fontColor;
    ctx.fillText(textDraw, referenceX + areaText.posXScroll, referenceY + areaText.posYScroll);

    // if textDraw empty or (scroll AUTO and width text < width area or height text < height area) => no draw scroll
    if (textDraw == '' || areaText.scrollStatus == ScrollStatusEnum.OFF || this.canNotScrollTextHorizontal(areaText, measureText)) {
      areaText[`subscription`]?.unsubscribe();
      return;
    }
    areaText[`isStart`] = this.isStartTimetable;
    if (areaText[`subscription`]) {
      areaText[`subscription`].unsubscribe();
    }
    let timeout = setTimeout(() => {
      // draw scroll text after stop duration

      const observable = interval(50);
      const subscription = observable
        .pipe(
          takeUntil(this.clearPreviewDisplay1Subject),
          takeUntil(this.pausePreviewDisplay1Subject),
          repeatWhen(() => this.startPreviewDisplay1Subject)
        )
        .subscribe(() => {
          this.drawScrollHorizontalText(areaText, widthMeasureText, distanceX, ctx, text, referenceX, referenceY);
        });
      areaText[`subscription`] = subscription;
      areaText[`clearPreviewSubject`] = this.clearPreviewDisplay1Subject.subscribe(() => {
        this.resetPositionScrollText(areaText);
        subscription.unsubscribe();
        areaText[`clearPreviewSubject`].unsubscribe();
      });
    }, areaText.stopDuration * 1000);
    // push timeout to list => remove if needed
    this.timeoutsDisplay1Timetable.push(new TimeOut(timeout, areaText.linkReferenceData, areaText.id));
  }

  /**
   * Can not scroll text horizontal
   *
   * @param areaText
   * @param measureText
   * @returns true if width text < textArea.width or height text < textArea.height
   */
  private canNotScrollTextHorizontal(areaText: TextArea, measureText: any): boolean {
    const isScrollHorizontal =
      areaText.scrollDirection == ScrollDirectionsEnum.LEFT || areaText.scrollDirection == ScrollDirectionsEnum.RIGHT;
    return (
      (areaText.horizontalTextAlignment == AlignmentEnum.CENTER && areaText.verticalTextAlignment == AlignmentEnum.MIDDLE) ||
      (areaText.scrollStatus == ScrollStatusEnum.AUTO &&
        (isScrollHorizontal
          ? measureText.width < areaText.width
          : measureText.actualBoundingBoxDescent + measureText.actualBoundingBoxAscent < areaText.height))
    );
  }

  /**
   * Can note scroll text vertical and side way
   *
   * @param areaText
   * @param textHeight
   * @param maxWidth
   * @returns true if width text < textArea.width or height text < textArea.height
   */
  private canNotScrollTextVerticalAndSideways(areaText: TextArea, textHeight: number, maxWidth: number) {
    const isScrollHorizontal =
      areaText.scrollDirection == ScrollDirectionsEnum.LEFT || areaText.scrollDirection == ScrollDirectionsEnum.RIGHT;
    return (
      (areaText.horizontalTextAlignment == AlignmentEnum.CENTER && areaText.verticalTextAlignment == AlignmentEnum.MIDDLE) ||
      (areaText.scrollStatus == ScrollStatusEnum.AUTO && (isScrollHorizontal ? maxWidth < areaText.width : textHeight < areaText.height))
    );
  }

  /**
   * draw scroll horizontal text
   *
   * @param areaText
   * @param widthMeasureText
   * @param distanceX
   * @param canvasDisplayId
   * @param ctx
   * @param text
   * @param screenCanvasIdEnum
   * @param referenceX
   * @param referenceY
   */
  private drawScrollHorizontalText(
    areaText: TextArea,
    widthMeasureText: number,
    distanceX: number,
    ctx: any,
    text: string,
    referenceX: number,
    referenceY: number
  ): void {
    if (this.isPlay) {
      // start preview
      if (areaText[`isStart`]) {
        areaText[`subscription`].unsubscribe();
        this.isStartTimetable = false;
        areaText[`isStart}`] = false;
        this.drawTextOrientationHorizontal(ctx, areaText, text);
      } else {
        // finish scroll text (direction left)
        if (areaText.scrollDirection == ScrollDirectionsEnum.LEFT && Math.floor(areaText.posXScroll) < -Math.floor(widthMeasureText)) {
          areaText.posXScroll = 0;
          this.handleClearSubscriptionScroll(areaText, ctx, text, OrientationEnum.HORIZONTAL);
        } else if (
          areaText.scrollDirection == ScrollDirectionsEnum.RIGHT &&
          Math.floor(areaText.posXScroll) > Math.floor(widthMeasureText)
        ) {
          // finish scroll text (direction right)
          areaText.posXScroll = 0;
          this.handleClearSubscriptionScroll(areaText, ctx, text, OrientationEnum.HORIZONTAL);
        } else if (
          areaText.scrollDirection == ScrollDirectionsEnum.UP &&
          Math.floor(areaText.posYScroll) < -Math.floor(areaText.fontSize)
        ) {
          // finish scroll text (direction up)
          areaText.posYScroll = 0;
          this.handleClearSubscriptionScroll(areaText, ctx, text, OrientationEnum.HORIZONTAL);
        } else if (
          areaText.scrollDirection == ScrollDirectionsEnum.DOWN &&
          Math.floor(areaText.posYScroll) > Math.floor(areaText.height + areaText.fontSize)
        ) {
          // finish scroll text (direction down)
          areaText.posYScroll = 0;
          this.handleClearSubscriptionScroll(areaText, ctx, text, OrientationEnum.HORIZONTAL);
        } else {
          // draw scroll text
          this.drawTextScrollHorizontal(ctx, areaText, text, referenceX, referenceY);
        }
      }
    }
  }

  /**
   * Handle clear subscription horizontal scroll
   *
   * @param areaText
   * @param canvasDisplayId
   * @param ctx
   * @param text
   * @param screenCanvasIdEnum
   * @param orientation
   */
  private handleClearSubscriptionScroll(areaText: TextArea, ctx: any, text: string, orientation: OrientationEnum): void {
    areaText[`subscription`].unsubscribe();
    this.clearTimeoutFinishScroll(areaText);
    switch (orientation) {
      case OrientationEnum.HORIZONTAL:
        this.drawTextOrientationHorizontal(ctx, areaText, text);
        break;
      case OrientationEnum.VERTICAL:
        this.drawTextOrientationVertical(ctx, areaText, text);
        break;
      case OrientationEnum.SIDEWAYS:
        this.drawTextOrientationSideways(ctx, areaText, text);
        break;
      default:
        break;
    }
  }

  /**
   * clear time out finish scroll
   * @param areaText
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  private clearTimeoutFinishScroll(areaText: TextArea): void {
    // screen Timetable Editor
    this.clearTimeoutForAreaText(this.timeoutsDisplay1Timetable, areaText);
  }

  /**
   * clear timeout for area text
   * @param timeouts
   * @param areaText
   */
  private clearTimeoutForAreaText(timeouts: Array<TimeOut>, areaText: TextArea): void {
    const index = timeouts.findIndex(timeout => timeout.areaId == areaText.id);
    if (index == -1) {
      return;
    }
    clearTimeout(timeouts[index].time);
    timeouts.splice(index, 1);
  }

  /**
   * clear all time out of stop duration
   * @param isClearAllArea
   * @param screenCanvasIdEnum
   * @param types
   */
  public clearTimeoutsStopDurationArea(isClearAllArea: boolean, types?: Array<LinkDataTextEnum>): void {
    let timeoutsAreaDisplay1;

    if (isClearAllArea) {
      timeoutsAreaDisplay1 = this.timeoutsDisplay1Timetable;
    } else {
      timeoutsAreaDisplay1 = this.timeoutsDisplay1Timetable.filter(item1 => types.includes(item1.linkReferenceData));
    }

    // clear timeout for areas display 1
    for (let timeoutDisplay1 of timeoutsAreaDisplay1) {
      clearTimeout(timeoutDisplay1.time);
    }
    // reassign list timeouts
    if (isClearAllArea) {
      this.timeoutsDisplay1Timetable = [];
    } else {
      this.timeoutsDisplay1Timetable = this.timeoutsDisplay1Timetable.filter(item5 => !types.includes(item5.linkReferenceData));
    }
  }

  /**
   * Clear timeouts stop duration display 1
   *
   * @param screenCanvasIdEnum
   */
  public clearTimeoutsStopDurationDisplay1(screenCanvasIdEnum: any): void {
    let timeoutsAreaDisplay1 =
      screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR ? this.timeoutsDisplay1Timetable : this.timeoutsDisplay1TimetableOperation;
    // clear timeout for areas display 1
    for (let timeoutDisplay1 of timeoutsAreaDisplay1) {
      clearTimeout(timeoutDisplay1.time);
    }
    if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
      this.timeoutsDisplay1Timetable = [];
    } else {
      this.timeoutsDisplay1TimetableOperation = [];
    }
  }

  /**
   * Clear timeouts stop duration display 2
   *
   * @param screenCanvasIdEnum
   */
  public clearTimeoutsStopDurationDisplay2(screenCanvasIdEnum: any): void {
    let timeoutsAreaDisplay2 =
      screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR ? this.timeoutsDisplay2Timetable : this.timeoutsDisplay2TimetableOperation;
    // clear timeout for areas display 1
    for (let timeoutDisplay2 of timeoutsAreaDisplay2) {
      clearTimeout(timeoutDisplay2.time);
    }
    if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
      this.timeoutsDisplay2Timetable = [];
    } else {
      this.timeoutsDisplay2TimetableOperation = [];
    }
  }

  /**
   * draw text scroll horizontal
   * @param ctx
   * @param areaText
   * @param text
   * @param referenceX
   * @param referenceY
   */
  private drawTextScrollHorizontal(ctx: any, areaText: TextArea, text: string, referenceX: any, referenceY: any): void {
    const textDraw = text ?? this.getTimer();
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.fontColor;
    ctx.fillText(textDraw, referenceX + areaText.posXScroll, referenceY + areaText.posYScroll);
    this.calcPositionScrollHorizontal(areaText);
  }

  /**
   * draw text orientation vertical
   * @param ctx
   * @param areaText TextArea
   * @param text
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum
   */
  private drawTextOrientationVertical(ctx: any, areaText: TextArea, text: string): void {
    var textDraw = text ?? this.getTimer();
    var charsSplit = textDraw.split('');
    let metrics = ctx.measureText(textDraw);
    let firefox = navigator.userAgent.search('Firefox');
    let characterHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
    if (firefox > -1) {
      characterHeight = areaText.fontSize;
    }
    let textHeight = characterHeight * charsSplit.length;
    let maxWidth = Math.max(...charsSplit.map(ch => ctx.measureText(ch).width));
    const referencePosition = Helper.getReferencePositionOrientationVertical(ctx, areaText, textHeight);
    let referenceX = referencePosition.referenceX;
    let referenceY = referencePosition.referenceY;
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // draw color text
    ctx.fillStyle = areaText.fontColor;
    var i = 0;
    var ch: string;
    var posY = 0.5;
    while ((ch = charsSplit[i++])) {
      var chWidth = ctx.measureText(ch).width;
      ctx.fillText(ch, referenceX - chWidth / 2 + areaText.posXScroll, referenceY + posY * characterHeight + areaText.posYScroll);
      posY++;
    }
    if (
      textDraw == '' ||
      areaText.scrollStatus == ScrollStatusEnum.OFF ||
      this.canNotScrollTextVerticalAndSideways(areaText, textHeight, maxWidth)
    ) {
      areaText[`subscription`]?.unsubscribe();
      return;
    }
    areaText[`isStart`] = this.isStartTimetable;
    if (areaText[`subscription`]) {
      areaText[`subscription`].unsubscribe();
    }
    let timeout = setTimeout(() => {
      var widthMeasureText = ctx.measureText(textDraw).width;
      const observable = interval(50);
      const subscription = observable
        .pipe(
          takeUntil(this.clearPreviewDisplay1Subject),
          takeUntil(this.pausePreviewDisplay1Subject),
          repeatWhen(() => this.startPreviewDisplay1Subject)
        )
        .subscribe(() => {
          this.drawScrollVerticalText(areaText, widthMeasureText, ctx, text, referenceX, referenceY, charsSplit, textHeight, maxWidth);
        });
      areaText[`subscription`] = subscription;
      areaText[`clearPreviewSubject`] = this.clearPreviewDisplay1Subject.subscribe(() => {
        this.resetPositionScrollText(areaText);
        subscription.unsubscribe();
        areaText[`clearPreviewSubject`].unsubscribe();
      });
    }, areaText.stopDuration * 1000);
    // push timeout to list => remove if needed
    this.timeoutsDisplay1Timetable.push(new TimeOut(timeout, areaText.linkReferenceData, areaText.id));
  }

  /**
   * Draw scroll vertical text
   *
   * @param areaText
   * @param widthMeasureText
   * @param ctx
   * @param text
   * @param referenceX
   * @param referenceY
   * @param charsSplit
   * @param textHeight
   * @param maxWidth
   */
  private drawScrollVerticalText(
    areaText: TextArea,
    widthMeasureText: number,
    ctx: any,
    text: string,
    referenceX: number,
    referenceY: number,
    charsSplit: string[],
    textHeight: number,
    maxWidth: number
  ): void {
    if (this.isPlay) {
      // start preview
      if (areaText[`isStart`]) {
        areaText[`subscription`].unsubscribe();
        this.isStartTimetable = false;
        areaText[`isStart`] = false;
        this.drawTextOrientationVertical(ctx, areaText, text);
      } else {
        // finish scroll text (direction left)
        if (areaText.scrollDirection == ScrollDirectionsEnum.LEFT && Math.floor(areaText.posXScroll) < -Math.floor(maxWidth)) {
          areaText.posXScroll = 0;
          this.handleClearSubscriptionScroll(areaText, ctx, text, OrientationEnum.VERTICAL);
        } else if (areaText.scrollDirection == ScrollDirectionsEnum.RIGHT && Math.floor(areaText.posXScroll) > Math.floor(maxWidth)) {
          // finish scroll text (direction right)
          areaText.posXScroll = 0;
          this.handleClearSubscriptionScroll(areaText, ctx, text, OrientationEnum.VERTICAL);
        } else if (areaText.scrollDirection == ScrollDirectionsEnum.UP && Math.floor(areaText.posYScroll) < -Math.floor(textHeight)) {
          // finish scroll text (direction up)
          areaText.posYScroll = 0;
          this.handleClearSubscriptionScroll(areaText, ctx, text, OrientationEnum.VERTICAL);
        } else if (areaText.scrollDirection == ScrollDirectionsEnum.DOWN && Math.floor(areaText.posYScroll) > Math.floor(textHeight)) {
          // finish scroll text (direction down)
          areaText.posYScroll = 0;
          this.handleClearSubscriptionScroll(areaText, ctx, text, OrientationEnum.VERTICAL);
        } else {
          // draw scroll text
          this.drawTextScrollVertical(ctx, areaText, charsSplit, referenceX, referenceY);
        }
      }
    }
  }

  /**
   * Clear areas
   *
   * @param areas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  public clearAreas(areas: Area[]) {
    if (!areas) {
      return;
    }
    Promise.all(
      areas.map(area => {
        this.clearCanvas(area.canvas);
        if (!area.checkTypeTextArea()) {
          return;
        }
        let textArea = area as TextArea;
        textArea.text = '';
        if (textArea.scrollStatus != ScrollStatusEnum.OFF) {
          this.resetPositionScrollText(textArea);
          area[`subscription`]?.unsubscribe();
          delete area[`subscription`];
        } else {
          area[`subscription`]?.unsubscribe();
        }
        // clear timeout stop duration for area
        this.clearTimeoutForAreaText(this.timeoutsDisplay1Timetable, textArea);
      })
    );
  }

  /**
   * draw text scroll vertical
   *
   * @param ctx
   * @param areaText
   * @param charsSplit
   * @param referenceX
   * @param referenceY
   */
  private drawTextScrollVertical(ctx, areaText, charsSplit, referenceX, referenceY): void {
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.fontColor;
    var i = 0;
    var ch: string;
    var posY = 0.5;
    while ((ch = charsSplit[i++])) {
      var chWidth = ctx.measureText(ch).width;
      ctx.fillText(ch, referenceX - chWidth / 2 + areaText.posXScroll, referenceY + posY * areaText.fontSize + areaText.posYScroll);
      posY++;
    }
    this.calcPositionScrollVertical(areaText);
  }

  /**
   * draw text orientation sideways
   *
   * @param ctx
   * @param areaText TextArea
   * @param text
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private drawTextOrientationSideways(ctx: any, areaText: TextArea, text: string): void {
    var textDraw = text ?? this.getTimer();
    const measureText = ctx.measureText(textDraw);
    let widthMeasureText = measureText.width;
    const maxWidth = measureText.actualBoundingBoxAscent + measureText.actualBoundingBoxDescent;
    const referencePosition = Helper.getReferencePositionOrientationSideways(ctx, areaText, widthMeasureText);
    const referenceX = referencePosition.referenceX;
    const referenceY = referencePosition.referenceY;
    ctx.rotate(Math.PI / 2);
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // draw color text
    ctx.fillStyle = areaText.fontColor;
    ctx.fillText(textDraw, referenceX + areaText.posYScroll, referenceY - areaText.posXScroll);
    ctx.resetTransform();
    if (
      textDraw == '' ||
      areaText.scrollStatus == ScrollStatusEnum.OFF ||
      this.canNotScrollTextVerticalAndSideways(areaText, widthMeasureText, maxWidth)
    ) {
      areaText[`subscription`]?.unsubscribe();
      return;
    }
    areaText[`isStart`] = this.isStartTimetable;
    if (areaText[`subscription`]) {
      areaText[`subscription`].unsubscribe();
    }
    let timeout = setTimeout(() => {
      var widthMeasureText = ctx.measureText(textDraw).width;
      const observable = interval(50);
      const subscription = observable
        .pipe(
          takeUntil(this.clearPreviewDisplay1Subject),
          takeUntil(this.pausePreviewDisplay1Subject),
          repeatWhen(() => this.startPreviewDisplay1Subject)
        )
        .subscribe(() => {
          this.drawScrollSidewaysText(areaText, widthMeasureText, ctx, text, referenceX, referenceY, maxWidth);
        });
      areaText[`subscription`] = subscription;
      areaText[`clearPreviewSubject`] = this.clearPreviewDisplay1Subject.subscribe(() => {
        this.resetPositionScrollText(areaText);
        subscription.unsubscribe();
        areaText[`clearPreviewSubject`].unsubscribe();
      });
    }, areaText.stopDuration * 1000);
    // push timeout to list => remove if needed
    this.timeoutsDisplay1Timetable.push(new TimeOut(timeout, areaText.linkReferenceData, areaText.id));
  }
  /**
   * Draw scroll sideways text
   *
   * @param areaText
   * @param widthMeasureText
   * @param ctx
   * @param text
   * @param referenceX
   * @param referenceY
   * @param maxWidth
   */
  private drawScrollSidewaysText(
    areaText: TextArea,
    widthMeasureText: number,
    ctx: any,
    text: string,
    referenceX: number,
    referenceY: number,
    maxWidth: number
  ): void {
    if (this.isPlay) {
      // start preview
      if (areaText[`isStart`]) {
        areaText[`subscription`].unsubscribe();
        this.isStartTimetable = false;
        areaText[`isStart`] = false;
        this.drawTextOrientationSideways(ctx, areaText, text);
      } else {
        // finish scroll text (direction left)
        if (areaText.scrollDirection == ScrollDirectionsEnum.LEFT && Math.floor(areaText.posXScroll) < -Math.floor(maxWidth)) {
          areaText.posXScroll = 0;
          this.handleClearSubscriptionScroll(areaText, ctx, text, OrientationEnum.SIDEWAYS);
        } else if (areaText.scrollDirection == ScrollDirectionsEnum.RIGHT && Math.floor(areaText.posXScroll) > Math.floor(maxWidth)) {
          // finish scroll text (direction right)
          areaText.posXScroll = 0;
          this.handleClearSubscriptionScroll(areaText, ctx, text, OrientationEnum.SIDEWAYS);
        } else if (areaText.scrollDirection == ScrollDirectionsEnum.UP && Math.floor(areaText.posYScroll) < -Math.floor(widthMeasureText)) {
          // finish scroll text (direction up)
          areaText.posYScroll = 0;
          this.handleClearSubscriptionScroll(areaText, ctx, text, OrientationEnum.SIDEWAYS);
        } else if (
          areaText.scrollDirection == ScrollDirectionsEnum.DOWN &&
          Math.floor(areaText.posYScroll) > Math.floor(widthMeasureText)
        ) {
          // finish scroll text (direction down)
          areaText.posYScroll = 0;
          this.handleClearSubscriptionScroll(areaText, ctx, text, OrientationEnum.SIDEWAYS);
        } else {
          // draw scroll text
          this.drawTextScrollSideways(ctx, areaText, text, referenceX, referenceY);
        }
      }
    }
  }

  /**
   * draw text scroll sideways
   *
   * @param ctx
   * @param areaText
   * @param text
   * @param referenceX
   * @param referenceY
   * @param widthMeasureText
   */
  private drawTextScrollSideways(ctx, areaText, text, referenceX, referenceY): void {
    const textDraw = text ?? this.getTimer();
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.rotate(Math.PI / 2);
    ctx.fillStyle = areaText.fontColor;
    ctx.fillText(textDraw, referenceX + areaText.posYScroll, referenceY - areaText.posXScroll);
    this.calcPositionScrollSideway(areaText);
    ctx.resetTransform();
  }

  /**
   * draw clock preview
   * @param area
   * @param renderer
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  private drawClock(area: Area, renderer: Renderer2) {
    let areaText = area as TextArea;
    renderer.setStyle(area.canvas, 'visibility', 'visible');
    if (areaText.scrollStatus == ScrollStatusEnum.OFF) {
      var time = setInterval(() => {
        // update timezone
        const textFill = this.getTimer();
        // draw areaText
        this.drawAreaText(areaText, textFill);
      }, 50);
      this.listAreaClockIntervals.push({ areaId: areaText.id, time: time });
    } else {
      // draw areaText
      this.drawAreaText(areaText);
    }
  }

  /**
   * reset position scroll text
   *
   * @param textArea
   */
  private resetPositionScrollText(textArea: TextArea): void {
    textArea.posXScroll = 0;
    textArea.posYScroll = 0;
    textArea.timesScroll = 0;
  }

  /**
   * get timer
   */
  private getTimer(): string {
    // update timezone
    var offsetHour = 0;
    var offsetMinute = 0;
    var setting = this.commonService.getCommonObject().setting;
    if (setting) {
      offsetHour = setting.timezone.offsetHour;
      offsetMinute = setting.timezone.offsetMinute;
    }
    return moment
      .utc()
      .add(offsetHour, 'hour')
      .add(offsetMinute, 'minute')
      .format('HH:mm');
  }

  /**
   * draw fix picture preview
   *
   * @param areaPicture PictureArea
   * @param renderer Renderer2
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum
   * @param isFinishSchedule
   * @param template
   */
  private async drawAreaFixPicture(areaPicture: PictureArea, renderer: Renderer2, template: Template, isFinishSchedule?: boolean) {
    if (areaPicture.isTimingOn && !isFinishSchedule) {
      return;
    }
    renderer.setStyle(areaPicture.canvas, 'visibility', 'visible');
    if (areaPicture.media) {
      // draw areaPicture
      this.drawAreaPicture(areaPicture, template);
    } else {
      this.playAudio(areaPicture);
    }
  }

  /**
   * create new image by url
   * @param type type of image
   * @param url url media
   * @returns new image
   */
  private createNewImage(type: string, url: string): Image {
    if (!url) {
      return null;
    }
    let media: Media = new Image();
    media.type = type;
    media.url = url;
    return media as Image;
  }

  /**
   * clear all thread draw news page
   */
  public clearAllIntervalDrawsNewsDisplay2(): void {
    this.intervalsDrawNewsDisplay2?.forEach(subscription => subscription?.unsubscribe());
    this.subscribesGetUrlPresignedDisplay2?.forEach(subscription => subscription?.unsubscribe());
  }

  public clearAllIntervalDrawsNewsDisplay1() {
    this.intervalsDrawNewsDisplay1?.forEach(subscription => subscription?.unsubscribe());
    this.subscribesGetUrlPresignedDisplay1?.forEach(subscription => subscription?.unsubscribe());
  }

  /**
   * draw area picture external content
   * @param area Area
   * @param template
   */
  public async drawAreaPictureExternalContent(area: Area, template: Template) {
    let areaPicture = area as PictureArea;
    let canvas = areaPicture.canvas;
    if (areaPicture.media) {
      // case w, h image do not exist
      if (!areaPicture.media['width'] && !areaPicture.media['height']) {
        delete areaPicture.media['width'];
        delete areaPicture.media['height'];
        let imageInfo = await Helper.getImageInformation(areaPicture.media);
        areaPicture.media['width'] = `${imageInfo.width}`;
        areaPicture.media['height'] = `${imageInfo.height}`;
      }
    }
    let mediaPosition = Helper.coverMedia(canvas, areaPicture.media, areaPicture.objectFit);
    let ctx = canvas.getContext('2d');
    if (areaPicture.media) {
      ctx.globalAlpha = 1;
      if (areaPicture.media.type != TypeMediaFileEnum.MP4) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        try {
          let img = document.createElement('img');
          let $this = this;
          let layerOfArea = template ? Helper.findLayerOfArea(areaPicture, template) : undefined;
          img.onload = function() {
            if (
              !layerOfArea ||
              !layerOfArea.isSwitchingArea ||
              !$this.switchingTiming ||
              (layerOfArea.isSwitchingArea && $this.areasDrawing?.map(area => area?.id)?.includes(areaPicture?.id))
            ) {
              ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
              if (areaPicture.objectFit == ObjectFitEnum.FILL) {
                ctx.drawImage(img, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
              } else {
                ctx.drawImage(
                  img,
                  mediaPosition.sX,
                  mediaPosition.sY,
                  mediaPosition.sWidth,
                  mediaPosition.sHeight,
                  mediaPosition.x,
                  mediaPosition.y,
                  mediaPosition.width,
                  mediaPosition.height
                );
              }
            }
          };
          img.src = areaPicture.media.url;
        } catch (error) {
          console.log('error draw 3', error);
          return true;
        }
      }
    }
  }

  /**
   * draw area picture
   *
   * @param areaPicture PictureArea object
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum
   * @param template
   */
  public async drawAreaPicture(areaPicture: PictureArea, template: Template) {
    if (areaPicture.media) {
      // case w, h image do not exist
      if (!areaPicture.media['width'] && !areaPicture.media['height']) {
        delete areaPicture.media['width'];
        delete areaPicture.media['height'];
        let imageInfo = await Helper.getImageInformation(areaPicture.media);
        areaPicture.media['width'] = `${imageInfo.width}`;
        areaPicture.media['height'] = `${imageInfo.height}`;
      }
    }
    try {
      let ctx = areaPicture.canvas.getContext('2d');
      let mediaPosition = Helper.coverMedia(areaPicture.canvas, areaPicture.media, areaPicture.objectFit);
      let img = document.createElement('img');
      let $this = this;
      let layerOfArea = template ? Helper.findLayerOfArea(areaPicture, template) : undefined;
      img.onload = function() {
        if (
          !layerOfArea ||
          !layerOfArea.isSwitchingArea ||
          !$this.switchingTiming ||
          (layerOfArea.isSwitchingArea && $this.areasDrawing?.map(area => area?.id)?.includes(areaPicture.id))
        ) {
          ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
          if (areaPicture.objectFit == ObjectFitEnum.FILL) {
            ctx.drawImage(img, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
          } else {
            ctx.drawImage(
              img,
              mediaPosition.sX,
              mediaPosition.sY,
              mediaPosition.sWidth,
              mediaPosition.sHeight,
              mediaPosition.x,
              mediaPosition.y,
              mediaPosition.width,
              mediaPosition.height
            );
          }
        }
      };
      img.src = areaPicture.media.url;
    } catch (error) {
      console.log('error draw 3', error);
    }
    this.playAudio(areaPicture);
  }

  /**
   * play audio
   *
   * @param areaPicture PictureArea
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  public async playAudio(areaPicture: PictureArea) {
    if (!areaPicture?.isFix) {
      return;
    }
    // play audio;
    if (!areaPicture.soundAPreview && areaPicture.soundA?.url) {
      areaPicture.soundAPreview = new Audio(areaPicture.soundA.url);
      areaPicture.soundAPreview.load();
    }
    if (!areaPicture.soundBPreview && areaPicture.soundB?.url) {
      areaPicture.soundBPreview = new Audio(areaPicture.soundB.url);
      areaPicture.soundBPreview.load();
    }
    if (this.isPlay) {
      areaPicture?.soundAPreview?.play();
      areaPicture?.soundBPreview?.play();
    }
    // play again
    if (areaPicture?.soundAPreview) {
      areaPicture.soundAPreview.onplay = () => {
        this.playSoundAgain(areaPicture);
      };
    }
    if (areaPicture?.soundBPreview) {
      areaPicture.soundBPreview.onplay = () => {
        this.playSoundAgain(areaPicture);
      };
    }
    // unsubscribe
    this.unsubscribedSubjectArea(areaPicture);
    areaPicture[`pausePreviewSubject`] = this.pausePreviewDisplay1Subject.subscribe(() => {
      this.pauseAudio(areaPicture);
    });
    areaPicture[`clearPreviewSubject`] = this.clearPreviewDisplay1Subject.subscribe(() => {
      this.stopAudio(areaPicture);
      this.unsubscribedSubjectArea(areaPicture);
    });
    areaPicture[`startPreviewSubject`] = this.startPreviewDisplay1Subject.subscribe(() => {
      this.playAudio(areaPicture);
    });
  }

  /**
   * pause audio
   * @param areaPicture PictureArea
   */
  private pauseAudio(areaPicture: PictureArea): void {
    if (!areaPicture?.isFix) {
      return;
    }
    // pause
    if (areaPicture.soundAPreview) {
      areaPicture.soundAPreview.pause();
    }
    if (areaPicture.soundBPreview) {
      areaPicture.soundBPreview.pause();
    }
  }

  /**
   * stop audio
   * @param areaPicture PictureArea
   */
  private stopAudio(areaPicture: PictureArea): void {
    if (!areaPicture?.isFix) {
      return;
    }
    // stop
    if (areaPicture.soundAPreview) {
      areaPicture.soundAPreview.pause();
      areaPicture.soundAPreview.currentTime = 0;
      areaPicture.soundAPreview.load();
    }
    if (areaPicture.soundBPreview) {
      areaPicture.soundBPreview.pause();
      areaPicture.soundBPreview.currentTime = 0;
      areaPicture.soundBPreview.load();
    }
  }

  /**
   * play sound
   * @param mediaSound Media
   * @param areaPicture PictureArea
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private playSound(areaPicture: PictureArea, mediaSound: Media): void {
    const url = mediaSound ? mediaSound?.url : areaPicture?.media?.url;
    if (!url) {
      return;
    }
    if (!areaPicture[`sound`]) {
      areaPicture[`sound`] = document.createElement('audio');
    }
    if (!areaPicture[`sound`]?.src) {
      areaPicture[`sound`].src = url;
    }
    if (this.isPlay) {
      areaPicture[`sound`]?.play();
    }
    // play again
    areaPicture[`sound`].onplay = () => {
      this.playSoundAgain(areaPicture);
    };
    this.unsubscribedSubjectArea(areaPicture);
    areaPicture[`pausePreviewSubject`] = this.pausePreviewDisplay1Subject.subscribe(() => {
      this.pauseSound(areaPicture);
    });
    areaPicture[`clearPreviewSubject`] = this.clearPreviewDisplay1Subject.subscribe(() => {
      this.stopSound(areaPicture);
      this.unsubscribedSubjectArea(areaPicture);
    });
    areaPicture[`startPreviewSubject}`] = this.startPreviewDisplay1Subject.subscribe(() => {
      if (mediaSound) {
        this.playSound(areaPicture, mediaSound);
      } else {
        this.playSound(areaPicture, undefined);
      }
    });
  }

  /**
   * play again when ended
   * @param areaPicture
   */
  private playSoundAgain(areaPicture: PictureArea): void {
    // sound A
    if (areaPicture?.soundAPreview && areaPicture.soundAPreview.ended) {
      areaPicture.soundAPreview.currentTime = 0;
      areaPicture.soundAPreview.play().then(() => this.playSoundAgain(areaPicture));
    }
    // sound B
    if (areaPicture?.soundBPreview && areaPicture.soundBPreview.ended) {
      areaPicture.soundBPreview.currentTime = 0;
      areaPicture.soundBPreview.play().then(() => this.playSoundAgain(areaPicture));
    }
    // audio attach image
    if (areaPicture[`sound`] && areaPicture[`sound`].ended) {
      areaPicture[`sound`].currentTime = 0;
      areaPicture[`sound`].play().then(() => this.playSoundAgain(areaPicture));
    }
    if (areaPicture[`animationId`]) {
      cancelAnimationFrame(areaPicture[`animationId`]);
    }
    areaPicture[`animationId`] = requestAnimationFrame(() => this.playSoundAgain(areaPicture));
  }

  /**
   * stop sound
   * @param areaPicture PictureArea
   */
  private stopSound(areaPicture: PictureArea): void {
    if (!areaPicture?.[`sound`]) {
      return;
    }
    if (areaPicture[`sound`].currentTime > 0) {
      areaPicture[`sound`].pause();
      areaPicture[`sound`].currentTime = 0;
    }
    delete areaPicture[`sound`];
  }

  /**
   * pause sound
   * @param areaPicture PictureArea
   */
  private pauseSound(areaPicture: PictureArea): void {
    if (!areaPicture?.[`sound`]) {
      return;
    }
    if (areaPicture[`sound`].currentTime > 0) {
      areaPicture[`sound`].pause();
    }
  }

  /**
   * draw video fix picture preview
   * @param areaPicture Area
   * @param renderer Renderer2
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum
   * @param isFinishSchedule
   */
  private async drawVideoFixPicture(areaPicture: PictureArea, renderer: Renderer2, isFinishSchedule?: boolean) {
    if (areaPicture.isTimingOn && !isFinishSchedule) {
      return;
    }
    renderer.setStyle(areaPicture.canvas, 'visibility', 'visible');
    if (areaPicture.media) {
      let ctx = areaPicture.canvas.getContext('2d');
      ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
      let mediaPos = Helper.coverMedia(areaPicture.canvas, areaPicture.media, areaPicture.objectFit);
      this.drawVideo(areaPicture, ctx, mediaPos, areaPicture.canvas);
    }
  }

  /**
   * drawVideo
   * @param areaPicture PictureArea
   * @param ctx
   * @param mediaPos any
   * @param canvas
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private async drawVideo(areaPicture: PictureArea, ctx: any, mediaPos: any, canvas: any) {
    if (!areaPicture.media) {
      return;
    }
    if (!areaPicture.videoPreview) {
      areaPicture.videoPreview = document.createElement('video');
    }
    areaPicture.videoPreview.src = '';
    if (areaPicture.videoPreview.src != areaPicture?.media?.url) {
      areaPicture.videoPreview.src = areaPicture?.media?.url ?? '';
      function drawOrigin() {
        areaPicture.videoPreview?.addEventListener(
          'loadeddata',
          function() {
            areaPicture.videoPreview.currentTime = 0;
          },
          false
        );
        areaPicture.videoPreview?.addEventListener(
          'seeked',
          function() {
            if (areaPicture.objectFit == ObjectFitEnum.FILL) {
              ctx.drawImage(areaPicture.videoPreview, mediaPos.x, mediaPos.y, mediaPos.width, mediaPos.height);
            } else {
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(
                areaPicture.videoPreview,
                mediaPos.sX,
                mediaPos.sY,
                mediaPos.sWidth,
                mediaPos.sHeight,
                mediaPos.x,
                mediaPos.y,
                mediaPos.width,
                mediaPos.height
              );
            }
          },
          false
        );
        return;
      }
      await areaPicture.videoPreview.addEventListener('timeupdate', drawOrigin, false);
      if (this.isPlay) {
        areaPicture.videoPreview.play();
      }
      areaPicture.videoPreview.onplay = () => {
        this.animationDrawVideo(ctx, areaPicture, mediaPos, canvas);
      };
    }
    this.unsubscribedSubjectArea(areaPicture);
    areaPicture[`startPreviewSubject`] = this.startPreviewDisplay1Subject.subscribe(() => areaPicture.videoPreview.play());
    areaPicture[`pausePreviewSubject`] = this.pausePreviewDisplay1Subject.subscribe(() => areaPicture.videoPreview.pause());
    areaPicture[`clearPreviewSubject`] = this.clearPreviewDisplay1Subject.subscribe(() => {
      this.pausePreviewDisplay1Subject.next();
      this.unsubscribedSubjectArea(areaPicture);
    });
  }

  /**
   * animation draw area
   * @param ctx
   * @param areaPicture
   * @param mediaPos
   * @param canvas
   */
  private animationDrawVideo(ctx: any, areaPicture: PictureArea, mediaPos: any, canvas: any): void {
    if (areaPicture.media && areaPicture.media.type != TypeMediaFileEnum.MP4) {
      this.stopVideo(areaPicture);
      return;
    }

    if (areaPicture.videoPreview?.ended) {
      areaPicture.videoPreview.currentTime = 0;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      areaPicture.videoPreview.play().then(() => this.animationDrawVideo(ctx, areaPicture, mediaPos, canvas));
    }

    if (!areaPicture?.videoPreview?.src || areaPicture?.videoPreview?.src == '') {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      return;
    }
    if (areaPicture.objectFit == ObjectFitEnum.FILL) {
      ctx.drawImage(areaPicture.videoPreview, mediaPos.x, mediaPos.y, mediaPos.width, mediaPos.height);
    } else {
      ctx.drawImage(
        areaPicture.videoPreview,
        mediaPos.sX,
        mediaPos.sY,
        mediaPos.sWidth,
        mediaPos.sHeight,
        mediaPos.x,
        mediaPos.y,
        mediaPos.width,
        mediaPos.height
      );
    }
    if (areaPicture.videoPreview?.paused || !this.isPlay) {
      areaPicture.videoPreview && areaPicture.videoPreview.pause();
    }
    if (areaPicture[`animationId`]) {
      cancelAnimationFrame(areaPicture[`animationId`]);
    }
    areaPicture[`animationId`] = requestAnimationFrame(() => this.animationDrawVideo(ctx, areaPicture, mediaPos, canvas));
  }

  /**
   * stop video
   * @param areaPicture PictureArea
   */
  private stopVideo(areaPicture: PictureArea): void {
    if (areaPicture.videoPreview) {
      let ctx = areaPicture.canvas.getContext('2d');
      ctx.clearRect(0, 0, areaPicture.width, areaPicture.height);
      cancelAnimationFrame(areaPicture[`animationId`]);
      areaPicture.videoPreview.pause();
      areaPicture.videoPreview.currentTime = 0;
      delete areaPicture.videoPreview;
    }
  }

  /**
   * clear canvas
   * @param canvasLayoutRealTime
   */
  public clearCanvas(canvasLayoutRealTime) {
    if (!canvasLayoutRealTime) {
      return;
    }
    let ctx = canvasLayoutRealTime.getContext('2d');
    ctx.clearRect(0, 0, canvasLayoutRealTime.width, canvasLayoutRealTime.height);
  }

  /**
   * clear canvas
   * @param template
   * @param canvasDisplayId
   */
  public clearCanvasAreas(template: Template) {
    if (!template) {
      return;
    }
    let areas = Helper.getAllAreaTemplate(template);
    let pictureAreas = areas
      .filter(area => !area.checkTypeTextArea() && !area.isFix && area.getArea().attribute == LinkDataPictureEnum.SIGNAGE_CHANNEL)
      .map(areaData => areaData.getArea() as PictureArea);
    pictureAreas.forEach(pictureArea => {
      if (pictureArea?.media && pictureArea.media instanceof Video) {
        this.stopVideo(pictureArea);
      }
      this.unsubscribedSubjectArea(pictureArea);
      this.clearCanvas(pictureArea?.canvas);
    });
  }

  /**
   * unsubscribed Subject in Area
   * @param area area unsubscribe
   * @param canvasDisplayId
   */
  private unsubscribedSubjectArea(area: Area): void {
    if (area[`pausePreviewSubject`]) {
      area[`pausePreviewSubject`].unsubscribe();
    }
    if (area[`clearPreviewSubject}`]) {
      area[`clearPreviewSubject`].unsubscribe();
    }
    if (area[`startPreviewSubject`]) {
      area[`startPreviewSubject`].unsubscribe();
    }
  }

  /**
   * calculate position scroll Sideway
   * @param areaText TextArea
   */
  private calcPositionScrollSideway(areaText: TextArea): void {
    switch (areaText.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        areaText.posXScroll -= areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.RIGHT:
        areaText.posXScroll += areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.UP:
        areaText.posYScroll -= areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.DOWN:
        areaText.posYScroll += areaText.scrollSpeed / 20;
        break;
      default:
        break;
    }
  }

  /**
   * calculate position scroll Vertical
   * @param areaText TextArea
   */
  private calcPositionScrollVertical(areaText: TextArea): void {
    switch (areaText.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        areaText.posXScroll -= areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.RIGHT:
        areaText.posXScroll += areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.UP:
        areaText.posYScroll -= areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.DOWN:
        areaText.posYScroll += areaText.scrollSpeed / 20;
        break;
      default:
        break;
    }
  }

  /**
   * calculate position scroll Horizontal
   * @param areaText TextArea
   */
  private calcPositionScrollHorizontal(areaText: TextArea): void {
    switch (areaText.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        areaText.posXScroll -= areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.RIGHT:
        areaText.posXScroll += areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.UP:
        areaText.posYScroll -= areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.DOWN:
        areaText.posYScroll += areaText.scrollSpeed / 20;
        break;
      default:
        break;
    }
  }

  /**
   * draw areas signage channel
   * @param screenCanvasIdEnum
   * @param renderer
   * @param canvasDisplayId
   * @param template
   */
  public drawAreasSignageChannel(template: Template, renderer: Renderer2): void {
    if (!this.mediaSetting) {
      return;
    }
    let areas = Helper.getAllAreaTemplate(template);
    let pictureAreas = areas
      .filter(area => !area.checkTypeTextArea() && !area.isFix && area.getArea().attribute == LinkDataPictureEnum.SIGNAGE_CHANNEL)
      .map(areaData => areaData.getArea() as PictureArea);
    Promise.all(
      pictureAreas.map(async pictureArea => {
        await this.drawSignageChannel(pictureArea, this.mediaSetting, renderer, template);
      })
    );
  }

  /**
   * draw signage channel
   * @param screenCanvasIdEnum
   * @param media
   * @param renderer
   * @param canvasDisplayId
   * @param template
   * @param pictureArea
   */
  public async drawSignageChannel(pictureArea: PictureArea, media: Media, renderer: Renderer2, template: Template) {
    if (media instanceof Image) {
      // draw image
      pictureArea.media = media;
      await this.drawAreaPicture(pictureArea, template);
    } else if (media instanceof Video) {
      // draw video
      pictureArea.media = media;
      await this.drawVideoFixPicture(pictureArea, renderer);
    } else if (media instanceof Sequence) {
      // Code here
    }
  }

  /**
   * set data schedule
   * @param timetableSchedule
   * @param dataResponse
   * @param pictureAreaService
   * @param areasIndexWordDisplay
   */
  public setDataPreviewTimetableEditor(
    timetableSchedule: any,
    dataResponse?: any,
    pictureAreaService?: PictureAreaService,
    areasIndexWordDisplay?: Area[]
  ): void {
    if (timetableSchedule) {
      this.timetableSchedule = timetableSchedule;
    }
    if (dataResponse) {
      this.dataResponse = dataResponse;
    }
    if (pictureAreaService) {
      this.pictureAreaService = pictureAreaService;
    }
    if (areasIndexWordDisplay) {
      this.areasIndexWordDisplay = areasIndexWordDisplay;
    }
  }
}

/**
 * Class Timeout
 */
class TimeOut {
  time: any;
  linkReferenceData: number;
  areaId: Number;
  constructor(time: any, linkReferenceData: number, areaId: Number) {
    this.time = time;
    this.linkReferenceData = linkReferenceData;
    this.areaId = areaId;
  }
}

/**
 * class MediaIndexWord
 */
class MediaIndexWord {
  /**
   * unique key for each draw area
   */
  key: any;
  /**
   * img for each draw area
   */
  img: any;

  constructor(key: any, img: any) {
    this.key = key;
    this.img = img;
  }
}
