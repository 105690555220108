import { EventEmitter, Injectable, Output, Renderer2 } from '@angular/core';
import { Helper } from 'app/common/helper';
import {
  AlignmentEnum,
  Constant,
  DestinationEnum,
  GIFFileExtensions,
  LinkDataPictureEnum,
  LinkDataTextEnum,
  ObjectFitEnum,
  OnClickEventTypeEnum,
  OrientationEnum,
  ScrollDirectionsEnum,
  ScrollStatusEnum,
  TemplateTypeEnum,
  TypeMediaFileEnum
} from 'app/config/constants';
import { Area } from 'app/model/entity/area';
import { BusStop } from 'app/model/entity/bus-stop';
import { PictureArea } from 'app/model/entity/picture-area';
import { Route } from 'app/model/entity/route.js';
import { StationCell } from 'app/model/entity/station-cell';
import { Template } from 'app/model/entity/template';
import { TextArea } from 'app/model/entity/text-area';
import moment from 'moment';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class StationDrawService {
  /**
   * list interval scroll display 1
   */
  intervalScrollListForDisplay1: Array<ScrollInterval> = new Array<ScrollInterval>();
  /**
   * list interval scroll display 2
   */
  intervalScrollListForDisplay2: Array<ScrollInterval> = new Array<ScrollInterval>();
  /**
   * route
   */
  route: Route;
  /**
   * bus stop
   */
  busStop: BusStop;
  /**
   * list animation
   */
  animationList: Array<any> = new Array<any>();
  /**
   * toSwitchBetweenPage
   */
  @Output() toSwitchBetweenPage = new EventEmitter<any>();

  /**
   * onClickCanvas
   */
  @Output() onClickCanvas = new EventEmitter<any>();
  /**
   * true if preview on
   */
  isPlay: boolean;

  /**
   * set up preview
   * @param busStop BusStop object
   * @param route Route object
   */
  setupPreview(busStop?: BusStop, route?: Route): void {
    this.route = route;
    this.busStop = busStop;
  }
  /**
   * change state play/pause
   * @param isPlayOn
   */
  changeStatePlayPause(isPlayOn: boolean): void {
    this.isPlay = isPlayOn;
  }

  constructor(private commonService: CommonService) {}

  /**
   * draw preview station
   * @param display template
   * @param renderer
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  public drawPreviewStation(display: Template, renderer: Renderer2, canvasDisplayId: any): void {
    if (display) {
      var areas = Helper.getAllAreaTemplate(display);
      Promise.all(
        areas.map(async area => {
          // case area instanceof TextArea
          if (area.checkTypeTextArea()) {
            var textArea = area as TextArea;
            if (textArea.isFix) {
              // draw fix text
              this.drawAreaFixText(textArea, renderer, canvasDisplayId);
              // draw link text
            } else {
              switch (textArea.linkReferenceData) {
                case LinkDataTextEnum.CLOCK:
                  // draw link text if linkReferenceData is Clock
                  this.drawClock(textArea, renderer, canvasDisplayId);
                  break;
                case LinkDataTextEnum.BUS_STOP_NO:
                  // draw link text if linkReferenceData is Bus Stop No
                  this.drawBusStop(textArea, this.busStop.no, renderer, canvasDisplayId);
                  break;
                case LinkDataTextEnum.BUS_STOP_NAME:
                  // draw link text if linkReferenceData is Bus Stop Name
                  this.drawBusStop(textArea, this.busStop.name, renderer, canvasDisplayId);
                  break;
                case LinkDataTextEnum.ROUTE_NO:
                  // draw link text if linkReferenceData is Route No
                  if (this.busStop.stationCells?.length > 0) {
                    this.drawRoute(textArea, true, renderer, canvasDisplayId);
                  }
                  break;
                case LinkDataTextEnum.ROUTE_NAME:
                  // draw link text if linkReferenceData is Route Name
                  if (this.busStop.stationCells?.length > 0) {
                    this.drawRoute(textArea, false, renderer, canvasDisplayId);
                  }
                  break;
                case LinkDataTextEnum.ARRIVAL_TIME:
                  // draw link text if linkReferenceData is arrival time
                  break;
                case LinkDataTextEnum.NEXT_ARRIVAL_TIME:
                  // draw link text if linkReferenceData is next arrival time
                  break;
                case LinkDataTextEnum.INDEX_WORD:
                  // draw link text if linkReferenceData is IndexWord
                  if (this.busStop) {
                    this.drawTextIndexWord(textArea, this.busStop, renderer, canvasDisplayId);
                  }
                  break;
                case LinkDataTextEnum.EXTERNAL_SOURCE:
                  // draw link text if linkReferenceData is external source
                  // To do
                  break;
                default:
                  break;
              }
            }
            // case area instanceof Picture
          } else {
            var pictureArea = area.getArea() as PictureArea;
            if (pictureArea.isFix) {
              if (pictureArea.media) {
                if (pictureArea.media.type != TypeMediaFileEnum.MP4) {
                  // draw fix picture
                  await this.drawAreaFixPicture(pictureArea, renderer, canvasDisplayId);
                } else {
                  await this.drawVideoFixPicture(pictureArea, renderer);
                }
              }
              // draw link picture
            } else {
              switch (pictureArea.attribute) {
                case LinkDataPictureEnum.INDEX_WORD:
                  // draw link picture if attribute is IndexWord
                  await this.drawPictureIndexWord(area, this.busStop, renderer, canvasDisplayId);
                  break;
                case LinkDataPictureEnum.EXTERNAL_SOURCE:
                  // draw link picture if attribute is External Source
                  // To do
                  break;
                case LinkDataPictureEnum.SIGNAGE_CHANNEL:
                  // draw link picture if attribute is signage channel
                  // To do
                  break;
                default:
                  break;
              }
            }
          }
        })
      );
    }
  }

  /**
   * draw cell
   * @param areas list area (link picture and area.attribute = refer to selected no)
   * @param renderer Renderer2
   * @param cell StationCell object
   * @param canvasDisplayId
   */
  public drawCell(areas: Array<Area>, renderer: Renderer2, cell: StationCell, canvasDisplayId: any): void {
    if (!cell) {
      return;
    }
    if (areas) {
      Promise.all(
        areas.map(async area => {
          let pictureArea = area.getArea() as PictureArea;
          this.clearCanvas(pictureArea.canvas);
          await this.drawAreaReferToSelectedNo(pictureArea, cell, renderer, canvasDisplayId);
        })
      );
    }
  }

  /**
   * draw fix text preview
   * @param area Area
   * @param renderer Renderer2
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private async drawAreaFixText(area: Area, renderer: Renderer2, canvasDisplayId) {
    let areaText = area as TextArea;
    renderer.setStyle(area.canvas, 'visibility', 'visible');
    let text = areaText.text !== '' ? areaText.text : ' ';
    // draw areaText
    this.drawAreaText(areaText, text, canvasDisplayId);
  }

  /**
   * draw info Route preview
   * @param area Area
   * @param isDrawRouteNo true if draw route no
   * @param renderer Renderer2
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private async drawRoute(area: Area, isDrawRouteNo: boolean, renderer: Renderer2, canvasDisplayId) {
    let areaText = area as TextArea;
    let text = '';
    let stationCells: StationCell[] = this.busStop.stationCells.filter(cell => cell.no);
    text = isDrawRouteNo
      ? stationCells[areaText.referencePositionRow]?.route?.routeNo ?? ''
      : stationCells[areaText.referencePositionRow]?.route?.name ?? '';
    renderer.setStyle(area.canvas, 'visibility', 'visible');
    // draw areaText
    this.drawAreaText(areaText, text, canvasDisplayId);
  }

  /**
   * draw clock preview
   * @param area Area
   * @param renderer Renderer2
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private async drawClock(area: Area, renderer: Renderer2, canvasDisplayId) {
    let areaText = area as TextArea;
    renderer.setStyle(area.canvas, 'visibility', 'visible');
    setInterval(() => {
      // update timezone
      let offsetHour = 0;
      let offsetMinute = 0;
      let setting = this.commonService.getCommonObject().setting;
      if (setting) {
        offsetHour = setting.timezone.offsetHour;
        offsetMinute = setting.timezone.offsetMinute;
      }
      let text = moment
        .utc()
        .add(offsetHour, 'hour')
        .add(offsetMinute, 'minute')
        .format('HH:mm');
      // draw areaText
      this.drawAreaText(areaText, text, canvasDisplayId);
    }, 50);
  }

  /**
   * draw info BusStop preview
   * @param area Area
   * @param text value to draw (busStopName or busStopNo)
   * @param renderer Renderer2
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private async drawBusStop(area: Area, text: string, renderer: Renderer2, canvasDisplayId) {
    let areaText = area as TextArea;
    renderer.setStyle(area.canvas, 'visibility', 'visible');
    // draw areaText
    this.drawAreaText(areaText, text, canvasDisplayId);
  }

  /**
   * draw area text
   * @param areaText TextArea
   * @param text
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private drawAreaText(areaText: TextArea, text: string, canvasDisplayId) {
    areaText.posXScroll = 0;
    areaText.posYScroll = 0;
    areaText.timesScroll = 0;
    let textFill: string = '';
    if (areaText.text.includes('@')) {
      let stringReplace = areaText.text.replace(/@/g, text);
      textFill = stringReplace;
    } else {
      textFill = text;
    }
    function scrollFunction(object: any) {
      let ctx = areaText.canvas.getContext('2d');
      ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
      // draw color background
      ctx.fillStyle = areaText.backgroundColor;
      ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);

      // set style bold / italic / normal
      let textStyle = 'normal';
      if (areaText.isBold && !areaText.isItalic) {
        textStyle = Constant.BOLD_STYLE;
      } else if (areaText.isItalic && !areaText.isBold) {
        textStyle = Constant.ITALIC_STYLE;
      } else if (areaText.isItalic && areaText.isBold) {
        textStyle = Constant.BOLD_STYLE + ' ' + Constant.ITALIC_STYLE;
      }

      // draw color text
      ctx.fillStyle = areaText.fontColor;

      // draw font text
      ctx.font = textStyle + ' ' + areaText.fontSize + 'px ' + (typeof areaText.fontName == 'undefined' ? 'Arial' : areaText.fontName);

      // set orientation
      object.setOrientation(areaText, ctx, areaText.canvas, text);

      var widthMeasureText = ctx.measureText(textFill).width;
      if (areaText.scrollStatus == ScrollStatusEnum.ON) {
        object.resetPositionScroll(areaText, widthMeasureText, textFill);
      }
    }
    if (areaText.scrollStatus == ScrollStatusEnum.OFF) {
      scrollFunction(this);
    } else {
      scrollFunction(this);
      var time = setInterval(() => {
        if (this.isPlay) {
          scrollFunction(this);
        }
      }, 50);
      if (canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID) {
        this.intervalScrollListForDisplay1.push(new ScrollInterval(time, areaText.id));
      } else {
        this.intervalScrollListForDisplay2.push(new ScrollInterval(time, areaText.id));
      }
    }
  }

  /**
   * draw fix picture preview
   * @param area Area
   * @param renderer Renderer2
   * @param canvasDisplayId
   */
  private async drawAreaFixPicture(area: Area, renderer: Renderer2, canvasDisplayId: any): Promise<void> {
    let areaPicture = area as PictureArea;
    renderer.setStyle(areaPicture.canvas, 'visibility', 'visible');
    if (areaPicture.media) {
      // draw areaPicture
      this.drawAreaPicture(areaPicture, canvasDisplayId);
    }
  }

  /**
   * draw video fix picture preview
   * @param area Area
   * @param renderer Renderer2
   */
  private async drawVideoFixPicture(area: Area, renderer: Renderer2) {
    let areaPicture = area as PictureArea;
    renderer.setStyle(area.canvas, 'visibility', 'visible');
    if (areaPicture.media) {
      let ctx = areaPicture.canvas.getContext('2d');
      ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
      let mediaPos = Helper.coverMedia(areaPicture.canvas, areaPicture.media, areaPicture.objectFit);
      this.drawVideo(areaPicture, ctx, mediaPos, areaPicture.canvas);
    }
  }

  /**
   * draw indexWord - text area
   * @param area PictureArea
   * @param busStop BusStop object
   * @param renderer Renderer2
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private async drawTextIndexWord(area: Area, busStop: BusStop, renderer: Renderer2, canvasDisplayId) {
    let areaText = area as TextArea;
    renderer.setStyle(area.canvas, 'visibility', 'visible');
    let text = '';
    if (busStop.indexWords) {
      if (!busStop.indexWords[areaText.referencePositionColumn]) {
        text = ' ';
      } else {
        var indexWordPreview = busStop.indexWords.filter(indexWord => indexWord.groupId == area.indexWordGroupId);
        text = indexWordPreview[areaText.referencePositionColumn]?.text ?? ' ';
      }
    }
    // draw areaText
    this.drawAreaText(areaText, text, canvasDisplayId);
  }

  /**
   * draw area refer to selected no
   * @param area PictureArea
   * @param cell StationCell object
   * @param renderer Renderer2
   * @param canvasDisplayId
   */
  public async drawAreaReferToSelectedNo(area: Area, cell: StationCell, renderer: Renderer2, canvasDisplayId: any): Promise<void> {
    let areaPicture = area as PictureArea;
    renderer.setStyle(area.canvas, 'visibility', 'visible');
    areaPicture.media = cell?.media ?? undefined;
    if (areaPicture.media) {
      // draw areaPicture
      this.drawAreaPicture(areaPicture, canvasDisplayId);
    } else {
      this.clearCanvas(areaPicture.canvas);
      renderer.setStyle(area.canvas, 'visibility', 'hidden');
    }
  }

  /**
   * draw area picture
   * @param areaPicture PictureArea object
   * @param canvasDisplayId
   */
  private async drawAreaPicture(areaPicture: PictureArea, canvasDisplayId: any): Promise<void> {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/octet-stream');
    myHeaders.append('Accept', '*/*');
    myHeaders.append('Access-Control-Allow-Origin', '*');
    myHeaders.append('Access-Control-Allow-Headers', '*');
    const myRequest = new Request(areaPicture.media.url, {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin'
    });
    await fetch(myRequest)
      .then(response => response.blob())
      .then(blob => createImageBitmap(blob))
      .then(bitmap => {
        areaPicture.canvas._img = bitmap;
      });
    // draw canvas
    let ctx = areaPicture.canvas.getContext('2d');
    ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);

    let pattern = ctx.createPattern(areaPicture.canvas._img, 'no-repeat');
    ctx.fillStyle = pattern;
    let mediaPosition = Helper.coverMedia(areaPicture.canvas, areaPicture.media, areaPicture.objectFit);
    if (areaPicture.objectFit == ObjectFitEnum.FILL) {
      ctx.drawImage(areaPicture.canvas._img, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
    } else {
      ctx.drawImage(
        areaPicture.canvas._img,
        mediaPosition.sX,
        mediaPosition.sY,
        mediaPosition.sWidth,
        mediaPosition.sHeight,
        mediaPosition.x,
        mediaPosition.y,
        mediaPosition.width,
        mediaPosition.height
      );
    }
  }

  /**
   * draw index word preview(LinkPicture)
   * @param area area
   * @param busStop busStop
   * @param renderer Renderer2
   * @param canvasDisplayId
   */
  private async drawPictureIndexWord(area: Area, busStop: BusStop, renderer: Renderer2, canvasDisplayId: any): Promise<void> {
    let areaPicture = area as PictureArea;
    renderer.setStyle(area.canvas, 'visibility', 'visible');
    if (busStop.indexWords) {
      var mediaIndexWord = null;
      if (!busStop.indexWords[areaPicture.referencePositionColumn]) {
        mediaIndexWord = null;
      } else {
        var linkPicturePreview = busStop.indexWords.filter(indexWord => indexWord.groupId == area.indexWordGroupId);
        if (linkPicturePreview.length != 0) {
          mediaIndexWord = linkPicturePreview[areaPicture.referencePositionColumn]?.media ?? null;
        }
      }
      areaPicture.media = mediaIndexWord;
      if (areaPicture.media) {
        // draw areaPicture
        this.drawAreaPicture(areaPicture, canvasDisplayId);
      }
    } else {
      let ctx = areaPicture.canvas.getContext('2d');
      ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
    }
  }

  /**
   * drawVideo
   * @param areaPicture PictureArea
   * @param ctx
   * @param mediaPos any
   * @param canvas
   * @param isPlay true if play video
   */
  public drawVideo(areaPicture: PictureArea, ctx, mediaPos: any, canvas): void {
    if (!areaPicture.videoPreview) {
      areaPicture.videoPreview = document.createElement('video');
    }
    if (!areaPicture.media) {
      return;
    }
    areaPicture.videoPreview.src = areaPicture.media.url;
    areaPicture.videoPreview?.addEventListener(
      'loadeddata',
      function() {
        areaPicture.videoPreview.currentTime = 0;
      },
      false
    );
    areaPicture.videoPreview?.addEventListener(
      'seeked',
      function() {
        if (areaPicture.objectFit == ObjectFitEnum.FILL) {
          ctx.drawImage(areaPicture.videoPreview, mediaPos.x, mediaPos.y, mediaPos.width, mediaPos.height);
        } else {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(
            areaPicture.videoPreview,
            mediaPos.sX,
            mediaPos.sY,
            mediaPos.sWidth,
            mediaPos.sHeight,
            mediaPos.x,
            mediaPos.y,
            mediaPos.width,
            mediaPos.height
          );
        }
      },
      false
    );
    return;
  }

  /**
   * set orientation
   * @param areaText TextArea
   * @param ctx
   * @param canvas
   * @param text
   */
  private setOrientation(areaText: TextArea, ctx: any, canvas, text: string): void {
    // set orientation
    if (areaText.orientation == OrientationEnum.HORIZONTAL) {
      const referencePosition = Helper.getReferencePositionOrientationHorizontal(ctx, areaText);
      const referenceX = referencePosition.referenceX;
      const referenceY = referencePosition.referenceY;
      ctx.fillText(text, referenceX + areaText.posXScroll, referenceY + areaText.posYScroll);
    } else if (areaText.orientation == OrientationEnum.VERTICAL) {
      var charsSplit = text.split('');
      let metrics = ctx.measureText(text);
      let firefox = navigator.userAgent.search('Firefox');
      let characterHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
      if (firefox > -1) {
        characterHeight = areaText.fontSize;
      }
      let textHeight = characterHeight * charsSplit.length;
      const referencePosition = Helper.getReferencePositionOrientationVertical(ctx, areaText, textHeight);
      let referenceX = referencePosition.referenceX;
      let referenceY = referencePosition.referenceY;
      ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
      ctx.fillStyle = areaText.backgroundColor;
      ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
      // draw color text
      ctx.fillStyle = areaText.fontColor;
      var i = 0;
      var ch: string;
      var posY = 0.5;
      while ((ch = charsSplit[i++])) {
        var chWidth = ctx.measureText(ch).width;
        ctx.fillText(ch, referenceX - chWidth / 2 + areaText.posXScroll, referenceY + posY * characterHeight + areaText.posYScroll);
        posY++;
      }
    } else if (areaText.orientation == OrientationEnum.SIDEWAYS) {
      let widthMeasureText = ctx.measureText(text).width;
      const referencePosition = Helper.getReferencePositionOrientationSideways(ctx, areaText, widthMeasureText);
      ctx.rotate(Math.PI / 2);
      ctx.fillText(text, referencePosition.referenceX + areaText.posYScroll, referencePosition.referenceY - areaText.posXScroll);
      ctx.resetTransform();
    }
  }

  /**
   * Reset position scroll
   * @param areaText TextArea
   * @param widthMeasureText width Measure Text
   * @param str value
   */
  private resetPositionScroll(areaText: TextArea, widthMeasureText, str): void {
    switch (areaText.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        areaText.posXScroll -= areaText.scrollSpeed / 20;
        this.scrollLeft(areaText, widthMeasureText);
        break;
      case ScrollDirectionsEnum.RIGHT:
        areaText.posXScroll += areaText.scrollSpeed / 20;
        this.scrollRight(areaText, widthMeasureText);
        break;
      case ScrollDirectionsEnum.UP:
        areaText.posYScroll -= areaText.scrollSpeed / 20;
        this.scrollUp(areaText);
        break;
      case ScrollDirectionsEnum.DOWN:
        areaText.posYScroll += areaText.scrollSpeed / 20;
        this.scrollDown(areaText);
        break;
      default:
        break;
    }
  }

  /**
   * play audio
   * @param areaPicture PictureArea
   */
  public async playAudio(areaPicture: PictureArea) {
    if (!areaPicture || (areaPicture && !areaPicture.isFix)) {
      return;
    }

    if (!areaPicture.soundAPreview && areaPicture.soundA?.url) {
      areaPicture.soundAPreview = new Audio(areaPicture.soundA.url);
      areaPicture.soundAPreview.load();
    }
    if (!areaPicture.soundBPreview && areaPicture.soundB?.url) {
      areaPicture.soundBPreview = new Audio(areaPicture.soundB.url);
      areaPicture.soundBPreview.load();
    }
    if (areaPicture?.soundAPreview?.paused) {
      await areaPicture.soundAPreview.play();
    }
    if (areaPicture?.soundBPreview?.paused) {
      await areaPicture.soundBPreview.play();
    }
  }

  /**
   * pause video
   * @param areaPicture PictureArea object
   */
  public pauseVideo(areaPicture: PictureArea): void {
    if (areaPicture.media && areaPicture.media.type == TypeMediaFileEnum.MP4 && areaPicture.videoPreview) {
      areaPicture.videoPreview.pause();
    }
  }

  /**
   * stop audio
   * @param areaPicture PictureArea
   */
  public stopAudio(areaPicture: PictureArea): void {
    if (areaPicture && areaPicture.isFix) {
      if (areaPicture.soundAPreview) {
        areaPicture.soundAPreview.pause();
        areaPicture.soundAPreview.currentTime = 0;
      }
      if (areaPicture.soundBPreview) {
        areaPicture.soundBPreview.pause();
        areaPicture.soundBPreview.currentTime = 0;
      }
    }
  }

  /**
   * play all sound when preview on display 1
   * @param busStop
   * @param destinationDisplay DestinationEnum
   */
  public playAllVideoSoundDisplay1(busStop: BusStop, destinationDisplay: DestinationEnum): void {
    if (!busStop) {
      return;
    }
    if (busStop?.display1Templates) {
      this.playAllVideoSound(busStop.display1Templates[destinationDisplay]);
    }
  }

  /**
   * play all sound when preview on display 2
   * @param busStop
   * @param destinationDisplay DestinationEnum
   */
  public playAllVideoSoundDisplay2(busStop: BusStop, destinationDisplay: DestinationEnum): void {
    if (!busStop) {
      return;
    }
    if (busStop?.display2Templates) {
      this.playAllVideoSound(busStop.display2Templates[destinationDisplay]);
    }
  }

  /**
   * play all video and sound
   * @param display Template
   */
  playAllVideoSound(display: Template): void {
    if (display) {
      let areas = Helper.getAllAreaTemplate(display).filter(area => !area.checkTypeTextArea());
      areas.forEach(area => {
        let areaPicture = area as PictureArea;
        // play video
        if (areaPicture.videoPreview) {
          if (areaPicture.media?.type == TypeMediaFileEnum.MP4) {
            this.playVideoCanvas(areaPicture);
          }
        }
        if (area.isFix) {
          // play sound
          if (areaPicture.soundA) {
            this.playAudio(areaPicture);
          }
          if (areaPicture.soundB) {
            this.playAudio(areaPicture);
          }
        }
      });
    }
  }

  /**
   * play all sound when preview on display 1
   * @param busStop
   * @param destinationDisplay DestinationEnum
   */
  public stopAllVideoSoundDisplay1(busStop: BusStop, destinationDisplay: DestinationEnum): void {
    if (!busStop) {
      return;
    }
    if (busStop?.display1Templates) {
      this.stopAllVideoSound(busStop.display1Templates[destinationDisplay]);
    }
  }

  /**
   * play all sound when preview on display 2
   * @param busStop
   * @param destinationDisplay DestinationEnum
   */
  public stopAllVideoSoundDisplay2(busStop: BusStop, destinationDisplay: DestinationEnum): void {
    if (!busStop) {
      return;
    }
    if (busStop?.display2Templates) {
      this.stopAllVideoSound(busStop.display2Templates[destinationDisplay]);
    }
  }

  /**
   * stop all video, sound when preview on display
   * @param display Template object
   */
  public stopAllVideoSound(display: Template): void {
    if (display) {
      let areas = Helper.getAllAreaTemplate(display).filter(area => !area.checkTypeTextArea());
      areas.forEach(area => {
        let areaPicture = area as PictureArea;
        // pause video
        if (areaPicture.videoPreview) {
          this.pauseVideo(areaPicture);
          areaPicture.videoPreview.currentTime = 0;
        }
        if (area.isFix) {
          // stop audio
          if (areaPicture.soundAPreview) {
            this.stopAudio(areaPicture);
          }
          if (areaPicture.soundBPreview) {
            this.stopAudio(areaPicture);
          }
        }
      });
    }
  }

  /**
   * pause all sound when preview on display 1
   * @param busStop
   * @param destinationDisplay DestinationEnum
   */
  public pauseAllVideoSoundDisplay1(busStop: BusStop, destinationDisplay: DestinationEnum): void {
    if (!busStop) {
      return;
    }
    if (busStop?.display1Templates) {
      this.pauseAllVideoSound(busStop.display1Templates[destinationDisplay]);
    }
  }

  /**
   * pause all sound when preview on display 2
   * @param busStop
   * @param destinationDisplay DestinationEnum
   */
  public pauseAllVideoSoundDisplay2(busStop: BusStop, destinationDisplay: DestinationEnum) {
    if (!busStop) {
      return;
    }
    if (busStop?.display2Templates) {
      this.pauseAllVideoSound(busStop.display2Templates[destinationDisplay]);
    }
  }

  /**
   * pause all video, sound when preview on display
   * @param display Template object
   */
  public pauseAllVideoSound(display: Template): void {
    if (display) {
      let areas = Helper.getAllAreaTemplate(display).filter(area => !area.checkTypeTextArea());
      areas.forEach(area => {
        let areaPicture = area as PictureArea;
        // pause video
        if (areaPicture.videoPreview) {
          this.pauseVideo(areaPicture);
        }
        if (areaPicture && areaPicture.isFix) {
          if (areaPicture.soundAPreview) {
            areaPicture.soundAPreview.pause();
          }
          if (areaPicture.soundBPreview) {
            areaPicture.soundBPreview.pause();
          }
        }
      });
    }
  }

  /**
   * play video
   * @param areaPicture PictureArea object
   */
  public playVideoCanvas(areaPicture: PictureArea): void {
    let ctx = areaPicture.canvas.getContext('2d');
    ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
    let mediaPos = Helper.coverMedia(areaPicture.canvas, areaPicture.media, areaPicture.objectFit);
    areaPicture.videoPreview?.play().then(animation);
    function animation() {
      if (areaPicture.videoPreview?.paused || areaPicture.videoPreview?.ended) {
        return;
      }
      if (areaPicture.objectFit == ObjectFitEnum.FILL) {
        ctx.drawImage(areaPicture.videoPreview, mediaPos.x, mediaPos.y, mediaPos.width, mediaPos.height);
      } else {
        ctx.drawImage(
          areaPicture.videoPreview,
          mediaPos.sX,
          mediaPos.sY,
          mediaPos.sWidth,
          mediaPos.sHeight,
          mediaPos.x,
          mediaPos.y,
          mediaPos.width,
          mediaPos.height
        );
      }
      requestAnimationFrame(animation);
    }
  }

  /**
   * clear interval scroll list for display 1
   */
  public clearIntervalScrollListForDisplay1(): void {
    if (this.intervalScrollListForDisplay1.length > 0) {
      this.intervalScrollListForDisplay1.forEach(intervalScroll => {
        clearInterval(intervalScroll.time);
      });
    }
    this.intervalScrollListForDisplay1 = [];
  }

  /**
   * clear interval scroll list for display 2
   */
  public clearIntervalScrollListForDisplay2(): void {
    if (this.intervalScrollListForDisplay2.length > 0) {
      this.intervalScrollListForDisplay2.forEach(intervalScroll => {
        clearInterval(intervalScroll.time);
      });
    }
    this.intervalScrollListForDisplay2 = [];
  }

  /**
   * create canvas template
   * @param template template
   * @param canvasContainerDisplay ElementRef
   */
  public createCanvasTemplate(template: Template, canvasContainerDisplay: any, renderer: Renderer2): void {
    const canvas = renderer.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.background = '#000';
    canvas.style.width = template.width + 'px';
    canvas.style.height = template.height + 'px';
    canvas.width = template.width;
    canvas.height = template.height;
    renderer.appendChild(canvasContainerDisplay.nativeElement, canvas);
  }

  /**
   * create all canvas area template
   * @param template Template
   * @param canvasContainerDisplay ElementRef
   */
  public createAllCanvasAreaTemplate(template: Template, canvasContainerDisplay: any, renderer: Renderer2): void {
    template?.layers &&
      template.layers.forEach(layer => {
        layer.areas.forEach(area => {
          this.createCanvasArea(area, canvasContainerDisplay, renderer);
        });
      });
  }

  /**
   * create canvas area
   * @param area Area
   * @param canvasContainerDisplay ElementRef
   * @param renderer Renderer2
   */
  public createCanvasArea(area: Area, canvasContainerDisplay: any, renderer: Renderer2): void {
    if (
      area.getArea().attribute == LinkDataPictureEnum.EMERGENCY_MESSAGE ||
      area.getArea().linkReferenceData == LinkDataTextEnum.EMERGENCY_MESSAGE
    ) {
      return;
    }
    const canvas = renderer.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.zIndex = area.index;
    canvas.style.left = area.posX + 'px';
    canvas.style.top = area.posY + 'px';
    canvas.style.width = area.width + 'px';
    canvas.style.height = area.height + 'px';
    canvas.width = area.width;
    canvas.height = area.height;
    // canvas.style.visibility = area.isHidden ? 'hidden' : 'visible';
    if (area.isOnClickEvent) {
      canvas.style.cursor = 'pointer';
      let $this = this;
      canvas.addEventListener('click', function() {
        if (area.onClickEventType == OnClickEventTypeEnum.SHOW_CONNECTED_MEDIA) {
          if (!$this.isPlay) {
            return;
          }
          if (
            area.getArea().linkReferenceData == LinkDataTextEnum.ROUTE_NO ||
            area.getArea().linkReferenceData == LinkDataTextEnum.ROUTE_NAME
          ) {
            let stationCells: StationCell[] = $this.busStop?.stationCells.filter(cell => cell.no);
            let cell = stationCells ? stationCells[area.referencePositionRow] : undefined;
            if (cell) {
              if (canvasContainerDisplay.nativeElement.id == Constant.CANVAS_DISPLAY_1_ID) {
                $this.selectAreaOnCanvas(area, cell, $this.busStop.display1Templates, $this, renderer, Constant.CANVAS_DISPLAY_1_ID);
                $this.onClickCanvas.emit(Constant.CANVAS_DISPLAY_1_ID);
              } else {
                $this.selectAreaOnCanvas(area, cell, $this.busStop.display2Templates, $this, renderer, Constant.CANVAS_DISPLAY_2_ID);
                $this.onClickCanvas.emit(Constant.CANVAS_DISPLAY_2_ID);
              }
            }
          }
        } else {
          if (
            canvasContainerDisplay.nativeElement.id == Constant.CANVAS_DISPLAY_1_ID &&
            $this.busStop.display1Templates[area.onClickEventDestination]
          ) {
            $this.toSwitchBetweenPage.emit([Constant.CANVAS_DISPLAY_1_ID, area.onClickEventDestination]);
          }
          if (
            canvasContainerDisplay.nativeElement.id == Constant.CANVAS_DISPLAY_2_ID &&
            $this.busStop.display2Templates[area.onClickEventDestination]
          ) {
            $this.toSwitchBetweenPage.emit([Constant.CANVAS_DISPLAY_2_ID, area.onClickEventDestination]);
          }
        }
      });
    }
    renderer.appendChild(canvasContainerDisplay.nativeElement, canvas);
    area.canvas = canvas;
  }

  /**
   * check area belong to template
   * @param areaCheck Area
   * @param template Template
   */
  private checkAreaBelongToTemplate(areaCheck: Area, template: Template): number {
    let areasInTemplate = Helper.getAllAreaTemplate(template);
    return areasInTemplate.findIndex(area => area.id == areaCheck.id);
  }

  /**
   * select area on canvas
   * @param areaClicked Area
   * @param cell StationCell object
   * @param displayTemplates List Template
   * @param $this any
   * @param renderer Renderer2
   * @param canvasDisplayId
   */
  private selectAreaOnCanvas(
    areaClicked: Area,
    cell: StationCell,
    displayTemplates: Template[],
    $this: any,
    renderer: Renderer2,
    canvasDisplayId: any
  ): void {
    let areasDisplay: Area[] = [];
    if (
      displayTemplates[TemplateTypeEnum.MAIN] &&
      $this.checkAreaBelongToTemplate(areaClicked, displayTemplates[TemplateTypeEnum.MAIN]) != -1
    ) {
      areasDisplay = $this.getAreaPicturesReferToSelectedNo(displayTemplates[TemplateTypeEnum.MAIN]);
    } else if (
      displayTemplates[TemplateTypeEnum.SUB_PAGE_1] &&
      $this.checkAreaBelongToTemplate(areaClicked, displayTemplates[TemplateTypeEnum.SUB_PAGE_1]) != -1
    ) {
      areasDisplay = $this.getAreaPicturesReferToSelectedNo(displayTemplates[TemplateTypeEnum.SUB_PAGE_1]);
    } else if (
      displayTemplates[TemplateTypeEnum.SUB_PAGE_2] &&
      $this.checkAreaBelongToTemplate(areaClicked, displayTemplates[TemplateTypeEnum.SUB_PAGE_2]) != -1
    ) {
      areasDisplay = $this.getAreaPicturesReferToSelectedNo(displayTemplates[TemplateTypeEnum.SUB_PAGE_2]);
    } else if (
      displayTemplates[TemplateTypeEnum.SUB_PAGE_3] &&
      $this.checkAreaBelongToTemplate(areaClicked, displayTemplates[TemplateTypeEnum.SUB_PAGE_3]) != -1
    ) {
      areasDisplay = $this.getAreaPicturesReferToSelectedNo(displayTemplates[TemplateTypeEnum.SUB_PAGE_3]);
    } else if (
      displayTemplates[TemplateTypeEnum.SUB_PAGE_4] &&
      $this.checkAreaBelongToTemplate(areaClicked, displayTemplates[TemplateTypeEnum.SUB_PAGE_4]) != -1
    ) {
      areasDisplay = $this.getAreaPicturesReferToSelectedNo(displayTemplates[TemplateTypeEnum.SUB_PAGE_4]);
    } else if (
      displayTemplates[TemplateTypeEnum.SUB_PAGE_5] &&
      $this.checkAreaBelongToTemplate(areaClicked, displayTemplates[TemplateTypeEnum.SUB_PAGE_5]) != -1
    ) {
      areasDisplay = $this.getAreaPicturesReferToSelectedNo(displayTemplates[TemplateTypeEnum.SUB_PAGE_5]);
    } else if (
      displayTemplates[TemplateTypeEnum.SUB_PAGE_6] &&
      $this.checkAreaBelongToTemplate(areaClicked, displayTemplates[TemplateTypeEnum.SUB_PAGE_6]) != -1
    ) {
      areasDisplay = $this.getAreaPicturesReferToSelectedNo(displayTemplates[TemplateTypeEnum.SUB_PAGE_6]);
    } else if (
      displayTemplates[TemplateTypeEnum.SUB_PAGE_7] &&
      $this.checkAreaBelongToTemplate(areaClicked, displayTemplates[TemplateTypeEnum.SUB_PAGE_7]) != -1
    ) {
      areasDisplay = $this.getAreaPicturesReferToSelectedNo(displayTemplates[TemplateTypeEnum.SUB_PAGE_7]);
    } else if (
      displayTemplates[TemplateTypeEnum.SUB_PAGE_8] &&
      $this.checkAreaBelongToTemplate(areaClicked, displayTemplates[TemplateTypeEnum.SUB_PAGE_8]) != -1
    ) {
      areasDisplay = $this.getAreaPicturesReferToSelectedNo(displayTemplates[TemplateTypeEnum.SUB_PAGE_8]);
    } else if (
      displayTemplates[TemplateTypeEnum.SUB_PAGE_9] &&
      $this.checkAreaBelongToTemplate(areaClicked, displayTemplates[TemplateTypeEnum.SUB_PAGE_9]) != -1
    ) {
      areasDisplay = $this.getAreaPicturesReferToSelectedNo(displayTemplates[TemplateTypeEnum.SUB_PAGE_9]);
    }
    $this.drawCell(areasDisplay, renderer, cell, canvasDisplayId);
  }

  /**
   * get list areaPictures have attribute: REFER_TO_SELECTED_NO
   * @param display Template
   */
  private getAreaPicturesReferToSelectedNo(display: Template): Area[] {
    return Helper.getAllAreaTemplate(display).filter(
      area => !area.checkTypeTextArea() && !area.isFix && area.getArea().attribute == LinkDataPictureEnum.REFER_TO_SELECTED_NO
    );
  }

  /**
   * clear canvas
   * @param canvasLayoutRealTime
   */
  private clearCanvas(canvasLayoutRealTime): void {
    let ctx = canvasLayoutRealTime.getContext('2d');
    ctx.clearRect(0, 0, canvasLayoutRealTime.width, canvasLayoutRealTime.height);
  }

  /**
   * clear all canvas
   * @param display Template object
   */
  public clearAllCanvas(display: Template): void {
    if (!display) {
      return;
    }
    Helper.getAllAreaTemplate(display).forEach(area => {
      this.clearCanvas(area.canvas);
    });
  }
  /**
   * scrollLeft
   */
  public scrollLeft(areaText: TextArea, widthMeasureText) {
    switch (areaText.horizontalTextAlignment) {
      case AlignmentEnum.LEFT:
        if (areaText.posXScroll < -widthMeasureText) {
          if (areaText.orientation == OrientationEnum.HORIZONTAL) {
            areaText.posXScroll = areaText.width;
          } else {
            //Xử lý trường hợp không phải HORIZONTAL;
          }
          areaText.timesScroll++;
        }
        break;
      case AlignmentEnum.CENTER:
        if (areaText.posXScroll < -(areaText.width / 2 + widthMeasureText / 2)) {
          if (areaText.orientation == OrientationEnum.HORIZONTAL) {
            areaText.posXScroll = areaText.width / 2 + widthMeasureText / 2;
          } else {
            //Xử lý trường hợp không phải HORIZONTAL;
          }
          areaText.timesScroll++;
        }
        break;
      case AlignmentEnum.RIGHT:
        if (areaText.posXScroll < -areaText.width) {
          if (areaText.orientation == OrientationEnum.HORIZONTAL) {
            areaText.posXScroll = widthMeasureText;
          } else {
            //Xử lý trường hợp không phải HORIZONTAL;
          }
          areaText.timesScroll++;
        }
        break;
    }
  }
  /**
   * scrollRight
   */
  public scrollRight(areaText: TextArea, widthMeasureText) {
    switch (areaText.horizontalTextAlignment) {
      case AlignmentEnum.LEFT:
        if (areaText.posXScroll > areaText.width) {
          if (areaText.orientation == OrientationEnum.HORIZONTAL) {
            areaText.posXScroll = -widthMeasureText;
          } else {
            //Xử lý trường hợp không phải HORIZONTAL;
          }
          areaText.timesScroll++;
        }
        break;
      case AlignmentEnum.CENTER:
        if (areaText.posXScroll > areaText.width / 2 + widthMeasureText / 2) {
          if (areaText.orientation == OrientationEnum.HORIZONTAL) {
            areaText.posXScroll = -areaText.width / 2 - widthMeasureText / 2;
          } else {
            //Xử lý trường hợp không phải HORIZONTAL
          }
          areaText.timesScroll++;
        }
        break;
      case AlignmentEnum.RIGHT:
        if (areaText.posXScroll > widthMeasureText) {
          if (areaText.orientation == OrientationEnum.HORIZONTAL) {
            areaText.posXScroll = -areaText.width;
          } else {
            //Xử lý trường hợp không phải HORIZONTAL
          }
          areaText.timesScroll++;
        }
        break;
    }
  }
  /**
   * scrollUp
   */
  public scrollUp(areaText: TextArea) {
    switch (areaText.verticalTextAlignment) {
      case AlignmentEnum.TOP:
        if (areaText.orientation == OrientationEnum.HORIZONTAL) {
          if (areaText.posYScroll < -areaText.fontSize) {
            areaText.posYScroll = areaText.height;
          }
        }
        break;
      case AlignmentEnum.MIDDLE:
        if (areaText.orientation == OrientationEnum.HORIZONTAL) {
          if (areaText.posYScroll < -areaText.height / 2) {
            areaText.posYScroll = areaText.height / 2 + areaText.fontSize;
          }
        }
        break;
      case AlignmentEnum.BOTTOM:
        if (areaText.orientation == OrientationEnum.HORIZONTAL) {
          if (areaText.posYScroll < -areaText.height) {
            areaText.posYScroll = areaText.fontSize;
          }
        }
        break;
    }
  }
  /**
   * scrollDown
   */
  public scrollDown(areaText: TextArea) {
    switch (areaText.verticalTextAlignment) {
      case AlignmentEnum.TOP:
        if (areaText.orientation == OrientationEnum.HORIZONTAL) {
          if (areaText.posYScroll > areaText.height) {
            areaText.posYScroll = -areaText.fontSize;
          }
        }
        break;
      case AlignmentEnum.MIDDLE:
        if (areaText.orientation == OrientationEnum.HORIZONTAL) {
          if (areaText.posYScroll > areaText.height / 2) {
            areaText.posYScroll = -(areaText.height / 2 + areaText.fontSize);
          }
        }
        break;
      case AlignmentEnum.BOTTOM:
        if (areaText.orientation == OrientationEnum.HORIZONTAL) {
          if (areaText.posYScroll > areaText.fontSize) {
            areaText.posYScroll = -areaText.height;
          }
        }
        break;
    }
  }
}

class ScrollInterval {
  time: any;
  id: Number;
  constructor(time: any, id: Number) {
    this.time = time;
    this.id = id;
  }
}
