import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { RouteDestination } from 'app/model/entity/destination/route-destination';
import { PublishSetting } from 'app/model/entity/publish-setting';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for publish destination action
 */
export class PublishDestinationService {
  /**
   * URL of publish API
   */
  publishDestinationUrl = Constant.BACKEND_URL + Constant.PUBLISH_SERVICE_URL + 'api/publish';
  constructor(private http: HttpClient) {}

  /**
   * Be used by DESTINATION-SIGN
   * publish data destination
   * @param dataSetting
   * @param templateIds
   */
  publishDataDestination(dataSetting: PublishSetting, templateIds: any): Observable<any> {
    const params = new HttpParams().set('templateIds', templateIds);
    return this.http.post<any>(`${this.publishDestinationUrl}/destination-sign-editor`, dataSetting, {
      reportProgress: true,
      observe: 'events',
      params
    });
  }

  /**
   * Be used by DESTINATION-SIGN
   * download publish file
   * @param edsRoutesDownload
   * @param templateIds
   */
  downloadPublishFile(edsRoutesDownload: Array<RouteDestination>, templateIds: any): Observable<any> {
    const params = new HttpParams().set('templateIds', templateIds);
    return this.http.post(`${this.publishDestinationUrl}/download-publish-file`, edsRoutesDownload, {
      responseType: 'blob',
      observe: 'response',
      params
    });
  }
}
