import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { TemplateLED } from 'app/model/entity/destination/template-led';
import { AreaLED } from 'app/model/entity/led/area-led';
import { DataResponseArea } from 'app/model/entity/led/data-response-area';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * led layout editor service
 */
export class TemplateLedService {
  private templateLedUrl = Constant.BACKEND_URL + Constant.EDS_SERVICE_URL + 'api/template-led';
  constructor(private http: HttpClient) {}
  /**
   * Be used LED-LAYOUT-EDITOR-COMPONENT
   * get all template by template group id
   *
   * @returns
   */
  getAllTemplateByGroupId(templateGroupId: Number): Observable<TemplateLED[]> {
    const params = new HttpParams().set('templateGroupId', templateGroupId + '');
    return this.http.get<TemplateLED[]>(`${this.templateLedUrl}/${Constant.LED_LAYOUT_EDITOR_COMPONENT_URL}/get-all-template-by-group-id`, {
      params
    });
  }

  /**
   * Be used LED-LAYOUT-EDITOR-COMPONENT
   * add new template
   *
   * @param template
   * @returns
   */
  addNewTemplate(template: TemplateLED): Observable<TemplateLED> {
    return this.http.post<TemplateLED>(`${this.templateLedUrl}/${Constant.LED_LAYOUT_EDITOR_COMPONENT_URL}/add-template`, template);
  }

  /**
   * Be used LED-LAYOUT-EDITOR-COMPONENT
   * edit template
   *
   * @param template
   * @returns
   */
  editTemplate(template: TemplateLED): Observable<TemplateLED> {
    return this.http.post<TemplateLED>(`${this.templateLedUrl}/${Constant.LED_LAYOUT_EDITOR_COMPONENT_URL}/edit-template`, template);
  }

  /**
   * Be used LED-LAYOUT-EDITOR-COMPONENT
   * duplicate template
   *
   * @param templateName
   * @param templateId
   * @returns
   */
  duplicateTemplate(templateName: string, templateId: Number): Observable<TemplateLED> {
    const params = new HttpParams().set('templateId', templateId + '').set('templateName', templateName);
    return this.http.get<TemplateLED>(`${this.templateLedUrl}/${Constant.LED_LAYOUT_EDITOR_COMPONENT_URL}/duplicate-template`, { params });
  }

  /**
   * Be used LED-LAYOUT-EDITOR-COMPONENT
   * delete template
   *
   * @param id
   */
  deleteTemplate(id: Number) {
    const params = new HttpParams().set('templateId', id + '');
    return this.http.delete(`${this.templateLedUrl}/${Constant.LED_LAYOUT_EDITOR_COMPONENT_URL}/delete-template`, { params });
  }

  /**
   * Be used LED-LAYOUT-EDITOR-COMPONENT
   * get all template by template group id
   *
   * @returns
   */
  getAllTemplate(): Observable<TemplateLED[]> {
    return this.http.get<TemplateLED[]>(`${this.templateLedUrl}/${Constant.LED_LAYOUT_EDITOR_COMPONENT_URL}/get-all-template`);
  }

  /**
   * Be used LED-LAYOUT-EDITOR-COMPONENT
   * save as template
   *
   * @param newTemplate
   * @param areas
   * @returns
   */
  saveAsTemplate(newTemplate: TemplateLED, dataResponse: DataResponseArea): Observable<TemplateLED> {
    const params = new HttpParams().set('newTemplateJson', JSON.stringify(newTemplate));
    return this.http.post<TemplateLED>(
      `${this.templateLedUrl}/${Constant.LED_LAYOUT_EDITOR_COMPONENT_URL}/save-as-template`,
      dataResponse,
      {
        params
      }
    );
  }
}
