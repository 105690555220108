import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { TimetableDetail } from 'app/model/entity/timetable-detail';
import { TimetableDetailURLArea } from 'app/model/entity/timetable-detail-url-area';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimetableDetailURLService {
  /**
   * timetable detail url
   */
  private timetableDetailUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/timetables-url-detail';

  constructor(private http: HttpClient) {}

  /**
   * Be used by TIMETABLE-COMPONENT
   * Get timetable url detail information
   *
   * @param timetableId
   * @returns
   */
  public getTimetableDetailURLInformation(timetableId: Number): Observable<Array<TimetableDetailURLArea>> {
    const params = new HttpParams().set('timetableId', timetableId + '');
    return this.http.get<Array<TimetableDetailURLArea>>(
      `${this.timetableDetailUrl}/${Constant.TIMETABLE_COMPONENT_URL}/get-timetable-detail-url-information`,
      {
        params
      }
    );
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * Save timetable url Details
   *
   * @param timetableId
   * @param timetableDetails
   * @returns
   */
  public saveTimetableDetailURLs(
    timetableId: Number,
    timetableDetails: Array<TimetableDetailURLArea>
  ): Observable<Array<TimetableDetailURLArea>> {
    const params = new HttpParams().set('timetableId', timetableId + '');
    return this.http.post<Array<TimetableDetailURLArea>>(
      `${this.timetableDetailUrl}/${Constant.TIMETABLE_COMPONENT_URL}/save-timetable-detail-url-information`,
      timetableDetails,
      { params }
    );
  }
}
