import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { TemplateGroupLED } from 'app/model/entity/destination/template-group-led';
import { TemplateLED } from 'app/model/entity/destination/template-led';

@Injectable({
  providedIn: 'root'
})
/**
 * led layout editor service
 */
export class TemplateGroupLedService {
  private templateGroupUrl = Constant.BACKEND_URL + Constant.EDS_SERVICE_URL + 'api/template-groups-led';
  constructor(private http: HttpClient) {}

  /**
   * Be used LED-LAYOUT-EDITOR-COMPONENT
   * get all template group
   *
   * @returns
   */
  getAllTemplateGroup(): Observable<TemplateGroupLED[]> {
    return this.http.get<TemplateGroupLED[]>(`${this.templateGroupUrl}/${Constant.LED_LAYOUT_EDITOR_COMPONENT_URL}/get-all-template-group`);
  }

  /**
   * Be used LED-LAYOUT-EDITOR-COMPONENT
   * add new template group
   *
   * @param templateGroup
   * @returns
   */
  addNewTemplateGroup(templateGroup: TemplateGroupLED): Observable<TemplateGroupLED> {
    return this.http.post<TemplateGroupLED>(
      `${this.templateGroupUrl}/${Constant.LED_LAYOUT_EDITOR_COMPONENT_URL}/add-template-groups`,
      templateGroup
    );
  }

  /**
   * Be used LED-LAYOUT-EDITOR-COMPONENT
   * edit template group
   *
   * @param templateGroup
   * @returns
   */
  editTemplateGroup(templateGroup: TemplateGroupLED): Observable<TemplateGroupLED> {
    return this.http.post<TemplateGroupLED>(
      `${this.templateGroupUrl}/${Constant.LED_LAYOUT_EDITOR_COMPONENT_URL}/edit-template-groups`,
      templateGroup
    );
  }

  /**
   * delete template group
   * @param id
   * @returns
   */
  deleteTemplateGroup(id: Number) {
    const params = new HttpParams().set('id', id + '');
    return this.http.delete(`${this.templateGroupUrl}/${Constant.LED_LAYOUT_EDITOR_COMPONENT_URL}/delete-template-group`, { params });
  }
}
