import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { Observable } from 'rxjs';
import { IndexWordGroup } from '../model/entity/index-word-group';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for index word group action
 */
export class IndexWordGroupService {
  /**
   * URL of index word group API
   */
  indexWordGroupUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/index-word-groups';

  constructor(private http: HttpClient) {}

  /**
   * Get all index word groups
   * @returns {Observable<IndexWordGroup[]>} list of all index word group
   */
  getIndexWordGroups(): Observable<IndexWordGroup[]> {
    return this.http.get<IndexWordGroup[]>(`${this.indexWordGroupUrl}/get-index-word-groups`);
  }

  /**
   * Be used INDEX-WORD-EDITOR-COMPONENT
   * save IndexWordGroup
   *
   * @param indexWordGroup new IndexWordGroup need to add
   */
  saveIndexWordGroup(indexWordGroup: IndexWordGroup): Observable<IndexWordGroup> {
    return this.http.post<IndexWordGroup>(`${this.indexWordGroupUrl}/${Constant.INDEX_WORD_EDITOR_COMPONENT_URL}/save`, indexWordGroup);
  }

  /**
   * Be used INDEX-WORD-EDITOR-COMPONENT
   * delete IndexWordGroup
   *
   * @param indexWordGroupId selected index word
   */
  deleteIndexWordGroup(indexWordGroupId: number) {
    const params = new HttpParams().set('indexWordGroupId', indexWordGroupId + '');
    return this.http.delete(`${this.indexWordGroupUrl}/${Constant.INDEX_WORD_EDITOR_COMPONENT_URL}/delete`, { params });
  }

  /**
   * Be used INDEX-WORD-EDITOR-COMPONENT
   * Is duplicate index word group
   *
   * @param indexWordGroupName
   * @param groupId
   * @returns true if index word group is already exists
   */
  public isDuplicateIndexWordGroup(indexWordGroupName: string, groupId: Number): Observable<boolean> {
    const params = new HttpParams().set('indexWordGroupName', indexWordGroupName + '').set('groupId', (groupId ? groupId : 0) + '');
    return this.http.get<boolean>(
      `${this.indexWordGroupUrl}/${Constant.INDEX_WORD_EDITOR_COMPONENT_URL}/check-duplicate-index-word-group-name`,
      { params }
    );
  }
}
