import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { StyleDetail } from 'app/model/entity/style-detail';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Class StyleDetailService
 */
export class StyleDetailService {
  /**
   * style detail url
   */
  private readonly styleDetailUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/style-details';

  constructor(private http: HttpClient) {}

  /**
   * Be used by SIGNAGE-DISPLAY-EDITOR-COMPONENT
   * get details information for style
   *
   * @param styleId style id
   */
  getDetailsInformationForStyle(styleId: Number): Observable<Array<StyleDetail>> {
    const params = new HttpParams().set('styleId', styleId + '');
    return this.http.get<Array<StyleDetail>>(
      `${this.styleDetailUrl}/${Constant.SIGNAGE_DISPLAY_EDITOR_COMPONENT_URL}/get-details-information-for-style`,
      { params }
    );
  }

  /**
   * Be used by SIGNAGE-DISPLAY-EDITOR-COMPONENT
   * save style details
   *
   * @param styleId
   * @param styleDetails list style detail
   */
  saveStyleDetails(styleId: Number, styleDetails: Array<StyleDetail>): Observable<Array<StyleDetail>> {
    const params = new HttpParams().set('styleId', `${styleId}`);
    return this.http.post<Array<StyleDetail>>(
      `${this.styleDetailUrl}/${Constant.SIGNAGE_DISPLAY_EDITOR_COMPONENT_URL}/save-style-details`,
      styleDetails,
      { params }
    );
  }
}
