import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { DynamicMessage } from 'app/model/entity/dynamic-message';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
/**
 * Class DynamicMessageService
 */
export class DynamicMessageService {
  /**
   * dynamic message url
   */
  private dynamicMessageUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/dynamic-message';

  constructor(private http: HttpClient) {}

  /**
   * get dynamic message for multi device by device id and current date
   *
   * @param checkedDevicesId
   * @param currentDate
   * @returns
   */
  getDynamicMessageForMultiDeviceByDeviceId(checkedDevicesId: Number[], currentDate: string): Observable<DynamicMessage[]> {
    const params = new HttpParams().set('currentDate', currentDate);
    return this.http.post<DynamicMessage[]>(
      `${this.dynamicMessageUrl}/get-dynamic-message-for-multi-device-by-device-id`,
      checkedDevicesId,
      {
        params
      }
    );
  }

  /**
   * get dynamic message for multi device by device id and current date
   *
   * @param checkedDevicesId
   * @param currentDate
   * @returns
   */
  getDynamicMessageForMultiDeviceByDeviceIdSchedule(checkedDevicesId: Number[], currentDate: string): Observable<DynamicMessage[]> {
    const params = new HttpParams().set('currentDate', currentDate);
    return this.http.post<DynamicMessage[]>(
      `${this.dynamicMessageUrl}/get-dynamic-message-for-multi-device-by-device-id-schedule`,
      checkedDevicesId,
      {
        params
      }
    );
  }

  /**
   * get dynamic message for one device by device id and current date
   *
   * @param checkedDeviceId
   * @returns
   */
  getDynamicMessageForOneDeviceByDeviceId(checkedDeviceId: Number): Observable<DynamicMessage[]> {
    return this.http.post<DynamicMessage[]>(`${this.dynamicMessageUrl}/get-dynamic-message-for-one-device-by-device-id`, checkedDeviceId);
  }

  /**
   * save dynamic messages
   *
   * @param dynamicMessagesSave
   * @returns
   */
  saveDynamicMessages(dynamicMessagesSave: any[]): Observable<DynamicMessage[]> {
    return this.http.post<DynamicMessage[]>(`${this.dynamicMessageUrl}/save-dynamic-messages`, dynamicMessagesSave);
  }

  /**
   * save dynamic messages
   *
   * @param dynamicMessagesSave
   * @returns
   */
  saveDynamicMessagesSchedule(dynamicMessagesSave: any[]): Observable<DynamicMessage[]> {
    return this.http.post<DynamicMessage[]>(`${this.dynamicMessageUrl}/save-dynamic-messages-schedule`, dynamicMessagesSave);
  }
}
