import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { AnnouncementFolder } from 'app/model/entity/announcement/announcement-folder';
import { AnnouncementMedia } from 'app/model/entity/announcement/announcement-media';
import { AnnouncementPlaylist } from 'app/model/entity/announcement/announcement-playlist';
import { AudioOfSequence } from 'app/model/entity/announcement/audio-of-sequence';
import { CommonPlaylistRegistration } from 'app/model/entity/announcement/common-playlist-registratrion';
import { PlayListTranslation } from 'app/model/entity/announcement/playlist-translation';
import { Common } from 'app/model/entity/common';
import moment from 'moment';
import { Observable, Subject, from } from 'rxjs';
import { skip, take, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementManagerService {
  constructor(private http: HttpClient, private translateService: TranslateService) {}

  public endPoint: string;
  /**
   * URL for common simple media
   */
  annoucementManagerUrl = Constant.BACKEND_URL + Constant.DSC_SERVICE_URL + 'api';

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * get sequence of playlist
   *
   * @param id
   * @returns
   */
  getSequenceOfPlaylist(id: Number): Observable<Array<AudioOfSequence>> {
    const params = new HttpParams().set('playlistId', `${id}`);
    return this.http.get<Array<AudioOfSequence>>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/get-sequence-of-playlist`,
      { params }
    );
  }

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * Add new simple media
   *
   * @param file
   * @param folderId
   * @returns
   */
  addNewAnnouncementMedia(file: File, folderId: Number): Observable<AnnouncementMedia> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folderId', `${folderId}`);
    formData.append('size', `${file.size}`);
    return this.http.post<AnnouncementMedia>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/add-new-announcement-manager`,
      formData
    );
  }

  /**
   * save playlist
   * @param announcementPlaylist
   * @returns
   */
  savePlaylist(announcementPlaylist: AnnouncementPlaylist): Observable<AnnouncementPlaylist> {
    return this.http.post<AnnouncementPlaylist>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/save-playlist`,
      announcementPlaylist
    );
  }

  /**
   * save sequence
   * @param announcementPlaylist
   * @returns
   */
  saveSequence(announcementPlaylist: AnnouncementPlaylist): Observable<AnnouncementPlaylist> {
    return this.http.post<AnnouncementPlaylist>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/save-sequence`,
      announcementPlaylist
    );
  }

  /**
   * check exist playlist
   *
   * @param name
   * @param id
   * @returns
   */
  checkExistPlaylist(name: string, id: Number): Observable<boolean> {
    const params = new HttpParams().set('name', name).set('id', `${id}`);
    return this.http.get<boolean>(`${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/check-exist-playlist`, { params });
  }

  /**
   * get all playlists
   *
   * @returns
   */
  getAllPlaylists(): Observable<Array<AnnouncementPlaylist>> {
    return this.http.get<Array<AnnouncementPlaylist>>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/get-all-playlists`
    );
  }

  /**
   * duplicate playlist
   * @param announcementPlaylist
   * @returns
   */
  duplicatePlaylist(announcementPlaylist: AnnouncementPlaylist): Observable<AnnouncementPlaylist> {
    return this.http.post<AnnouncementPlaylist>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/duplicate-playlist`,
      announcementPlaylist
    );
  }

  /**
   * delete simple playlist
   *
   * @param id
   * @returns
   */
  deletePlaylist(id: Number) {
    const params = new HttpParams().set('playlistId', `${id}`);
    return this.http.delete(`${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/delete-playlist`, {
      params
    });
  }

  /**
   * get all folders
   *
   * @returns
   */
  getAllFolderMedias(): Observable<Array<AnnouncementFolder>> {
    return this.http.get<Array<AnnouncementFolder>>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/get-all-folder-medias`
    );
  }

  /**
   * get medias by folder id
   *
   * @param folderId
   * @returns
   */
  getMediasByFolderId(folderId: Number): Observable<Array<AnnouncementMedia>> {
    const params = new HttpParams().set('folderId', `${folderId}`);
    return this.http.get<Array<AnnouncementMedia>>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/get-medias-by-folder-id`,
      {
        params
      }
    );
  }

  /**
   * get medias by folder id
   *
   * @param languages
   * @returns
   */
  getMediasSpeech(languages: Array<string>): Observable<Array<AnnouncementMedia>> {
    const params = new HttpParams().set('languages', `${languages}`);
    return this.http.get<Array<AnnouncementMedia>>(`${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/get-medias-speech`, {
      params
    });
  }

  /**
   * check exist folder
   *
   * @param name
   * @param id
   */
  checkExistFolder(name: string, id: Number): Observable<boolean> {
    const params = new HttpParams().set('name', name).set('id', `${id}`);
    return this.http.get<boolean>(`${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/check-exist-folder`, { params });
  }

  /**
   * save simple folder media
   *
   * @param announcementFolder
   * @returns
   */
  saveFolder(announcementFolder: AnnouncementFolder): Observable<AnnouncementFolder> {
    return this.http.post<AnnouncementFolder>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/save-folder`,
      announcementFolder
    );
  }

  /**
   * search by name
   *
   * @param name
   * @returns
   */
  searchByName(name: string): Observable<Array<AnnouncementMedia>> {
    const params = new HttpParams().set('name', `${name}`);
    return this.http.get<Array<AnnouncementMedia>>(`${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/search-by-name`, {
      params
    });
  }

  /**
   * check exist media used
   *
   * @param idMedia
   * @returns
   */
  checkExistMediaUsed(idMedia: Number): Observable<boolean> {
    const params = new HttpParams().set('idMedia', `${idMedia}`);
    return this.http.get<boolean>(`${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/check-exist-media-used`, {
      params
    });
  }

  /**
   * save simple media dropped in folder
   *
   * @param announcementMedia
   * @param folderId
   * @returns
   */
  saveMediaDroppedInFolder(announcementMedia: AnnouncementMedia, folderId: Number): Observable<AnnouncementMedia> {
    const params = new HttpParams().set('folderId', `${folderId}`);
    return this.http.post<AnnouncementMedia>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/save-media-dropped-in-folder`,
      announcementMedia,
      {
        params
      }
    );
  }

  /**
   * delete folder media
   *
   * @param id
   * @returns
   */
  deleteFolder(id: Number) {
    const params = new HttpParams().set('folderId', `${id}`);
    return this.http.delete(`${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/delete-folder`, {
      params
    });
  }

  /**
   * delete media
   *
   * @param id
   * @returns
   */
  deleteMedia(id: Number) {
    const params = new HttpParams().set('mediaId', `${id}`);
    return this.http.delete(`${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/delete-media`, {
      params
    });
  }

  /**
   * get list common playlist registration
   */
  public getListCommonPlaylistRegistration(): Observable<Array<CommonPlaylistRegistration>> {
    return this.http.get<Array<CommonPlaylistRegistration>>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/get-common-playlist-registration`
    );
  }

  /**
   * save common registration
   * @param listCommonPlaylistRegsitration
   * @returns
   */
  public saveCommonRegistration(
    listCommonPlaylistRegsitration: Array<CommonPlaylistRegistration>
  ): Observable<Array<CommonPlaylistRegistration>> {
    return this.http.post<Array<CommonPlaylistRegistration>>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/save-list-common-playlist-registration`,
      listCommonPlaylistRegsitration
    );
  }

  public getDataCurrent(registrationId: string, timezone: any): Observable<any> {
    const params = new HttpParams().set('timezone', `${timezone}`);
    return this.http.post<any>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/get-data-playlist-for-device-selected`,
      registrationId,
      { params }
    );
  }

  /**
   * get Headers From Timetable
   * @param deviceId
   * @param currentDate
   * @param itemMax
   * @returns
   */
  public getHeadersFromTimetable(deviceId: number, currentDate: string, itemMax: any): Observable<any> {
    const params = new HttpParams().set('currentDate', `${currentDate}`).set('itemMax', `${itemMax}`);
    return this.http.post<any>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/get-headers-from-timetable-editor`,
      deviceId,
      { params }
    );
  }

  listScheduleFromAPI: any;
  listSchedule = new Subject<any>();
  headerSchedules: any;
  firstOriginalLiveTime: number;
  listScheduleCanRun: any;
  getScheduleFromAPI(index, size, isScrollDown = true): Observable<any> {
    // lazyloading data when scroll
    if (this.listScheduleFromAPI && this.listScheduleFromAPI.length > 0) {
      const schedules = this.listScheduleFromAPI;
      return from(schedules).pipe(
        skip(isScrollDown ? index : index - size * 4 > 0 ? index - size * 4 : 0),
        take(this.listScheduleFromAPI.length < index ? this.listScheduleFromAPI.length + size - index : size),
        toArray()
      );
    } else {
      return from([]);
    }
  }

  arrConvertData(index, size, isScrollDown = true, dataArr): any[] {
    //func to splice arr (lazyloading)
    let numDataNeedSplice = dataArr.length - size * 3;
    if (numDataNeedSplice < 0) {
      numDataNeedSplice = 0;
    }
    if (isScrollDown) {
      dataArr.splice(0, numDataNeedSplice);
    } else {
      dataArr.splice(size * 3, numDataNeedSplice);
    }
    return dataArr;
  }

  convertDataApi(numTimeDateLine: number, commonObject: Common) {
    this.firstOriginalLiveTime = 0;
    this.listScheduleFromAPI?.sort((a, b) => (parseInt(a.sequenceIndex) > parseInt(b.sequenceIndex) ? 1 : -1));
    const dateNow = new Date(Helper.getCurrentDateByTimeZone(commonObject));
    const time = moment(dateNow)
      .format('HH:mm:ss')
      .replace(/:?/g, '');
    let timeInt = parseInt(time);
    if (timeInt < numTimeDateLine) {
      timeInt = timeInt + 240000;
    }
    let running = 1;
    for (let i = 0; i < this.listScheduleFromAPI.length; i++) {
      this.listScheduleFromAPI[i].key = i + 1;
      this.listScheduleFromAPI[i].isExpand = false;
      this.listScheduleFromAPI[i].filename = this.listScheduleFromAPI[i].sequence;
      if (this.listScheduleFromAPI[i].skipSequence && this.listScheduleFromAPI[i].skipReason == 'no sequence') {
        this.listScheduleFromAPI[i].audio = this.translateService.instant('announcement-manager.no-sequence');
        this.listScheduleFromAPI[i].sequenceIndex = '-';
        this.listScheduleFromAPI[i].class = 'conflictTime';
        continue;
      }
      const isRunPlaylist = this.listScheduleFromAPI[i].audioFiles.some(e => e.skipAudio || e.skipSequence);
      this.listScheduleFromAPI[i].audioFiles.forEach((e, j) => {
        e.isAudio = true;
        if (!isRunPlaylist) {
          if (e.completed != undefined) {
            if (e.completed) {
              this.listScheduleFromAPI[i].sequenceIndex = '-';
              e.sequenceIndex = '-';
            } else if (!e.skipAudio) {
              let timeRowStart = parseInt(e.startTime.replace(/:?/g, ''));
              if (timeRowStart < numTimeDateLine) {
                timeRowStart = timeRowStart + 240000;
              }
              if (timeRowStart > timeInt) {
                this.firstOriginalLiveTime = +`${i}.${j}`;
              }
              if (this.firstOriginalLiveTime && +`${i}.${j}` >= this.firstOriginalLiveTime) {
                e.sequenceIndex = running;
                e.originalIndex = running;
                running++;
              }
            }
            e.audio = this.translateService.instant('announcement-manager.completed');
            if (!this.listScheduleFromAPI[i].audio) {
              this.listScheduleFromAPI[i].audio = this.translateService.instant('announcement-manager.completed');
            }
          }
        } else {
          e.class = 'conflictTime';
          this.listScheduleFromAPI[i].sequenceIndex = '-';
          e.sequenceIndex = '-';
          e.originalIndex = undefined;
        }
        if (e.skipAudio) {
          e.class = 'conflictTime';
          this.listScheduleFromAPI[i].class = 'conflictTime';
          this.listScheduleFromAPI[i].sequenceIndex = '-';
          e.sequenceIndex = '-';
          e.originalIndex = undefined;
          if (e.skipReason) {
            e.class = 'conflictTime';
            if (e.skipReason == 'hold') {
              this.listScheduleFromAPI[i].audio = this.translateService.instant('announcement-manager.delay');
              e.audio = this.translateService.instant('announcement-manager.delay');
            } else if (e.skipReason == 'timeover') {
              this.listScheduleFromAPI[i].audio = this.translateService.instant('announcement-manager.time-over');
              e.audio = this.translateService.instant('announcement-manager.time-over');
            } else if (e.skipReason == 'overlap') {
              this.listScheduleFromAPI[i].audio = this.translateService.instant('announcement-manager.overlap');
              e.audio = this.translateService.instant('announcement-manager.overlap');
            } else if (e.skipReason == 'no file') {
              this.listScheduleFromAPI[i].audio = this.translateService.instant('announcement-manager.no-file');
              e.audio = this.translateService.instant('announcement-manager.no-file');
            } else if (e.skipReason == 'information') {
              this.listScheduleFromAPI[i].audio = Helper.formatString(
                this.translateService.instant('announcement-manager.skipInfor'),
                e.skipInfo
              );
              e.audio = Helper.formatString(this.translateService.instant('announcement-manager.skipInfor'), e.skipInfo);
            } else if (e.skipReason == 'delete') {
              this.listScheduleFromAPI[i].audio = this.translateService.instant('announcement-manager.delete');
              e.audio = this.translateService.instant('announcement-manager.delete');
            }
          }
        }
      });
      this.listScheduleFromAPI[i].audioFiles.sort((a, b) => (a.index > b.index ? 1 : -1));
      if (this.listScheduleFromAPI[i].audioFiles.every(audio => audio.class != 'conflictTime')) {
        const audioNotYet = this.listScheduleFromAPI[i].audioFiles.filter(e => e.originalIndex)?.map(data => data.originalIndex);
        if (audioNotYet.length > 0) {
          const minNumber = Math.min(...audioNotYet);
          this.listScheduleFromAPI[i].originalIndex = minNumber;
        }
      }
    }
    this.listScheduleCanRun = this.listScheduleFromAPI?.filter(
      e => e.audio == this.translateService.instant('announcement-manager.completed')
    );
  }

  /**
   * get all medias from db
   * @returns
   */
  getAllMediaFromDB(): Observable<Array<AnnouncementMedia>> {
    return this.http.get<Array<AnnouncementMedia>>(`${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/get-all-medias`);
  }

  /**
   * translate
   * @param payload
   * @returns
   */
  translate(payload: any): Observable<any> {
    return this.http.post<any>(`${this.endPoint}/translation`, JSON.stringify(payload));
  }

  /**
   * speech Synthesis
   * @param payload
   * @returns
   */
  speechSynthesis(payload: any): Observable<any> {
    return this.http.post<any>(`${this.endPoint}/polly`, JSON.stringify(payload));
  }

  /**
   * Get languages
   */
  getLanguages(): Object[] {
    return [
      {
        language_name_en: 'Japanese',
        language_name_ja: '日本語',
        translation_language_code: 'ja',
        polly_available: true,
        polly_language_code: 'ja-JP',
        speaker: [
          {
            woman1: 'Kazuha',
            engine: 'neural'
          },
          {
            woman2: 'Tomoko',
            engine: 'neural'
          },
          {
            man1: 'Takumi',
            engine: 'neural'
          }
        ]
      },
      {
        language_name_en: 'English',
        language_name_ja: '英語',
        translation_language_code: 'en',
        polly_available: true,
        polly_language_code: 'en-US',
        speaker: [
          {
            woman1: 'Joanna',
            engine: 'neural'
          },
          {
            woman2: 'Salli',
            engine: 'neural'
          },
          {
            man1: 'Joey',
            engine: 'neural'
          },
          {
            man2: 'Matthew',
            engine: 'neural'
          }
        ]
      },
      {
        language_name_en: 'Chinese (Simplified)',
        language_name_ja: '中国語 (簡体字)',
        translation_language_code: 'zh',
        polly_available: true,
        polly_language_code: 'cmn-CN',
        speaker: [
          {
            woman1: 'Zhiyu',
            engine: 'neural'
          }
        ]
      },
      {
        language_name_en: 'Chinese (Traditional)',
        language_name_ja: '中国語 (繁体字)',
        translation_language_code: 'zh-TW',
        polly_available: true,
        polly_language_code: 'yue-CN',
        speaker: [
          {
            woman1: 'Hiujin',
            engine: 'neural'
          }
        ]
      },
      {
        language_name_en: 'Korean',
        language_name_ja: '韓国語',
        translation_language_code: 'ko',
        polly_available: true,
        polly_language_code: 'ko-KR',
        speaker: [
          {
            woman1: 'Seoyeon',
            engine: 'neural'
          }
        ]
      },
      {
        language_name_en: 'Vietnamese',
        language_name_ja: 'ベトナム語',
        translation_language_code: 'vi',
        polly_available: false
      },
      {
        language_name_en: 'Thai',
        language_name_ja: 'タイ語',
        translation_language_code: 'th',
        polly_available: false
      }
    ];
  }

  /**
   * save playlist translation speech synthesis
   * @param playlistTranslation
   * @returns
   */
  public savePlaylistTranslation(playlistTranslation: PlayListTranslation): Observable<any> {
    return this.http.post<PlayListTranslation>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/save-playlist-translation`,
      playlistTranslation
    );
  }

  /**
   * get Bucket Save Audio
   * @returns
   */
  public getBucketSaveAudio(): Observable<any> {
    return this.http.get(`${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/get-bucket-save`, {
      responseType: 'text'
    });
  }

  /**
   * delete-speech-synthesis
   * @returns
   */
  public deleteSpeechSynthesis(pollyLanguageCodes: Array<String>): Observable<any> {
    return this.http.post<PlayListTranslation>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/delete-speech-synthesis`,
      pollyLanguageCodes
    );
  }

  /**
   * check exist playlist tab translation & speech Synthesis
   *
   * @param name
   * @returns
   */
  checkExistPlaylistTabTranslation(name: string): Observable<boolean> {
    const params = new HttpParams().set('name', name);
    return this.http.get<boolean>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/check-exist-playlist-tab-translation`,
      { params }
    );
  }

  /**
   * Single Retranslation
   * @param payload
   * @returns
   */
  singleRetranslation(payload: any): Observable<any> {
    return this.http.post<any>(`${this.endPoint}/retranslation`, JSON.stringify(payload));
  }

  /**
   * import Glossary File
   * @param file
   * @returns
   */
  importGlossaryFile(file: any, screen: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    const params = new HttpParams().set('screen', screen);
    return this.http.post<AnnouncementMedia>(
      `${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/import-glossary-file`,
      formData,
      { params }
    );
  }

  /**
   * export Glossary
   * @param glossary
   * @returns
   */
  exportGlossary(glossary: any): Observable<any> {
    return this.http.post(`${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/export-excel`, glossary, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  /**
   * delete Glossary
   * @returns
   */
  deleteGlossary(screen: string): Observable<void> {
    const params = new HttpParams().set('screen', screen);
    return this.http.get<void>(`${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/delete-glossary-file`, { params });
  }

  getEndPoint(): Observable<string> {
    return this.http.get(`${this.annoucementManagerUrl}/${Constant.ANNOUNCEMENT_MANAGER_URL}/get-end-point`, {
      responseType: 'text'
    });
  }
}
