import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Constant } from 'app/config/constants';
import { Common } from 'app/model/entity/common';
import { PublishSetting } from 'app/model/entity/publish-setting';
import { AppState } from 'app/store/app.state';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for common action
 */
export class CommonService {
  /**
   * common object
   */
  commonObject: Common;

  constructor(public readonly store: Store<AppState>, private http: HttpClient) {
    this.store
      .select(state => state)
      .subscribe((componentState: any) => {
        this.commonObject = componentState?.mainState?.stateOfComponent?.common ?? new Common();
      });
  }

  /**
   * get common object
   * @returns
   */
  public getCommonObject(): Common {
    return this.commonObject;
  }

  /**
   * get information common for publish
   * @returns
   */
  public getInfoPublish(): PublishSetting {
    return new PublishSetting(this.commonObject.userName, this.commonObject.projectName, this.commonObject.projectId);
  }

  /**
   * check network before save
   * @returns
   */
  public checkNetWorkBeforeSave(): Observable<any> {
    return this.http.get(Constant.BACKEND_URL + 'auth/inactive');
  }

  /**
   * write error log
   * @param error
   * @param screenName
   * @param functionName
   * @returns
   */
  public writeErrorLog(error: string, screenName: string, functionName: string): Observable<any> {
    const params = new HttpParams()
      .set('error', error)
      .set('screenName', screenName)
      .set('functionName', functionName);
    return this.http.get(Constant.BACKEND_URL + 'auth/write-error-log', { params });
  }
}
