import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output, Renderer2 } from '@angular/core';
import { Helper } from 'app/common/helper';
import {
  AlignmentEnum,
  Constant,
  DestinationEnum,
  DisplayCanvasIdEnum,
  LinkDataPictureEnum,
  LinkDataTextEnum,
  ObjectFitEnum,
  OrientationEnum,
  ReferencePositionTimetableColumnEnum,
  ScreenCanvasIdEnum,
  ScrollDirectionsEnum,
  ScrollStatusEnum,
  TypeMediaFileEnum
} from 'app/config/constants';
import { Area } from 'app/model/entity/area';
import { DataExternalSetting } from 'app/model/entity/data-external-setting';
import { DynamicMessage } from 'app/model/entity/dynamic-message';
import { EmergencyData } from 'app/model/entity/emergency-data';
import { Image } from 'app/model/entity/image';
import { Layer } from 'app/model/entity/layer';
import { Media } from 'app/model/entity/media';
import { PictureArea } from 'app/model/entity/picture-area';
import { Sequence } from 'app/model/entity/sequence';
import { Template } from 'app/model/entity/template';
import { TextArea } from 'app/model/entity/text-area';
import { Timetable } from 'app/model/entity/timetable';
import { TimetableSchedule } from 'app/model/entity/timetable-schedule';
import { URLArea } from 'app/model/entity/url-area';
import { Video } from 'app/model/entity/video';
import { ActiveScheduleRow } from 'app/module/timetable-operation-manager/timetable-operation-manager.component';
import _ from 'lodash';
import * as moment from 'moment';
import { Subject, Subscription, interval } from 'rxjs';
import { repeatWhen, takeUntil, timeInterval } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { CommonService } from './common.service';
import { PictureAreaService } from './picture-area-service';

@Injectable({
  providedIn: 'root'
})
export class DrawTimetableService {
  /**
   * true if preview on
   */
  isPlay: boolean;

  /**
   * media is set
   */
  mediaSetting: Media;

  /**
   * timetable
   */
  timetable: Timetable;
  /**
   * toSwitchBetweenPage
   */
  @Output() toSwitchBetweenPage = new EventEmitter<{ key: string; value: DestinationEnum }>();

  /**
   * subject display 1
   */
  private readonly pausePreviewDisplay1Subject = new Subject<void>();
  private readonly startPreviewDisplay1Subject = new Subject<void>();
  private readonly clearPreviewDisplay1Subject = new Subject<void>();
  /**
   * subject display 2
   */
  private readonly pausePreviewDisplay2Subject = new Subject<void>();
  private readonly startPreviewDisplay2Subject = new Subject<void>();
  private readonly clearPreviewDisplay2Subject = new Subject<void>();
  /**
   * list area clock intervals
   */
  listAreaClockIntervals = new Array<any>();
  /**
   * timeouts display 1 timetable
   */
  timeoutsDisplay1Timetable: Array<TimeOut> = new Array<TimeOut>();
  /**
   * timeouts display 2 timetable
   */
  timeoutsDisplay2Timetable: Array<TimeOut> = new Array<TimeOut>();
  /**
   * true if start preview Timetable Editor
   */
  isStartTimetable: boolean = true;
  /**
   * timeouts display 1 timetable operation
   */
  timeoutsDisplay1TimetableOperation: Array<TimeOut> = new Array<TimeOut>();
  /**
   * timeouts display 2 timetable operation
   */
  timeoutsDisplay2TimetableOperation: Array<TimeOut> = new Array<TimeOut>();
  /**
   * true if start preview Timetable Operation
   */
  isStartTimetableOperation: boolean = true;
  /**
   * medias of index words
   */
  mediaIndexWords = new Array<MediaIndexWord>();
  /**
   * key unique
   */
  keyUnique: any;
  /**
   * interval start time
   */
  intervalStartTime: number = 0;
  /**
   * switching timing
   */
  switchingTiming: number = 0;
  /**
   * timetable schedule
   */
  timetableSchedule: TimetableSchedule;
  /**
   * current index timetable schedule
   */
  currentIndexTimetableSchedule: number;
  /**
   * reference position columns by template
   */
  referencePositionColumnsByTemplate: number[];
  /**
   * areas Drawing
   */
  areasDrawing: Area[] = [];
  /**
   * active schedule row
   */
  activeScheduleRow: ActiveScheduleRow;
  /**
   * dynamic messages
   */
  dynamicMessages: DynamicMessage[];
  /**
   * emergency
   */
  emergencyData: EmergencyData;

  /**
   * list interval draw news picture display 1
   */
  intervalsDrawNewsDisplay1: Array<any> = new Array<any>();
  /**
   * list interval draw news picture display 2
   */
  intervalsDrawNewsDisplay2: Array<any> = new Array<any>();
  /**
   * subscribe for get url media presigned for display 1
   */
  subscribesGetUrlPresignedDisplay1: Subscription[] = [];
  /**
   * subscribe for get url media presigned for display 2
   */
  subscribesGetUrlPresignedDisplay2: Subscription[] = [];

  dataResponse: any;
  pictureAreaService: PictureAreaService;
  areasIndexWordDisplay: Area[];
  /**
   * true if on emergency
   */
  isOnEmergency: boolean;

  constructor(private commonService: CommonService, private httpClient: HttpClient) {}

  /**
   * clear media index words
   * @returns
   */
  public clearMediaIndexWords() {
    this.mediaIndexWords = new Array<MediaIndexWord>();
    this.keyUnique = uuidv4();
  }

  /**
   * clear all threads draw template
   * @param template
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @returns
   */
  public clearAllThreadDrawTemplate(template: Template, canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum): void {
    if (!template) {
      return;
    }
    this.handleUnSubscriptionForLayer(template, canvasDisplayId);
    const areas = Helper.getAllAreaTemplate(template);
    areas.forEach(area => {
      this.handleUnSubscriptionForArea(area, canvasDisplayId);
    });
    if (canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
      this.clearPreviewDisplay1Subject.next();
    } else {
      this.clearPreviewDisplay2Subject.next();
    }
  }

  /**
   * Handle un subscription for layer
   *
   * @param template
   * @param canvasDisplayId
   * @returns
   */
  public handleUnSubscriptionForLayer(template: Template, canvasDisplayId: any): void {
    if (!template.layers) {
      return;
    }
    template.layers.forEach(layer => {
      if (!layer[`subscription${canvasDisplayId}`]) {
        return;
      }
      layer[`subscription${canvasDisplayId}`].unsubscribe();
      delete layer[`subscription${canvasDisplayId}`];
    });
  }

  /**
   * Handle un subscription for area
   *
   * @param area
   * @param canvasDisplayId
   * @returns
   */
  public handleUnSubscriptionForArea(area: Area, canvasDisplayId: any): void {
    this.resetPositionScrollText(area as TextArea);
    if (!area[`subscription${canvasDisplayId}`]) {
      return;
    }
    area[`subscription${canvasDisplayId}`].unsubscribe();
    delete area[`subscription${canvasDisplayId}`];
  }

  /**
   * change start state
   * @param isStart
   * @param screenCanvasIdEnum
   */
  public changeStartState(isStart: boolean, screenCanvasIdEnum: ScreenCanvasIdEnum) {
    if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
      this.isStartTimetable = isStart;
    } else {
      this.isStartTimetableOperation = isStart;
    }
  }

  /**
   * reset data
   */
  public resetData(template: Template, canvasId: string, screenCanvasIdEnum): void {
    if (!template) {
      if (canvasId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
        _.remove(this.areasDrawing, function(areaDraw) {
          return areaDraw?.idWithDisplay.includes('Display1');
        });
      } else {
        _.remove(this.areasDrawing, function(areaDraw) {
          return areaDraw?.idWithDisplay.includes('Display2');
        });
      }
      this.dynamicMessages = [];
      this.activeScheduleRow = null;
      this.emergencyData = null;
      this.referencePositionColumnsByTemplate = [];
      this.currentIndexTimetableSchedule = null;
      if (canvasId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
        _.remove(this.listAreaClockIntervals, function(clockInterval) {
          return clockInterval?.areaId.includes('Display1');
        });
      } else {
        _.remove(this.listAreaClockIntervals, function(clockInterval) {
          return clockInterval?.areaId.includes('Display2');
        });
      }
      this.timetableSchedule = null;
      this.isOnEmergency = false;
      this.dataResponse = null;
      this.areasIndexWordDisplay = [];
    } else {
      template?.layers?.forEach(layer => {
        layer.areas?.forEach(area => {
          _.remove(this.areasDrawing, function(areaDraw) {
            return areaDraw?.idWithDisplay == area.idWithDisplay;
          });
          _.remove(this.areasIndexWordDisplay, function(areaDraw) {
            return areaDraw?.idWithDisplay == area.idWithDisplay;
          });
        });
      });
    }
  }

  /**
   * change state play/pause
   *
   * @param isPlayOn
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum
   */
  public changeStatePlayPause(isPlayOn: boolean, canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum): void {
    this.isPlay = isPlayOn;
    if (this.isPlay) {
      if (canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
        this.startPreviewDisplay1Subject.next();
      } else {
        this.startPreviewDisplay2Subject.next();
      }
    } else {
      if (canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
        this.pausePreviewDisplay1Subject.next();
      } else {
        this.pausePreviewDisplay2Subject.next();
      }
    }
  }

  /**
   * play preview
   *
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  public playPreview(canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum) {
    this.isPlay = true;
    if (canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
      this.startPreviewDisplay1Subject.next();
    } else {
      this.startPreviewDisplay2Subject.next();
    }
  }

  /**
   * pause preview
   *
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  public pausePreview(canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum) {
    this.isPlay = false;
    if (canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
      this.pausePreviewDisplay1Subject.next();
    } else {
      this.pausePreviewDisplay2Subject.next();
    }
  }

  /**
   * set up preview
   *
   * @param timetable timetable object
   * @param mediaSetting Media
   */
  public setupPreview(timetable: Timetable, mediaSetting: Media): void {
    this.timetable = timetable;
    this.mediaSetting = mediaSetting;
  }

  /**
   * create all canvas area template
   * @param template Template
   * @param canvasContainerDisplay ElementRef
   * @param renderer
   */
  public createAllCanvasAreaTemplate(
    template: Template,
    canvasContainerDisplay: any,
    renderer: Renderer2,
    isDisplay2: boolean,
    screenCanvasIdEnum: ScreenCanvasIdEnum
  ): void {
    template?.layers &&
      template.layers.forEach(layer => {
        layer.areas.forEach(area => {
          this.createCanvasArea(area, canvasContainerDisplay, renderer, isDisplay2, screenCanvasIdEnum);
        });
      });
  }

  /**
   * create canvas template
   * @param template template
   * @param canvasContainerDisplay ElementRef
   * @param renderer
   */
  public createCanvasTemplate(template: Template, canvasContainerDisplay: any, renderer: Renderer2, isDisplay2: boolean): void {
    const canvas = renderer.createElement('canvas');
    canvas.id = !isDisplay2 ? `timetable-previewCanvas1` : `timetable-previewCanvas2`;
    canvas.style.position = 'absolute';
    canvas.style.background = '#000';
    canvas.style.width = template.width + 'px';
    canvas.style.height = template.height + 'px';
    canvas.width = template.width;
    canvas.height = template.height;
    renderer.appendChild(canvasContainerDisplay.nativeElement, canvas);
  }

  /**
   * create canvas area
   *
   * @param area Area
   * @param canvasContainerDisplay ElementRef
   * @param renderer Renderer2
   */
  public createCanvasArea(
    area: Area,
    canvasContainerDisplay: any,
    renderer: Renderer2,
    isDisplay2: boolean,
    screenCanvasIdEnum: ScreenCanvasIdEnum
  ): void {
    if (
      area.getArea().attribute == LinkDataPictureEnum.EMERGENCY_MESSAGE ||
      area.getArea().linkReferenceData == LinkDataTextEnum.EMERGENCY_MESSAGE
    ) {
      return;
    }
    const canvas = renderer.createElement('canvas');
    canvas.id = !isDisplay2 ? `timetable-previewCanvas1-${area.id}` : `timetable-previewCanvas2-${area.id}`;
    canvas.style.position = 'absolute';
    canvas.style.zIndex = area.index;
    canvas.style.left = area.posX + 'px';
    canvas.style.top = area.posY + 'px';
    canvas.style.width = area.width + 'px';
    canvas.style.height = area.height + 'px';
    canvas.width = area.width;
    canvas.height = area.height;
    if (area.isURL) {
      canvas.style.border = '3px ' + 'solid' + '#FFEB8D';
    }
    if (area.isOnClickEvent) {
      canvas.style.cursor = 'pointer';
      canvas.addEventListener('click', () => {
        if (
          canvasContainerDisplay.nativeElement.id == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}` &&
          this.timetable.templateDisplay1s[area.onClickEventDestination]
        ) {
          this.toSwitchBetweenPage.emit({
            key: `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`,
            value: area.onClickEventDestination
          });
        }
        if (
          canvasContainerDisplay.nativeElement.id == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_2}` &&
          this.timetable.templateDisplay2s[area.onClickEventDestination]
        ) {
          this.toSwitchBetweenPage.emit({
            key: `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_2}`,
            value: area.onClickEventDestination
          });
        }
      });
    }
    renderer.appendChild(canvasContainerDisplay.nativeElement, canvas);
    area.canvas = canvas;
  }

  /**
   * draw preview
   *
   * @param template template
   * @param renderer
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum (TimetableEditor or TimetableOperation)
   * @param isMonitorMode
   */
  public drawPreview(
    template: Template,
    renderer: Renderer2,
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    isMonitorMode?: boolean
  ) {
    if (template) {
      template?.layers?.forEach(layer => {
        if (!layer.isSwitchingArea || this.switchingTiming == 0) {
          Promise.all(
            layer.areas.map(async area => {
              if (!area.canvas) {
                return;
              }
              this.drawArea(area, renderer, canvasDisplayId, screenCanvasIdEnum, template, isMonitorMode);
            })
          );
        } else {
          if (!layer.areas.length) {
            return;
          }
          layer.areas = _.orderBy(layer.areas, ['index'], ['desc']);
          this.drawArea(layer.areas[Constant.FIRST_ELEMENT_INDEX], renderer, canvasDisplayId, screenCanvasIdEnum, template, isMonitorMode);
          this.areasDrawing.push(layer.areas[Constant.FIRST_ELEMENT_INDEX]);
          this.drawLayerSwitching(layer, renderer, canvasDisplayId, screenCanvasIdEnum, template);
        }
      });
    }
  }

  /**
   * set Area Switching Timing
   * @param switchingTiming
   */
  public setAreaSwitchingTiming(switchingTiming: number): void {
    this.switchingTiming = switchingTiming;
  }

  /**
   * draw Area
   * @param area
   * @param renderer
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param template
   * @param isMonitorMode
   * @returns
   */
  private async drawArea(
    area: Area,
    renderer: Renderer2,
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    template: Template,
    isMonitorMode?: boolean
  ) {
    // case area instanceof TextArea
    if (area?.checkTypeTextArea()) {
      var textArea = area as TextArea;
      if (textArea.isFix) {
        // draw fix text
        this.drawAreaFixText(textArea, renderer, canvasDisplayId, screenCanvasIdEnum);
      } else {
        switch (textArea.linkReferenceData) {
          // draw link text if linkReferenceData is clock
          case LinkDataTextEnum.CLOCK:
            this.drawClock(textArea, renderer, canvasDisplayId, screenCanvasIdEnum);
            break;
          // draw link text if linkReferenceData is free text
          case LinkDataTextEnum.FREE_TEXT:
            this.drawFreeText(textArea, renderer, screenCanvasIdEnum, canvasDisplayId);
            break;
          // draw link text if linkReferenceData is index word
          case LinkDataTextEnum.INDEX_WORD:
            this.drawIndexWordForDisplay(textArea, canvasDisplayId, screenCanvasIdEnum, template);
            break;
          // draw link text if linkReferenceData is timetable
          case LinkDataTextEnum.TIMETABLE:
            this.drawTimetableForDisplay(textArea, renderer, canvasDisplayId, screenCanvasIdEnum);
            break;
          // draw link text if linkReferenceData is dynamic message
          case LinkDataTextEnum.DYNAMIC_MESSAGE:
            if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR || isMonitorMode) {
              break;
            }
            this.drawDynamicMessages(this.dynamicMessages, [textArea], canvasDisplayId, screenCanvasIdEnum, template);
            break;
          // draw link text if linkReferenceData is emergency
          case LinkDataTextEnum.EMERGENCY_MESSAGE:
            if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR || !this.isOnEmergency || isMonitorMode) {
              break;
            }
            this.drawEmergency(this.emergencyData, [textArea], canvasDisplayId, screenCanvasIdEnum, template);
            break;
          // draw link text if linkReferenceData is operation info
          case LinkDataTextEnum.OPERATION_INFO:
            if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR || isMonitorMode) {
              break;
            }
            this.drawOperationInfo(this.activeScheduleRow, [textArea], canvasDisplayId, screenCanvasIdEnum);
            break;
          default:
            break;
        }
      }
      // case area instanceof Picture
    } else if (!area.isURL) {
      var pictureArea = area?.getArea() as PictureArea;
      if (pictureArea?.isFix) {
        if (pictureArea?.media?.type == TypeMediaFileEnum.MP4) {
          // draw video fix picture
          await this.drawVideoFixPicture(pictureArea, renderer, canvasDisplayId, screenCanvasIdEnum);
        } else {
          // draw fix picture
          await this.drawAreaFixPicture(pictureArea, renderer, canvasDisplayId, screenCanvasIdEnum, template);
        }
        // draw link picture
      } else {
        switch (pictureArea.attribute) {
          case LinkDataPictureEnum.SIGNAGE_CHANNEL:
            // draw signage channel
            if (!this.mediaSetting) {
              return;
            }
            this.drawSignageChannel(pictureArea, this.mediaSetting, renderer, canvasDisplayId, screenCanvasIdEnum, template);
            break;
          case LinkDataPictureEnum.FREE_PICTURE:
            // draw link picture if attribute is free picture
            await this.drawFreePicture(pictureArea, renderer, canvasDisplayId, screenCanvasIdEnum, template);
            break;
          case LinkDataPictureEnum.INDEX_WORD:
            // draw link picture if attribute is index word
            this.drawIndexWordForDisplay(pictureArea, canvasDisplayId, screenCanvasIdEnum, template);
            break;
          // draw link picture if attribute is emergency
          case LinkDataPictureEnum.EMERGENCY_MESSAGE:
            if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR || !this.isOnEmergency || isMonitorMode) {
              break;
            }
            this.drawEmergency(this.emergencyData, [pictureArea], canvasDisplayId, screenCanvasIdEnum, template);
            break;
          case LinkDataPictureEnum.EXTERNAL_CONTENT:
            if (this.dataResponse && this.pictureAreaService) {
              this.drawExternalContent(canvasDisplayId, this.dataResponse, template, this.pictureAreaService, screenCanvasIdEnum);
            }
            break;
          // draw link picture if attribute is dynamic message
          case LinkDataPictureEnum.DYNAMIC_MESSAGE:
            if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR || isMonitorMode) {
              break;
            }
            this.drawDynamicMessages(this.dynamicMessages, [pictureArea], canvasDisplayId, screenCanvasIdEnum, template);
            break;
          default:
            break;
        }
      }
    } else {
      this.drawUrlPicture(area as URLArea, renderer, canvasDisplayId, screenCanvasIdEnum, template);
    }
  }

  /**
   * draw Timetable For Display
   * @param textArea
   * @param renderer
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  private drawTimetableForDisplay(textArea: TextArea, renderer: Renderer2, canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum) {
    if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
      if (this.timetableSchedule) {
        this.drawAreasTimetable(
          [textArea],
          renderer,
          canvasDisplayId,
          this.timetableSchedule,
          this.currentIndexTimetableSchedule,
          screenCanvasIdEnum,
          this.referencePositionColumnsByTemplate
        );
      }
    } else {
      this.drawSchedule(this.activeScheduleRow, [textArea], canvasDisplayId, screenCanvasIdEnum, this.referencePositionColumnsByTemplate);
    }
  }

  /**
   * draw index word for display
   * @param area
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param template
   */
  private drawIndexWordForDisplay(area: Area, canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum, template: Template): void {
    if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
      if (this.timetableSchedule && this.areasIndexWordDisplay) {
        this.setDataIndexWordForAreasDrawing([area], this.areasIndexWordDisplay);
        this.drawAreasIndexWord([area], canvasDisplayId, this.timetableSchedule, screenCanvasIdEnum, template);
      }
    } else if (this.areasIndexWordDisplay) {
      this.setDataIndexWordForAreasDrawing([area], this.areasIndexWordDisplay);
      this.drawIndexWordBySchedule([area], canvasDisplayId, screenCanvasIdEnum, template);
    }
  }

  /**
   * draw Layer Switching
   * @param layer
   * @param renderer
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param template
   */
  private drawLayerSwitching(
    layer: Layer,
    renderer: Renderer2,
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    template: Template
  ): void {
    if (layer[`subscription${canvasDisplayId}`]) {
      layer[`subscription${canvasDisplayId}`].unsubscribe();
    }
    if (!this.switchingTiming) {
      return;
    }
    const observable = interval(1000);
    const subscription =
      canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
        ? observable
            .pipe(
              takeUntil(this.clearPreviewDisplay1Subject),
              takeUntil(this.pausePreviewDisplay1Subject),
              repeatWhen(() => this.startPreviewDisplay1Subject)
            )
            .subscribe(count => {
              if (count == this.switchingTiming - 1) {
                this.drawLayerSwitchingForDisplay(layer, screenCanvasIdEnum, canvasDisplayId, renderer, template);
              }
            })
        : observable
            .pipe(
              takeUntil(this.clearPreviewDisplay2Subject),
              takeUntil(this.pausePreviewDisplay2Subject),
              repeatWhen(() => this.startPreviewDisplay2Subject)
            )
            .subscribe(count => {
              if (count == this.switchingTiming - 1) {
                this.drawLayerSwitchingForDisplay(layer, screenCanvasIdEnum, canvasDisplayId, renderer, template);
              }
            });
    layer[`subscription${canvasDisplayId}`] = subscription;
    layer[`clearPreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
        ? this.clearPreviewDisplay1Subject.subscribe(() => {
            subscription.unsubscribe();
            layer[`clearPreviewSubject${canvasDisplayId}`]?.unsubscribe();
          })
        : this.clearPreviewDisplay2Subject.subscribe(() => {
            subscription.unsubscribe();
            layer[`clearPreviewSubject${canvasDisplayId}`]?.unsubscribe();
          });
  }

  /**
   * draw layer switching for display
   * @param layer
   * @param screenCanvasIdEnum
   * @param canvasDisplayId
   * @param renderer
   * @param template
   */
  private drawLayerSwitchingForDisplay(
    layer: Layer,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    canvasDisplayId: any,
    renderer: Renderer2,
    template: Template
  ): void {
    if (this.isPlay) {
      layer[`subscription${canvasDisplayId}`]?.unsubscribe();
      // find index of prevArea
      let index2 = layer.areas.findIndex(item2 => this.areasDrawing.map(area => area?.idWithDisplay)?.includes(item2?.idWithDisplay));
      if (index2 != -1) {
        let indexDraw = this.areasDrawing.findIndex(e => e.idWithDisplay == layer.areas[index2].idWithDisplay);
        // clear prevArea
        this.clearPreviousArea(this.areasDrawing[indexDraw], canvasDisplayId, screenCanvasIdEnum);
        this.clearPreviousArea(layer.areas[index2], canvasDisplayId, screenCanvasIdEnum);
        if (index2 + 1 >= layer.areas.length) {
          index2 = -1;
        }
        // draw new area
        this.drawArea(layer.areas[index2 + 1], renderer, canvasDisplayId, screenCanvasIdEnum, template);
        this.areasDrawing.push(layer.areas[index2 + 1]);
        // draw layer switching
        this.drawLayerSwitching(layer, renderer, canvasDisplayId, screenCanvasIdEnum, template);
      }
    }
  }

  /**
   * clear previous area
   * @param prevArea
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  private clearPreviousArea(prevArea: Area, canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum) {
    // clear interval clock, animation frame gif
    if (prevArea.checkTypeTextArea()) {
      let intervals = this.listAreaClockIntervals.filter(data => data.areaId == prevArea.idWithDisplay);
      if (intervals.length) {
        intervals.forEach(item => {
          clearInterval(item.time);
        });
        _.remove(this.listAreaClockIntervals, function(areaClock) {
          return intervals.map(data => data.areaId).includes(areaClock?.idWithDisplay);
        });
      }
    } else {
      let pictureArea = <PictureArea>prevArea;
      if (Helper.isVideo(pictureArea.media)) {
        pictureArea.videoPreview && pictureArea.videoPreview.pause();
        if (pictureArea[`animationId${canvasDisplayId}`]) {
          cancelAnimationFrame(pictureArea[`animationId${canvasDisplayId}`]);
        }
      }
    }
    if (!prevArea.checkTypeTextArea() && !prevArea.isFix && prevArea.getArea().attribute == LinkDataPictureEnum.EXTERNAL_CONTENT) {
      if (canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
        this.clearAllIntervalDrawsNewsDisplay1();
      } else {
        this.clearAllIntervalDrawsNewsDisplay2();
      }
    }
    // clear timeout
    this.clearTimeoutStopDurationPrevArea(prevArea, canvasDisplayId, screenCanvasIdEnum);
    // clear canvas prevArea
    this.handleUnSubscriptionForArea(prevArea, canvasDisplayId);
    this.clearCanvas(prevArea.canvas);
    // remove area drawing
    _.remove(this.areasDrawing, function(areaDraw) {
      return areaDraw?.idWithDisplay === prevArea.idWithDisplay;
    });
  }

  /**
   * clear timeout stop duration prev area
   * @param prevArea
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  private clearTimeoutStopDurationPrevArea(prevArea: Area, canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum): void {
    if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
      let timeoutsTimetableEditor =
        canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
          ? this.timeoutsDisplay1Timetable.filter(time1 => time1.areaId == prevArea.id)
          : this.timeoutsDisplay2Timetable.filter(time2 => time2.areaId == prevArea.id);
      for (let timeout of timeoutsTimetableEditor) {
        clearTimeout(timeout.time);
      }
    } else {
      let timeoutsTimetableOperation =
        canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
          ? this.timeoutsDisplay1TimetableOperation.filter(time3 => time3.areaId == prevArea.id)
          : this.timeoutsDisplay2TimetableOperation.filter(time4 => time4.areaId == prevArea.id);
      for (let timeout of timeoutsTimetableOperation) {
        clearTimeout(timeout.time);
      }
    }
  }

  /**
   * draw preview fix area timing on
   *
   * @param areasTimingOn areas timing on
   * @param renderer
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum (TimetableEditor or TimetableOperation)
   * @param isFinishSchedule
   * @param template
   */
  public drawPreviewFixAreaTimingOn(
    areasTimingOn: Area[],
    renderer: Renderer2,
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    isFinishSchedule: boolean,
    template: Template
  ) {
    Promise.all(
      areasTimingOn.map(async area => {
        if (!area.canvas) {
          return;
        }
        // case area instanceof TextArea
        if (area.checkTypeTextArea()) {
          var textArea = area as TextArea;
          // draw fix text
          this.drawAreaFixText(textArea, renderer, canvasDisplayId, screenCanvasIdEnum, isFinishSchedule);
          // case area instanceof Picture
        } else {
          var pictureArea = area.getArea() as PictureArea;
          if (pictureArea?.media?.type == TypeMediaFileEnum.MP4) {
            // draw video fix picture
            await this.drawVideoFixPicture(pictureArea, renderer, canvasDisplayId, screenCanvasIdEnum, isFinishSchedule);
          } else {
            // draw fix picture
            await this.drawAreaFixPicture(pictureArea, renderer, canvasDisplayId, screenCanvasIdEnum, template, isFinishSchedule);
          }
        }
      })
    );
  }

  /**
   * clear fix area timing on
   * @param areasTimingOn
   * @param canvasDisplayId
   */
  public clearFixAreaTimingOn(areasTimingOn: Area[], canvasDisplayId: any): void {
    areasTimingOn.forEach(area => {
      if (!area.canvas) {
        return;
      }
      this.clearCanvas(area.canvas);
      if (area.checkTypeTextArea()) {
        if ((area as TextArea).scrollStatus != ScrollStatusEnum.OFF) {
          this.resetPositionScrollText(area as TextArea);
          area[`subscription${canvasDisplayId}`]?.unsubscribe();
          delete area[`subscription${canvasDisplayId}`];
        }
        return;
      }
      // clear video and sound
      if ((area as PictureArea)?.media?.type == TypeMediaFileEnum.MP4) {
        this.stopVideo(area as PictureArea, canvasDisplayId);
      } else {
        this.stopAudio(area as PictureArea);
        this.unsubscribedSubjectArea(area, canvasDisplayId);
      }
    });
  }

  /**
   * clear intervals clock
   */
  public clearIntervalsClock(canvasId: string, screenCanvasIdEnum): void {
    this.listAreaClockIntervals.forEach(item => {
      if (
        (canvasId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}` && item.areaId.includes('Display1')) ||
        (canvasId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_2}` && item.areaId.includes('Display2'))
      ) {
        clearInterval(item.time);
      }
    });
    if (canvasId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
      _.remove(this.listAreaClockIntervals, function(clockInterval) {
        return clockInterval?.areaId.includes('Display1');
      });
    } else {
      _.remove(this.listAreaClockIntervals, function(clockInterval) {
        return clockInterval?.areaId.includes('Display2');
      });
    }
  }

  /**
   * draw free text
   * @param textArea TextArea
   * @param renderer Renderer2
   * @param screenCanvasIdEnum ScreenCanvasIdEnum
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private drawFreeText(textArea: TextArea, renderer: Renderer2, screenCanvasIdEnum: ScreenCanvasIdEnum, canvasDisplayId): void {
    if (!this.timetable?.timetableDetails) {
      return;
    }
    const index = this.findIndexToFreeArea(canvasDisplayId, textArea.id);
    if (index == -1) {
      return;
    }
    const text = this.timetable?.timetableDetails[index].text ?? Constant.EMPTY;
    if (text === Constant.EMPTY) {
      return;
    }
    // draw areaText
    renderer.setStyle(textArea.canvas, 'visibility', 'visible');
    this.drawAreaText(textArea, canvasDisplayId, screenCanvasIdEnum, text);
  }

  /**
   * Find index to free area
   *
   * @param canvasDisplayId
   * @param textArea
   * @returns
   */
  private findIndexToFreeArea(canvasDisplayId: any, areaId: Number): number {
    return canvasDisplayId == Constant.TIMETABLE_CANVAS_DISPLAY_1_ID || canvasDisplayId == Constant.TIMETABLE_OPERATION_CANVAS_DISPLAY_1_ID
      ? this.timetable?.timetableDetails.findIndex(timetableDetail => timetableDetail?.areaDisplay1Id == areaId)
      : this.timetable?.timetableDetails.findIndex(timetableDetail => timetableDetail?.areaDisplay2Id == areaId);
  }

  /**
   * Find index to url area
   * @param canvasDisplayId
   * @param areaId
   * @returns
   */
  private findIndexToUrlArea(canvasDisplayId: any, areaId: Number): number {
    return canvasDisplayId == Constant.TIMETABLE_CANVAS_DISPLAY_1_ID || canvasDisplayId == Constant.TIMETABLE_OPERATION_CANVAS_DISPLAY_1_ID
      ? this.timetable?.timetableUrlDetails.findIndex(timetableDetail => timetableDetail?.areaDisplay1Id == areaId)
      : this.timetable?.timetableUrlDetails.findIndex(timetableDetail => timetableDetail?.areaDisplay2Id == areaId);
  }

  /**
   * draw free picture
   *
   * @param pictureArea PictureArea
   * @param renderer Renderer2
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum ScreenCanvasIdEnum
   * @param template
   */
  private drawFreePicture(
    pictureArea: PictureArea,
    renderer: Renderer2,
    canvasDisplayId,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    template: Template
  ): void {
    if (!this.timetable?.timetableDetails) {
      return;
    }
    const index = this.findIndexToFreeArea(canvasDisplayId, pictureArea.id);
    if (index == -1) {
      return;
    }
    const media = this.timetable.timetableDetails[index]?.media;
    if (!media) {
      return;
    }
    renderer.setStyle(pictureArea.canvas, 'visibility', 'visible');
    pictureArea.media = media;
    if (pictureArea.media instanceof Image) {
      // draw areaPicture
      this.drawAreaPicture(pictureArea, canvasDisplayId, screenCanvasIdEnum, template);
    }
  }

  /**
   * draw free picture
   *
   * @param urlArea urlArea
   * @param renderer Renderer2
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum ScreenCanvasIdEnum
   * @param template
   */
  private async drawUrlPicture(
    urlArea: URLArea,
    renderer: Renderer2,
    canvasDisplayId,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    template: Template
  ): Promise<void> {
    if (!this.timetable?.timetableUrlDetails) {
      return;
    }
    const index = this.findIndexToUrlArea(canvasDisplayId, urlArea.id);
    if (index == -1) {
      if ([Constant.TIMETABLE_CANVAS_DISPLAY_2_ID, Constant.TIMETABLE_OPERATION_CANVAS_DISPLAY_2_ID].includes(canvasDisplayId)) {
        this.drawUrlAreaText(urlArea, 'URL');
      }
      return;
    }
    const media = this.timetable.timetableUrlDetails[index]?.media;
    const textURl = this.timetable.timetableUrlDetails[index]?.textUrl ? this.timetable.timetableUrlDetails[index]?.textUrl : 'URL';
    renderer.setStyle(urlArea.canvas, 'visibility', 'visible');
    this.drawUrlAreaText(urlArea, textURl);
  }

  /**
   * draw Url Area Text
   */
  private drawUrlAreaText(urlArea: URLArea, text?: string) {
    urlArea.canvas.style.border = '3px ' + 'solid' + '#FFEB8D';
    let ctx = urlArea.canvas.getContext('2d');
    ctx.clearRect(0, 0, urlArea.canvas.width, urlArea.canvas.height);
    ctx.font = 'normal 50px Arial';
    ctx.fillStyle = '#000000';
    ctx.fillRect(0, 0, urlArea.canvas.width, urlArea.canvas.height);
    ctx.fillStyle = '#FFFFFF';
    ctx.fillText(text, 0, 50);
  }

  /**
   * draw data schedule
   *
   * @param activeScheduleRow
   * @param textAreas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param referencePositionColumnsByTemplate
   */
  public drawSchedule(
    activeScheduleRow: ActiveScheduleRow,
    textAreas: TextArea[],
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    referencePositionColumnsByTemplate: Array<number>
  ) {
    if (!activeScheduleRow) {
      return;
    }
    Promise.all(
      textAreas.map(textArea => {
        if (!textArea.canvas) {
          return;
        }
        let ctx = textArea.canvas.getContext('2d');
        ctx.clearRect(0, 0, textArea.canvas.width, textArea.canvas.height);
        let textDraw: string = '';
        if (textArea.linkReferenceData == LinkDataTextEnum.TIMETABLE) {
          let indexPositionColumn = referencePositionColumnsByTemplate.find(column => column == textArea.referencePositionColumn);
          if (indexPositionColumn != undefined) {
            textDraw = activeScheduleRow[`current_${textArea.referencePositionRow}`]?.list[indexPositionColumn] ?? '';
          }
        } else if (textArea.linkReferenceData == LinkDataTextEnum.OPERATION_INFO) {
          textDraw = activeScheduleRow[`current_${textArea.referencePositionRow}`]?.operationInfo?.text;
        }
        if (
          textArea.linkReferenceData == LinkDataTextEnum.TIMETABLE &&
          textArea.referencePositionColumn == ReferencePositionTimetableColumnEnum.TIME &&
          textDraw.length != 0
        ) {
          textDraw = Helper.convertTimeTimetableSchedule(textDraw, true);
        }
        if (!textDraw || textDraw === '') {
          return;
        }
        this.drawAreaText(textArea, canvasDisplayId, screenCanvasIdEnum, textDraw);
      })
    );
  }

  /**
   * draw index word by schedule
   *
   * @param areas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param template
   */
  public drawIndexWordBySchedule(areas: Area[], canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum, template: Template) {
    areas.forEach(area => {
      if (!area.canvas) {
        return;
      }
      let ctx = area.canvas.getContext('2d');
      ctx.clearRect(0, 0, area.canvas.width, area.canvas.height);

      if (area.checkTypeTextArea()) {
        const textDraw = area.getArea().text ?? Constant.EMPTY;
        if (textDraw === Constant.EMPTY) {
          return;
        }
        this.drawAreaText(area as TextArea, canvasDisplayId, screenCanvasIdEnum, textDraw);
      } else if (area.getArea().media) {
        this.drawAreaIndexWord(area as PictureArea, template);
      }
    });
  }

  /**
   * draw all dynamic messages
   *
   * @param dynamicMessages
   * @param areas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param template
   * @returns
   */
  public drawDynamicMessages(
    dynamicMessages: DynamicMessage[],
    areas: Area[],
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    template: Template
  ) {
    if (areas?.length == 0) {
      return;
    }
    Promise.all(
      areas.map(area => {
        if (!area.canvas) {
          return;
        }
        if (area.checkTypeTextArea()) {
          let textArea = area as TextArea;
          const dynamicMessage = dynamicMessages.find(message => message.textArea?.id == textArea.id);
          let textDraw = dynamicMessage?.message ?? Constant.EMPTY;
          if (textDraw === Constant.EMPTY) {
            return;
          }
          this.drawAreaText(textArea, canvasDisplayId, screenCanvasIdEnum, textDraw);
        } else {
          let pictureArea = area as PictureArea;
          const dynamicMessage = dynamicMessages.find(message => message.pictureArea?.id == pictureArea.id);
          let media = dynamicMessage?.media ?? null;
          if (!media) {
            return;
          }
          pictureArea.media = media;
          this.drawAreaPicture(pictureArea, canvasDisplayId, screenCanvasIdEnum, template);
        }
      })
    );
  }

  /**
   * draw emergency
   *
   * @param emergencyData
   * @param areas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param template
   * @returns
   */
  public drawEmergency(
    emergencyData: EmergencyData,
    areas: Area[],
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    template: Template
  ): void {
    if (!emergencyData || !areas?.length) {
      return;
    }
    Promise.all(
      areas.map(area => {
        if (!area.canvas) {
          return;
        }
        if (area.checkTypeTextArea()) {
          const textArea = <TextArea>area;
          let textDraw = emergencyData.emergencyText ?? Constant.EMPTY;
          if (textDraw === Constant.EMPTY) {
            return;
          }
          this.drawAreaText(textArea, canvasDisplayId, screenCanvasIdEnum, textDraw);
        } else if (emergencyData.media) {
          let pictureArea = <PictureArea>area;
          pictureArea.media = emergencyData.media;
          this.drawAreaPicture(pictureArea, canvasDisplayId, screenCanvasIdEnum, template);
        }
      })
    );
  }

  /**
   * Draw free Area
   *
   * @param areas
   * @param renderer
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param template
   * @returns
   */
  public drawFreeArea(
    areas: Area[],
    renderer: Renderer2,
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    template: Template
  ) {
    if (!areas || areas?.length == 0) {
      return;
    }
    Promise.all(
      areas.map(area => {
        if (area.checkTypeTextArea()) {
          const textArea = <TextArea>area;
          this.drawFreeText(textArea, renderer, screenCanvasIdEnum, canvasDisplayId);
        } else {
          const pictureArea = <PictureArea>area;
          this.drawFreePicture(pictureArea, renderer, canvasDisplayId, screenCanvasIdEnum, template);
        }
      })
    );
  }

  /**
   * Draw url Area
   *
   * @param areas
   * @param renderer
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param template
   * @returns
   */
  public drawUrlArea(areas: Area[], renderer: Renderer2, canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum, template: Template) {
    if (!areas || areas?.length == 0) {
      return;
    }
    Promise.all(
      areas.map(area => {
        const urlArea = <URLArea>area;
        this.drawUrlPicture(urlArea, renderer, canvasDisplayId, screenCanvasIdEnum, template);
      })
    );
  }

  /**
   * draw operation info
   *
   * @param activeScheduleRow
   * @param textAreas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  public drawOperationInfo(
    activeScheduleRow: ActiveScheduleRow,
    textAreas: TextArea[],
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum
  ) {
    Promise.all(
      textAreas.map(textArea => {
        if (!textArea.canvas) {
          return;
        }
        let ctx = textArea.canvas.getContext('2d');
        ctx.clearRect(0, 0, textArea.canvas.width, textArea.canvas.height);
        let textDraw = activeScheduleRow[`current_${textArea.referencePositionRow}`]?.operationInfo?.text;
        if (textDraw) {
          this.drawAreaText(textArea, canvasDisplayId, screenCanvasIdEnum, textDraw);
        }
      })
    );
  }

  /**
   * Draw timetable monitor
   *
   * @param activeScheduleRow
   * @param textAreas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @returns
   */
  public drawTimetableMonitor(
    activeScheduleRow: ActiveScheduleRow,
    textAreas: TextArea[],
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    referencePositionColumnsByTemplate: Array<number>
  ) {
    if (!activeScheduleRow) {
      return;
    }
    Promise.all(
      textAreas.map(textArea => {
        if (!textArea.canvas) {
          return;
        }
        let ctx = textArea.canvas.getContext('2d');
        ctx.clearRect(0, 0, textArea.canvas.width, textArea.canvas.height);
        let textDraw: string = '';
        let indexPositionColumn = referencePositionColumnsByTemplate.find(column => column == textArea.referencePositionColumn);
        if (indexPositionColumn != undefined) {
          textDraw = activeScheduleRow[`current_${textArea.referencePositionRow}`]?.list[indexPositionColumn] ?? '';
          if (
            textArea.linkReferenceData == LinkDataTextEnum.TIMETABLE &&
            textArea.referencePositionColumn == ReferencePositionTimetableColumnEnum.TIME
          ) {
            textDraw = Helper.convertTimeTimetableSchedule(textDraw, true);
          }
        }
        if (!textDraw || textDraw === '') {
          return;
        }
        this.drawAreaText(textArea, canvasDisplayId, screenCanvasIdEnum, textDraw);
      })
    );
  }

  /**
   * draw operation info monitor
   *
   * @param delayOperationInfo
   * @param textAreas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  public drawOperationInfoMonitor(
    delayOperationInfo: any[],
    textAreas: TextArea[],
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum
  ) {
    Promise.all(
      textAreas.map(textArea => {
        if (!textArea.canvas) {
          return;
        }
        let ctx = textArea.canvas.getContext('2d');
        ctx.clearRect(0, 0, textArea.canvas.width, textArea.canvas.height);
        let textDraw = delayOperationInfo[textArea.referencePositionRow] ?? Constant.EMPTY;
        if (textDraw === Constant.EMPTY) {
          return;
        }
        this.drawAreaText(textArea, canvasDisplayId, screenCanvasIdEnum, Helper.decodeHTML(textDraw));
      })
    );
  }

  /**
   *
   * @param areaTexts
   * @param renderer
   * @param canvasDisplayId
   * @param timetableSchedule
   * @param currentIndex
   * @param screenCanvasIdEnum
   * @param referencePositionColumnsByTemplate
   * @returns
   */
  public async drawAreasTimetable(
    areaTexts: TextArea[],
    renderer: Renderer2,
    canvasDisplayId: any,
    timetableSchedule: TimetableSchedule,
    currentIndex: number,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    referencePositionColumnsByTemplate: Array<number>
  ) {
    if (!timetableSchedule?.itemDetails || !areaTexts?.length) {
      return;
    }
    await areaTexts.forEach(areaText => {
      let textDraw =
        this.getTextDrawFromTimetableSchedule(areaText, timetableSchedule, currentIndex, referencePositionColumnsByTemplate) ??
        Constant.EMPTY;
      if (textDraw === Constant.EMPTY) {
        return;
      }
      // draw areaText
      renderer.setStyle(areaText.canvas, 'visibility', 'visible');
      this.drawAreaText(areaText, canvasDisplayId, screenCanvasIdEnum, textDraw);
    });
  }

  /**
   * draw areas timetable layer on
   * @param areaTexts
   * @param renderer
   * @param canvasDisplayId
   * @param timetableSchedule
   * @param currentIndex
   * @param screenCanvasIdEnum
   * @param referencePositionColumnsByTemplate
   * @returns
   */
  public async drawAreasTimetableLayerOn(
    areaTexts: TextArea[],
    renderer: Renderer2,
    canvasDisplayId: any,
    timetableSchedule: TimetableSchedule,
    currentIndex: number,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    referencePositionColumnsByTemplate: Array<number>
  ) {
    if (!timetableSchedule?.itemDetails) {
      return;
    }
    const areaIdsDrawing = areaTexts.map(area => area.idWithDisplay);
    const areasTimetable = this.areasDrawing.filter(areaText => areaIdsDrawing.includes(areaText?.idWithDisplay));
    this.drawAreasTimetable(
      areasTimetable as TextArea[],
      renderer,
      canvasDisplayId,
      timetableSchedule,
      currentIndex,
      screenCanvasIdEnum,
      referencePositionColumnsByTemplate
    );
  }

  /**
   * draw operation info
   *
   * @param activeScheduleRow
   * @param textAreas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  public drawOperationInfoOn(
    activeScheduleRow: ActiveScheduleRow,
    textAreas: TextArea[],
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum
  ) {
    const areaIdsDrawing = textAreas.map(area => area.idWithDisplay);
    const areasOperation = this.areasDrawing.filter(areaText => areaIdsDrawing.includes(areaText?.idWithDisplay));
    this.drawOperationInfo(activeScheduleRow, areasOperation as TextArea[], canvasDisplayId, screenCanvasIdEnum);
  }

  /**
   * set data schedule
   * @param timetableSchedule
   * @param dataResponse
   * @param pictureAreaService
   * @param areasIndexWordDisplay
   */
  public setDataPreviewTimetableEditor(
    timetableSchedule: TimetableSchedule,
    dataResponse?: any,
    pictureAreaService?: PictureAreaService,
    areasIndexWordDisplay?: Area[]
  ): void {
    if (timetableSchedule) {
      this.timetableSchedule = timetableSchedule;
    }
    if (dataResponse) {
      this.dataResponse = dataResponse;
    }
    if (pictureAreaService) {
      this.pictureAreaService = pictureAreaService;
    }
    if (areasIndexWordDisplay) {
      areasIndexWordDisplay.forEach(e => {
        let index = this.areasIndexWordDisplay ? this.areasIndexWordDisplay.findIndex(area => area.idWithDisplay == e.idWithDisplay) : -1;
        if (index == -1) {
          this.areasIndexWordDisplay.push(e);
        } else {
          this.areasIndexWordDisplay[index] = e;
        }
      });
    }
  }

  /**
   * set data preview timetable operation manager
   * @param activeScheduleRow
   * @param referencePositionColumnsByTemplate
   * @param dynamicMessages
   * @param emergencyData
   */
  public setDataPreviewTimetableOperationManager(
    activeScheduleRow: ActiveScheduleRow,
    referencePositionColumnsByTemplate?: number[],
    dynamicMessages?: DynamicMessage[],
    emergencyData?: EmergencyData,
    areasIndexWordDisplay?: Area[]
  ) {
    if (activeScheduleRow) {
      this.activeScheduleRow = activeScheduleRow;
    }
    if (referencePositionColumnsByTemplate) {
      referencePositionColumnsByTemplate.forEach(e => {
        if (!this.referencePositionColumnsByTemplate.includes(e)) this.referencePositionColumnsByTemplate.push(e);
      });
      //this.referencePositionColumnsByTemplate = referencePositionColumnsByTemplate;
    }
    if (dynamicMessages) {
      this.dynamicMessages = dynamicMessages;
    }
    if (emergencyData) {
      this.emergencyData = emergencyData;
    }
    if (areasIndexWordDisplay) {
      areasIndexWordDisplay.forEach(e => {
        let index = this.areasIndexWordDisplay ? this.areasIndexWordDisplay.findIndex(area => area.idWithDisplay == e.idWithDisplay) : -1;
        if (index == -1) {
          this.areasIndexWordDisplay.push(e);
        } else {
          this.areasIndexWordDisplay[index] = e;
        }
      });
    }
  }

  /**
   * set data is on emergency
   * @param isOnEmergency
   */
  public setDataIsOnEmergency(isOnEmergency: boolean): void {
    this.isOnEmergency = isOnEmergency;
  }

  /**
   * set data timetables
   * @param currentIndex
   * @param referencePoint
   */
  public setDataTimetables(currentIndex: number, referencePoint: number[]): void {
    this.currentIndexTimetableSchedule = currentIndex;
    this.referencePositionColumnsByTemplate = referencePoint;
  }

  /**
   * draw area index world
   *
   * @param areas
   * @param canvasDisplayId
   * @param timetableSchedule
   * @param screenCanvasIdEnum
   * @param template
   * @returns
   */
  public async drawAreasIndexWord(
    areas: Area[],
    canvasDisplayId: any,
    timetableSchedule: TimetableSchedule,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    template: Template
  ) {
    if (!timetableSchedule?.itemDetails) {
      return;
    }
    Promise.all(
      areas.map(area => {
        if (area.checkTypeTextArea()) {
          const textDraw = area.getArea().text ?? Constant.EMPTY;
          if (textDraw === Constant.EMPTY) {
            return;
          }
          this.drawAreaText(area as TextArea, canvasDisplayId, screenCanvasIdEnum, textDraw);
        } else if (area.getArea().media) {
          this.drawAreaIndexWord(area as PictureArea, template);
        }
      })
    );
  }

  /**
   * draw areas index word layer on
   * @param areas
   * @param canvasDisplayId
   * @param timetableSchedule
   * @param screenCanvasIdEnum
   * @returns
   */
  public async drawAreasIndexWordLayerOn(
    areas: Area[],
    canvasDisplayId: any,
    timetableSchedule: TimetableSchedule,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    template: Template
  ) {
    if (!timetableSchedule?.itemDetails) {
      return;
    }
    const areasIdIndexWord = areas.map(area => area.idWithDisplay);
    let areasIndexWord = this.areasDrawing.filter(areaText => areasIdIndexWord.includes(areaText?.idWithDisplay));
    this.setDataIndexWordForAreasDrawing(areasIndexWord, areas);
    this.drawAreasIndexWord(areasIndexWord, canvasDisplayId, timetableSchedule, screenCanvasIdEnum, template);
  }

  /**
   * set data index word for areas drawing
   * @param areasIndexWord
   * @param areas
   */
  private setDataIndexWordForAreasDrawing(areasIndexWord: Area[], areas: Area[]) {
    areasIndexWord.forEach(item => {
      let areaIndexWord = areas.find(area => area.idWithDisplay == item.idWithDisplay);
      item.getArea().text = areaIndexWord ? areaIndexWord.getArea().text : '';
      item.getArea().media = areaIndexWord ? areaIndexWord.getArea().media : null;
    });
  }

  /**
   * draw dynamic messages layer on
   *
   * @param dynamicMessages
   * @param textAreas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @returns
   */
  public drawDynamicMessagesOn(
    dynamicMessages: DynamicMessage[],
    areas: Area[],
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    template: Template
  ) {
    if (areas?.length == 0) {
      return;
    }
    const areasIdDynamicMessage = areas.map(area => area.idWithDisplay);
    const areasDynamicMessage = this.areasDrawing.filter(areaText => areasIdDynamicMessage.includes(areaText?.idWithDisplay));
    this.drawDynamicMessages(dynamicMessages, areasDynamicMessage, canvasDisplayId, screenCanvasIdEnum, template);
  }

  /**
   * draw emergency layer on
   *
   * @param emergencyData
   * @param areas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param template
   * @returns
   */
  public drawEmergencyOn(
    emergencyData: EmergencyData,
    areas: Area[],
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    template: Template
  ): void {
    if (!emergencyData || !areas?.length) {
      return;
    }
    const areasIdEmergency = areas.map(area => area.idWithDisplay);
    const areasEmergency = this.areasDrawing.filter(areaText => areasIdEmergency.includes(areaText?.idWithDisplay));
    this.drawEmergency(emergencyData, areasEmergency, canvasDisplayId, screenCanvasIdEnum, template);
  }

  /**
   * draw data schedule layer on
   *
   * @param activeScheduleRow
   * @param textAreas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param referencePositionColumnsByTemplate
   */
  public drawScheduleOn(
    activeScheduleRow: ActiveScheduleRow,
    textAreas: TextArea[],
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    referencePositionColumnsByTemplate: Array<number>
  ) {
    if (!activeScheduleRow) {
      return;
    }
    const areasIdSchedule = textAreas.map(area => area.idWithDisplay);
    const areasSchedule = this.areasDrawing.filter(areaText => areasIdSchedule.includes(areaText?.idWithDisplay));
    this.drawSchedule(
      activeScheduleRow,
      areasSchedule as TextArea[],
      canvasDisplayId,
      screenCanvasIdEnum,
      referencePositionColumnsByTemplate
    );
  }

  /**
   * draw index word by schedule layer on
   *
   * @param areas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param template
   */
  public drawIndexWordByScheduleOn(areas: Area[], canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum, template: Template) {
    const areasIdIndexWord = areas.map(area => area.idWithDisplay);
    const areasIndexWord = this.areasDrawing.filter(areaText => areasIdIndexWord.includes(areaText?.idWithDisplay));
    this.setDataIndexWordForAreasDrawing(areasIndexWord, areas);
    this.drawIndexWordBySchedule(areasIndexWord, canvasDisplayId, screenCanvasIdEnum, template);
  }

  /**
   * Draw free Area layer on
   *
   * @param areas
   * @param renderer
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @returns
   */
  public drawFreeAreaOn(
    areas: Area[],
    renderer: Renderer2,
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    template: Template
  ) {
    if (!areas || areas?.length == 0) {
      return;
    }
    const areasIdFreeArea = areas.map(area => area.idWithDisplay);
    const areasFreeArea = this.areasDrawing.filter(areaText => areasIdFreeArea.includes(areaText?.idWithDisplay));
    this.drawFreeArea(areasFreeArea, renderer, canvasDisplayId, screenCanvasIdEnum, template);
  }

  /**
   * Draw url Area layer on
   *
   * @param areas
   * @param renderer
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   * @param template
   * @returns
   */
  public drawUrlAreaOn(
    areas: Area[],
    renderer: Renderer2,
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    template: Template
  ) {
    if (!areas || areas?.length == 0) {
      return;
    }
    const areasIdUrlArea = areas.map(area => area.idWithDisplay);
    const areasUrlArea = this.areasDrawing.filter(areaText => areasIdUrlArea.includes(areaText?.idWithDisplay));
    this.drawUrlArea(areasUrlArea, renderer, canvasDisplayId, screenCanvasIdEnum, template);
  }

  /**
   * draw area index word
   *
   * @param areaPicture
   * @param template
   */
  private async drawAreaIndexWord(areaPicture: PictureArea, template: Template) {
    // if image is svg file -> convert w, h for file
    if (areaPicture.media) {
      if (!areaPicture.media['width'] && !areaPicture.media['height']) {
        delete areaPicture.media['width'];
        delete areaPicture.media['height'];
        let imageInfo = await Helper.getImageInformation(areaPicture.media);
        areaPicture.media['width'] = `${imageInfo.width}`;
        areaPicture.media['height'] = `${imageInfo.height}`;
      }
    }
    try {
      // draw canvas
      let $this = this;
      let ctx = areaPicture.canvas.getContext('2d');
      let mediaPosition = Helper.coverMedia(areaPicture.canvas, areaPicture.media, areaPicture.objectFit);
      let img = document.createElement('img');
      img.src = areaPicture.media.url;
      const indexArea = this.mediaIndexWords.findIndex(media => media?.key == this.keyUnique && media?.img.src == img.src);
      if (indexArea == -1) {
        this.mediaIndexWords.push(new MediaIndexWord(this.keyUnique, img));
      } else {
        this.mediaIndexWords[indexArea] = new MediaIndexWord(this.keyUnique, img);
      }
      let layerOfArea = template ? Helper.findLayerOfArea(areaPicture, template) : undefined;
      img.onload = function() {
        if (
          !layerOfArea ||
          !layerOfArea.isSwitchingArea ||
          !$this.switchingTiming ||
          (layerOfArea.isSwitchingArea && $this.areasDrawing?.map(area => area?.idWithDisplay)?.includes(areaPicture?.idWithDisplay))
        ) {
          let index = $this.mediaIndexWords.findIndex(media => media?.key == $this.keyUnique && media?.img.src == img.src);
          if (index == -1) {
            return;
          }
          ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
          if (areaPicture.objectFit == ObjectFitEnum.FILL) {
            ctx.drawImage($this.mediaIndexWords[index].img, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
          } else {
            ctx.drawImage(
              $this.mediaIndexWords[index].img,
              mediaPosition.sX,
              mediaPosition.sY,
              mediaPosition.sWidth,
              mediaPosition.sHeight,
              mediaPosition.x,
              mediaPosition.y,
              mediaPosition.width,
              mediaPosition.height
            );
          }
        }
      };
    } catch (error) {
      console.log('error draw 3', error);
    }
  }

  /**
   * get text form schedule
   * @param textArea
   * @param timetableSchedule
   * @param currentIndex
   * @param referencePositionColumnsByTemplate
   * @returns
   */
  public getTextDrawFromTimetableSchedule(
    textArea: TextArea,
    timetableSchedule: TimetableSchedule,
    currentIndex: number,
    referencePositionColumnsByTemplate: Array<number>
  ): string {
    // get list item detail
    let itemDetails = timetableSchedule?.itemDetails;
    // get index of row area
    let indexCurrentOfArea = textArea.referencePositionRow + currentIndex;
    let indexPositionColumn = referencePositionColumnsByTemplate.find(column => column == textArea.referencePositionColumn);
    if (indexPositionColumn == undefined) {
      return '';
    }
    let textResult = itemDetails[indexCurrentOfArea]?.items[indexPositionColumn];
    // if bad format
    if (
      itemDetails &&
      itemDetails[indexCurrentOfArea] &&
      !itemDetails[indexCurrentOfArea].isInValidFormat &&
      textArea.referencePositionColumn == ReferencePositionTimetableColumnEnum.TIME
    ) {
      // if text start with 0, remove
      if (textResult?.startsWith('0')) {
        textResult = textResult.substring(1);
      }
      // if text type as 'HH:MM:SS' => only get HH:MM
      if (textResult?.split(':').length == Constant.NUMBER_ELEMENTS_OF_SECOND_FORMAT) {
        textResult = textResult.substring(0, textResult.length - 3);
      }
    }
    // return textResult
    return textResult;
  }

  /**
   * draw fix text preview
   * @param areaText TextArea
   * @param renderer Renderer2
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum
   * @param isFinishSchedule
   */
  private drawAreaFixText(
    areaText: TextArea,
    renderer: Renderer2,
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    isFinishSchedule?: boolean
  ): void {
    if (areaText.isTimingOn && !isFinishSchedule) {
      return;
    }
    renderer.setStyle(areaText.canvas, 'visibility', 'visible');
    let text = areaText.text;
    // draw areaText
    this.drawAreaText(areaText, canvasDisplayId, screenCanvasIdEnum, text);
  }

  /**
   * draw text
   * @param areaText TextArea
   * @param text
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private drawAreaText(areaText: TextArea, canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum, text?: string): void {
    // get ctx from canvas
    let ctx = areaText.canvas.getContext('2d');
    // draw color background
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // set style bold / italic / normal
    let textStyle = 'normal';
    if (areaText.isBold && !areaText.isItalic) {
      textStyle = Constant.BOLD_STYLE;
    } else if (areaText.isItalic && !areaText.isBold) {
      textStyle = Constant.ITALIC_STYLE;
    } else if (areaText.isItalic && areaText.isBold) {
      textStyle = `${Constant.BOLD_STYLE} ${Constant.ITALIC_STYLE}`;
    }
    // draw color text
    ctx.fillStyle = areaText.fontColor;
    // draw font text
    ctx.font = `${textStyle} ${areaText.fontSize}px ${!areaText.fontName ? 'Arial' : areaText.fontName}`;
    // set orientation
    if (areaText.orientation == OrientationEnum.HORIZONTAL) {
      this.drawTextOrientationHorizontal(ctx, areaText, text, canvasDisplayId, screenCanvasIdEnum);
    } else if (areaText.orientation == OrientationEnum.VERTICAL) {
      this.drawTextOrientationVertical(ctx, areaText, text, canvasDisplayId, screenCanvasIdEnum);
    } else if (areaText.orientation == OrientationEnum.SIDEWAYS) {
      this.drawTextOrientationSideways(ctx, areaText, text, canvasDisplayId, screenCanvasIdEnum);
    }
  }

  /**
   * draw text orientation horizontal
   * @param ctx
   * @param areaText area draw
   * @param text text need to draw
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum (timetableEditor or timetableOperation)
   */
  private drawTextOrientationHorizontal(
    ctx: any,
    areaText: TextArea,
    text: string,
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum
  ): void {
    var distanceX = 0;
    var textDraw = text ?? this.getTimer();
    const measureText = ctx.measureText(textDraw);
    const widthMeasureText = measureText.width;
    const referencePosition = Helper.getReferencePositionOrientationHorizontal(ctx, areaText);
    let referenceX = referencePosition.referenceX;
    let referenceY = referencePosition.referenceY;
    switch (ctx.textAlign) {
      case 'left':
        referenceX = 0;
        break;
      case 'center':
        distanceX = referenceX - widthMeasureText / 2;
        break;
      case 'right':
        distanceX = referenceX - widthMeasureText;
        break;
    }
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // draw color text
    ctx.fillStyle = areaText.fontColor;
    ctx.fillText(textDraw, referenceX + areaText.posXScroll, referenceY + areaText.posYScroll);

    // if textDraw empty or (scroll AUTO and width text < width area or height text < height area) => no draw scroll
    if (textDraw == '' || areaText.scrollStatus == ScrollStatusEnum.OFF || this.canNotScrollTextHorizontal(areaText, measureText)) {
      areaText[`subscription${canvasDisplayId}`]?.unsubscribe();
      return;
    }
    areaText[`isStart${screenCanvasIdEnum}`] =
      screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR ? this.isStartTimetable : this.isStartTimetableOperation;
    if (areaText[`subscription${canvasDisplayId}`]) {
      areaText[`subscription${canvasDisplayId}`].unsubscribe();
    }
    let timeout = setTimeout(() => {
      // draw scroll text after stop duration
      const observable = interval(50);
      const subscription =
        canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
          ? observable
              .pipe(
                takeUntil(this.clearPreviewDisplay1Subject),
                takeUntil(this.pausePreviewDisplay1Subject),
                repeatWhen(() => this.startPreviewDisplay1Subject)
              )
              .subscribe(() => {
                this.drawScrollHorizontalText(
                  areaText,
                  widthMeasureText,
                  distanceX,
                  canvasDisplayId,
                  ctx,
                  text,
                  screenCanvasIdEnum,
                  referenceX,
                  referenceY
                );
              })
          : observable
              .pipe(
                takeUntil(this.clearPreviewDisplay2Subject),
                takeUntil(this.pausePreviewDisplay2Subject),
                repeatWhen(() => this.startPreviewDisplay2Subject)
              )
              .subscribe(() => {
                this.drawScrollHorizontalText(
                  areaText,
                  widthMeasureText,
                  distanceX,
                  canvasDisplayId,
                  ctx,
                  text,
                  screenCanvasIdEnum,
                  referenceX,
                  referenceY
                );
              });
      areaText[`subscription${canvasDisplayId}`] = subscription;
      areaText[`clearPreviewSubject${canvasDisplayId}`] =
        canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
          ? this.clearPreviewDisplay1Subject.subscribe(() => {
              this.resetPositionScrollText(areaText);
              subscription.unsubscribe();
              areaText[`clearPreviewSubject${canvasDisplayId}`].unsubscribe();
            })
          : this.clearPreviewDisplay2Subject.subscribe(() => {
              this.resetPositionScrollText(areaText);
              subscription.unsubscribe();
              areaText[`clearPreviewSubject${canvasDisplayId}`].unsubscribe();
            });
    }, areaText.stopDuration * 1000);
    // push timeout to list => remove if needed
    if (canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
      if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
        this.timeoutsDisplay1Timetable.push(new TimeOut(timeout, areaText.linkReferenceData, areaText.id));
      } else {
        this.timeoutsDisplay1TimetableOperation.push(new TimeOut(timeout, areaText.linkReferenceData, areaText.id));
      }
    } else {
      if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
        this.timeoutsDisplay2Timetable.push(new TimeOut(timeout, areaText.linkReferenceData, areaText.id));
      } else {
        this.timeoutsDisplay2TimetableOperation.push(new TimeOut(timeout, areaText.linkReferenceData, areaText.id));
      }
    }
  }

  /**
   * Can not scroll text horizontal
   *
   * @param areaText
   * @param measureText
   * @returns true if width text < textArea.width or height text < textArea.height
   */
  private canNotScrollTextHorizontal(areaText: TextArea, measureText: any): boolean {
    const isScrollHorizontal =
      areaText.scrollDirection == ScrollDirectionsEnum.LEFT || areaText.scrollDirection == ScrollDirectionsEnum.RIGHT;
    return (
      (areaText.horizontalTextAlignment == AlignmentEnum.CENTER && areaText.verticalTextAlignment == AlignmentEnum.MIDDLE) ||
      (areaText.scrollStatus == ScrollStatusEnum.AUTO &&
        (isScrollHorizontal
          ? measureText.width < areaText.width
          : measureText.actualBoundingBoxDescent + measureText.actualBoundingBoxAscent < areaText.height))
    );
  }

  /**
   * Can note scroll text vertical and side way
   *
   * @param areaText
   * @param textHeight
   * @param maxWidth
   * @returns true if width text < textArea.width or height text < textArea.height
   */
  private canNotScrollTextVerticalAndSideways(areaText: TextArea, textHeight: number, maxWidth: number) {
    const isScrollHorizontal =
      areaText.scrollDirection == ScrollDirectionsEnum.LEFT || areaText.scrollDirection == ScrollDirectionsEnum.RIGHT;
    return (
      (areaText.horizontalTextAlignment == AlignmentEnum.CENTER && areaText.verticalTextAlignment == AlignmentEnum.MIDDLE) ||
      (areaText.scrollStatus == ScrollStatusEnum.AUTO && (isScrollHorizontal ? maxWidth < areaText.width : textHeight < areaText.height))
    );
  }

  /**
   * draw scroll horizontal text
   *
   * @param areaText
   * @param widthMeasureText
   * @param distanceX
   * @param canvasDisplayId
   * @param ctx
   * @param text
   * @param screenCanvasIdEnum
   * @param referenceX
   * @param referenceY
   */
  private drawScrollHorizontalText(
    areaText: TextArea,
    widthMeasureText: number,
    distanceX: number,
    canvasDisplayId: any,
    ctx: any,
    text: string,
    screenCanvasIdEnum: any,
    referenceX: number,
    referenceY: number
  ): void {
    if (this.isPlay) {
      // start preview
      if (areaText[`isStart${screenCanvasIdEnum}`]) {
        areaText[`subscription${canvasDisplayId}`].unsubscribe();
        if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
          this.isStartTimetable = false;
        } else {
          this.isStartTimetableOperation = false;
        }
        areaText[`isStart${screenCanvasIdEnum}`] = false;
        this.drawTextOrientationHorizontal(ctx, areaText, text, canvasDisplayId, screenCanvasIdEnum);
      } else {
        // finish scroll text (direction left)
        if (areaText.scrollDirection == ScrollDirectionsEnum.LEFT && Math.floor(areaText.posXScroll) < -Math.floor(widthMeasureText)) {
          areaText.posXScroll = 0;
          this.handleClearSubscriptionScroll(areaText, canvasDisplayId, ctx, text, screenCanvasIdEnum, OrientationEnum.HORIZONTAL);
        } else if (
          areaText.scrollDirection == ScrollDirectionsEnum.RIGHT &&
          Math.floor(areaText.posXScroll) > Math.floor(widthMeasureText)
        ) {
          // finish scroll text (direction right)
          areaText.posXScroll = 0;
          this.handleClearSubscriptionScroll(areaText, canvasDisplayId, ctx, text, screenCanvasIdEnum, OrientationEnum.HORIZONTAL);
        } else if (
          areaText.scrollDirection == ScrollDirectionsEnum.UP &&
          Math.floor(areaText.posYScroll) < -Math.floor(areaText.fontSize)
        ) {
          // finish scroll text (direction up)
          areaText.posYScroll = 0;
          this.handleClearSubscriptionScroll(areaText, canvasDisplayId, ctx, text, screenCanvasIdEnum, OrientationEnum.HORIZONTAL);
        } else if (
          areaText.scrollDirection == ScrollDirectionsEnum.DOWN &&
          Math.floor(areaText.posYScroll) > Math.floor(areaText.height + areaText.fontSize)
        ) {
          // finish scroll text (direction down)
          areaText.posYScroll = 0;
          this.handleClearSubscriptionScroll(areaText, canvasDisplayId, ctx, text, screenCanvasIdEnum, OrientationEnum.HORIZONTAL);
        } else {
          // draw scroll text
          this.drawTextScrollHorizontal(ctx, areaText, text, referenceX, referenceY);
        }
      }
    }
  }

  /**
   * Handle clear subscription horizontal scroll
   *
   * @param areaText
   * @param canvasDisplayId
   * @param ctx
   * @param text
   * @param screenCanvasIdEnum
   * @param orientation
   */
  private handleClearSubscriptionScroll(
    areaText: TextArea,
    canvasDisplayId: any,
    ctx: any,
    text: string,
    screenCanvasIdEnum: any,
    orientation: OrientationEnum
  ): void {
    areaText[`subscription${canvasDisplayId}`].unsubscribe();
    this.clearTimeoutFinishScroll(areaText, canvasDisplayId, screenCanvasIdEnum);
    switch (orientation) {
      case OrientationEnum.HORIZONTAL:
        this.drawTextOrientationHorizontal(ctx, areaText, text, canvasDisplayId, screenCanvasIdEnum);
        break;
      case OrientationEnum.VERTICAL:
        this.drawTextOrientationVertical(ctx, areaText, text, canvasDisplayId, screenCanvasIdEnum);
        break;
      case OrientationEnum.SIDEWAYS:
        this.drawTextOrientationSideways(ctx, areaText, text, canvasDisplayId, screenCanvasIdEnum);
        break;
      default:
        break;
    }
  }

  /**
   * clear time out finish scroll
   * @param areaText
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  private clearTimeoutFinishScroll(areaText: TextArea, canvasDisplayId: any, screenCanvasIdEnum: any): void {
    // screen Timetable Editor
    if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
      // clear timeout display 1
      if (canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
        this.clearTimeoutForAreaText(this.timeoutsDisplay1Timetable, areaText);
      } else {
        // clear timeout display 2
        this.clearTimeoutForAreaText(this.timeoutsDisplay2Timetable, areaText);
      }
    } else {
      // clear timeout display 1
      if (canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
        this.clearTimeoutForAreaText(this.timeoutsDisplay1TimetableOperation, areaText);
      } else {
        // clear timeout display 2
        this.clearTimeoutForAreaText(this.timeoutsDisplay2TimetableOperation, areaText);
      }
    }
  }

  /**
   * clear timeout for area text
   * @param timeouts
   * @param areaText
   */
  private clearTimeoutForAreaText(timeouts: Array<TimeOut>, areaText: TextArea): void {
    const index = timeouts.findIndex(timeout => timeout.areaId == areaText.id);
    if (index == -1) {
      return;
    }
    clearTimeout(timeouts[index].time);
    timeouts.splice(index, 1);
  }

  /**
   * clear all time out of stop duration
   * @param isClearAllArea
   * @param screenCanvasIdEnum
   * @param types
   */
  public clearTimeoutsStopDurationArea(
    isClearAllArea: boolean,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    types?: Array<LinkDataTextEnum>
  ): void {
    let timeoutsAreaDisplay1;
    let timeoutsAreaDisplay2;

    if (isClearAllArea) {
      if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
        timeoutsAreaDisplay1 = this.timeoutsDisplay1Timetable;
        timeoutsAreaDisplay2 = this.timeoutsDisplay2Timetable;
      } else {
        timeoutsAreaDisplay1 = this.timeoutsDisplay1TimetableOperation;
        timeoutsAreaDisplay2 = this.timeoutsDisplay2TimetableOperation;
      }
    } else {
      if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
        timeoutsAreaDisplay1 = this.timeoutsDisplay1Timetable.filter(item1 => types.includes(item1.linkReferenceData));
        timeoutsAreaDisplay2 = this.timeoutsDisplay2Timetable.filter(item2 => types.includes(item2.linkReferenceData));
      } else {
        timeoutsAreaDisplay1 = this.timeoutsDisplay1TimetableOperation.filter(item3 => types.includes(item3.linkReferenceData));
        timeoutsAreaDisplay2 = this.timeoutsDisplay2TimetableOperation.filter(item4 => types.includes(item4.linkReferenceData));
      }
    }

    // clear timeout for areas display 1
    for (let timeoutDisplay1 of timeoutsAreaDisplay1) {
      clearTimeout(timeoutDisplay1.time);
    }
    // clear timeout for areas display 2
    for (let timeoutDisplay2 of timeoutsAreaDisplay2) {
      clearTimeout(timeoutDisplay2.time);
    }

    // reassign list timeouts
    if (isClearAllArea) {
      if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
        this.timeoutsDisplay1Timetable = [];
        this.timeoutsDisplay2Timetable = [];
      } else {
        this.timeoutsDisplay1TimetableOperation = [];
        this.timeoutsDisplay2TimetableOperation = [];
      }
    } else {
      if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
        this.timeoutsDisplay1Timetable = this.timeoutsDisplay1Timetable.filter(item5 => !types.includes(item5.linkReferenceData));
        this.timeoutsDisplay2Timetable = this.timeoutsDisplay2Timetable.filter(item6 => !types.includes(item6.linkReferenceData));
      } else {
        this.timeoutsDisplay1TimetableOperation = this.timeoutsDisplay1TimetableOperation.filter(
          item7 => !types.includes(item7.linkReferenceData)
        );
        this.timeoutsDisplay2TimetableOperation = this.timeoutsDisplay2TimetableOperation.filter(
          item8 => !types.includes(item8.linkReferenceData)
        );
      }
    }
  }

  /**
   * Clear timeouts stop duration display 1
   *
   * @param screenCanvasIdEnum
   */
  public clearTimeoutsStopDurationDisplay1(screenCanvasIdEnum: any): void {
    let timeoutsAreaDisplay1 =
      screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR ? this.timeoutsDisplay1Timetable : this.timeoutsDisplay1TimetableOperation;
    // clear timeout for areas display 1
    for (let timeoutDisplay1 of timeoutsAreaDisplay1) {
      clearTimeout(timeoutDisplay1.time);
    }
    if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
      this.timeoutsDisplay1Timetable = [];
    } else {
      this.timeoutsDisplay1TimetableOperation = [];
    }
  }

  /**
   * Clear timeouts stop duration display 2
   *
   * @param screenCanvasIdEnum
   */
  public clearTimeoutsStopDurationDisplay2(screenCanvasIdEnum: any): void {
    let timeoutsAreaDisplay2 =
      screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR ? this.timeoutsDisplay2Timetable : this.timeoutsDisplay2TimetableOperation;
    // clear timeout for areas display 1
    for (let timeoutDisplay2 of timeoutsAreaDisplay2) {
      clearTimeout(timeoutDisplay2.time);
    }
    if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
      this.timeoutsDisplay2Timetable = [];
    } else {
      this.timeoutsDisplay2TimetableOperation = [];
    }
  }

  /**
   * draw text scroll horizontal
   * @param ctx
   * @param areaText
   * @param text
   * @param referenceX
   * @param referenceY
   */
  private drawTextScrollHorizontal(ctx: any, areaText: TextArea, text: string, referenceX: any, referenceY: any): void {
    const textDraw = text ?? this.getTimer();
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.fontColor;
    ctx.fillText(textDraw, referenceX + areaText.posXScroll, referenceY + areaText.posYScroll);
    this.calcPositionScrollHorizontal(areaText);
  }

  /**
   * draw text orientation vertical
   * @param ctx
   * @param areaText TextArea
   * @param text
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum
   */
  private drawTextOrientationVertical(
    ctx: any,
    areaText: TextArea,
    text: string,
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum
  ): void {
    var textDraw = text ?? this.getTimer();
    var charsSplit = textDraw.split('');
    let metrics = ctx.measureText(textDraw);
    let firefox = navigator.userAgent.search('Firefox');
    let characterHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
    if (firefox > -1) {
      characterHeight = areaText.fontSize;
    }
    let textHeight = characterHeight * charsSplit.length;
    let maxWidth = Math.max(...charsSplit.map(ch => ctx.measureText(ch).width));
    const referencePosition = Helper.getReferencePositionOrientationVertical(ctx, areaText, textHeight);
    let referenceX = referencePosition.referenceX;
    let referenceY = referencePosition.referenceY;
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // draw color text
    ctx.fillStyle = areaText.fontColor;
    var i = 0;
    var ch: string;
    var posY = 0.5;
    while ((ch = charsSplit[i++])) {
      var chWidth = ctx.measureText(ch).width;
      ctx.fillText(ch, referenceX - chWidth / 2 + areaText.posXScroll, referenceY + posY * characterHeight + areaText.posYScroll);
      posY++;
    }
    if (
      textDraw == '' ||
      areaText.scrollStatus == ScrollStatusEnum.OFF ||
      this.canNotScrollTextVerticalAndSideways(areaText, textHeight, maxWidth)
    ) {
      areaText[`subscription${canvasDisplayId}`]?.unsubscribe();
      return;
    }
    areaText[`isStart${screenCanvasIdEnum}`] =
      screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR ? this.isStartTimetable : this.isStartTimetableOperation;
    if (areaText[`subscription${canvasDisplayId}`]) {
      areaText[`subscription${canvasDisplayId}`].unsubscribe();
    }
    let timeout = setTimeout(() => {
      var widthMeasureText = ctx.measureText(textDraw).width;
      const observable = interval(50);
      const subscription =
        canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
          ? observable
              .pipe(
                takeUntil(this.clearPreviewDisplay1Subject),
                takeUntil(this.pausePreviewDisplay1Subject),
                repeatWhen(() => this.startPreviewDisplay1Subject)
              )
              .subscribe(() => {
                this.drawScrollVerticalText(
                  areaText,
                  widthMeasureText,
                  canvasDisplayId,
                  ctx,
                  text,
                  screenCanvasIdEnum,
                  referenceX,
                  referenceY,
                  charsSplit,
                  textHeight,
                  maxWidth
                );
              })
          : observable
              .pipe(
                takeUntil(this.clearPreviewDisplay2Subject),
                takeUntil(this.pausePreviewDisplay2Subject),
                repeatWhen(() => this.startPreviewDisplay2Subject)
              )
              .subscribe(() => {
                this.drawScrollVerticalText(
                  areaText,
                  widthMeasureText,
                  canvasDisplayId,
                  ctx,
                  text,
                  screenCanvasIdEnum,
                  referenceX,
                  referenceY,
                  charsSplit,
                  textHeight,
                  maxWidth
                );
              });
      areaText[`subscription${canvasDisplayId}`] = subscription;
      areaText[`clearPreviewSubject${canvasDisplayId}`] =
        canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
          ? this.clearPreviewDisplay1Subject.subscribe(() => {
              this.resetPositionScrollText(areaText);
              subscription.unsubscribe();
              areaText[`clearPreviewSubject${canvasDisplayId}`].unsubscribe();
            })
          : this.clearPreviewDisplay2Subject.subscribe(() => {
              this.resetPositionScrollText(areaText);
              subscription.unsubscribe();
              areaText[`clearPreviewSubject${canvasDisplayId}`].unsubscribe();
            });
    }, areaText.stopDuration * 1000);
    // push timeout to list => remove if needed
    if (canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
      if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
        this.timeoutsDisplay1Timetable.push(new TimeOut(timeout, areaText.linkReferenceData, areaText.id));
      } else {
        this.timeoutsDisplay1TimetableOperation.push(new TimeOut(timeout, areaText.linkReferenceData, areaText.id));
      }
    } else {
      if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
        this.timeoutsDisplay2Timetable.push(new TimeOut(timeout, areaText.linkReferenceData, areaText.id));
      } else {
        this.timeoutsDisplay2TimetableOperation.push(new TimeOut(timeout, areaText.linkReferenceData, areaText.id));
      }
    }
  }

  /**
   * Draw scroll vertical text
   *
   * @param areaText
   * @param widthMeasureText
   * @param canvasDisplayId
   * @param ctx
   * @param text
   * @param screenCanvasIdEnum
   * @param referenceX
   * @param referenceY
   * @param charsSplit
   * @param textHeight
   * @param maxWidth
   */
  private drawScrollVerticalText(
    areaText: TextArea,
    widthMeasureText: number,
    canvasDisplayId: any,
    ctx: any,
    text: string,
    screenCanvasIdEnum: any,
    referenceX: number,
    referenceY: number,
    charsSplit: string[],
    textHeight: number,
    maxWidth: number
  ): void {
    if (this.isPlay) {
      // start preview
      if (areaText[`isStart${screenCanvasIdEnum}`]) {
        areaText[`subscription${canvasDisplayId}`].unsubscribe();
        if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
          this.isStartTimetable = false;
        } else {
          this.isStartTimetableOperation = false;
        }
        areaText[`isStart${screenCanvasIdEnum}`] = false;
        this.drawTextOrientationVertical(ctx, areaText, text, canvasDisplayId, screenCanvasIdEnum);
      } else {
        // finish scroll text (direction left)
        if (areaText.scrollDirection == ScrollDirectionsEnum.LEFT && Math.floor(areaText.posXScroll) < -Math.floor(maxWidth)) {
          areaText.posXScroll = 0;
          this.handleClearSubscriptionScroll(areaText, canvasDisplayId, ctx, text, screenCanvasIdEnum, OrientationEnum.VERTICAL);
        } else if (areaText.scrollDirection == ScrollDirectionsEnum.RIGHT && Math.floor(areaText.posXScroll) > Math.floor(maxWidth)) {
          // finish scroll text (direction right)
          areaText.posXScroll = 0;
          this.handleClearSubscriptionScroll(areaText, canvasDisplayId, ctx, text, screenCanvasIdEnum, OrientationEnum.VERTICAL);
        } else if (areaText.scrollDirection == ScrollDirectionsEnum.UP && Math.floor(areaText.posYScroll) < -Math.floor(textHeight)) {
          // finish scroll text (direction up)
          areaText.posYScroll = 0;
          this.handleClearSubscriptionScroll(areaText, canvasDisplayId, ctx, text, screenCanvasIdEnum, OrientationEnum.VERTICAL);
        } else if (areaText.scrollDirection == ScrollDirectionsEnum.DOWN && Math.floor(areaText.posYScroll) > Math.floor(textHeight)) {
          // finish scroll text (direction down)
          areaText.posYScroll = 0;
          this.handleClearSubscriptionScroll(areaText, canvasDisplayId, ctx, text, screenCanvasIdEnum, OrientationEnum.VERTICAL);
        } else {
          // draw scroll text
          this.drawTextScrollVertical(ctx, areaText, charsSplit, referenceX, referenceY);
        }
      }
    }
  }

  /**
   * Clear areas
   *
   * @param areas
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  public clearAreas(areas: Area[], canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum) {
    if (!areas) {
      return;
    }
    Promise.all(
      areas.map(area => {
        this.clearCanvas(area.canvas);
        if (!area.checkTypeTextArea()) {
          return;
        }
        let textArea = area as TextArea;
        textArea.text = '';
        if (textArea.scrollStatus != ScrollStatusEnum.OFF) {
          this.resetPositionScrollText(textArea);
          area[`subscription${canvasDisplayId}`]?.unsubscribe();
          delete area[`subscription${canvasDisplayId}`];
        } else {
          area[`subscription${canvasDisplayId}`]?.unsubscribe();
        }
        // clear timeout stop duration for area
        if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
          if (canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
            this.clearTimeoutForAreaText(this.timeoutsDisplay1Timetable, textArea);
          } else {
            this.clearTimeoutForAreaText(this.timeoutsDisplay2Timetable, textArea);
          }
        } else {
          if (canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
            this.clearTimeoutForAreaText(this.timeoutsDisplay1TimetableOperation, textArea);
          } else {
            this.clearTimeoutForAreaText(this.timeoutsDisplay2TimetableOperation, textArea);
          }
        }
      })
    );
  }

  /**
   * draw text scroll vertical
   *
   * @param ctx
   * @param areaText
   * @param charsSplit
   * @param referenceX
   * @param referenceY
   */
  private drawTextScrollVertical(ctx, areaText, charsSplit, referenceX, referenceY): void {
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.fontColor;
    var i = 0;
    var ch: string;
    var posY = 0.5;
    while ((ch = charsSplit[i++])) {
      var chWidth = ctx.measureText(ch).width;
      ctx.fillText(ch, referenceX - chWidth / 2 + areaText.posXScroll, referenceY + posY * areaText.fontSize + areaText.posYScroll);
      posY++;
    }
    this.calcPositionScrollVertical(areaText);
  }

  /**
   * draw text orientation sideways
   *
   * @param ctx
   * @param areaText TextArea
   * @param text
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private drawTextOrientationSideways(
    ctx: any,
    areaText: TextArea,
    text: string,
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum
  ): void {
    var textDraw = text ?? this.getTimer();
    const measureText = ctx.measureText(textDraw);
    let widthMeasureText = measureText.width;
    const maxWidth = measureText.actualBoundingBoxAscent + measureText.actualBoundingBoxDescent;
    const referencePosition = Helper.getReferencePositionOrientationSideways(ctx, areaText, widthMeasureText);
    const referenceX = referencePosition.referenceX;
    const referenceY = referencePosition.referenceY;
    ctx.rotate(Math.PI / 2);
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    // draw color text
    ctx.fillStyle = areaText.fontColor;
    ctx.fillText(textDraw, referenceX + areaText.posYScroll, referenceY - areaText.posXScroll);
    ctx.resetTransform();
    if (
      textDraw == '' ||
      areaText.scrollStatus == ScrollStatusEnum.OFF ||
      this.canNotScrollTextVerticalAndSideways(areaText, widthMeasureText, maxWidth)
    ) {
      areaText[`subscription${canvasDisplayId}`]?.unsubscribe();
      return;
    }
    areaText[`isStart${screenCanvasIdEnum}`] =
      screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR ? this.isStartTimetable : this.isStartTimetableOperation;
    if (areaText[`subscription${canvasDisplayId}`]) {
      areaText[`subscription${canvasDisplayId}`].unsubscribe();
    }
    let timeout = setTimeout(() => {
      var widthMeasureText = ctx.measureText(textDraw).width;
      const observable = interval(50);
      const subscription =
        canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
          ? observable
              .pipe(
                takeUntil(this.clearPreviewDisplay1Subject),
                takeUntil(this.pausePreviewDisplay1Subject),
                repeatWhen(() => this.startPreviewDisplay1Subject)
              )
              .subscribe(() => {
                this.drawScrollSidewaysText(
                  areaText,
                  widthMeasureText,
                  canvasDisplayId,
                  ctx,
                  text,
                  screenCanvasIdEnum,
                  referenceX,
                  referenceY,
                  maxWidth
                );
              })
          : observable
              .pipe(
                takeUntil(this.clearPreviewDisplay2Subject),
                takeUntil(this.pausePreviewDisplay2Subject),
                repeatWhen(() => this.startPreviewDisplay2Subject)
              )
              .subscribe(() => {
                this.drawScrollSidewaysText(
                  areaText,
                  widthMeasureText,
                  canvasDisplayId,
                  ctx,
                  text,
                  screenCanvasIdEnum,
                  referenceX,
                  referenceY,
                  maxWidth
                );
              });
      areaText[`subscription${canvasDisplayId}`] = subscription;
      areaText[`clearPreviewSubject${canvasDisplayId}`] =
        canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
          ? this.clearPreviewDisplay1Subject.subscribe(() => {
              this.resetPositionScrollText(areaText);
              subscription.unsubscribe();
              areaText[`clearPreviewSubject${canvasDisplayId}`].unsubscribe();
            })
          : this.clearPreviewDisplay2Subject.subscribe(() => {
              this.resetPositionScrollText(areaText);
              subscription.unsubscribe();
              areaText[`clearPreviewSubject${canvasDisplayId}`].unsubscribe();
            });
    }, areaText.stopDuration * 1000);
    // push timeout to list => remove if needed
    if (canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
      if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
        this.timeoutsDisplay1Timetable.push(new TimeOut(timeout, areaText.linkReferenceData, areaText.id));
      } else {
        this.timeoutsDisplay1TimetableOperation.push(new TimeOut(timeout, areaText.linkReferenceData, areaText.id));
      }
    } else {
      if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
        this.timeoutsDisplay2Timetable.push(new TimeOut(timeout, areaText.linkReferenceData, areaText.id));
      } else {
        this.timeoutsDisplay2TimetableOperation.push(new TimeOut(timeout, areaText.linkReferenceData, areaText.id));
      }
    }
  }
  /**
   * Draw scroll sideways text
   *
   * @param areaText
   * @param widthMeasureText
   * @param canvasDisplayId
   * @param ctx
   * @param text
   * @param screenCanvasIdEnum
   * @param referenceX
   * @param referenceY
   * @param maxWidth
   */
  private drawScrollSidewaysText(
    areaText: TextArea,
    widthMeasureText: number,
    canvasDisplayId: any,
    ctx: any,
    text: string,
    screenCanvasIdEnum: any,
    referenceX: number,
    referenceY: number,
    maxWidth: number
  ): void {
    if (this.isPlay) {
      // start preview
      if (areaText[`isStart${screenCanvasIdEnum}`]) {
        areaText[`subscription${canvasDisplayId}`].unsubscribe();
        if (screenCanvasIdEnum == ScreenCanvasIdEnum.TIMETABLE_EDITOR) {
          this.isStartTimetable = false;
        } else {
          this.isStartTimetableOperation = false;
        }
        areaText[`isStart${screenCanvasIdEnum}`] = false;
        this.drawTextOrientationSideways(ctx, areaText, text, canvasDisplayId, screenCanvasIdEnum);
      } else {
        // finish scroll text (direction left)
        if (areaText.scrollDirection == ScrollDirectionsEnum.LEFT && Math.floor(areaText.posXScroll) < -Math.floor(maxWidth)) {
          areaText.posXScroll = 0;
          this.handleClearSubscriptionScroll(areaText, canvasDisplayId, ctx, text, screenCanvasIdEnum, OrientationEnum.SIDEWAYS);
        } else if (areaText.scrollDirection == ScrollDirectionsEnum.RIGHT && Math.floor(areaText.posXScroll) > Math.floor(maxWidth)) {
          // finish scroll text (direction right)
          areaText.posXScroll = 0;
          this.handleClearSubscriptionScroll(areaText, canvasDisplayId, ctx, text, screenCanvasIdEnum, OrientationEnum.SIDEWAYS);
        } else if (areaText.scrollDirection == ScrollDirectionsEnum.UP && Math.floor(areaText.posYScroll) < -Math.floor(widthMeasureText)) {
          // finish scroll text (direction up)
          areaText.posYScroll = 0;
          this.handleClearSubscriptionScroll(areaText, canvasDisplayId, ctx, text, screenCanvasIdEnum, OrientationEnum.SIDEWAYS);
        } else if (
          areaText.scrollDirection == ScrollDirectionsEnum.DOWN &&
          Math.floor(areaText.posYScroll) > Math.floor(widthMeasureText)
        ) {
          // finish scroll text (direction down)
          areaText.posYScroll = 0;
          this.handleClearSubscriptionScroll(areaText, canvasDisplayId, ctx, text, screenCanvasIdEnum, OrientationEnum.SIDEWAYS);
        } else {
          // draw scroll text
          this.drawTextScrollSideways(ctx, areaText, text, referenceX, referenceY);
        }
      }
    }
  }

  /**
   * draw text scroll sideways
   *
   * @param ctx
   * @param areaText
   * @param text
   * @param referenceX
   * @param referenceY
   * @param widthMeasureText
   */
  private drawTextScrollSideways(ctx, areaText, text, referenceX, referenceY): void {
    const textDraw = text ?? this.getTimer();
    ctx.clearRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.fillStyle = areaText.backgroundColor;
    ctx.fillRect(0, 0, areaText.canvas.width, areaText.canvas.height);
    ctx.rotate(Math.PI / 2);
    ctx.fillStyle = areaText.fontColor;
    ctx.fillText(textDraw, referenceX + areaText.posYScroll, referenceY - areaText.posXScroll);
    this.calcPositionScrollSideway(areaText);
    ctx.resetTransform();
  }

  /**
   * draw clock preview
   * @param area
   * @param renderer
   * @param canvasDisplayId
   * @param screenCanvasIdEnum
   */
  private drawClock(area: Area, renderer: Renderer2, canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum) {
    let areaText = area as TextArea;
    renderer.setStyle(area.canvas, 'visibility', 'visible');
    if (areaText.scrollStatus == ScrollStatusEnum.OFF) {
      var time = setInterval(() => {
        // update timezone
        const textFill = this.getTimer();
        // draw areaText
        this.drawAreaText(areaText, canvasDisplayId, screenCanvasIdEnum, textFill);
      }, 50);
      this.listAreaClockIntervals.push({ areaId: areaText.idWithDisplay, time: time });
    } else {
      this.drawAreaText(areaText, canvasDisplayId, screenCanvasIdEnum);
    }
  }

  /**
   * reset position scroll text
   *
   * @param textArea
   */
  private resetPositionScrollText(textArea: TextArea): void {
    textArea.posXScroll = 0;
    textArea.posYScroll = 0;
    textArea.timesScroll = 0;
  }

  /**
   * get timer
   */
  private getTimer(): string {
    // update timezone
    var offsetHour = 0;
    var offsetMinute = 0;
    var setting = this.commonService.getCommonObject().setting;
    if (setting) {
      offsetHour = setting.timezone.offsetHour;
      offsetMinute = setting.timezone.offsetMinute;
    }
    return moment
      .utc()
      .add(offsetHour, 'hour')
      .add(offsetMinute, 'minute')
      .format('HH:mm');
  }

  /**
   * draw fix picture preview
   *
   * @param areaPicture PictureArea
   * @param renderer Renderer2
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum
   * @param isFinishSchedule
   * @param template
   */
  private async drawAreaFixPicture(
    areaPicture: PictureArea,
    renderer: Renderer2,
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    template: Template,
    isFinishSchedule?: boolean
  ) {
    if (areaPicture.isTimingOn && !isFinishSchedule) {
      return;
    }
    renderer.setStyle(areaPicture.canvas, 'visibility', 'visible');
    if (areaPicture.media) {
      // draw areaPicture
      this.drawAreaPicture(areaPicture, canvasDisplayId, screenCanvasIdEnum, template);
    } else {
      this.playAudio(areaPicture, canvasDisplayId, screenCanvasIdEnum);
    }
  }

  public async drawExternalContent(
    canvasDisplayId: any,
    dataResponse: any,
    display: Template,
    pictureAreaService: PictureAreaService,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    areaExternalContentOn?: Area[]
  ) {
    let intervals = new Array<any>();
    for (let i = 0; i < dataResponse?.length; i++) {
      // get area to draw
      let area = this.getAreaExternalContent(display, dataResponse[i].idArea);
      if (!areaExternalContentOn || (areaExternalContentOn && areaExternalContentOn?.map(item => item.id)?.includes(area?.id))) {
        // create media by url to draw
        let media = this.createNewImage('png', dataResponse[i]['mediaUrl']);
        // draw weather
        if (area && !dataResponse[i]['isNews']) {
          area['media'] = media;
          if (media) {
            this.drawAreaPictureExternalContent(area, display);
          }
          // draw news
        } else if (area && dataResponse[i]['isNews']) {
          intervals.push(null);
          area['media'] = media;
          area['countPage'] = 1;
          await this.drawAreaPictureNews(
            canvasDisplayId,
            intervals.length - 1,
            area,
            dataResponse[i],
            display,
            screenCanvasIdEnum,
            pictureAreaService
          );
        }
      }
    }
  }

  /**
   * draw news picture
   * @param indexInterval index interval drawing
   * @param area area draw
   * @param dataExternalSetting data external content to draw
   * @param canvasDisplayId
   */
  private async drawAreaPictureNews(
    canvasDisplayId: string,
    indexInterval: number,
    area: PictureArea,
    dataExternalSetting: DataExternalSetting,
    template: Template,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    pictureAreaService: PictureAreaService
  ) {
    // repeat
    if (area['countPage'] > dataExternalSetting['numberPage']) {
      area['countPage'] = 1;
      this.drawAreaPictureNews(canvasDisplayId, indexInterval, area, dataExternalSetting, template, screenCanvasIdEnum, pictureAreaService);
      // draw news picture
    } else if (canvasDisplayId !== `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`) {
      this.drawPictureNews(
        this.subscribesGetUrlPresignedDisplay2,
        indexInterval,
        dataExternalSetting,
        area,
        canvasDisplayId,
        this.intervalsDrawNewsDisplay2,
        template,
        pictureAreaService,
        screenCanvasIdEnum
      );
    } else {
      this.drawPictureNews(
        this.subscribesGetUrlPresignedDisplay1,
        indexInterval,
        dataExternalSetting,
        area,
        canvasDisplayId,
        this.intervalsDrawNewsDisplay1,
        template,
        pictureAreaService,
        screenCanvasIdEnum
      );
    }
  }

  /**
   * draw news
   * @param subscriptionUrl
   * @param index
   * @param dataExternalSetting
   * @param area
   * @param canvasDisplayId
   * @param intervals
   */
  private drawPictureNews(
    subscriptionUrl: Subscription[],
    index: number,
    dataExternalSetting: DataExternalSetting,
    area: PictureArea,
    canvasDisplayId: string,
    intervals: any,
    template: Template,
    pictureAreaService: PictureAreaService,
    screenCanvasIdEnum: ScreenCanvasIdEnum
  ): void {
    intervals[index]?.unsubscribe();
    subscriptionUrl[index]?.unsubscribe();
    // get news picture url next page
    subscriptionUrl[index] = pictureAreaService
      .getNewsPictureUrlPresigned(area['countPage'], dataExternalSetting.idExternalContent)
      .subscribe(async data => {
        area['media'] = this.createNewImage('png', data['url']);
        // draw present page
        if (area['media']) {
          const isCancelFetch = await this.drawAreaPictureExternalContent(area, template);
          if (isCancelFetch) {
            intervals[index]?.unsubscribe();
            return;
          }
        } else {
          let canvas = area.canvas;
          let ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
        // draw next page
        const timeOut = interval(+dataExternalSetting['duration'] * 1000);
        intervals[index] =
          canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
            ? timeOut
                .pipe(
                  timeInterval(),
                  takeUntil(this.pausePreviewDisplay1Subject),
                  repeatWhen(() => this.startPreviewDisplay1Subject)
                )
                .subscribe(() => {
                  area['countPage'] += 1;
                  this.drawAreaPictureNews(
                    canvasDisplayId,
                    index,
                    area,
                    dataExternalSetting,
                    template,
                    screenCanvasIdEnum,
                    pictureAreaService
                  );
                })
            : timeOut
                .pipe(
                  timeInterval(),
                  takeUntil(this.pausePreviewDisplay2Subject),
                  repeatWhen(() => this.startPreviewDisplay2Subject)
                )
                .subscribe(() => {
                  area['countPage'] += 1;
                  this.drawAreaPictureNews(
                    canvasDisplayId,
                    index,
                    area,
                    dataExternalSetting,
                    template,
                    screenCanvasIdEnum,
                    pictureAreaService
                  );
                });
        if (!this.isPlay) {
          canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
            ? this.pausePreviewDisplay1Subject.next()
            : this.pausePreviewDisplay2Subject.next();
        }
      });
  }

  /**
   * create new image by url
   * @param type type of image
   * @param url url media
   * @returns new image
   */
  private createNewImage(type: string, url: string): Image {
    if (!url) {
      return null;
    }
    let media: Media = new Image();
    media.type = type;
    media.url = url;
    return media as Image;
  }

  /**
   * get area external content by id
   * @param template
   * @param idArea of external content
   * @returns area external content
   */
  private getAreaExternalContent(template: Template, idArea: number): PictureArea {
    if (!template) {
      return null;
    }
    for (let layer of template?.layers) {
      for (let area of layer?.areas) {
        if (area.id === idArea) {
          return area as PictureArea;
        }
      }
    }
  }

  /**
   * clear all thread draw news page
   */
  public clearAllIntervalDrawsNewsDisplay2(): void {
    this.intervalsDrawNewsDisplay2?.forEach(subscription => subscription?.unsubscribe());
    this.subscribesGetUrlPresignedDisplay2?.forEach(subscription => subscription?.unsubscribe());
  }

  public clearAllIntervalDrawsNewsDisplay1() {
    this.intervalsDrawNewsDisplay1?.forEach(subscription => subscription?.unsubscribe());
    this.subscribesGetUrlPresignedDisplay1?.forEach(subscription => subscription?.unsubscribe());
  }

  /**
   * draw area picture external content
   * @param area Area
   * @param template
   */
  public async drawAreaPictureExternalContent(area: Area, template: Template) {
    let areaPicture = area as PictureArea;
    let canvas = areaPicture.canvas;
    if (areaPicture.media) {
      // case w, h image do not exist
      if (!areaPicture.media['width'] && !areaPicture.media['height']) {
        delete areaPicture.media['width'];
        delete areaPicture.media['height'];
        let imageInfo = await Helper.getImageInformation(areaPicture.media);
        areaPicture.media['width'] = `${imageInfo.width}`;
        areaPicture.media['height'] = `${imageInfo.height}`;
      }
    }
    let mediaPosition = Helper.coverMedia(canvas, areaPicture.media, areaPicture.objectFit);
    let ctx = canvas.getContext('2d');
    if (areaPicture.media) {
      ctx.globalAlpha = 1;
      if (areaPicture.media.type != TypeMediaFileEnum.MP4) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        try {
          let img = document.createElement('img');
          let $this = this;
          let layerOfArea = template ? Helper.findLayerOfArea(areaPicture, template) : undefined;
          img.onload = function() {
            if (
              !layerOfArea ||
              !layerOfArea.isSwitchingArea ||
              !$this.switchingTiming ||
              (layerOfArea.isSwitchingArea && $this.areasDrawing?.map(area => area?.idWithDisplay)?.includes(areaPicture?.idWithDisplay))
            ) {
              ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
              if (areaPicture.objectFit == ObjectFitEnum.FILL) {
                ctx.drawImage(img, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
              } else {
                ctx.drawImage(
                  img,
                  mediaPosition.sX,
                  mediaPosition.sY,
                  mediaPosition.sWidth,
                  mediaPosition.sHeight,
                  mediaPosition.x,
                  mediaPosition.y,
                  mediaPosition.width,
                  mediaPosition.height
                );
              }
            }
          };
          img.src = areaPicture.media.url;
        } catch (error) {
          console.log('error draw 3', error);
          return true;
        }
      }
    }
  }

  /**
   * draw area picture
   *
   * @param areaPicture PictureArea object
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum
   * @param template
   */
  public async drawAreaPicture(areaPicture: any, canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum, template: Template) {
    if (areaPicture.media) {
      // case w, h image do not exist
      if (!areaPicture.media['width'] && !areaPicture.media['height']) {
        delete areaPicture.media['width'];
        delete areaPicture.media['height'];
        let imageInfo = await Helper.getImageInformation(areaPicture.media);
        areaPicture.media['width'] = `${imageInfo.width}`;
        areaPicture.media['height'] = `${imageInfo.height}`;
      }
    }
    try {
      let ctx = areaPicture.canvas.getContext('2d');
      if (areaPicture.isURL) {
        areaPicture.objectFit = ObjectFitEnum.CONTAIN;
      }
      let mediaPosition = Helper.coverMedia(areaPicture.canvas, areaPicture.media, areaPicture.objectFit);
      let img = document.createElement('img');
      let $this = this;
      let layerOfArea = template ? Helper.findLayerOfArea(areaPicture, template) : undefined;
      img.onload = function() {
        if (
          !layerOfArea ||
          !layerOfArea.isSwitchingArea ||
          !$this.switchingTiming ||
          (layerOfArea.isSwitchingArea && $this.areasDrawing?.map(area => area?.idWithDisplay)?.includes(areaPicture.idWithDisplay))
        ) {
          ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
          if (areaPicture.objectFit == ObjectFitEnum.FILL) {
            ctx.drawImage(img, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
          } else if (!areaPicture.isURL) {
            ctx.drawImage(
              img,
              mediaPosition.sX,
              mediaPosition.sY,
              mediaPosition.sWidth,
              mediaPosition.sHeight,
              mediaPosition.x,
              mediaPosition.y,
              mediaPosition.width,
              mediaPosition.height
            );
          } else {
            areaPicture.canvas.style.border = '3px ' + 'solid' + '#FFEB8D';
            ctx.drawImage(
              img,
              mediaPosition.sX,
              mediaPosition.sY,
              mediaPosition.sWidth,
              mediaPosition.sHeight,
              mediaPosition.x,
              mediaPosition.y,
              mediaPosition.width,
              mediaPosition.height
            );
          }
        }
      };
      img.src = areaPicture.media.url;
    } catch (error) {
      console.log('error draw 3', error);
    }
    this.playAudio(areaPicture, canvasDisplayId, screenCanvasIdEnum);
  }

  /**
   * play audio
   *
   * @param areaPicture PictureArea
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  public async playAudio(areaPicture: PictureArea, canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum) {
    if (!areaPicture?.isFix) {
      return;
    }
    // play audio;
    if (!areaPicture.soundAPreview && areaPicture.soundA?.url) {
      areaPicture.soundAPreview = new Audio(areaPicture.soundA.url);
      areaPicture.soundAPreview.load();
    }
    if (!areaPicture.soundBPreview && areaPicture.soundB?.url) {
      areaPicture.soundBPreview = new Audio(areaPicture.soundB.url);
      areaPicture.soundBPreview.load();
    }
    if (this.isPlay) {
      areaPicture?.soundAPreview?.play();
      areaPicture?.soundBPreview?.play();
    }
    // play again
    if (areaPicture?.soundAPreview) {
      areaPicture.soundAPreview.onplay = () => {
        this.playSoundAgain(areaPicture, canvasDisplayId);
      };
    }
    if (areaPicture?.soundBPreview) {
      areaPicture.soundBPreview.onplay = () => {
        this.playSoundAgain(areaPicture, canvasDisplayId);
      };
    }
    // unsubscribe
    this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
    areaPicture[`pausePreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
        ? this.pausePreviewDisplay1Subject.subscribe(() => {
            this.pauseAudio(areaPicture);
          })
        : this.pausePreviewDisplay2Subject.subscribe(() => {
            this.pauseAudio(areaPicture);
          });
    areaPicture[`clearPreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
        ? this.clearPreviewDisplay1Subject.subscribe(() => {
            this.stopAudio(areaPicture);
            this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
          })
        : this.clearPreviewDisplay2Subject.subscribe(() => {
            this.stopAudio(areaPicture);
            this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
          });
    areaPicture[`startPreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
        ? this.startPreviewDisplay1Subject.subscribe(() => {
            this.playAudio(areaPicture, canvasDisplayId, screenCanvasIdEnum);
          })
        : this.startPreviewDisplay2Subject.subscribe(() => {
            this.playAudio(areaPicture, canvasDisplayId, screenCanvasIdEnum);
          });
  }

  /**
   * pause audio
   * @param areaPicture PictureArea
   */
  private pauseAudio(areaPicture: PictureArea): void {
    if (!areaPicture?.isFix) {
      return;
    }
    // pause
    if (areaPicture.soundAPreview) {
      areaPicture.soundAPreview.pause();
    }
    if (areaPicture.soundBPreview) {
      areaPicture.soundBPreview.pause();
    }
  }

  /**
   * stop audio
   * @param areaPicture PictureArea
   */
  private stopAudio(areaPicture: PictureArea): void {
    if (!areaPicture?.isFix) {
      return;
    }
    // stop
    if (areaPicture.soundAPreview) {
      areaPicture.soundAPreview.pause();
      areaPicture.soundAPreview.currentTime = 0;
      areaPicture.soundAPreview.load();
    }
    if (areaPicture.soundBPreview) {
      areaPicture.soundBPreview.pause();
      areaPicture.soundBPreview.currentTime = 0;
      areaPicture.soundBPreview.load();
    }
  }

  /**
   * play sound
   * @param mediaSound Media
   * @param areaPicture PictureArea
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private playSound(areaPicture: PictureArea, mediaSound: Media, canvasDisplayId: any, screenCanvasIdEnum: ScreenCanvasIdEnum): void {
    const url = mediaSound ? mediaSound?.url : areaPicture?.media?.url;
    if (!url) {
      return;
    }
    if (!areaPicture[`sound${canvasDisplayId}`]) {
      areaPicture[`sound${canvasDisplayId}`] = document.createElement('audio');
    }
    if (!areaPicture[`sound${canvasDisplayId}`]?.src) {
      areaPicture[`sound${canvasDisplayId}`].src = url;
    }
    if (this.isPlay) {
      areaPicture[`sound${canvasDisplayId}`]?.play();
    }
    // play again
    areaPicture[`sound${canvasDisplayId}`].onplay = () => {
      this.playSoundAgain(areaPicture, canvasDisplayId);
    };
    this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
    areaPicture[`pausePreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
        ? this.pausePreviewDisplay1Subject.subscribe(() => {
            this.pauseSound(areaPicture, canvasDisplayId);
          })
        : this.pausePreviewDisplay2Subject.subscribe(() => {
            this.pauseSound(areaPicture, canvasDisplayId);
          });
    areaPicture[`clearPreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
        ? this.clearPreviewDisplay1Subject.subscribe(() => {
            this.stopSound(areaPicture, canvasDisplayId);
            this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
          })
        : this.clearPreviewDisplay2Subject.subscribe(() => {
            this.stopSound(areaPicture, canvasDisplayId);
            this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
          });
    areaPicture[`startPreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
        ? this.startPreviewDisplay1Subject.subscribe(() => {
            if (mediaSound) {
              this.playSound(areaPicture, mediaSound, canvasDisplayId, screenCanvasIdEnum);
            } else {
              this.playSound(areaPicture, undefined, canvasDisplayId, screenCanvasIdEnum);
            }
          })
        : this.startPreviewDisplay2Subject.subscribe(() => {
            if (mediaSound) {
              this.playSound(areaPicture, mediaSound, canvasDisplayId, screenCanvasIdEnum);
            } else {
              this.playSound(areaPicture, undefined, canvasDisplayId, screenCanvasIdEnum);
            }
          });
  }

  /**
   * play again when ended
   * @param areaPicture
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private playSoundAgain(areaPicture: PictureArea, canvasDisplayId): void {
    // sound A
    if (areaPicture?.soundAPreview && areaPicture.soundAPreview.ended) {
      areaPicture.soundAPreview.currentTime = 0;
      areaPicture.soundAPreview.play().then(() => this.playSoundAgain(areaPicture, canvasDisplayId));
    }
    // sound B
    if (areaPicture?.soundBPreview && areaPicture.soundBPreview.ended) {
      areaPicture.soundBPreview.currentTime = 0;
      areaPicture.soundBPreview.play().then(() => this.playSoundAgain(areaPicture, canvasDisplayId));
    }
    // audio attach image
    if (areaPicture[`sound${canvasDisplayId}`] && areaPicture[`sound${canvasDisplayId}`].ended) {
      areaPicture[`sound${canvasDisplayId}`].currentTime = 0;
      areaPicture[`sound${canvasDisplayId}`].play().then(() => this.playSoundAgain(areaPicture, canvasDisplayId));
    }
    if (areaPicture[`animationId${canvasDisplayId}`]) {
      cancelAnimationFrame(areaPicture[`animationId${canvasDisplayId}`]);
    }
    areaPicture[`animationId${canvasDisplayId}`] = requestAnimationFrame(() => this.playSoundAgain(areaPicture, canvasDisplayId));
  }

  /**
   * stop sound
   * @param areaPicture PictureArea
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private stopSound(areaPicture: PictureArea, canvasDisplayId): void {
    if (!areaPicture?.[`sound${canvasDisplayId}`]) {
      return;
    }
    if (areaPicture[`sound${canvasDisplayId}`].currentTime > 0) {
      areaPicture[`sound${canvasDisplayId}`].pause();
      areaPicture[`sound${canvasDisplayId}`].currentTime = 0;
    }
    delete areaPicture[`sound${canvasDisplayId}`];
  }

  /**
   * pause sound
   * @param areaPicture PictureArea
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private pauseSound(areaPicture: PictureArea, canvasDisplayId): void {
    if (!areaPicture?.[`sound${canvasDisplayId}`]) {
      return;
    }
    if (areaPicture[`sound${canvasDisplayId}`].currentTime > 0) {
      areaPicture[`sound${canvasDisplayId}`].pause();
    }
  }

  /**
   * draw video fix picture preview
   * @param areaPicture Area
   * @param renderer Renderer2
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   * @param screenCanvasIdEnum
   * @param isFinishSchedule
   */
  private async drawVideoFixPicture(
    areaPicture: PictureArea,
    renderer: Renderer2,
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    isFinishSchedule?: boolean
  ) {
    if (areaPicture.isTimingOn && !isFinishSchedule) {
      return;
    }
    renderer.setStyle(areaPicture.canvas, 'visibility', 'visible');
    if (areaPicture.media) {
      let ctx = areaPicture.canvas.getContext('2d');
      ctx.clearRect(0, 0, areaPicture.canvas.width, areaPicture.canvas.height);
      let mediaPos = Helper.coverMedia(areaPicture.canvas, areaPicture.media, areaPicture.objectFit);
      this.drawVideo(areaPicture, ctx, mediaPos, areaPicture.canvas, canvasDisplayId, screenCanvasIdEnum);
    }
  }

  /**
   * drawVideo
   * @param areaPicture PictureArea
   * @param ctx
   * @param mediaPos any
   * @param canvas
   * @param canvasDisplayId (canvasDisplay1 or canvasDisplay2)
   */
  private async drawVideo(
    areaPicture: PictureArea,
    ctx: any,
    mediaPos: any,
    canvas: any,
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum
  ) {
    if (!areaPicture.media) {
      return;
    }
    if (!areaPicture.videoPreview) {
      areaPicture.videoPreview = document.createElement('video');
    }
    areaPicture.videoPreview.src = '';
    if (areaPicture.videoPreview.src != areaPicture?.media?.url) {
      areaPicture.videoPreview.src = areaPicture?.media?.url ?? '';
      function drawOrigin() {
        areaPicture.videoPreview?.addEventListener(
          'loadeddata',
          function() {
            areaPicture.videoPreview.currentTime = 0;
          },
          false
        );
        areaPicture.videoPreview?.addEventListener(
          'seeked',
          function() {
            if (areaPicture.objectFit == ObjectFitEnum.FILL) {
              ctx.drawImage(areaPicture.videoPreview, mediaPos.x, mediaPos.y, mediaPos.width, mediaPos.height);
            } else {
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(
                areaPicture.videoPreview,
                mediaPos.sX,
                mediaPos.sY,
                mediaPos.sWidth,
                mediaPos.sHeight,
                mediaPos.x,
                mediaPos.y,
                mediaPos.width,
                mediaPos.height
              );
            }
          },
          false
        );
        return;
      }
      await areaPicture.videoPreview.addEventListener('timeupdate', drawOrigin, false);
      if (this.isPlay) {
        areaPicture.videoPreview.play();
      }
      areaPicture.videoPreview.onplay = () => {
        this.animationDrawVideo(ctx, areaPicture, mediaPos, canvas, canvasDisplayId);
      };
    }
    this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
    areaPicture[`startPreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
        ? this.startPreviewDisplay1Subject.subscribe(() => areaPicture.videoPreview.play())
        : this.startPreviewDisplay2Subject.subscribe(() => areaPicture.videoPreview.play());
    areaPicture[`pausePreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
        ? this.pausePreviewDisplay1Subject.subscribe(() => areaPicture.videoPreview.pause())
        : this.pausePreviewDisplay2Subject.subscribe(() => areaPicture.videoPreview.pause());
    areaPicture[`clearPreviewSubject${canvasDisplayId}`] =
      canvasDisplayId == `${screenCanvasIdEnum}${DisplayCanvasIdEnum.DISPLAY_1}`
        ? this.clearPreviewDisplay1Subject.subscribe(() => {
            this.pausePreviewDisplay1Subject.next();
            this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
          })
        : this.clearPreviewDisplay2Subject.subscribe(() => {
            this.pausePreviewDisplay2Subject.next();
            this.unsubscribedSubjectArea(areaPicture, canvasDisplayId);
          });
  }

  /**
   * animation draw area
   * @param ctx
   * @param areaPicture
   * @param mediaPos
   * @param canvas
   * @param canvasDisplayId
   */
  private animationDrawVideo(ctx: any, areaPicture: PictureArea, mediaPos: any, canvas: any, canvasDisplayId): void {
    if (areaPicture.media && areaPicture.media.type != TypeMediaFileEnum.MP4) {
      this.stopVideo(areaPicture, canvasDisplayId);
      return;
    }

    if (areaPicture.videoPreview?.ended) {
      areaPicture.videoPreview.currentTime = 0;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      areaPicture.videoPreview.play().then(() => this.animationDrawVideo(ctx, areaPicture, mediaPos, canvas, canvasDisplayId));
    }

    if (!areaPicture?.videoPreview?.src || areaPicture?.videoPreview?.src == '') {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      return;
    }
    if (areaPicture.objectFit == ObjectFitEnum.FILL) {
      ctx.drawImage(areaPicture.videoPreview, mediaPos.x, mediaPos.y, mediaPos.width, mediaPos.height);
    } else {
      ctx.drawImage(
        areaPicture.videoPreview,
        mediaPos.sX,
        mediaPos.sY,
        mediaPos.sWidth,
        mediaPos.sHeight,
        mediaPos.x,
        mediaPos.y,
        mediaPos.width,
        mediaPos.height
      );
    }
    if (areaPicture.videoPreview?.paused || !this.isPlay) {
      areaPicture.videoPreview && areaPicture.videoPreview.pause();
    }
    if (areaPicture[`animationId${canvasDisplayId}`]) {
      cancelAnimationFrame(areaPicture[`animationId${canvasDisplayId}`]);
    }
    areaPicture[`animationId${canvasDisplayId}`] = requestAnimationFrame(() =>
      this.animationDrawVideo(ctx, areaPicture, mediaPos, canvas, canvasDisplayId)
    );
  }

  /**
   * stop video
   * @param areaPicture PictureArea
   * @param canvasDisplayId
   */
  private stopVideo(areaPicture: PictureArea, canvasDisplayId): void {
    if (areaPicture.videoPreview) {
      let ctx = areaPicture.canvas.getContext('2d');
      ctx.clearRect(0, 0, areaPicture.width, areaPicture.height);
      cancelAnimationFrame(areaPicture[`animationId${canvasDisplayId}`]);
      areaPicture.videoPreview.pause();
      areaPicture.videoPreview.currentTime = 0;
      delete areaPicture.videoPreview;
    }
  }

  /**
   * clear canvas
   * @param canvasLayoutRealTime
   */
  public clearCanvas(canvasLayoutRealTime) {
    if (!canvasLayoutRealTime) {
      return;
    }
    let ctx = canvasLayoutRealTime.getContext('2d');
    ctx.clearRect(0, 0, canvasLayoutRealTime.width, canvasLayoutRealTime.height);
  }

  /**
   * clear canvas
   * @param template
   * @param canvasDisplayId
   */
  public clearCanvasAreas(template: Template, canvasDisplayId) {
    if (!template) {
      return;
    }
    let areas = Helper.getAllAreaTemplate(template);
    let pictureAreas = areas
      .filter(area => !area.checkTypeTextArea() && !area.isFix && area.getArea().attribute == LinkDataPictureEnum.SIGNAGE_CHANNEL)
      .map(areaData => areaData.getArea() as PictureArea);
    pictureAreas.forEach(pictureArea => {
      if (pictureArea?.media && pictureArea.media instanceof Video) {
        this.stopVideo(pictureArea, canvasDisplayId);
      }
      this.unsubscribedSubjectArea(pictureArea, canvasDisplayId);
      this.clearCanvas(pictureArea?.canvas);
    });
  }

  /**
   * unsubscribed Subject in Area
   * @param area area unsubscribe
   * @param canvasDisplayId
   */
  private unsubscribedSubjectArea(area: Area, canvasDisplayId): void {
    if (area[`pausePreviewSubject${canvasDisplayId}`]) {
      area[`pausePreviewSubject${canvasDisplayId}`].unsubscribe();
    }
    if (area[`clearPreviewSubject${canvasDisplayId}`]) {
      area[`clearPreviewSubject${canvasDisplayId}`].unsubscribe();
    }
    if (area[`startPreviewSubject${canvasDisplayId}`]) {
      area[`startPreviewSubject${canvasDisplayId}`].unsubscribe();
    }
  }

  /**
   * calculate position scroll Sideway
   * @param areaText TextArea
   */
  private calcPositionScrollSideway(areaText: TextArea): void {
    switch (areaText.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        areaText.posXScroll -= areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.RIGHT:
        areaText.posXScroll += areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.UP:
        areaText.posYScroll -= areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.DOWN:
        areaText.posYScroll += areaText.scrollSpeed / 20;
        break;
      default:
        break;
    }
  }

  /**
   * calculate position scroll Vertical
   * @param areaText TextArea
   */
  private calcPositionScrollVertical(areaText: TextArea): void {
    switch (areaText.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        areaText.posXScroll -= areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.RIGHT:
        areaText.posXScroll += areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.UP:
        areaText.posYScroll -= areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.DOWN:
        areaText.posYScroll += areaText.scrollSpeed / 20;
        break;
      default:
        break;
    }
  }

  /**
   * calculate position scroll Horizontal
   * @param areaText TextArea
   */
  private calcPositionScrollHorizontal(areaText: TextArea): void {
    switch (areaText.scrollDirection) {
      case ScrollDirectionsEnum.LEFT:
        areaText.posXScroll -= areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.RIGHT:
        areaText.posXScroll += areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.UP:
        areaText.posYScroll -= areaText.scrollSpeed / 20;
        break;
      case ScrollDirectionsEnum.DOWN:
        areaText.posYScroll += areaText.scrollSpeed / 20;
        break;
      default:
        break;
    }
  }

  /**
   * draw areas signage channel
   * @param screenCanvasIdEnum
   * @param renderer
   * @param canvasDisplayId
   * @param template
   */
  public drawAreasSignageChannel(
    template: Template,
    renderer: Renderer2,
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum
  ): void {
    if (!this.mediaSetting) {
      return;
    }
    let areas = Helper.getAllAreaTemplate(template);
    let pictureAreas = areas
      .filter(area => !area.checkTypeTextArea() && !area.isFix && area.getArea().attribute == LinkDataPictureEnum.SIGNAGE_CHANNEL)
      .map(areaData => areaData.getArea() as PictureArea);
    Promise.all(
      pictureAreas.map(async pictureArea => {
        await this.drawSignageChannel(pictureArea, this.mediaSetting, renderer, canvasDisplayId, screenCanvasIdEnum, template);
      })
    );
  }

  /**
   * draw signage channel
   * @param screenCanvasIdEnum
   * @param media
   * @param renderer
   * @param canvasDisplayId
   * @param template
   * @param pictureArea
   */
  public async drawSignageChannel(
    pictureArea: PictureArea,
    media: Media,
    renderer: Renderer2,
    canvasDisplayId: any,
    screenCanvasIdEnum: ScreenCanvasIdEnum,
    template: Template
  ) {
    if (media instanceof Image) {
      // draw image
      pictureArea.media = media;
      await this.drawAreaPicture(pictureArea, canvasDisplayId, screenCanvasIdEnum, template);
    } else if (media instanceof Video) {
      // draw video
      pictureArea.media = media;
      await this.drawVideoFixPicture(pictureArea, renderer, canvasDisplayId, screenCanvasIdEnum);
    } else if (media instanceof Sequence) {
      // Code here
    }
  }

  /**
   * returns true if url image exists
   * @param imageUrl
   */
  checkImageExistence(imageUrl: string): Promise<boolean> {
    return new Promise(resolve => {
      const imgElement = document.createElement('img');
      imgElement.src = imageUrl;

      imgElement.addEventListener('load', () => {
        resolve(true);
      });

      imgElement.addEventListener('error', () => {
        resolve(false);
      });
    });
  }
}

/**
 * Class Timeout
 */
class TimeOut {
  time: any;
  linkReferenceData: number;
  areaId: Number;
  constructor(time: any, linkReferenceData: number, areaId: Number) {
    this.time = time;
    this.linkReferenceData = linkReferenceData;
    this.areaId = areaId;
  }
}

/**
 * class MediaIndexWord
 */
class MediaIndexWord {
  /**
   * unique key for each draw area
   */
  key: any;
  /**
   * img for each draw area
   */
  img: any;

  constructor(key: any, img: any) {
    this.key = key;
    this.img = img;
  }
}
