import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendDataService {
  constructor() {}
  private dataSource = new Subject<{ object: any }>();
  currentData = this.dataSource.asObservable();

  /**
   * send data to subscriber
   * @param data tuple containing key and data
   */
  sendData(data: { object: any }) {
    this.dataSource.next(data);
  }
}
