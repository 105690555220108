import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant, SettingType } from 'app/config/constants';
import { SettingSignageChannel } from 'app/model/entity/simple/setting-signage-channel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for setting signage channel action
 */
export class SettingSignageChannelService {
  /**
   * URL for setting signage channel API
   */
  private readonly settingSignageChannelUrl = Constant.BACKEND_URL + Constant.DSC_SERVICE_URL + 'api/simple-signage/setting';

  constructor(private http: HttpClient) {}

  /**
   * Be used by SIGNAGE-DISPLAY-COMPONENT
   * save data setting
   *
   * @param settingSignageChannel
   */
  saveDataSetting(settingSignageChannel: SettingSignageChannel): Observable<SettingSignageChannel> {
    return this.http.post<SettingSignageChannel>(`${this.settingSignageChannelUrl}/save`, settingSignageChannel);
  }

  /**
   * get setting signage channel by type(Timetable or Signage)
   *
   * @param type
   */
  public getSettingSignageChannelByType(type: SettingType): Observable<SettingSignageChannel> {
    const params = new HttpParams().set('type', `${type}`);
    return this.http.get<SettingSignageChannel>(`${this.settingSignageChannelUrl}/get-setting-signage-channel-by-type`, {
      params
    });
  }
}
