import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { RouteDestination } from 'app/model/entity/destination/route-destination';
import { Route } from 'app/model/entity/route';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * EDS route list service
 */
export class EdsRouteListService {
  /**
   * URL for common eds route list
   */
  private edsRouteListUrl = Constant.BACKEND_URL + Constant.EDS_SERVICE_URL + 'api/eds-route-list';

  constructor(private http: HttpClient) {}

  /**
   * Be used SIMPLE-SIGNAGE-EDITOR
   * Get all data
   *
   * @returns
   */
  public getAllData(): Observable<Array<Route>> {
    return this.http.get<Array<Route>>(`${this.edsRouteListUrl}/route-lists`);
  }

  /**
   * Be used DESTINATION-SIGN-EDITOR
   * Get all data route list
   *
   * @returns Array<RouteDestination>
   */
  public getAllDataRouteList(): Observable<Array<RouteDestination>> {
    return this.http.get<Array<RouteDestination>>(`${this.edsRouteListUrl}/get-all-data-route-list`);
  }

  /**
   * Be used DESTINATION-SIGN-EDITOR
   * Reflect data from Route List Editor
   *
   * @param isReplaceData
   * @returns
   */
  public reflectDataFromRouteListEditor(isReplaceData: boolean): Observable<Array<RouteDestination>> {
    const params = new HttpParams().set('isReplaceData', `${isReplaceData}`);
    return this.http.get<Array<RouteDestination>>(`${this.edsRouteListUrl}/reflect-data-from-route-list-editor`, { params });
  }

  /**
   * Be used DESTINATION-SIGN-EDITOR
   * Upload route list from client file
   *
   * @param isReplaceData
   * @param file (from client)
   * @returns
   */
  public uploadRouteListFromClientFile(isReplaceData: boolean, file: File): Observable<Array<any>> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('isReplaceData', `${isReplaceData}`);
    return this.http.post<Array<any>>(`${this.edsRouteListUrl}/upload-route-list-from-file`, formData);
  }

  /**
   * Be used DESTINATION-SIGN-EDITOR
   * Save eds route list
   *
   * @param routeToSave
   * @returns
   */
  public saveEdsRouteList(routeToSave: any): Observable<any> {
    return this.http.post(`${this.edsRouteListUrl}/save-eds-route-list`, routeToSave);
  }

  /**
   * Be used DESTINATION-SIGN-EDITOR
   * Save eds routes
   *
   * @param routesToSave
   * @returns
   */
  public saveEdsRoutes(routesToSave: any[]): Observable<any> {
    return this.http.post(`${this.edsRouteListUrl}/save-eds-routes`, routesToSave);
  }

  /**
   * Be used DESTINATION-SIGN-EDITOR
   * Write route list to file
   *
   * @param edsRoutesToExport
   * @returns
   */
  public writeRouteListToFile(edsRoutesToExport: Array<RouteDestination>): Observable<any> {
    return this.http.post(`${this.edsRouteListUrl}/export-eds-route-list`, edsRoutesToExport, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  /**
   * Be used DESTINATION-SIGN-EDITOR
   * Delete eds route checked
   *
   * @param edsRouteIds
   * @returns
   */
  public deleteEdsRouteChecked(edsRouteIds: Array<Number>): Observable<any> {
    return this.http.post(`${this.edsRouteListUrl}/delete-eds-routes`, edsRouteIds);
  }
}
