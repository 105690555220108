import { HttpClient, HttpParams } from '@angular/common/http';
import { TemplateGroup } from '../model/entity/template-group';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';

@Injectable({
  providedIn: 'root'
})
/**
 * template group service
 */
export class TemplateGroupService {
  private templateGroupUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/template-groups';
  constructor(private http: HttpClient) {}

  /**
   * Be used LCD-LAYOUT-EDITOR-COMPONENT
   * get all template groups
   */
  getAllTemplateGroup(): Observable<TemplateGroup[]> {
    return this.http.get<TemplateGroup[]>(`${this.templateGroupUrl}/${Constant.LCD_LAYOUT_EDITOR_COMPONENT_URL}/get-template-groups`);
  }

  /**
   * Be used LCD-LAYOUT-EDITOR-COMPONENT
   * add new template group
   *
   * @param templateGroup
   */
  addTemplateGroup(templateGroup: TemplateGroup): Observable<TemplateGroup> {
    return this.http.post<TemplateGroup>(`${this.templateGroupUrl}/${Constant.LCD_LAYOUT_EDITOR_COMPONENT_URL}/add`, templateGroup);
  }

  /**
   * Be used LCD-LAYOUT-EDITOR-COMPONENT
   * update template group
   *
   * @param templateGroup
   */
  updateTemplateGroup(templateGroup: TemplateGroup): Observable<TemplateGroup> {
    return this.http.put<TemplateGroup>(`${this.templateGroupUrl}/${Constant.LCD_LAYOUT_EDITOR_COMPONENT_URL}/edit`, templateGroup);
  }

  /**
   * Be used LCD-LAYOUT-EDITOR-COMPONENT
   * delete template group
   *
   * @param templateGroup
   */
  deleteTemplateGroup(templateGroup: TemplateGroup) {
    const params = new HttpParams().set('templateGroupId', templateGroup.id + '');
    return this.http.delete(`${this.templateGroupUrl}/${Constant.LCD_LAYOUT_EDITOR_COMPONENT_URL}/delete`, { params });
  }
}
