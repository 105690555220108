import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  constructor(private http: HttpClient) {}

  /**
   * URL for file manual
   */
  private fileManulaUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api';

  /**
   * get url manual by software name
   * @param softwareName
   * @returns
   */
  public getUrlManualBySoftware(softwareName: string): Observable<any> {
    const params = new HttpParams().set('softwareName', softwareName);
    return this.http.get<any>(`${this.fileManulaUrl}/get-url-manual-by-software-name`, { params });
  }
}
