import { NgModule } from '@angular/core';
import { GatewaySharedLibsModule } from './shared-libs.module';
import { LoginModalComponent } from './login/login.component';
import { HasAnyAuthorityDirective } from './auth/has-any-authority.directive';
import { FindLanguageFromKeyPipe } from './language/find-language-from-key-pipe';

@NgModule({
  imports: [GatewaySharedLibsModule],
  declarations: [FindLanguageFromKeyPipe, LoginModalComponent, HasAnyAuthorityDirective],
  entryComponents: [LoginModalComponent],
  exports: [FindLanguageFromKeyPipe, GatewaySharedLibsModule, LoginModalComponent, HasAnyAuthorityDirective]
})
export class GatewaySharedModule {}
