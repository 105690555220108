import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BusStop } from '../model/entity/bus-stop';
import { Constant } from 'app/config/constants';
import { Route } from 'app/model/entity/route';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for bus stop action
 */
export class BusStopService {
  /**
   * URL for bus stop API
   */
  busStopUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/bus-stops';

  constructor(private http: HttpClient) {}

  /**
   * Be used by MASTER-LIST-EDITOR-COMPONENT
   * get all bus stops
   *
   * @param pageNumber
   */
  getBusStopsForMasterListEditorComponent(pageNumber: number): Observable<BusStop[]> {
    const params = new HttpParams().set('pageNumber', pageNumber + '');
    return this.http.get<BusStop[]>(`${this.busStopUrl}/${Constant.MASTER_LIST_EDITOR_COMPONENT_URL}/get-bus-stops`, { params });
  }

  /**
   * Be used by MASTER-LIST-EDITOR-COMPONENT
   * add a new bus stop
   *
   * @param {BusStop} busStop new bus stop
   */
  addBusStop(busStop: BusStop): Observable<BusStop> {
    return this.http.post<BusStop>(`${this.busStopUrl}/${Constant.MASTER_LIST_EDITOR_COMPONENT_URL}/add`, busStop);
  }

  /**
   * Be used by MASTER-LIST-EDITOR-COMPONENT
   * update a bus stop
   *
   * @param {BusStop} busStop selected bus stop
   */
  updateBusStop(busStop: BusStop): Observable<BusStop> {
    return this.http.put<BusStop>(`${this.busStopUrl}/${Constant.MASTER_LIST_EDITOR_COMPONENT_URL}/edit`, busStop);
  }

  /**
   * Be used by MASTER-LIST-EDITOR-COMPONENT
   * delete bus stops
   *
   * @param {BusStop} busStop selected bus stop
   */
  deleteBusStops(busStopIds: Number[]) {
    return this.http.post(`${this.busStopUrl}/${Constant.MASTER_LIST_EDITOR_COMPONENT_URL}/delete-bus-stops`, busStopIds);
  }

  /**
   * Be used by MASTER-LIST-EDITOR-COMPONENT
   * count all bus stops
   */
  countAllBusStop(): Observable<number> {
    return this.http.get<number>(`${this.busStopUrl}/${Constant.MASTER_LIST_EDITOR_COMPONENT_URL}/count-all-bus-stops`);
  }

  /**
   * Be used by MASTER-LIST-EDITOR-COMPONENT
   * get bus stop by id
   *
   * @param id id bus stop
   */
  getBusStopById(id: Number): Observable<BusStop> {
    const params = new HttpParams().set('busStopId', id + '');
    return this.http.get<BusStop>(`${this.busStopUrl}/${Constant.MASTER_LIST_EDITOR_COMPONENT_URL}/get-bus-stop`, { params });
  }

  /**
   * Be used by MASTER-LIST-EDITOR-COMPONENT
   * check exist bus stop
   *
   * @param id
   * @param no
   * @param suffix
   */
  checkExist(no: string, suffix: string, id: Number): Observable<BusStop> {
    const params = new HttpParams()
      .set('busStopNo', no)
      .set('suffix', suffix)
      .set('id', id + '');
    return this.http.get<BusStop>(`${this.busStopUrl}/${Constant.MASTER_LIST_EDITOR_COMPONENT_URL}/check-exist`, { params });
  }

  /**
   * Be used by ROUTE-LIST-EDITOR-COMPONENT
   * get bus stops
   *
   * @param routeId id's route
   */
  getBusStopsForRouteListEditorComponentByRouteId(routeId: Number): Observable<BusStop[]> {
    const params = new HttpParams().set('routeId', routeId + '');
    return this.http.get<BusStop[]>(`${this.busStopUrl}/${Constant.ROUTE_LIST_EDITOR_COMPONENT_URL}/get-bus-stops-by-route-id`, {
      params
    });
  }

  /**
   * Be used by STATION-DISPLAY-EDITOR-COMPONENT
   * get bus stops
   */
  getBusStopsForStationDisplayEditorComponent(): Observable<BusStop[]> {
    return this.http.get<BusStop[]>(`${this.busStopUrl}/${Constant.STATION_DISPLAY_EDITOR_COMPONENT_URL}/get-bus-stops`);
  }

  /**
   * Be used by STATION-DISPLAY-EDITOR-COMPONENT
   *
   * update template for a bus stop
   * @param {BusStop} busStop checked bus stop
   */
  updateTemplateForBusStop(busStop: BusStop): Observable<BusStop> {
    return this.http.post<BusStop>(
      `${this.busStopUrl}/${Constant.STATION_DISPLAY_EDITOR_COMPONENT_URL}/update-template-for-a-bus-stop`,
      busStop
    );
  }

  /**
   * Be used by STATION-DISPLAY-EDITOR-COMPONENT
   * update templates for many bus stops
   *
   * @param {BusStop} busStop checked bus stops
   */
  updateTemplatesForBusStops(busStops: BusStop[]): Observable<BusStop[]> {
    return this.http.post<BusStop[]>(
      `${this.busStopUrl}/${Constant.STATION_DISPLAY_EDITOR_COMPONENT_URL}/update-template-for-bus-stops`,
      busStops
    );
  }

  /**
   * Be used by BUS-INFO-DISPLAY-EDITOR-COMPONENT
   * get bus stops by route id
   *
   * @param routeId
   */
  getBusStopsForBusInfoDisplayComponentByRouteId(routeId: Number): Observable<BusStop[]> {
    const params = new HttpParams().set('routeId', routeId + '');
    return this.http.get<BusStop[]>(`${this.busStopUrl}/${Constant.BUS_INFO_DISPLAY_COMPONENT_URL}/get-bus-stops-by-route-id`, {
      params
    });
  }

  /**
   * Be used by BUS-INFO-DISPLAY-EDITOR-COMPONENT
   * update events
   *
   * @param routeId
   * @param busStop
   */
  updateEvents(route: Route) {
    return this.http.post(`${this.busStopUrl}/update-events`, route);
  }

  /**
   * Be used by PROJECT-MANAGER-COMPONENT
   * get bus stops by project id
   *
   * @param projectId id's project
   */
  getBusStopsForProjectManagerComponentByProjectId(projectId: Number): Observable<BusStop[]> {
    const params = new HttpParams().set('projectId', projectId + '');
    return this.http.get<BusStop[]>(`${this.busStopUrl}/${Constant.PROJECT_MANAGER_COMPONENT_URL}/get-bus-stops-by-project-id`, {
      params
    });
  }
}
