import { TextHighlightingSetting } from './../model/entity/text-highlighting-setting';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';

@Injectable({
  providedIn: 'root'
})
export class TextHighlightSettingService {
  /**
   * timetable url
   */
  private scheduleMergeUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/schedule-merge';

  constructor(private http: HttpClient) {}

  /**
   * get all text highlight setting
   * @returns
   */
  public getAllTextHighLightSetting(): Observable<any> {
    return this.http.get<any>(`${this.scheduleMergeUrl}/get-all-text-highlight`);
  }
}
