import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constant } from 'app/config/constants';
import { ExternalContent } from 'app/model/entity/external-content';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for external content
 */
export class ExternalContentService {
  /**
   * URL of external content API
   */
  externalContentUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/external-content';

  constructor(private http: HttpClient) {}

  /**
   * Be used by Change template
   * get external content
   *
   * @returns {Observable<ExternalContent[]>} list of all external content
   */
  getAllExternalContent(): Observable<ExternalContent[]> {
    return this.http.get<ExternalContent[]>(`${this.externalContentUrl}/get-all-external-content`);
  }
}
