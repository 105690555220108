import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { TimetableUpdateTiming } from 'app/model/entity/timetable-update-timing';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimetableUpdateTimingService {
  /**
   * URL for TIMETABLE-UPDATE-TIMING API
   */
  timetableUpdateTimingUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/timetable-update-timing';
  constructor(private http: HttpClient) {}

  /**
   * Be used by TIMETABLE-EDITOR
   * get timetable update timing
   */
  getTimetableUpdateTiming(): Observable<TimetableUpdateTiming> {
    return this.http.get<TimetableUpdateTiming>(`${this.timetableUpdateTimingUrl}/get-timetable-update-timing`);
  }

  /**
   * Be used by TIMETABLE-EDITOR
   * save timetable update timing
   */
  save(timetableUpdateTiming: TimetableUpdateTiming): Observable<TimetableUpdateTiming> {
    return this.http.post<TimetableUpdateTiming>(`${this.timetableUpdateTimingUrl}/save`, timetableUpdateTiming);
  }
}
