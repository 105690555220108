import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constant } from 'app/config/constants';
import { Observable } from 'rxjs';
import { AppState } from 'app/store/app.state';

@Injectable({
  providedIn: 'root'
})
export class DataConfigService {
  /**
   * URL for get data config backend
   */
  dataConfigUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/';

  constructor(private http: HttpClient) {}

  /**
   * Be used to get customer url api from backend config
   */
  getCustomerApiUrl(): Observable<any> {
    return this.http.get<any>(`${this.dataConfigUrl}get-customer-api-url`);
  }
}
