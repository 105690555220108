import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MunicipalService {
  private readonly municipalUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/municipal';

  constructor(private http: HttpClient) {}

  /**
   * get municipal by code
   *
   * @param municipalCode
   */
  getMunicipalByCode(municipalCode: string): Observable<any> {
    const params = new HttpParams().set('municipalCode', municipalCode);
    return this.http.get(`${this.municipalUrl}/get-municipal-by-code`, { params });
  }
}
