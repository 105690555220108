import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constant } from 'app/config/constants';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for common table action
 */
export class CommonTableService {
  /**
   * URL for common table
   */
  commonTableUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/common-table';

  constructor(private http: HttpClient) {}

  /**
   * get record by key
   * @returns common table value
   */
  getValueCommonTableByKey(key: string): Observable<any> {
    const params = new HttpParams().set('key', key + '');
    return this.http.get<any>(this.commonTableUrl, { params });
  }

  /**
   * save record
   * @param commonTableRecord
   * @returns
   */
  save(commonTableRecord: any): Observable<any> {
    return this.http.post<any>(this.commonTableUrl, commonTableRecord);
  }
}
