import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { Program } from 'app/model/entity/playlist';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DailyScheduleProgramService {
  programUrl = Constant.BACKEND_URL + Constant.DSC_SERVICE_URL + 'api/programs';

  constructor(private http: HttpClient) {}

  /**
   * Be used by DIGITAL-SIGNAGE-DISPLAY-COMPONENT
   * get programs
   *
   * @param dailyScheduleId
   */
  getProgramsByDailyScheduleId(dailyScheduleId: Number): Observable<Program[]> {
    const params = new HttpParams().set('dailyScheduleId', dailyScheduleId + '');
    return this.http.get<Program[]>(
      `${this.programUrl}/${Constant.DIGITAL_SIGNAGE_CHANNEL_EDITOR_COMPONENT_URL}/get-programs-by-daily-schedule-id`,
      { params }
    );
  }
}
